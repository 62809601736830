import React, { useEffect, useState } from "react";
import { Avatar, Badge, Card, Col, Row, Space, Table } from "antd";
import ReactApexChart from "react-apexcharts";
import DashboardService from "../../../services/DashboardService";
import { getProfilePhotoPath } from "../../../utils";
import { PhoneOutlined } from "@ant-design/icons";

interface CallCardProps {
  title: string;
  dateRange: any;
  roleName: string;
}

const CallCard: React.FC<CallCardProps> = ({ title, dateRange, roleName }) => {
  // eslint-disable-next-line
  const [phoneCallSummary, setPhoneCallSummary] = useState<any>();
  const [totalCalls, setTotalCalls] = useState<number>(0);
  const [reached, setReached] = useState<number>(0);
  const [notReached, setNotReached] = useState<number>(0);
  const [reachedRatio, setReachedRatio] = useState<number>(0);
  // eslint-disable-next-line
  const [phoneCallSummaryMe, setPhoneCallSummaryMe] = useState<any>();
  const [totalCallsMe, setTotalCallsMe] = useState<number>(0);
  const [reachedMe, setReachedMe] = useState<number>(0);
  const [notReachedMe, setNotReachedMe] = useState<number>(0);
  const [reachedRatioMe, setReachedRatioMe] = useState<number>(0);
  const [allTeamUsers, setAllTeamUsers] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  // eslint-disable-next-line
  const [options, setOptions] = useState<any>({
    chart: {
      type: "donut",
    },
    stroke: {
      lineCap: "round",
    },
    plotOptions: {
      radialBar: {
        track: {
          background: "#f4f4f4",
        },
        dataLabels: {
          name: {
            show: true,
            fontSize: "15px",
          },
          value: {
            fontWeight: 600,
          },
        },
      },
    },
    labels: ["Answered"],
    colors: ["#EEC73B"],
    tooltip: {
      enabled: true,
      theme: "dark",
      y: {
        formatter: function (val: number) {
          return val.toFixed(2) + "%";
        },
      },
    },
    responsive: [
      {
        breakpoint: 992,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "12px",
                },
                value: {
                  fontSize: "12px",
                },
              },
            },
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "15px",
                },
                value: {
                  fontSize: "15px",
                },
              },
            },
          },
        },
      },
      // {
      //   breakpoint: 576,
      //   options: {
      //     plotOptions: {
      //       radialBar: {
      //         dataLabels: {
      //           name: {
      //             fontSize: "12px",
      //           },
      //           value: {
      //             fontSize: "12px",
      //           },
      //         },
      //       },
      //     },
      //   },
      // },
      {
        breakpoint: 480,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "12px",
                },
                value: {
                  fontSize: "12px",
                },
              },
            },
          },
        },
      },
      {
        breakpoint: 380,
        options: {
          chart: {
            width: 100,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchPhoneCallSummary = async (formattedDateRange: string) => {
    try {
      if (roleName === "HI5_ACM_MANAGER") {
        const summaryResponse = await DashboardService.phoneCallSummaryDashboard(
          `${formattedDateRange}&roleName=HI5_ACM_MEMBER`
        );
        const { totalCalls, missed, answered, userList } = summaryResponse.data;

        const filteredUsers = userList.filter(
          (user: any) =>
            user.user.username !== "duygu.karakilic.hi5acm.crm@oneamz.com" &&
            user.user.username !== "murat.yakar.hi5acm.crm@oneamz.com" &&
            user.totalCalls > 0
        );

        setPhoneCallSummary(filteredUsers);
        setAllTeamUsers(filteredUsers);
        setTotalCalls(totalCalls || 0);
        setNotReached(missed || 0);
        setReached(answered || 0);
        const ratio = ((answered / totalCalls) * 100).toFixed(2);
        setReachedRatio(isNaN(parseFloat(ratio)) ? 0 : parseFloat(ratio));
      } else {
        const summaryMeResponse = await DashboardService.phoneCallSummaryMe(
          `${formattedDateRange}&roleName=HI5_ACM_MEMBER`
        );
        const { totalCalls, missed, answered } = summaryMeResponse.data;
        setPhoneCallSummaryMe(summaryMeResponse.data);
        setTotalCallsMe(totalCalls || 0);
        setNotReachedMe(missed || 0);
        setReachedMe(answered || 0);
        const ratioMe = ((answered / totalCalls) * 100).toFixed(2);
        setReachedRatioMe(isNaN(parseFloat(ratioMe)) ? 0 : parseFloat(ratioMe));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dateRange.length > 0) {
      const [startDate, endDate] = dateRange;
      if (startDate && endDate) {
        const formattedDateRange = `?startDate=${startDate}&endDate=${endDate}`;
        setLoading(true);
        fetchPhoneCallSummary(formattedDateRange);
      }
    }
    // eslint-disable-next-line
  }, [dateRange, roleName]);

  const columns = [
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Hi5 ACM Member
        </p>
      ),
      dataIndex: "member",
      key: "member",
      ellipsis: true,
      width: 220,
      sorter: (a: any, b: any) =>
        a?.user?.firstName?.localeCompare(b?.user?.firstName),
      defaultSortOrder: "ascend" as "ascend",
      render: (text: string, record: any) => (
        <Row
          align={"middle"}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            padding: "0px 0px",
          }}
        >
          <Avatar
            style={{ marginRight: 5 }}
            size={30}
            src={getProfilePhotoPath(record?.user?.profilePhoto || 1)}
          />
          <b
            style={{
              margin: "0px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {record?.user?.firstName} {record?.user?.lastName}
          </b>
        </Row>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Calls
        </p>
      ),
      dataIndex: "totalCalls",
      key: "totalCalls",
      width: 70,
      align: "center" as const,
      sorter: (a: any, b: any) => a?.totalCalls - b?.totalCalls,
      defaultSortOrder: "descend" as const,
      render: (text: string) => (
        <p
          style={{
            margin: "0px",
            padding: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          <PhoneOutlined style={{ marginRight: 5 }} />
          {text ? text : 0}
        </p>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Answered
        </p>
      ),
      dataIndex: "answered",
      key: "answered",
      width: 90,
      align: "center" as const,
      sorter: (a: any, b: any) => a?.answered - b?.answered,
      render: (text: string) => (
        <p
          style={{
            margin: "0px",
            padding: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          <PhoneOutlined style={{ marginRight: 5, color: "#3f8600" }} />
          {text ? text : 0}
        </p>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Missed
        </p>
      ),
      dataIndex: "missed",
      key: "missed",
      width: 70,
      align: "center" as const,
      sorter: (a: any, b: any) => a?.missed - b?.missed,
      render: (text: string) => (
        <p
          style={{
            margin: "0px",
            padding: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          <PhoneOutlined style={{ marginRight: 5, color: "#cf1322" }} />
          {text ? text : 0}
        </p>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Ratio
        </p>
      ),
      dataIndex: "answeredCallRatio",
      key: "answeredCallRatio",
      width: 70,
      align: "center" as const,
      sorter: (a: any, b: any) => a?.answeredCallRatio - b?.answeredCallRatio,
      render: (text: string) => (
        <p
          style={{
            margin: "0px",
            padding: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          {text ? text : 0}%
        </p>
      ),
    },
  ];

  return (
    <Card bordered={false} className="info-card" loading={loading}>
      <p
        className="title"
        style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
      >
        {title}
      </p>
      <Row gutter={[12, 12]} align="middle" justify="space-between">
        <Col xs={24} sm={13} md={13}>
          <ReactApexChart
            options={options}
            series={
              roleName === "HI5_ACM_MANAGER" ? [reachedRatio] : [reachedRatioMe]
            }
            type="radialBar"
            height={200}
          />
        </Col>
        <Col xs={24} sm={11} md={11}>
          <Row
            align="middle"
            justify="center"
            style={{
              margin:
                windowWidth < 480
                  ? "-30px 0px 10px 0px"
                  : windowWidth < 576
                  ? "-20px 0px 10px 0px"
                  : "0px 0px 0px 0px",
            }}
          >
            <Space direction="vertical" size={0}>
              <Row gutter={[3, 3]}>
                <Col
                  xs={12}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "left" : "left",
                  }}
                >
                  <Badge
                    color="#f4f4f4"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Total:{" "}
                        <b>
                          {roleName === "HI5_ACM_MANAGER"
                            ? totalCalls
                            : totalCallsMe}
                        </b>{" "}
                        calls
                      </span>
                    }
                  />
                </Col>
                <Col
                  xs={12}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "right" : "left",
                  }}
                >
                  <Badge
                    color="#EEC73B"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Answered:{" "}
                        <b>
                          {roleName === "HI5_ACM_MANAGER" ? reached : reachedMe}
                        </b>
                      </span>
                    }
                  />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "center" : "left",
                  }}
                >
                  <Badge
                    color="#0086FF"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Missed:{" "}
                        <b>
                          {roleName === "HI5_ACM_MANAGER"
                            ? notReached
                            : notReachedMe}
                        </b>
                      </span>
                    }
                  />
                </Col>
              </Row>
            </Space>
          </Row>
        </Col>
      </Row>
      {roleName === "HI5_ACM_MANAGER" && (
        <Row>
          <Col span={24}>
            <Table
              rowKey={(record) => record?.id}
              columns={columns}
              dataSource={allTeamUsers}
              pagination={false}
              scroll={{ x: "100%", y: 185 }}
              size="small"
              className="dashboard-table"
            />
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default CallCard;
