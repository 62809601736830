import React, { useEffect, useState } from "react";
import { Avatar, Card, Col, Row, Table } from "antd";
import ReactApexChart from "react-apexcharts";
import DashboardService from "../../../services/DashboardService";
import { getProfilePhotoPath } from "../../../utils";

interface TotalCustomersCardProps {
  title: string;
  dateRange: any;
  roleName: string;
}

const TotalCustomersCard: React.FC<TotalCustomersCardProps> = ({
  title,
  dateRange,
  roleName,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const userId = localStorage.getItem("id");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const chartOptions = {
    chart: { type: "bar" as "bar", toolbar: { show: false } },
    plotOptions: {
      bar: { horizontal: true, dataLabels: { position: "top" } },
    },
    dataLabels: {
      enabled: true,
      offsetX: -10,
      offsetY: 0,
      style: {
        fontSize: "10px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories:
        roleName === "ACCOUNT_MANAGER_MANAGER"
          ? data.map((item: any) => item.member)
          : ["You"],
      labels: {
        rotate: -45,
      },
    },
    yaxis: {
      max: Math.max(...data.map((item: any) => item.activeCustomerCount)) + 20,
      tickAmount: 5,
    },
    fill: {
      colors: ["#008FFB", "#EEC73B"],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      y: {
        formatter: function (val: number) {
          return val.toFixed(0) + " Customers";
        },
      },
    },
  };

  const chartSeries =
    roleName === "ACCOUNT_MANAGER_MANAGER"
      ? [
          {
            name: "Active Customers",
            data: data.map((item: any) => item.activeCustomerCount),
          },
          {
            name: "Latest Customers",
            data: data.map((item: any) => item.latestCustomerCount),
          },
        ]
      : [
          {
            name: "Active Customers",
            data: [data.length > 0 ? data[0].activeCustomerCount : 0],
          },
          {
            name: "Latest Customers",
            data: [data.length > 0 ? data[0].latestCustomerCount : 0],
          },
        ];

  const fetchTotalCustomers = async (formattedDateRange: string) => {
    try {
      let responseData: any;
      if (roleName === "ACCOUNT_MANAGER_MANAGER") {
        const managerResponse =
          await DashboardService.getTotalCustomersAcmForManager(
            formattedDateRange
          );
        responseData = managerResponse.data.customerSummaries;

        // Filter out specific users
        const formattedData = Object.entries(responseData)
          .map(([member, summary]: [string, any]) => ({
            member,
            activeCustomerCount: summary.activeCustomerCount,
            latestCustomerCount: summary.latestCustomerCount,
          }))
          .filter(
            (item: any) =>
              item.member !== "Duygu Karakilic" &&
              item.member !== "Murat Yakar"
          );

        setData(formattedData);
      } else {
        const memberResponse =
          await DashboardService.getTotalCustomersAcmForMember(
            Number(userId),
            formattedDateRange
          );
        responseData = memberResponse.data.customerSummaries["ACCOUNT_MANAGER"];
        setData([
          {
            activeCustomerCount: responseData.activeCustomerCount,
            latestCustomerCount: responseData.latestCustomerCount,
          },
        ]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dateRange.length > 0) {
      const formattedDateRange = `?startDate=${dateRange[0]}&endDate=${dateRange[1]}`;
      setLoading(true);
      fetchTotalCustomers(formattedDateRange);
    }
    // eslint-disable-next-line
  }, [dateRange, roleName]);

  const columns = [
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Account Manager
        </p>
      ),
      dataIndex: "member",
      key: "member",
      ellipsis: true,
      width: 200,
      defaultSortOrder: "ascend" as "ascend",
      sorter: (a: any, b: any) => a.member.localeCompare(b.member),
      render: (text: string) => (
        <Row
          align={"middle"}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            padding: "0px 0px",
          }}
        >
          <Avatar
            style={{ marginRight: 5 }}
            size={30}
            src={getProfilePhotoPath(1)}
          />
          <b
            style={{
              margin: "0px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {text}
          </b>
        </Row>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Active Customers
        </p>
      ),
      dataIndex: "activeCustomerCount",
      key: "activeCustomerCount",
      width: 150,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) => a.activeCustomerCount - b.activeCustomerCount,
      render: (text: string) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px 10px 0px 0px",
              whiteSpace: "nowrap",
              width: "12px",
              fontWeight: "bold",
              textAlign: "center",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {text || 0}
          </p>
        </div>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Latest Customers
        </p>
      ),
      dataIndex: "latestCustomerCount",
      key: "latestCustomerCount",
      width: 150,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) => a.latestCustomerCount - b.latestCustomerCount,
      render: (text: string) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px 10px 0px 0px",
              whiteSpace: "nowrap",
              width: "12px",
              fontWeight: "bold",
              textAlign: "center",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {text || 0}
          </p>
        </div>
      ),
    },
  ];

  return (
    <Card bordered={false} className="info-card" loading={loading}>
      <p
        className="title"
        style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
      >
        {title}
      </p>
      <Row gutter={[12, 12]} align="middle" justify="space-between">
        <Col span={24}>
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={190}
          />
        </Col>
      </Row>
      {roleName === "ACCOUNT_MANAGER_MANAGER" && (
        <Row>
          <Col span={24}>
            <Table
              rowKey="member"
              columns={columns}
              dataSource={data}
              pagination={false}
              scroll={{ x: "100%", y: 185 }}
              size="small"
              className="dashboard-table"
            />
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default TotalCustomersCard;
