import { useEffect, useState } from "react";
import CustomerAssignmentHistoryService from "../services/CustomerAssignmentHistoryService";
import { ColumnsType } from "antd/es/table";
import { CopyClipboard } from "../components/General/CopyClipboard";
import { StartCall } from "../components/General/StartCall";
import { formatDateString, openNotification } from "../utils";
import { Card, Pagination, PaginationProps, Row, Select } from "antd";
import { Table } from "antd/lib";
import StatusService from "../services/StatusService";
import { useLocation } from "react-router-dom";
import { FilterField } from "../components/DropoutFeedbackFilter/FilterField";

const DropoutFeedbackList = () => {
  const [data, setData] = useState<any>([]);
  const [totalCustomerCount, setTotalCustomerCount] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [reasonForDropoutStatusOptions, setReasonForDropoutStatusOptions] =
    useState<any[]>([]);
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 20;
  const lastSortField = location.state?.sortField ?? "assignmentDate";
  const lastSortOrder = location.state?.sortOrder ?? "desc";
  const lastFilterText =
    location.state?.filterText ??
    `?page=${lastCurrentPage}&size=${lastPageSize}&sort=${lastSortField}&direction=${lastSortOrder}`;
  const lastFormFields = location.state?.formFields ?? {};
  const [formFields, setFormFields] = useState(lastFormFields);
  const [filterText, setFilterText] = useState<string>(lastFilterText);
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);
  const [sortField, setSortField] = useState(lastSortField);
  const [sortOrder, setSortOrder] = useState(lastSortOrder);

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    setFilterText(newFilterText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const onChangeSearch = (search: string) => {
    const params = new URLSearchParams(filterText);
    if (search) {
      params.set("nameOrEmailOrPhone", search);
    } else {
      params.delete("nameOrEmailOrPhone");
    }
    setCurrentPage(0);
    setPageSize(20);
    const newFilterText = "?" + params.toString();
    setFilterText(newFilterText);
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const updateFilterSort = (field: string, order: any) => {
    const direction =
      order === "ascend" ? "asc" : order === "descend" ? "desc" : "";

    const sortParamRegex = /&sort=([^&]*)&direction=([^&]*)/;
    const sortMatch = filterText.match(sortParamRegex);

    let newFilterText = filterText;

    if (sortMatch) {
      newFilterText = newFilterText.replace(
        sortParamRegex,
        `&sort=${field}&direction=${direction}`
      );
    } else if (field && direction) {
      newFilterText = `${newFilterText}&sort=${field}&direction=${direction}`;
    }

    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";

    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }

    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    updateFilterSort(sorter.field, nextSortOrder);
  };

  const getCustomerAssignmentHistoryList = () => {
    setLoading(true);
    CustomerAssignmentHistoryService.getCustomerAssignmentHistory(
      `${filterText}`
    )
      .then((response) => {
        setData(response.data.data);
        setTotalCustomerCount(response.data.totalElements);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCustomerAssignmentHistoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, sortField, sortOrder]);

  const getReasonForDropoutStatus = () => {
    let mainStatuses: any[] = [];
    let subStatuses: any[] = [];
    StatusService.getAll(
      "statusType=CUSTOMER_ASSIGNMENT_REASON_STATUS",
      `&active=true`
    )
      .then((response: any) => {
        if (response.data) {
          let data = response.data;
          data.forEach((item: any) => {
            if (item.parent === null) {
              mainStatuses.push(item);
            } else {
              subStatuses.push(item);
            }
          });

          let processedData = mainStatuses.map((mainStatus: any) => {
            return {
              ...mainStatus,
              subStatuses: subStatuses.filter(
                (subStatus: any) => subStatus.parent.id === mainStatus.id
              ),
            };
          });
          setReasonForDropoutStatusOptions(processedData);
          setLoading(false);
        }
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (reasonForDropoutStatusOptions.length === 0) {
      getReasonForDropoutStatus();
    }
    // eslint-disable-next-line
  }, []);

  const handleChangeReasonForDropoutStatus = (status: any, id: any) => {
    setLoading(true);
    CustomerAssignmentHistoryService.updateAssignmentReasonStatus(id, status)
      .then((response: any) => {
        openNotification("success", "Success", "Changed reason for dropout");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Customer",
      dataIndex: "fullName",
      key: "fullName",
      fixed: "left",
      sorter: false,
      width: 250,
      render: (
        text: string,
        record: {
          customer: {
            fullName: string;
            email: string;
            phone: string;
          };
        }
      ) => (
        <>
          <div>
            <CopyClipboard text={record?.customer?.fullName} />{" "}
          </div>
          <span>
            <CopyClipboard text={record?.customer?.email} />{" "}
          </span>
          <br />
          <span>
            <span>{record?.customer?.phone}</span>{" "}
            <StartCall text={record?.customer?.phone} />
          </span>
        </>
      ),
    },
    {
      title: "Transition Date",
      dataIndex: "assignmentDate",
      key: "assignmentDate",
      align: "center",
      defaultSortOrder: "descend",
      sorter: true,
      width: 150,
      render: (text: string) => (
        <p style={{ fontWeight: "bolder", fontSize: 12 }}>
          {formatDateString(text)}
        </p>
      ),
    },
    {
      title: "Old Location",
      dataIndex: "oldMemberFlag",
      key: "oldMemberFlag",
      width: 250,
      sorter: true,
      render: (text: string) => {
        const formattedText = text
          ?.replace(/_/g, " ")
          ?.toLowerCase()
          ?.replace(/\b\w/g, (char) => char.toUpperCase());

        return <span>{formattedText}</span>;
      },
    },
    {
      title: "New Location",
      dataIndex: "newMemberFlag",
      key: "newMemberFlag",
      width: 250,
      sorter: true,
      render: (text: string) => {
        const formattedText = text
          ?.replace(/_/g, " ")
          ?.toLowerCase()
          ?.replace(/\b\w/g, (char) => char.toUpperCase());

        return <span>{formattedText}</span>;
      },
    },
    {
      title: "Reason for Dropout",
      dataIndex: ["assignmentReasonStatus", "name"],
      key: "name",
      width: 400,
      render: (_: any, row: any) => {
        return (
          <Select
            showSearch
            filterOption={(input, option) =>
              option && option.label && typeof option.label === "string"
                ? option.label.toLowerCase().includes(input.toLowerCase())
                : false
            }
            onChange={(selectedStatus) =>
              handleChangeReasonForDropoutStatus(selectedStatus, row.id)
            }
            key={row.id}
            defaultValue={
              row.assignmentReasonStatus && row.assignmentReasonStatus.id
            }
            placeholder={"Select a sub reason for dropout"}
            style={{ width: "100%" }}
          >
            {reasonForDropoutStatusOptions.map((mainStatus) => (
              <Select.OptGroup key={mainStatus.id} label={mainStatus.name}>
                {mainStatus.subStatuses.map((subStatus: any) => (
                  <Select.Option key={subStatus.id} value={subStatus.id}>
                    {subStatus.name}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
          </Select>
        );
      },
    },
  ];

  return (
    <>
      <Card
        className="card-customers"
        title={
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              className="customer-card-title"
              style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
            >
              Dropout Feedback List
            </div>
            <div className="customer-card-info">
              <b>{totalCustomerCount} </b>Customers
            </div>
          </div>
        }
        extra={
          <Row
            style={{ minWidth: 400, display: "flex", justifyContent: "end" }}
            gutter={[10, 10]}
          >
            <FilterField
              onChangeSearch={onChangeSearch}
              setFilterText={setFilterText}
              pageSize={pageSize}
              sortField={sortField}
              sortOrder={sortOrder}
              setFormFields={setFormFields}
              formFields={formFields}
            />
          </Row>
        }
        bordered={true}
        style={{ marginTop: "10px" }}
      >
        <Table
          rowKey={(record) => record?.id}
          scroll={{ x: "100%" }}
          sticky
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={loading}
          onChange={handleTableChange}
        />
        <Row style={{ marginTop: "30px" }} justify={"center"}>
          <Pagination
            showSizeChanger={true}
            total={totalCustomerCount}
            defaultPageSize={pageSize}
            defaultCurrent={0}
            current={currentPage + 1}
            pageSizeOptions={["10", "20", "50", "100", "250"]}
            showQuickJumper
            onChange={paginationOnChange}
          />
        </Row>
      </Card>
    </>
  );
};

export default DropoutFeedbackList;
