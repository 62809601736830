import React, { useEffect, useState } from "react";
import { Avatar, Badge, Card, Col, Row, Space, Table } from "antd";
import ReactApexChart from "react-apexcharts";
import "../AcmDashboard/acm-dashboard.css";
import DashboardService from "../../../services/DashboardService";
import { getProfilePhotoPath } from "../../../utils";

interface RetentionCardProps {
  title: string;
  dateRange: any;
  roleName: string;
}

const RetentionCard: React.FC<RetentionCardProps> = ({
  title,
  dateRange,
  roleName,
}) => {
  const [loading, setLoading] = useState(false);
  const [salesSummary, setSalesSummary] = useState<any>([]);
  const [goldRetention, setGoldRetention] = useState<number>(0);
  const [goldRetentionMe, setGoldRetentionMe] = useState<number>(0);
  const [goldRetentionPercentage, setGoldRetentionPercentage] = useState<number>(0);
  const [goldRetentionPercentageMe, setGoldRetentionPercentageMe] = useState<number>(0);
  const [slpRetentionPercentage, setSlpRetentionPercentage] = useState<number>(0);
  const [slpRetentionPercentageMe, setSlpRetentionPercentageMe] = useState<number>(0);
  const [slpRetention, setSlpRetention] = useState<number>(0);
  const [slpRetentionMe, setSlpRetentionMe] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  // eslint-disable-next-line
  const [options, setOptions] = useState<any>({
    chart: {
      type: "donut",
    },
    stroke: {
      lineCap: "round",
    },
    plotOptions: {
      radialBar: {
        track: {
          background: "#f4f4f4",
        },
        dataLabels: {
          name: {
            show: true,
            fontSize: "14px",
          },
          value: {
            fontWeight: 600,
          },
        },
      },
    },
    labels: ["Gold Retention", "SLP Retention"],
    colors: ["#EEC73B", "#0086FF"],
    responsive: [
      {
        breakpoint: 992,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "12px",
                },
                value: {
                  fontSize: "12px",
                },
              },
            },
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "15px",
                },
                value: {
                  fontSize: "15px",
                },
              },
            },
          },
        },
      },
      // {
      //   breakpoint: 576,
      //   options: {
      //     plotOptions: {
      //       radialBar: {
      //         dataLabels: {
      //           name: {
      //             fontSize: "12px",
      //           },
      //           value: {
      //             fontSize: "12px",
      //           },
      //         },
      //       },
      //     },
      //   },
      // },
      {
        breakpoint: 480,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "12px",
                },
                value: {
                  fontSize: "12px",
                },
              },
            },
          },
        },
      },
      {
        breakpoint: 380,
        options: {
          chart: {
            width: 100,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchSalesSummary = async (formattedDateRange: string) => {
    try {
      if (roleName === "HI5_ACM_MANAGER") {
        const summaryResponse =
          await DashboardService.getRetentionReportForManager(
            `${formattedDateRange}`
          );
        const totalGoldRetention = summaryResponse.data.reduce(
          (total: number, item: any) =>
            total + (item.goldRetentionCustomers?.length || 0),
          0
        );
        const totalSlpRetention = summaryResponse.data.reduce(
          (total: number, item: any) =>
            total + (item.slpRetentionCustomers?.length || 0),
          0
        );
  
        const totalRetention = totalGoldRetention + totalSlpRetention;
        const goldRetentionPercentage = (
          (totalGoldRetention / totalRetention) *
          100
        ).toFixed(2);
        const slpRetentionPercentage = (
          (totalSlpRetention / totalRetention) *
          100
        ).toFixed(2);
  
        // Filter out specific members
        const filteredData = summaryResponse.data.filter(
          (item: any) =>
            item.member.username !== "duygu.karakilic.hi5acm.crm@oneamz.com" &&
            item.member.username !== "murat.yakar.hi5acm.crm@oneamz.com"
        );
  
        setSalesSummary(filteredData);
        setGoldRetention(totalGoldRetention);
        setSlpRetention(totalSlpRetention);
        setGoldRetentionPercentage(
          isNaN(parseFloat(goldRetentionPercentage))
            ? 0
            : parseFloat(goldRetentionPercentage)
        );
        setSlpRetentionPercentage(
          isNaN(parseFloat(slpRetentionPercentage))
            ? 0
            : parseFloat(slpRetentionPercentage)
        );
      } else {
        const summaryMeResponse =
          await DashboardService.getRetentionReportForMember(
            `${formattedDateRange}`
          );
        const totalGoldRetentionMe =
          summaryMeResponse.data.goldRetentionCustomers?.length || 0;
        const totalSlpRetentionMe =
          summaryMeResponse.data.slpRetentionCustomers?.length || 0;
  
        const totalRetentionMe = totalGoldRetentionMe + totalSlpRetentionMe;
        const goldRetentionMePercentage = (
          (totalGoldRetentionMe / totalRetentionMe) *
          100
        ).toFixed(2);
        const slpRetentionMePercentage = (
          (totalSlpRetentionMe / totalRetentionMe) *
          100
        ).toFixed(2);
  
        setGoldRetentionMe(totalGoldRetentionMe);
        setSlpRetentionMe(totalSlpRetentionMe);
        setGoldRetentionPercentageMe(
          isNaN(parseFloat(goldRetentionMePercentage))
            ? 0
            : parseFloat(goldRetentionMePercentage)
        );
        setSlpRetentionPercentageMe(
          isNaN(parseFloat(slpRetentionMePercentage))
            ? 0
            : parseFloat(slpRetentionMePercentage)
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };   

  useEffect(() => {
    if (dateRange.length > 0) {
      const formattedDateRange = `?startDate=${dateRange[0]}&endDate=${dateRange[1]}`;
      if (formattedDateRange) {
        setLoading(true);
        fetchSalesSummary(formattedDateRange);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, roleName]);

  const columns = [
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Hi5 ACM Member
        </p>
      ),
      dataIndex: "member",
      key: "member",
      ellipsis: true,
      width: 200,
      sorter: (a: any, b: any) => a?.member?.firstName?.localeCompare(b?.member?.firstName),
      defaultSortOrder: "ascend" as "ascend",
      render: (text: string, record: any) => (
        <Row
          align={"middle"}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            padding: "0px 0px",
          }}
        >
          <Avatar
            style={{ marginRight: 5 }}
            size={30}
            src={getProfilePhotoPath(record?.member?.profilePhoto || 1)}
          />
          <b
            style={{
              margin: "0px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {record?.member?.firstName} {record?.member?.lastName}
          </b>
        </Row>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Gold Retention
        </p>
      ),
      dataIndex: "goldRetentionCustomers",
      key: "goldRetentionCustomers",
      width: 150,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) =>
        a?.goldRetentionCustomers?.length - b?.goldRetentionCustomers?.length,
      render: (text: string, record: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px 10px 0px 0px",
              whiteSpace: "nowrap",
              width: "12px",
              fontWeight: "bold",
              textAlign: "center",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {record?.goldRetentionCustomers?.length || 0}
          </p>
        </div>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          SLP Retention
        </p>
      ),
      dataIndex: "slpRetentionCustomers",
      key: "slpRetentionCustomers",
      width: 150,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) =>
        a?.slpRetentionCustomers?.length - b?.slpRetentionCustomers?.length,
      render: (text: string, record: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px 10px 0px 0px",
              whiteSpace: "nowrap",
              width: "12px",
              fontWeight: "bold",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {record?.slpRetentionCustomers?.length || 0}
          </p>
        </div>
      ),
    },
  ];

  return (
    <Card bordered={false} className="info-card" loading={loading}>
      <p
        className="title"
        style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
      >
        {title}
      </p>
      <Row gutter={[12, 12]} align="middle" justify="space-between">
        <Col xs={24} sm={13} md={13}>
          <ReactApexChart
            options={options}
            series={
              roleName === "HI5_ACM_MANAGER"
                ? [goldRetentionPercentage, slpRetentionPercentage]
                : [goldRetentionPercentageMe, slpRetentionPercentageMe]
            }
            type="radialBar"
            height={200}
          />
        </Col>
        <Col xs={24} sm={11} md={11}>
          <Row
            align="middle"
            justify="center"
            style={{
              margin:
                windowWidth < 480
                  ? "-30px 0px 10px 0px"
                  : windowWidth < 576
                  ? "-20px 0px 10px 0px"
                  : "0px 0px 0px 0px",
            }}
          >
            <Space direction="vertical" size={0}>
              <Row gutter={[3, 3]}>
                <Col
                  xs={12}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "left" : "left",
                  }}
                >
                  <Badge
                    color="#EEC73B"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Gold Retention:{" "}
                        <b>
                          {roleName === "HI5_ACM_MANAGER"
                            ? goldRetention
                            : goldRetentionMe}
                        </b>
                      </span>
                    }
                  />
                </Col>
                <Col
                  xs={12}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "right" : "left",
                  }}
                >
                  <Badge
                    color="#0086FF"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        SLP Retention:{" "}
                        <b>
                          {roleName === "HI5_ACM_MANAGER"
                            ? slpRetention
                            : slpRetentionMe}
                        </b>
                      </span>
                    }
                  />
                </Col>
              </Row>
            </Space>
          </Row>
        </Col>
      </Row>
      {roleName === "HI5_ACM_MANAGER" && (
        <>
          <Row>
            <Col span={24}>
              <Table
                rowKey={(record) => record?.member?.id}
                columns={columns}
                dataSource={salesSummary}
                pagination={false}
                scroll={{ x: "100%", y: 185 }}
                size="small"
                className="dashboard-table"
              />
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
};

export default RetentionCard;
