import { Col, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import CallCenterSalesLogService from "../../../../services/CallCenterSalesLogService";
import dayjs from "dayjs";

const CtmSalesLogReport = ({ dateRange }: { dateRange: any }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      let formattedStartDate = dayjs(dateRange[0]).format(
        "YYYY-MM-DDT00:00:00"
      );
      let formattedEndDate = dayjs(dateRange[1]).format("YYYY-MM-DDT23:59:59");
      getSalesLogReport(formattedStartDate, formattedEndDate);
    }
    // eslint-disable-next-line
  }, [dateRange]);

  const getSalesLogReport = (startDate: string, endDate: string) => {
    setLoading(true);
    CallCenterSalesLogService.getSalesLogReport(startDate, endDate, "CALL_TEAM")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUniqueSalesDataKeys = (data: any[]) => {
    const allKeys = data?.reduce((keys, item) => {
      return keys.concat(Object.keys(item?.salesDataMap));
    }, []);
    return Array.from(new Set(allKeys));
  };

  const salesDataKeys = getUniqueSalesDataKeys(data);

  const extractNumericValues = (
    value: string | undefined
  ): { current: number; previous: number } => {
    if (!value) {
      return { current: 0, previous: 0 };
    }

    const match = value.match(/^(\d+)\s\((\d+)\)$/);
    return match
      ? { current: parseFloat(match[1]), previous: parseFloat(match[2]) }
      : { current: 0, previous: 0 };
  };
  const calculateTotalSales = (salesDataMap: any) => {
    return Object.keys(salesDataMap).reduce(
      (total, key) => {
        const { current, previous } = extractNumericValues(salesDataMap[key]);
        total.current += current;
        total.previous += previous;
        return total;
      },
      { current: 0, previous: 0 }
    );
  };

  const capitalize = (text: string) => {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Call Team Member",
      dataIndex: "user",
      key: "user",
      fixed: "left",
      width: 200,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => a?.user?.firstName.localeCompare(b?.user?.firstName),
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${record?.user?.firstName} ${record?.user?.lastName}`}
          >
            <b>
              {record?.user?.firstName} {record?.user?.lastName}
            </b>
          </div>
        );
      },
    },
    ...salesDataKeys
      .filter((key: any) => {
        return data.some((item: any) => {
          const value = item.salesDataMap[key] || "0 (0)";
          const { current, previous } = extractNumericValues(value);
          return current !== 0 || previous !== 0;
        });
      })
      .map((key: any) => ({
        title: `${capitalize(key)}*`,
        dataIndex: "salesDataMap",
        key,
        width: 150,
        align: "center" as const,
        sorter: (a: any, b: any) => {
          const aValue = extractNumericValues(
            a?.salesDataMap[key] || "0"
          ).current;
          const bValue = extractNumericValues(
            b?.salesDataMap[key] || "0"
          ).current;
          return aValue - bValue;
        },
        render: (salesDataMap: any) => {
          const { current, previous } = extractNumericValues(
            salesDataMap[key] || "0 (0)"
          );
          return `${current} (${previous}) qty`;
        },
      })),
    {
      title: "Total Sales",
      dataIndex: "salesDataMap",
      key: "totalSales",
      width: 150,
      align: "center",
      sorter: (a: any, b: any) => {
        const totalSalesA = calculateTotalSales(a.salesDataMap);
        const totalSalesB = calculateTotalSales(b.salesDataMap);
        if (totalSalesA.current !== totalSalesB.current) {
          return totalSalesA.current - totalSalesB.current;
        }
        return totalSalesA.previous - totalSalesB.previous;
      },
      render: (salesDataMap: any) => {
        const totalSales = calculateTotalSales(salesDataMap);
        return (
          <b>
            {totalSales.current} ({totalSales.previous}) qty
          </b>
        );
      },
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>Sales Log Report</h3>
        <Table
          rowKey={(record) => record?.user?.id}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{ x: "100%" }}
          sticky
          pagination={false}
          summary={(pageData) => {
            const salesDataTotals = salesDataKeys.map((key: any) => {
              const totals = pageData.reduce(
                (total, record) => {
                  const value = record.salesDataMap[key];
                  const { current, previous } = extractNumericValues(
                    value || "0 (0)"
                  );
                  total.current += current;
                  total.previous += previous;
                  return total;
                },
                { current: 0, previous: 0 }
              );
              return totals.current !== 0 || totals.previous !== 0
                ? `${totals.current} (${totals.previous}) qty`
                : null;
            });

            const totalSalesSum = pageData?.reduce(
              (total, record) => {
                const { current, previous } = calculateTotalSales(
                  record?.salesDataMap
                );
                total.current += current;
                total.previous += previous;
                return total;
              },
              { current: 0, previous: 0 }
            );

            return (
              <Table.Summary.Row className="summary-cell">
                <Table.Summary.Cell
                  index={0}
                  colSpan={1}
                  className="summary-cell"
                >
                  <b>Total</b>
                </Table.Summary.Cell>
                {salesDataKeys.map((key: any, index: any) => {
                  const total = salesDataTotals[index];
                  if (total) {
                    return (
                      <Table.Summary.Cell
                        index={index + 1}
                        key={index}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{total}</b>
                      </Table.Summary.Cell>
                    );
                  } else {
                    return null;
                  }
                })}
                <Table.Summary.Cell
                  index={salesDataKeys.length + 1}
                  align="center"
                  className="summary-cell"
                >
                  <b>
                    {totalSalesSum.current} ({totalSalesSum.previous}) qty
                  </b>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default CtmSalesLogReport;
