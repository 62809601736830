import { Col, Row, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import { MonthlySuspend } from "../../../../types/AmazonSuspendedApplicantReports";

interface SuspendTypeBasedMonthlySuspendReportProps {
  dateRange: [string, string];
}

const SuspendTypeBasedMonthlySuspendReport = ({
  dateRange,
}: SuspendTypeBasedMonthlySuspendReportProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<MonthlySuspend[]>([]);

  const previousMonth = dayjs(dateRange[1]).subtract(1, "month").format("MMMM");
  const twoMonthsAgo = dayjs(dateRange[1]).subtract(2, "month").format("MMMM");

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const formattedStartDate = dayjs(dateRange[0]).format(
        "YYYY-MM-DDT00:00:00"
      );
      const formattedEndDate = dayjs(dateRange[1]).format(
        "YYYY-MM-DDT23:59:59"
      );
      getSuspendTypeBasedMonthlySuspendReport(formattedStartDate, formattedEndDate);
    }
  }, [dateRange]);

  const getSuspendTypeBasedMonthlySuspendReport = (
    startDate: string,
    endDate: string
  ) => {
    setLoading(true);
    AmazonSuspendedApplicantReportsService.getMonthlySuspend(
      startDate,
      endDate
    )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<MonthlySuspend> = [
    {
      title: "Suspend Type",
      dataIndex: "suspendType",
      key: "suspendType",
      fixed: "left",
      width: 200,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.suspendType.localeCompare(b.suspendType),
      render: (text: string, record: MonthlySuspend) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={record.suspendType?.toUpperCase()}
        >
          <b>{record.suspendType?.toUpperCase()}</b>
        </div>
      ),
    },
    {
      title: `${twoMonthsAgo} Received`,
      dataIndex: "twoMonthsAgoReceived",
      key: "twoMonthsAgoReceived",
      width: 180,
      align: "center",
      sorter: (a, b) => a.twoMonthsAgoReceived - b.twoMonthsAgoReceived,
      render: (twoMonthsAgoReceived: number) => (
        <div>
          <b>
            {twoMonthsAgoReceived !== undefined ? twoMonthsAgoReceived : "N/A"}
          </b>
        </div>
      ),
    },
    {
      title: `${twoMonthsAgo} Opened`,
      dataIndex: "twoMonthsAgoOpened",
      key: "twoMonthsAgoOpened",
      width: 180,
      align: "center",
      sorter: (a, b) => a.twoMonthsAgoOpened - b.twoMonthsAgoOpened,
      render: (twoMonthsAgoOpened: number) => (
        <div>
          <b>{twoMonthsAgoOpened !== undefined ? twoMonthsAgoOpened : "N/A"}</b>
        </div>
      ),
    },
    {
      title: `${twoMonthsAgo} Opened Percentage`,
      dataIndex: "twoMonthsAgoOpenedPercentage",
      key: "twoMonthsAgoOpenedPercentage",
      width: 180,
      align: "center",
      sorter: (a, b) =>
        a.twoMonthsAgoOpenedPercentage - b.twoMonthsAgoOpenedPercentage,
      render: (twoMonthsAgoOpenedPercentage: number) => (
        <div>
          <b>
            {twoMonthsAgoOpenedPercentage !== undefined
              ? twoMonthsAgoOpenedPercentage.toFixed(2)
              : "N/A"}
            %
          </b>
        </div>
      ),
    },
    {
      title: `${previousMonth} Received`,
      dataIndex: "previousMonthReceived",
      key: "previousMonthReceived",
      width: 180,
      align: "center",
      sorter: (a, b) => a.previousMonthReceived - b.previousMonthReceived,
      render: (previousMonthReceived: number) => (
        <div>
          <b>
            {previousMonthReceived !== undefined
              ? previousMonthReceived
              : "N/A"}
          </b>
        </div>
      ),
    },
    {
      title: `${previousMonth} Opened`,
      dataIndex: "previousMonthOpened",
      key: "previousMonthOpened",
      width: 180,
      align: "center",
      sorter: (a, b) => a.previousMonthOpened - b.previousMonthOpened,
      render: (previousMonthOpened: number) => (
        <div>
          <b>
            {previousMonthOpened !== undefined ? previousMonthOpened : "N/A"}
          </b>
        </div>
      ),
    },
    {
      title: `${previousMonth} Opened Percentage`,
      dataIndex: "previousMonthOpenedPercentage",
      key: "previousMonthOpenedPercentage",
      width: 180,
      align: "center",
      sorter: (a, b) =>
        a.previousMonthOpenedPercentage - b.previousMonthOpenedPercentage,
      render: (previousMonthOpenedPercentage: number) => (
        <div>
          <b>
            {previousMonthOpenedPercentage !== undefined
              ? previousMonthOpenedPercentage.toFixed(2)
              : "N/A"}
            %
          </b>
        </div>
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>Suspend Type Based Monthly Suspend Report</h3>
        <Table
          loading={loading}
          scroll={{ x: "max-content" }}
          sticky
          rowKey={(record) => record.suspendType}
          columns={columns}
          dataSource={data}
          pagination={false}
          summary={(pageData) => {
            let totalTwoMonthsAgoReceived = 0;
            let totalTwoMonthsAgoOpened = 0;
            let totalTwoMonthsAgoOpenedPercentage = 0;
            let totalPreviousMonthReceived = 0;
            let totalPreviousMonthOpened = 0;
            let totalPreviousMonthOpenedPercentage = 0;

            pageData.forEach(
              ({
                twoMonthsAgoReceived,
                twoMonthsAgoOpened,
                twoMonthsAgoOpenedPercentage,
                previousMonthReceived,
                previousMonthOpened,
                previousMonthOpenedPercentage,
              }) => {
                totalTwoMonthsAgoReceived += twoMonthsAgoReceived || 0;
                totalTwoMonthsAgoOpened += twoMonthsAgoOpened || 0;
                totalTwoMonthsAgoOpenedPercentage +=
                  twoMonthsAgoOpenedPercentage || 0;
                totalPreviousMonthReceived += previousMonthReceived || 0;
                totalPreviousMonthOpened += previousMonthOpened || 0;
                totalPreviousMonthOpenedPercentage +=
                  previousMonthOpenedPercentage || 0;
              }
            );

            const avgTwoMonthsAgoOpenedPercentage = (
              totalTwoMonthsAgoOpenedPercentage / pageData.length
            ).toFixed(2);
            const avgPreviousMonthOpenedPercentage = (
              totalPreviousMonthOpenedPercentage / pageData.length
            ).toFixed(2);

            return (
              <Table.Summary fixed>
                <Table.Summary.Row className="summary-cell">
                  <Table.Summary.Cell
                    index={0}
                    colSpan={1}
                    align="left"
                    className="summary-cell"
                  >
                    <b>Total</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={1}
                    align="center"
                    className="summary-cell"
                  >
                    <b>{totalTwoMonthsAgoReceived}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={2}
                    align="center"
                    className="summary-cell"
                  >
                    <b>{totalTwoMonthsAgoOpened}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={3}
                    align="center"
                    className="summary-cell"
                  >
                    <b>{avgTwoMonthsAgoOpenedPercentage}%</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={4}
                    align="center"
                    className="summary-cell"
                  >
                    <b>{totalPreviousMonthReceived}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={5}
                    align="center"
                    className="summary-cell"
                  >
                    <b>{totalPreviousMonthOpened}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={6}
                    align="center"
                    className="summary-cell"
                  >
                    <b>{avgPreviousMonthOpenedPercentage}%</b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default SuspendTypeBasedMonthlySuspendReport;
