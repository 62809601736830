import { Col, Row, Table } from "antd";
import CallTeamReportService from "../../../../services/CallTeamReportService";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";

const CtmSourceBasedSalesReport = ({ dateRange }: { dateRange: any }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [dynamicColumns, setDynamicColumns] = useState<any[]>([]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      let formattedStartDate = dayjs(dateRange[0]).format("YYYY-MM-DDT00:00:00");
      let formattedEndDate = dayjs(dateRange[1]).format("YYYY-MM-DDT23:59:59");
      getAllSourceBasedSalesReport(formattedStartDate, formattedEndDate);
    }
    // eslint-disable-next-line
  }, [dateRange]);

  const getAllSourceBasedSalesReport = (startDate: string, endDate: string) => {
    setLoading(true);
    CallTeamReportService.getAllSourceBasedSalesReport(startDate, endDate)
      .then((response) => {
        setData(response.data);
        generateDynamicColumns(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateDynamicColumns = (data: any[]) => {
    const uniquePackages = new Set<string>();
    data?.forEach((record) =>
      record?.list?.forEach((item: any) => {
        Object.keys(item?.packageSalesMap).forEach((packageName) =>
          uniquePackages.add(packageName)
        );
      })
    );

    const generatedColumns = Array.from(uniquePackages).map((packageName) => ({
      title: packageName,
      dataIndex: ["packageSalesMap", packageName],
      key: packageName,
      align: "center" as "center",
      width: 100,
      sorter: (a: any, b: any) => (a?.packageSalesMap[packageName] || 0) - (b?.packageSalesMap[packageName] || 0),
      render: (text: any) => text || 0,
    }));

    setDynamicColumns(generatedColumns);
  };

  const calculateTotalSales = (list: any) => {
    return list?.reduce((total: number, item: any) => {
      return (
        total +
        Object.values(item?.packageSalesMap).reduce(
          (sum: number, count: any) => sum + count,
          0
        )
      );
    }, 0);
  };

  const calculateOverallTotalSales = () => {
    return data.reduce((total: number, record: any) => {
      return total + calculateTotalSales(record?.list);
    }, 0);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Call Team Member",
      dataIndex: "member",
      key: "member",
      width: 500,
      align: "left",
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a: any, b: any) =>
        a?.member?.firstName.localeCompare(b?.member?.firstName),
      render: (text: any, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${record?.member?.firstName} ${record?.member?.lastName}`}
          >
            <b>
              {record?.member?.firstName} {record?.member?.lastName}
            </b>
          </div>
        );
      },
    },
    {
      title: "Total Sales",
      dataIndex: "list",
      key: "totalSales",
      width: 250,
      align: "center",
      sorter: (a: any, b: any) =>
        calculateTotalSales(a?.list) - calculateTotalSales(b?.list),
      render: (list: any) => (
        <b>{calculateTotalSales(list)}</b>
      ),
    },
  ];

  const expandedRowRender = (record: any) => {
    const innerColumns = [
      {
        title: "Source Name",
        dataIndex: "sourceName",
        key: "sourceName",
        align: "left" as "left",
        ellipsis: true,
        width: 150,
        defaultSortOrder: "ascend" as "ascend",
        sorter: (a: any, b: any) => a?.sourceName.localeCompare(b?.sourceName),
        render: (text: string) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={`${text}`}
            >
              <b>{text}</b>
            </div>
          );
        },
      },
      ...dynamicColumns,
    ];
    return (
      <Table
        columns={innerColumns}
        dataSource={record?.list}
        pagination={false}
        rowKey={(record) => record?.sourceName}
      />
    );
  };

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>Source Based Sales Report</h3>
        <Table
          rowKey={(record) => record?.member?.id}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{ x: "100%" }}
          expandable={{ expandedRowRender }}
          sticky
          pagination={false}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2} align="center">
                <strong>Total</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align="center">
                <strong>{calculateOverallTotalSales()}</strong>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      </Col>
    </Row>
  );
};

export default CtmSourceBasedSalesReport;
