import { Col, Row } from "antd";
import "./dashboard.css";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { HeaderBar } from "../components/Dashboard/HeaderBar";
// import { Earnings } from "../components/Dashboard/Earnings";
import { LearnerStatistics } from "../components/Dashboard/LearnerStatistics";
import { TaskList } from "../components/Dashboard/TaskList";
// import { TopTenCard } from "../components/Dashboard/StmDashboard/TopTenCard";
import TotalInfoCard from "../components/Dashboard/StmDashboard/TotalInfoCard";
import TotalAdditionalPackageCard from "../components/Dashboard/StmDashboard/TotalAdditionalPackageCard";
import CallCard from "../components/Dashboard/StmDashboard/CallCard";
import TotalSalesCard from "../components/Dashboard/StmDashboard/TotalSalesCard";
// import { CallStatistics } from "../components/Dashboard/CallStatistics";
// import { TargetStatistics } from "../components/Dashboard/TargetStatistics";
// import ActivityStatistics from "../components/Dashboard/ActivityStatistics";

export const SuccessManagerDashboard = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<any>([]);
  const roleName = localStorage.getItem("roleName");

  return (
    <div
      style={{ background: "#F0F0F0", padding: "30px" }}
      className="acm-dashboard"
    >
      <motion.div
        initial={{ y: 200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 300, opacity: 0 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 40,
        }}
      >
        <HeaderBar setDateRange={setDateRange} />
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col span={24}>
            <TotalInfoCard roleName={roleName ?? ""} dateRange={dateRange} />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col xs={24} md={24} xl={8}>
            <TotalAdditionalPackageCard
              dateRange={dateRange}
              title="Total Additional Package"
              roleName={roleName ?? ""}
            />
          </Col>
          <Col xs={24} md={12} xl={8}>
            <CallCard
              title="Call Chart"
              dateRange={dateRange}
              roleName={roleName ?? ""}
            />
          </Col>
          <Col xs={24} md={12} xl={8}>
            <TotalSalesCard
              dateRange={dateRange}
              title="Total Sales"
              roleName={roleName ?? ""}
            />
          </Col>
          {/* <Col
            span={5}
            xs={24}
            sm={12}
            md={12}
            lg={5}
            style={{ paddingBottom: 20 }}
          >
            <Earnings dateRange={dateRange} />
          </Col> */}
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
            <LearnerStatistics />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
            <TaskList />
          </Col>
        </Row>
      </motion.div>
    </div>
  );
};
