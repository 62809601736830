import { Col, Row, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import { MemberCallDetail } from "../../../../types/AmazonSuspendedApplicantReports";

interface NumberOfAmazonCallsReportProps {
  dateRange: [string, string];
}

const NumberOfAmazonCallsReport: React.FC<NumberOfAmazonCallsReportProps> = ({
  dateRange,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<MemberCallDetail[]>([]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const formattedStartDate = dayjs(dateRange[0]).format(
        "YYYY-MM-DDT00:00:00"
      );
      const formattedEndDate = dayjs(dateRange[1]).format(
        "YYYY-MM-DDT23:59:59"
      );
      getNumberOfAmazonCallsReport(formattedStartDate, formattedEndDate);
    }
  }, [dateRange]);

  const getNumberOfAmazonCallsReport = (startDate: string, endDate: string) => {
    setLoading(true);
    AmazonSuspendedApplicantReportsService.getAmazonCalls(startDate, endDate)
      .then((response) => {
        setData(response.data.memberCallsDetails);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<MemberCallDetail> = [
    {
      title: "Member",
      dataIndex: "memberName",
      key: "memberName",
      fixed: "left",
      width: 200,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.memberName.localeCompare(b.memberName),
      render: (text: string, record: MemberCallDetail) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={record.memberName}
        >
          <b>{record.memberName}</b>
        </div>
      ),
    },
    {
      title: "Number of Amazon Calls",
      dataIndex: "numberOfAmazonCalls",
      key: "numberOfAmazonCalls",
      align: "center",
      width: 150,
      sorter: (a, b) => a.numberOfAmazonCalls - b.numberOfAmazonCalls,
      render: (numberOfAmazonCalls: number) => (
        <div>
          <b>
            {numberOfAmazonCalls !== undefined ? numberOfAmazonCalls : "N/A"}
          </b>
        </div>
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>Amazon Calls Report</h3>
        <Table
          loading={loading}
          scroll={{ x: "max-content" }}
          sticky
          rowKey={(record) => record.memberName}
          columns={columns}
          dataSource={data}
          pagination={false}
          summary={(pageData) => {
            let totalAmazonCalls = 0;

            pageData.forEach(({ numberOfAmazonCalls }) => {
              totalAmazonCalls += numberOfAmazonCalls || 0;
            });

            return (
              <Table.Summary fixed>
                <Table.Summary.Row className="summary-cell">
                  <Table.Summary.Cell
                    index={0}
                    colSpan={1}
                    align="left"
                    className="summary-cell"
                  >
                    <b>Total</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={1}
                    align="center"
                    className="summary-cell"
                  >
                    <b>{totalAmazonCalls}</b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default NumberOfAmazonCallsReport;
