import { Input, Row, Space } from "antd";
import React, { useCallback, useState } from "react";
import Filter from "./Filter";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import _ from "lodash";

export const FilterField: React.FC<any> = ({
  onChangeSearch,
  setFilterText,
  pageSize,
  setFormFields,
  formFields,
}) => {
  const [searchText, setSearchText] = useState("");
  const roleName = localStorage.getItem("roleName");
  const authUserId = localStorage.getItem("id");

  // eslint-disable-next-line
  const debouncedSearch = useCallback(_.debounce(onChangeSearch, 500), [
    onChangeSearch,
  ]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchText(newValue);
    debouncedSearch(newValue);
  };

  const clearInput = () => {
    setSearchText("");
    debouncedSearch("");
  };

  const handleOnChangeFilter = (filterForm: any) => {
    let filterQuery = `?page=0&size=${pageSize}${
      roleName === "SUSPEND_TEAM" ? `&suspendTeamMemberId=${authUserId}` : ""
    }`;

    if (filterForm.suspendTeamMember && filterForm.suspendTeamMember != null) {
      filterQuery += `&suspendTeamMemberId=${filterForm.suspendTeamMember}`;
    }

    if (filterForm.suspendStatus && filterForm.suspendStatus != null) {
      filterQuery += `&amazonSuspendedApplicantStatusIds=${filterForm.suspendStatus}`;
    }

    if (filterForm.suspendType && filterForm.suspendType != null) {
      filterQuery += `&amazonSuspendedApplicantSuspendTypeIds=${filterForm.suspendType}`;
    }

    if (filterForm.input && filterForm.input != null) {
      filterQuery += `&amazonSuspendedApplicantInputIds=${filterForm.input}`;
    }

    if (filterForm.source) {
      filterQuery += `&sourceId=${filterForm.source}`;
    }

    if (filterForm.package) {
      filterQuery += `&packageId=${filterForm.package}`;
    }

    if (filterForm.assignmentDateRange !== undefined) {
      if (filterForm.assignmentDateRange.length > 0) {
        let startDate = dayjs(filterForm.assignmentDateRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.assignmentDateRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&assignmentDateStart=${startDate}&assignmentDateEnd=${endDate}`;
      }
    }

    if (filterForm.oneamzAppStatus) {
      filterQuery += `&oneamzAppStatus=${filterForm.oneamzAppStatus}`;
    }

    if (filterForm.serviceType) {
      filterQuery += `&amazonSuspendedApplicantServiceTypeIds=${filterForm.serviceType}`;
    }

    if (filterForm.actionType) {
      filterQuery += `&amazonSuspendedApplicantActionTypeIds=${filterForm.actionType}`;
    }

    if (filterForm.suspendedCountry) {
      filterQuery += `&suspendedCountry=${filterForm.suspendedCountry}`;
    }

    // if (filterForm.numberOfViolations) {
    //   filterQuery += `&numberOfViolations=${filterForm.numberOfViolations}`;
    // }

    // if (filterForm.numberOfAmazonCalls) {
    //   filterQuery += `&numberOfAmazonCalls=${filterForm.numberOfAmazonCalls}`;
    // }

    setFilterText(filterQuery);
  };

  return (
    <Row justify="end">
      <Space align="start">
        <Input
          prefix={<SearchOutlined />}
          suffix={
            searchText && (
              <CloseCircleOutlined
                onClick={clearInput}
                style={{ color: "rgba(0,0,0,.45)", cursor: "pointer" }}
              />
            )
          }
          placeholder="Search"
          value={searchText}
          onChange={onSearchChange}
          style={{ maxWidth: 200 }}
        />
        <Filter
          onChangeFilter={handleOnChangeFilter}
          setFormFields={setFormFields}
          formFields={formFields}
        />
      </Space>
    </Row>
  );
};
