import {
  Button,
  Modal,
  Form,
  Select,
  DatePicker,
  Row,
  Input,
  Tooltip,
  Col,
} from "antd";
import React, { useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import { can, openNotification } from "../../../utils";
import filterIcon from "../../../assets/filter.svg";
import filterClearIcon from "../../../assets/filter-clear.svg";
import saveIcon from "../../../assets/save.svg";
import dayjs from "dayjs";
import FilterService from "../../../services/FilterService";
import { IFilterRequestData, IFilterResponseData } from "../../../types/Filter";
import AdditionalPackageHistoryService from "../../../services/AdditionalPackageHistoryService";
import StatusService from "../../../services/StatusService";

const { Item } = Form;

interface CustomerFilterProps {
  onChangeFilter: (values: any) => void;
  setFormFields: any;
  formFields: any;
  setFilterOptions: any;
}

const Filter: React.FC<CustomerFilterProps> = ({
  onChangeFilter,
  setFormFields,
  formFields,
  setFilterOptions,
}) => {
  const { RangePicker } = DatePicker;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feedbackStatusOptions, setFeedbackStatusOptions] = useState<any[]>([]);
  const [feedbackChannelOptions, setFeedbackChannelOptions] = useState<any[]>(
    []
  );
  const [masTeamOptions, setMasTeamOptions] = useState<any[]>([]);
  const [selectedFeedbackStatusItems, setSelectedFeedbackStatusItems] =
    useState<any[]>([]);
  const [selectedFeedbackChannelItems, setSelectedFeedbackChannelItems] =
    useState<any[]>([]);
  const [form] = Form.useForm();
  const roleName = localStorage.getItem("roleName");
  const [isFilterSaveModalOpen, setIsFilterSaveModalOpen] = useState(false);
  const [savedFilters, setSavedFilters] = useState<any[]>([]);
  const [filterName, setFilterName] = useState("");
  const [isFilterSavedListModalOpen, setIsFilterSavedListModalOpen] =
    useState(false);
  const [isFilterDeleteModalOpen, setIsFilterDeleteModalOpen] = useState(false);
  const [savedFiltersList, setSavedFiltersList] = useState<any[]>([]);
  const [savedFilterId, setSavedFilterId] = useState<any>();
  const [additionalPackageOptions, setAdditionalPackageOptions] = useState<
    any[]
  >([]);

  const savedLocalFilters = localStorage.getItem("masCustomerFilterText");

  const showModal = () => {
    if (masTeamOptions.length === 0) {
      getUsers();
    }
    if (feedbackStatusOptions.length === 0) {
      getFeedbackStatus();
    }
    if (feedbackChannelOptions.length === 0) {
      getFeedbackChannels();
    }
    if (additionalPackageOptions.length === 0) {
      getAdditionalPackages();
    }
    setIsModalOpen(true);
  };

  const onFinish = (values: any) => {
    const filters = [];
    if (values.masTeamMember !== undefined && values.masTeamMember !== null) {
      const selectedUser = masTeamOptions.find(
        (user) => user.id === values.masTeamMember
      );
      const userName = `${selectedUser?.firstName} ${selectedUser?.lastName}`;
      filters.push({ name: "MAS Team Member", value: userName });
    }
    if (values.additionalPackage && values.additionalPackage.length > 0) {
      const selectedPackages = values.additionalPackage
        .map((id: any) => {
          const pkg = additionalPackageOptions.find(
            (pkg: any) => pkg.id === id
          );
          return pkg ? pkg.name : null;
        })
        .filter((name: any) => name !== null);
      filters.push({ name: "Additional Package", value: selectedPackages });
    }
    const selectedFeedbackStatuses =
      values.feedbackStatus && values.feedbackStatus.length > 0
        ? values.feedbackStatus
            .map((statusId: any) => {
              const statusOption = feedbackStatusOptions.find(
                (option: any) => option.value === statusId
              );
              return statusOption ? statusOption.label : null;
            })
            .filter((name: any) => name !== null)
        : [];
    if (selectedFeedbackStatuses.length > 0) {
      filters.push({ name: "feedbackStatus", value: selectedFeedbackStatuses });
    }
    const selectedFeedbackChannels =
      values.feedbackChannel && values.feedbackChannel.length > 0
        ? values.feedbackChannel
            .map((ChannelId: any) => {
              const ChannelOption = feedbackChannelOptions.find(
                (option: any) => option.value === ChannelId
              );
              return ChannelOption ? ChannelOption.label : null;
            })
            .filter((name: any) => name !== null)
        : [];
    if (selectedFeedbackChannels.length > 0) {
      filters.push({
        name: "feedbackChannel",
        value: selectedFeedbackChannels,
      });
    }
    setFilterOptions(filters);
    onChangeFilter(values);
    setFormFields(form.getFieldsValue());
    setIsModalOpen(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const getUsers = () => {
    if (can("users:getAll")) {
      Promise.all([UserService.getAll("?roleName=MAS_TEAM")])
        .then(([masTeamResponse]) => {
          setMasTeamOptions(masTeamResponse);
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const clearForm = () => {
    form.resetFields();
    setFormFields(form.getFieldsValue());
    localStorage.removeItem("masCustomerFilterText");
  };

  const clearLocalFilterAndForm = () => {
    clearForm();
    onFinish({ ...form.getFieldsValue() });
  };

  const getFeedbackStatus = () => {
    StatusService.getAll("statusType=MAS_TEAM_FEEDBACK_STATUS", `&active=true`)
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setFeedbackStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getFeedbackChannels = () => {
    StatusService.getAll("statusType=MAS_TEAM_FEEDBACK_CHANNEL", `&active=true`)
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setFeedbackChannelOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getAdditionalPackages = () => {
    AdditionalPackageHistoryService.getListPackages()
      .then((response: any) => {
        setAdditionalPackageOptions(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const selectAllFeedbackStatus = () => {
    const allIds = feedbackStatusOptions.map((option: any) => option.value);
    setSelectedFeedbackStatusItems(allIds);
    form.setFieldsValue({
      feedbackStatus: allIds,
    });
  };

  const handleChangeFeedbackStatus = (selected: any) => {
    setSelectedFeedbackStatusItems(selected);
    form.setFieldsValue({
      feedbackStatus: selected,
    });
  };

  const selectAllFeedbackChannel = () => {
    const allIds = feedbackChannelOptions.map((option: any) => option.value);
    setSelectedFeedbackChannelItems(allIds);
    form.setFieldsValue({
      feedbackChannel: allIds,
    });
  };

  const handleChangeFeedbackChannel = (selected: any) => {
    setSelectedFeedbackChannelItems(selected);
    form.setFieldsValue({
      feedbackChannel: selected,
    });
  };

  const showFilterSaveModal = () => {
    setIsFilterSaveModalOpen(true);
  };

  const handleSaveFilter = () => {
    const formData = form.getFieldsValue();
    const {
      state,
      accountManagerMember,
      hi5AcmMember,
      callTeamMember,
      successTeamMember,
      status,
      acmStatus,
      hi5AcmStatus,
      currentPackage,
      startPassiveDay,
      endPassiveDay,
      startLastContactedStm,
      endLastContactedStm,
      startLastContactedAcm,
      endLastContactedAcm,
      startLastContactedHi5Acm,
      endLastContactedHi5Acm,
      startTotalOrderCount,
      endTotalOrderCount,
      freeDays,
      level,
      unassigned,
      goldPackageHi5ACM,
      goldPackage,
      slpPackage,
      autoPilotPackage,
      inactiveGold7Days,
      inactiveSlp7Days,
      lastSalesDay,
      createdAtRange,
      assignmentDateRange,
      subscriptionEndDateRange,
      registeredDateRange,
      retentionAssignmentDateRange,
      hi5AcmAssignmentDateRange,
      hasNoPackages,
      additionalPackage,
      packageOfInterestSTM,
      oldPackageOfInterestSTM,
      packageOfInterestACM,
      currentPackageOfInterestACM,
      packageOfInterestHi5Acm,
      currentPackageOfInterestHi5Acm,
      goldPackageEndDate,
      slpPackageEndDate,
      autoSubscriptionRenewalEnabled,
      autoSlpSubscriptionRenewalEnabled,
      autoGoldSubscriptionRenewalEnabled,
    } = formData;
    const newFilter: IFilterRequestData = {
      name: filterName,
      query: {
        state: state ? state : null,
        userId: accountManagerMember ? accountManagerMember : null,
        hi5AcmMember: hi5AcmMember ? hi5AcmMember : null,
        callCenterMember: callTeamMember ? callTeamMember : null,
        successTeamMember: successTeamMember ? successTeamMember : null,
        hasNoPackages: hasNoPackages ? hasNoPackages : null,
        additionalPackgeIds: additionalPackage
          ? additionalPackage.join(",")
          : null,
        statusIds: status ? status.join(",") : null,
        acmStatusIds: acmStatus ? acmStatus.join(",") : null,
        hi5AcmStatusIds: hi5AcmStatus ? hi5AcmStatus.join(",") : null,
        currentPackage,
        startPassiveDays: startPassiveDay ? startPassiveDay : null,
        endPassiveDays: endPassiveDay ? endPassiveDay : null,
        startLastContactedStm: startLastContactedStm
          ? startLastContactedStm
          : null,
        endLastContactedStm: endLastContactedStm ? endLastContactedStm : null,
        startLastContactedAcm: startLastContactedAcm
          ? startLastContactedAcm
          : null,
        endLastContactedAcm: endLastContactedAcm ? endLastContactedAcm : null,
        startLastContactedHi5Acm: startLastContactedHi5Acm
          ? startLastContactedHi5Acm
          : null,
        endLastContactedHi5Acm: endLastContactedHi5Acm
          ? endLastContactedHi5Acm
          : null,
        startTotalOrderCount,
        endTotalOrderCount,
        freeDays,
        level,
        assignedUser: unassigned ? unassigned : null,
        goldPackageHi5ACM: goldPackageHi5ACM ? goldPackageHi5ACM : null,
        goldPackage: goldPackage ? goldPackage : null,
        slpPackage: slpPackage ? slpPackage : null,
        autoPilotPackage: autoPilotPackage ? autoPilotPackage : null,
        inactiveGold7Days: inactiveGold7Days ? inactiveGold7Days : null,
        inactiveSlp7Days: inactiveSlp7Days ? inactiveSlp7Days : null,
        startLastSalesDate: lastSalesDay ? lastSalesDay[0]["$d"] : null,
        endLastSalesDate: lastSalesDay ? lastSalesDay[1]["$d"] : null,
        startCreatedAt: createdAtRange ? createdAtRange[0]["$d"] : null,
        endCreatedAt: createdAtRange ? createdAtRange[1]["$d"] : null,
        startAssignmentDate: assignmentDateRange
          ? assignmentDateRange[0]["$d"]
          : null,
        endAssignmentDate: assignmentDateRange
          ? assignmentDateRange[1]["$d"]
          : null,
        subscriptionEndDateStartDate: subscriptionEndDateRange
          ? subscriptionEndDateRange[0]["$d"]
          : null,
        subscriptionEndDateEndDate: subscriptionEndDateRange
          ? subscriptionEndDateRange[1]["$d"]
          : null,
        registeredAtStartDate: registeredDateRange
          ? registeredDateRange[0]["$d"]
          : null,
        registeredAtEndDate: registeredDateRange
          ? registeredDateRange[1]["$d"]
          : null,
        retentionAssignmentDateStartDate: retentionAssignmentDateRange
          ? retentionAssignmentDateRange[0]["$d"]
          : null,
        retentionAssignmentDateEndDate: retentionAssignmentDateRange
          ? retentionAssignmentDateRange[1]["$d"]
          : null,
        hi5AcmAssignmentDateStartDate: hi5AcmAssignmentDateRange
          ? hi5AcmAssignmentDateRange[0]["$d"]
          : null,
        hi5AcmAssignmentDateEndDate: hi5AcmAssignmentDateRange
          ? hi5AcmAssignmentDateRange[1]["$d"]
          : null,
        packageOfInterestSTMIds: packageOfInterestSTM
          ? packageOfInterestSTM
          : null,
        oldPackageOfInterestSTMIds: oldPackageOfInterestSTM
          ? oldPackageOfInterestSTM
          : null,
        packageOfInterestACMIds: packageOfInterestACM
          ? packageOfInterestACM
          : null,
        currentPackageOfInterestACMIds: currentPackageOfInterestACM
          ? currentPackageOfInterestACM
          : null,
        packageOfInterestHi5AcmIds: packageOfInterestHi5Acm
          ? packageOfInterestHi5Acm
          : null,
        currentPackageOfInterestHi5AcmIds: currentPackageOfInterestHi5Acm
          ? currentPackageOfInterestHi5Acm
          : null,
        startGoldPackageEndDate: goldPackageEndDate
          ? goldPackageEndDate[0]["$d"]
          : null,
        endGoldPackageEndDate: goldPackageEndDate
          ? goldPackageEndDate[1]["$d"]
          : null,
        startSlpEndDate: slpPackageEndDate ? slpPackageEndDate[0]["$d"] : null,
        endSlpEndDate: slpPackageEndDate ? slpPackageEndDate[1]["$d"] : null,
        autoSubscriptionRenewalEnabled: autoSubscriptionRenewalEnabled
          ? autoSubscriptionRenewalEnabled
          : null,
        autoSlpSubscriptionRenewalEnabled: autoSlpSubscriptionRenewalEnabled
          ? autoSlpSubscriptionRenewalEnabled
          : null,
        autoGoldSubscriptionRenewalEnabled: autoGoldSubscriptionRenewalEnabled
          ? autoGoldSubscriptionRenewalEnabled
          : null,
      },
    };
    FilterService.create(newFilter)
      .then((response: any) => {
        const responseData: IFilterResponseData = response.data;
        const updatedFilters = [...savedFilters, responseData];
        setSavedFilters(updatedFilters);
        openNotification("success", "Success", "New filter created");
      })
      .catch((error: any) => {
        console.error("Error while saving filter:", error);
        openNotification("error", "Error", "Failed to create new filter");
      })
      .finally(() => {
        setIsFilterSaveModalOpen(false);
      });
  };

  useEffect(() => {
    const storedFilters = localStorage.getItem("savedFilters");
    if (storedFilters) {
      setSavedFilters(JSON.parse(storedFilters));
    }
  }, []);

  useEffect(() => {
    if (isModalOpen && formFields) {
      if (formFields.masTeamMember) {
        if (masTeamOptions.length > 0) {
          const selectedUser: any = masTeamOptions.find(
            // eslint-disable-next-line
            (option: any) => option.id == formFields.masTeamMember
          );
          form.setFieldsValue({
            masTeamMember: selectedUser.id,
          });
        }
      }
      if (formFields.hasNoPackages) {
        form.setFieldsValue({
          hasNoPackages: formFields.hasNoPackages,
        });
      }
      if (formFields.additionalPackage) {
        form.setFieldsValue({
          additionalPackage: formFields.additionalPackage,
        });
      }
      if (formFields.state) {
        form.setFieldsValue({ state: formFields.state });
      }
      if (formFields.startPassiveDay) {
        form.setFieldsValue({ startPassiveDay: formFields.startPassiveDay });
      }
      if (formFields.endPassiveDay) {
        form.setFieldsValue({ endPassiveDay: formFields.endPassiveDay });
      }
      if (formFields.startLastContactedHi5Acm) {
        form.setFieldsValue({
          startLastContactedHi5Acm: formFields.startLastContactedHi5Acm,
        });
      }
      if (formFields.endLastContactedHi5Acm) {
        form.setFieldsValue({
          endLastContactedHi5Acm: formFields.endLastContactedHi5Acm,
        });
      }
      if (formFields.currentPackage) {
        form.setFieldsValue({ currentPackage: formFields.currentPackage });
      }
      if (formFields.level) {
        form.setFieldsValue({ level: formFields.level });
      }
      if (formFields.startTotalOrderCount) {
        form.setFieldsValue({
          startTotalOrderCount: formFields.startTotalOrderCount,
        });
      }
      if (formFields.endTotalOrderCount) {
        form.setFieldsValue({
          endTotalOrderCount: formFields.endTotalOrderCount,
        });
      }
      if (formFields.subscriptionEndDate) {
        form.setFieldsValue({
          subscriptionEndDate: formFields.subscriptionEndDate,
        });
      }
      if (formFields.goldPackageHi5ACM) {
        form.setFieldsValue({
          goldPackageHi5ACM:
            formFields.goldPackageHi5ACM === "true" ? true : false,
        });
      }
      if (formFields.slpPackage) {
        form.setFieldsValue({
          slpPackage: formFields.slpPackage === "true" ? true : false,
        });
      }
      if (formFields.autoPilotPackage) {
        form.setFieldsValue({
          autoPilotPackage: formFields.autoPilotPackage === "true" ? true : false,
        });
      }
      if (formFields.inactiveSlp7Days) {
        form.setFieldsValue({
          inactiveSlp7Days:
            formFields.inactiveSlp7Days === "true" ? true : false,
        });
      }
      if (formFields.createdAtRange?.[0] && formFields.createdAtRange?.[1]) {
        const formattedStartDate = dayjs(
          dayjs(formFields.createdAtRange[0]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        const formattedEndDate = dayjs(
          dayjs(formFields.createdAtRange[1]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        form.setFieldsValue({
          createdAtRange: [formattedStartDate, formattedEndDate],
        });
      }
      if (
        formFields.assignmentDateRange?.[0] &&
        formFields.assignmentDateRange?.[1]
      ) {
        const formattedStartDate = dayjs(
          dayjs(formFields.assignmentDateRange[0]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        const formattedEndDate = dayjs(
          dayjs(formFields.assignmentDateRange[1]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        form.setFieldsValue({
          assignmentDateRange: [formattedStartDate, formattedEndDate],
        });
      }
      if (
        formFields.subscriptionEndDateRange?.[0] &&
        formFields.subscriptionEndDateRange?.[1]
      ) {
        const formattedStartDate = dayjs(
          dayjs(formFields.subscriptionEndDateRange[0]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        const formattedEndDate = dayjs(
          dayjs(formFields.subscriptionEndDateRange[1]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        form.setFieldsValue({
          subscriptionEndDateRange: [formattedStartDate, formattedEndDate],
        });
      }
      if (
        formFields.registeredDateRange?.[0] &&
        formFields.registeredDateRange?.[1]
      ) {
        const formattedStartDate = dayjs(
          dayjs(formFields.registeredDateRange[0]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        const formattedEndDate = dayjs(
          dayjs(formFields.registeredDateRange[1]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        form.setFieldsValue({
          registeredDateRange: [formattedStartDate, formattedEndDate],
        });
      }
      if (
        formFields.hi5AcmAssignmentDateRange?.[0] &&
        formFields.hi5AcmAssignmentDateRange?.[1]
      ) {
        const formattedStartDate = dayjs(
          dayjs(formFields.hi5AcmAssignmentDateRange[0]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        const formattedEndDate = dayjs(
          dayjs(formFields.hi5AcmAssignmentDateRange[1]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        form.setFieldsValue({
          hi5AcmAssignmentDateRange: [formattedStartDate, formattedEndDate],
        });
      }
      if (formFields.lastSalesDay?.[0] && formFields.lastSalesDay?.[1]) {
        const formattedStartDate = dayjs(
          dayjs(formFields.lastSalesDay[0]["$d"]).format("YYYY-MM-DDTHH:mm:ss"),
          "YYYY-MM-DDTHH:mm:ss"
        );
        const formattedEndDate = dayjs(
          dayjs(formFields.lastSalesDay[1]["$d"]).format("YYYY-MM-DDTHH:mm:ss"),
          "YYYY-MM-DDTHH:mm:ss"
        );
        form.setFieldsValue({
          lastSalesDay: [formattedStartDate, formattedEndDate],
        });
      }
      if (formFields.packageOfInterestHi5Acm) {
        form.setFieldsValue({
          packageOfInterestHi5Acm: formFields.packageOfInterestHi5Acm,
        });
      }
      if (formFields.currentPackageOfInterestHi5Acm) {
        form.setFieldsValue({
          currentPackageOfInterestHi5Acm:
            formFields.currentPackageOfInterestHi5Acm,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, masTeamOptions]);

  const showSavedFilterListModal = () => {
    getSavedFiltersList();
    setIsFilterSavedListModalOpen(true);
  };

  const getSavedFiltersList = () => {
    FilterService.getList()
      .then((response: any) => {
        let data = response.data;
        setSavedFiltersList(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const showFilterDeleteModal = () => {
    setIsFilterDeleteModalOpen(true);
  };

  const handleDeleteFilter = () => {
    const selectedFilterId = savedFilterId;
    if (!selectedFilterId) {
      return;
    }
    FilterService.remove(selectedFilterId)
      .then(() => {
        openNotification("success", "Success", "Filter deleted successfully");
        const updatedFilters = savedFilters.filter(
          (filter) => filter.id !== selectedFilterId
        );
        setSavedFilters(updatedFilters);
        setSavedFilterId(null);
        form.setFieldsValue({ savedFilter: null });
        setSavedFiltersList(
          savedFiltersList.filter((filter) => filter.id !== selectedFilterId)
        );
        setIsFilterDeleteModalOpen(false);
      })
      .catch((error) => {
        console.error("Error while deleting filter:", error);
        openNotification("error", "Error", "Failed to delete filter");
      });
  };

  const getSavedFilterById = () => {
    if (!savedFilterId) return;
    FilterService.get(savedFilterId)
      .then((response: any) => {
        const filterData = response.data;

        const formattedData = {
          accountManagerMember: filterData.userId || null,
          hi5AcmMember: filterData.hi5AcmMember || null,
          callCenterMember: filterData.callCenterMember || null,
          successTeamMember: filterData.successTeamMember || null,
          assignmentDateRange: [
            filterData.startAssignmentDate,
            filterData.endAssignmentDate,
          ].filter((date) => date),
          hasNoPackages: filterData.hasNoPackages
            ? filterData.hasNoPackages
            : null,
          additionalPackage: filterData.additionalPackgeIds
            ? filterData.additionalPackgeIds
                .split(",")
                .map((id: string) => parseInt(id))
            : null,
          createdAtRange: [
            filterData.startCreatedAt,
            filterData.endCreatedAt,
          ].filter((date) => date),
          currentPackage:
            filterData.currentPackage != null
              ? filterData.currentPackage.toString()
              : null,
          endPassiveDay:
            filterData.endPassiveDays != null
              ? filterData.endPassiveDays
              : null,
          endLastContactedStm:
            filterData.endLastContactedStm != null
              ? filterData.endLastContactedStm
              : null,
          endLastContactedAcm:
            filterData.endLastContactedAcm != null
              ? filterData.endLastContactedAcm
              : null,
          endLastContactedhi5Acm:
            filterData.endLastContactedhi5Acm != null
              ? filterData.endLastContactedhi5Acm
              : null,
          endTotalOrderCount:
            filterData.endTotalOrderCount != null
              ? filterData.endTotalOrderCount
              : null,
          freeDays:
            filterData.freeDays != null ? filterData.freeDays.toString() : null,
          lastSalesDay: [
            filterData.startLastSalesDate,
            filterData.endLastSalesDate,
          ].filter((date) => date),
          level: filterData.level != null ? filterData.level.toString() : null,
          startPassiveDay:
            filterData.startPassiveDays != null
              ? filterData.startPassiveDays
              : null,
          startLastContactedStm:
            filterData.startLastContactedStm != null
              ? filterData.startLastContactedStm
              : null,
          startLastContactedAcm:
            filterData.startLastContactedAcm != null
              ? filterData.startLastContactedAcm
              : null,
          startLastContactedHi5Acm:
            filterData.startLastContactedHi5Acm != null
              ? filterData.startLastContactedHi5Acm
              : null,
          startTotalOrderCount:
            filterData.startTotalOrderCount != null
              ? filterData.startTotalOrderCount
              : null,
          state: filterData.state || null,
          status: filterData.statusIds
            ? filterData.statusIds.split(",").map((id: string) => parseInt(id))
            : null,
          acmStatus: filterData.acmStatusIds
            ? filterData.acmStatusIds
                .split(",")
                .map((id: string) => parseInt(id))
            : null,
          hi5AcmStatus: filterData.hi5AcmStatusIds
            ? filterData.hi5AcmStatusIds
                .split(",")
                .map((id: string) => parseInt(id))
            : null,
          subscriptionEndDateRange: [
            filterData.subscriptionEndDateStartDate,
            filterData.subscriptionEndDateEndDate,
          ].filter((date) => date),
          registeredDateRange: [
            filterData.registeredAtStartDate,
            filterData.registeredAtEndDate,
          ].filter((date) => date),
          retentionAssignmentDateRange: [
            filterData.retentionAssignmentDateStartDate,
            filterData.retentionAssignmentDateEndDate,
          ].filter((date) => date),
          hi5AcmAssignmentDateRange: [
            filterData.hi5AcmAssignmentDateStartDate,
            filterData.hi5AcmAssignmentDateEndDate,
          ].filter((date) => date),
          unassigned:
            filterData.assignedUser != null ? filterData.assignedUser : null,
          goldPackageHi5ACM:
            filterData.goldPackageHi5ACM != null
              ? filterData.goldPackageHi5ACM
              : null,
          goldPackage:
            filterData.goldPackage != null ? filterData.goldPackage : null,
          slpPackage:
            filterData.slpPackage != null ? filterData.slpPackage : null,
          autoPilotPackage:
            filterData.autoPilotPackage != null ? filterData.autoPilotPackage : null,
          inactiveGold7Days:
            filterData.inactiveGold7Days != null
              ? filterData.inactiveGold7Days
              : null,
          inactiveSlp7Days:
            filterData.inactiveSlp7Days != null
              ? filterData.inactiveSlp7Days
              : null,
          packageOfInterestSTM: filterData.packageOfInterestSTMIds
            ? filterData.packageOfInterestSTMIds
            : null,
          oldPackageOfInterestSTM: filterData.oldPackageOfInterestSTMIds
            ? filterData.oldPackageOfInterestSTMIds
            : null,
          packageOfInterestACM: filterData.packageOfInterestACMIds
            ? filterData.packageOfInterestACMIds
            : null,
          currentPackageOfInterestACM: filterData.currentPackageOfInterestACMIds
            ? filterData.currentPackageOfInterestACMIds
            : null,
          packageOfInterestHi5Acm: filterData.packageOfInterestHi5AcmIds
            ? filterData.packageOfInterestHi5AcmIds
            : null,
          currentPackageOfInterestHi5Acm:
            filterData.currentPackageOfInterestHi5AcmIds
              ? filterData.currentPackageOfInterestHi5AcmIds
              : null,
          goldPackageEndDate: [
            filterData.startGoldPackageEndDate,
            filterData.endGoldPackageEndDate,
          ].filter((date) => date),
          slpPackageEndDate: [
            filterData.startSlpEndDate,
            filterData.endSlpEndDate,
          ].filter((date) => date),
          autoSubscriptionRenewalEnabled:
            filterData.autoSubscriptionRenewalEnabled != null
              ? filterData.autoSubscriptionRenewalEnabled
              : null,
          autoSlpSubscriptionRenewalEnabled:
            filterData.autoSlpSubscriptionRenewalEnabled != null
              ? filterData.autoSlpSubscriptionRenewalEnabled
              : null,
          autoGoldSubscriptionRenewalEnabled:
            filterData.autoGoldSubscriptionRenewalEnabled != null
              ? filterData.autoGoldSubscriptionRenewalEnabled
              : null,
        };
        onChangeFilter(formattedData);
        setIsFilterSavedListModalOpen(false);
      })
      .catch((error: any) => {
        console.error("Error while getting filter data:", error);
        openNotification("error", "Error", "Failed to get filter data");
      });
  };

  return (
    <>
      <Modal
        title="Customer Filter"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Row gutter={16}>
            {(roleName === "MAS_TEAM_MANAGER" || roleName === "ADMIN") &&
            masTeamOptions ? (
              <Col xs={24}>
                MAS Team Member
                <Item name="masTeamMember">
                  <Select placeholder={"Select a Member"}>
                    <Select.Option key={0} value={null}>
                      Select
                    </Select.Option>
                    {masTeamOptions?.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.firstName} {option.lastName}
                      </Select.Option>
                    ))}
                  </Select>
                </Item>
              </Col>
            ) : null}
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              Feedback Status
              <Item name="feedbackStatus">
                <Row gutter={16}>
                  <Col span={18}>
                    <Select
                      placeholder={"Select a Status"}
                      options={feedbackStatusOptions}
                      showSearch
                      allowClear
                      mode="multiple"
                      value={selectedFeedbackStatusItems}
                      onChange={handleChangeFeedbackStatus}
                      filterOption={(input, option: any) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  </Col>
                  <Col span={6}>
                    <Button
                      style={{ float: "right" }}
                      onClick={selectAllFeedbackStatus}
                    >
                      Select All
                    </Button>
                  </Col>
                </Row>
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              Feedback Channel
              <Item name="feedbackChannel">
                <Row gutter={16}>
                  <Col span={18}>
                    <Select
                      placeholder={"Select a Channel"}
                      options={feedbackChannelOptions}
                      showSearch
                      allowClear
                      mode="multiple"
                      value={selectedFeedbackChannelItems}
                      onChange={handleChangeFeedbackChannel}
                      filterOption={(input, option: any) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  </Col>
                  <Col span={6}>
                    <Button
                      style={{ float: "right" }}
                      onClick={selectAllFeedbackChannel}
                    >
                      Select All
                    </Button>
                  </Col>
                </Row>
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              Additional Packages
              <Item name="additionalPackage">
                <Select
                  placeholder={"Select a Package"}
                  allowClear
                  mode="multiple"
                >
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  {additionalPackageOptions?.map((option) => (
                    <Select.Option
                      key={option.packageId}
                      value={option.packageId}
                    >
                      {option.packageName}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              Created Date
              <Item name="createdAtRange">
                <RangePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              Registered Date
              <Item name="registeredDateRange">
                <RangePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              Subscription End Date
              <Item name="subscriptionEndDateRange">
                <RangePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
              </Item>
            </Col>
          </Row>
          <Item style={{ marginTop: "10px" }}>
            <Row justify="end">
              <Button
                key="back"
                style={{ marginRight: "10px" }}
                onClick={clearForm}
              >
                Clear
              </Button>
              <Button htmlType="submit" type="primary">
                Apply
              </Button>
            </Row>
          </Item>
        </Form>
        <Row justify="start">
          <Button onClick={showFilterSaveModal} type="primary">
            Save Filter
          </Button>
        </Row>
        <Modal
          title="Save Filter"
          open={isFilterSaveModalOpen}
          onCancel={() => setIsFilterSaveModalOpen(false)}
          footer={[
            <Button
              key="cancel"
              onClick={() => setIsFilterSaveModalOpen(false)}
            >
              Cancel
            </Button>,
            <Button key="save" type="primary" onClick={handleSaveFilter}>
              Save
            </Button>,
          ]}
        >
          <Form>
            Filter Name
            <Form.Item name="filterName">
              <Input
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                placeholder="Filter Name"
                required
                style={{ width: "100%" }}
                maxLength={50}
              />
            </Form.Item>
          </Form>
        </Modal>
      </Modal>
      <Tooltip title="Filters">
        <Button
          style={{ marginRight: "10px" }}
          onClick={showModal}
          type="default"
        >
          <img src={filterIcon} alt="userIcon" style={{ margin: "1px" }} />
        </Button>
      </Tooltip>
      {savedLocalFilters && savedLocalFilters.length > 0 ? (
        <Tooltip title="Clear Filters">
          <Button
            style={{ marginRight: "10px" }}
            onClick={clearLocalFilterAndForm}
            type="default"
          >
            <img
              src={filterClearIcon}
              alt="userIcon"
              style={{ margin: "1px" }}
            />
          </Button>
        </Tooltip>
      ) : null}
      <Tooltip title="Saved Filters">
        <Button
          style={{ marginRight: "10px" }}
          onClick={showSavedFilterListModal}
          type="default"
        >
          <img src={saveIcon} alt="userIcon" style={{ margin: "1px" }} />
        </Button>
      </Tooltip>
      <Modal
        title="Saved Filters"
        open={isFilterSavedListModalOpen}
        onCancel={() => setIsFilterSavedListModalOpen(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => setIsFilterSavedListModalOpen(false)}
          >
            Cancel
          </Button>,
          <Button key="delete" type="primary" onClick={showFilterDeleteModal}>
            Delete
          </Button>,
          <Button key="apply" type="primary" onClick={getSavedFilterById}>
            Apply
          </Button>,
        ]}
      >
        <Modal
          title="Delete Filter"
          open={isFilterDeleteModalOpen}
          onCancel={() => setIsFilterDeleteModalOpen(false)}
          footer={[
            <Button
              key="cancel"
              onClick={() => setIsFilterDeleteModalOpen(false)}
            >
              Cancel
            </Button>,
            <Button key="delete" type="primary" onClick={handleDeleteFilter}>
              Delete
            </Button>,
          ]}
        >
          <h3>Are you sure you want to delete the saved filter?</h3>
        </Modal>
        <Form>
          Saved Filters
          <Item name="savedFilter">
            <Select
              onChange={(value) => setSavedFilterId(value)}
              value={savedFilterId}
              placeholder="Select"
            >
              {savedFiltersList?.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default Filter;
