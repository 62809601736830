import React, { useEffect, useState } from "react";
import { Avatar, Badge, Card, Col, Row, Space, Table } from "antd";
import ReactApexChart from "react-apexcharts";
import "../AcmDashboard/acm-dashboard.css";
import { formatMoney, getProfilePhotoPath } from "../../../utils";
import DashboardService from "../../../services/DashboardService";

interface TotalCustomersCardProps {
  title: string;
  dateRange: any;
  roleName: string;
}

const TotalCustomersCard: React.FC<TotalCustomersCardProps> = ({
  title,
  dateRange,
  roleName,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [totalSale, setTotalSale] = useState(0);
  const [totalSaleMe, setTotalSaleMe] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalCostMe, setTotalCostMe] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalProfitMe, setTotalProfitMe] = useState(0);
  const [totalProfitRate, setTotalProfitRate] = useState(0);
  const [totalProfitRateMe, setTotalProfitRateMe] = useState(0);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const userId = localStorage.getItem("id");

  // eslint-disable-next-line
  const [options, setOptions] = useState<any>({
    chart: {
      type: "donut",
    },
    stroke: {
      lineCap: "round",
    },
    plotOptions: {
      radialBar: {
        track: {
          background: "#f4f4f4",
        },
        dataLabels: {
          name: {
            show: true,
            fontSize: "15px",
          },
          value: {
            fontWeight: 600,
            formatter: (val: number) => val.toFixed(2) + "%",
          },
        },
      },
    },
    labels: ["Profit Rate"],
    colors: ["#EEC73B"],
    tooltip: {
      enabled: true,
      theme: "dark",
      y: {
        formatter: function (val: number) {
          return val.toFixed(2) + "%";
        },
      },
    },
    responsive: [
      {
        breakpoint: 992,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "12px",
                },
                value: {
                  fontSize: "12px",
                },
              },
            },
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "15px",
                },
                value: {
                  fontSize: "15px",
                },
              },
            },
          },
        },
      },
      // {
      //   breakpoint: 576,
      //   options: {
      //     plotOptions: {
      //       radialBar: {
      //         dataLabels: {
      //           name: {
      //             fontSize: "12px",
      //           },
      //           value: {
      //             fontSize: "12px",
      //           },
      //         },
      //       },
      //     },
      //   },
      // },
      {
        breakpoint: 480,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "12px",
                },
                value: {
                  fontSize: "12px",
                },
              },
            },
          },
        },
      },
      {
        breakpoint: 380,
        options: {
          chart: {
            width: 100,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchTotalCustomers = async (startDate: string, endDate: string) => {
    try {
      const response = await DashboardService.getTotalCustomersMas(
        `?startDate=${startDate}&endDate=${endDate}`
      );
      const fetchedData = response.data.report;
      setData(fetchedData);

      if (roleName === "MAS_TEAM_MANAGER") {
        const totalSale = fetchedData
          .reduce((acc: number, item: any) => acc + item.sale, 0)
          .toFixed(2);
        const totalCost = fetchedData
          .reduce((acc: number, item: any) => acc + item.cost, 0)
          .toFixed(2);
        const totalProfit = fetchedData
          .reduce((acc: number, item: any) => acc + item.profit, 0)
          .toFixed(2);
        const totalProfitRate =
          fetchedData.reduce(
            (acc: number, item: any) => acc + item.profitRate,
            0
          ) / fetchedData.length;

        setTotalSale(totalSale);
        setTotalCost(totalCost);
        setTotalProfit(totalProfit);
        setTotalProfitRate(totalProfitRate);
      } else {
        const myData = fetchedData.find(
          (item: any) => item.member.id === parseInt(userId || "0")
        );
        if (myData) {
          setTotalSaleMe(myData.sale);
          setTotalCostMe(myData.cost);
          setTotalProfitMe(myData.profit);
          setTotalProfitRateMe(myData.profitRate);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dateRange.length > 0) {
      if (dateRange[0] && dateRange[1]) {
        fetchTotalCustomers(dateRange[0], dateRange[1]);
        setLoading(true);
      }
    }
    // eslint-disable-next-line
  }, [dateRange, roleName]);

  const columns = [
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          MAS Team Member
        </p>
      ),
      dataIndex: "member",
      key: "member",
      ellipsis: true,
      width: 220,
      sorter: (a: any, b: any) =>
        a?.member?.firstName?.localeCompare(b?.member?.firstName),
      defaultSortOrder: "ascend" as "ascend",
      render: (text: string, record: any) => (
        <Row
          align={"middle"}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            padding: "0px 0px",
          }}
        >
          <Avatar
            style={{ marginRight: 5 }}
            size={30}
            src={getProfilePhotoPath(1)}
          />
          <b
            style={{
              margin: "0px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {record?.member?.firstName} {record?.member?.lastName}
          </b>
        </Row>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Sale
        </p>
      ),
      dataIndex: "sale",
      key: "sale",
      width: 120,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) => a?.sale - b?.sale,
      render: (text: string, record: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px",
              whiteSpace: "nowrap",
              fontWeight: "bold",
              textAlign: "center",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {formatMoney(record?.sale || 0)}
          </p>
        </div>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Cost
        </p>
      ),
      dataIndex: "cost",
      key: "cost",
      width: 120,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) => a?.cost - b?.cost,
      render: (text: string, record: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px",
              whiteSpace: "nowrap",
              fontWeight: "bold",
              textAlign: "center",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {formatMoney(record?.cost || 0)}
          </p>
        </div>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Profit
        </p>
      ),
      dataIndex: "profit",
      key: "profit",
      width: 120,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) => a?.profit - b?.profit,
      render: (text: string, record: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px",
              whiteSpace: "nowrap",
              fontWeight: "bold",
              textAlign: "center",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {formatMoney(record?.profit || 0)}
          </p>
        </div>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Profit Rate
        </p>
      ),
      dataIndex: "profitRate",
      key: "profitRate",
      width: 150,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) => a?.profitRate - b?.profitRate,
      render: (text: string, record: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px",
              whiteSpace: "nowrap",
              fontWeight: "bold",
              textAlign: "center",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {record?.profitRate?.toFixed(2) || 0.00}%
          </p>
        </div>
      ),
    },
  ];

  return (
    <Card bordered={false} className="info-card" loading={loading}>
      <p
        className="title"
        style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
      >
        {title}
      </p>
      <Row gutter={[12, 12]} align="middle" justify="space-between">
        <Col xs={24} sm={13} md={13}>
          <ReactApexChart
            options={options}
            series={
              roleName === "MAS_TEAM_MANAGER"
                ? [totalProfitRate]
                : [totalProfitRateMe]
            }
            type="radialBar"
            height={200}
          />
        </Col>
        <Col xs={24} sm={11} md={11}>
          <Row
            align="middle"
            justify="center"
            style={{
              margin:
                windowWidth < 480
                  ? "-30px 0px 10px 0px"
                  : windowWidth < 576
                  ? "-20px 0px 10px 0px"
                  : "0px 0px 0px 0px",
            }}
          >
            <Space direction="vertical" size={0}>
              <Row gutter={[3, 3]}>
                <Col
                  xs={24}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "center" : "left",
                  }}
                >
                  <Badge
                    color="#f4f4f4"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Total Sale:{" "}
                        <b>
                          {formatMoney(
                            roleName === "MAS_TEAM_MANAGER"
                              ? totalSale
                              : totalSaleMe
                          )}
                        </b>{" "}
                      </span>
                    }
                  />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "center" : "left",
                  }}
                >
                  <Badge
                    color="#FF4560"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Total Cost:{" "}
                        <b>
                          {formatMoney(
                            roleName === "MAS_TEAM_MANAGER"
                              ? totalCost
                              : totalCostMe
                          )}
                        </b>
                      </span>
                    }
                  />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "center" : "left",
                  }}
                >
                  <Badge
                    color="#0086FF"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Total Profit:{" "}
                        <b>
                          {formatMoney(
                            roleName === "MAS_TEAM_MANAGER"
                              ? totalProfit
                              : totalProfitMe
                          )}
                        </b>
                      </span>
                    }
                  />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "center" : "left",
                  }}
                >
                  <Badge
                    color="#EEC73B"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Total Profit Rate:{" "}
                        <b>
                          %
                          {roleName === "MAS_TEAM_MANAGER"
                            ? totalProfitRate.toFixed(2)
                            : totalProfitRateMe.toFixed(2)}
                        </b>
                      </span>
                    }
                  />
                </Col>
              </Row>
            </Space>
          </Row>
        </Col>
      </Row>
      {roleName === "MAS_TEAM_MANAGER" && (
        <Row>
          <Col span={24}>
            <Table
              rowKey={(record) => record?.member?.id}
              columns={columns}
              dataSource={data}
              pagination={false}
              scroll={{ x: "100%", y: 185 }}
              size="small"
              className="dashboard-table"
            />
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default TotalCustomersCard;
