import { useEffect, useState } from "react";
import CustomerAssignmentHistoryService from "../../../../services/CustomerAssignmentHistoryService";
import { Col, Row, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

const ReasonForDropoutReport = ({
  memberFlag,
  dateRange,
}: {
  memberFlag: string;
  dateRange: any;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const location = useLocation();

  useEffect(() => {
    if (dateRange[0] && dateRange[1] && memberFlag) {
      let formattedStartDate = dayjs(dateRange[0]).format(
        "YYYY-MM-DDT00:00:00"
      );
      let formattedEndDate = dayjs(dateRange[1]).format("YYYY-MM-DDT23:59:59");
      getReasonForDropoutReport(
        formattedStartDate,
        formattedEndDate,
        memberFlag
      );
    }
    // eslint-disable-next-line
  }, [dateRange, location.pathname]);

  const getReasonForDropoutReport = (
    startDate: string,
    endDate: string,
    oldMemberFlag: string
  ) => {
    setLoading(true);
    CustomerAssignmentHistoryService.getStatusBasedOldMemberFlagReport(
      startDate,
      endDate,
      oldMemberFlag
    )
      .then((response) => {
        const groupedData = groupByParentStatus(response.data);
        setData(groupedData);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const groupByParentStatus = (data: any) => {
    const grouped = data.reduce((acc: any, item: any) => {
      const parent = item?.status?.parent;

      if (parent) {
        if (!acc[parent.id]) {
          acc[parent.id] = {
            parentStatus: parent,
            subStatuses: [],
            parentCustomerCount: 0,
          };
        }

        acc[parent.id].parentCustomerCount += item.customerCount;
        acc[parent.id].subStatuses.push(item);
      } else {
        if (!acc[item.status.id]) {
          acc[item.status.id] = {
            parentStatus: item.status,
            subStatuses: [],
            parentCustomerCount: item.customerCount,
          };
        }
      }

      return acc;
    }, {});
    return Object.values(grouped);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Parent Reason Name",
      dataIndex: "parentStatus",
      key: "parentStatus",
      width: 300,
      render: (parentStatus) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={`${parentStatus?.name || "N/A"}`}
        >
          <b>{parentStatus?.name || "N/A"}</b>
        </div>
      ),
    },
    {
      title: "Parent Customer Count",
      dataIndex: "parentCustomerCount",
      key: "parentCustomerCount",
      align: "center" as "center",
      width: 150,
      render: (parentCustomerCount) => <b>{parentCustomerCount || "N/A"}</b>,
    },
  ];

  const expandedRowRender = (record: any) => {
    const subStatusColumns: ColumnsType<any> = [
      {
        title: "Sub Reason Name",
        dataIndex: ["status", "name"],
        key: "name",
        width: 300,
        ellipsis: true,
        render: (text: string) => (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${text}`}
          >
            <b>{text}</b>
          </div>
        ),
      },
      {
        title: "Customer Count",
        dataIndex: "customerCount",
        key: "customerCount",
        align: "center" as "center",
        width: 150,
        render: (customerCount) => <b>{customerCount || "N/A"}</b>,
      },
    ];

    return (
      <Table
        columns={subStatusColumns}
        dataSource={record.subStatuses}
        pagination={false}
        rowKey={(subRecord) => subRecord?.status?.id}
      />
    );
  };

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        {location.pathname.includes("/stm-reports/summary-report") ? (
          <h3 style={{ marginLeft: 16 }}>
            {memberFlag
              .toLowerCase()
              .replace(/_/g, " ")
              .replace(/\b\w/g, (char) => char.toUpperCase())}{" "}
            Reason for Dropout Report
          </h3>
        ) : (
          <h3 style={{ marginLeft: 16 }}>Reason for Dropout Report</h3>
        )}
        <Table
          rowKey={(record) => record?.parentStatus?.id}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{ x: "100%" }}
          sticky
          pagination={false}
          expandable={{
            expandedRowRender,
          }}
          summary={(pageData) => {
            let totalCustomerCount = 0;

            pageData.forEach(({ parentCustomerCount }) => {
              totalCustomerCount += parentCustomerCount;
            });

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2} align="left">
                    <b>Total</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="center">
                    <b>{totalCustomerCount}</b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default ReasonForDropoutReport;
