import { Col, Row, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import { SuspendFileStatusInput } from "../../../../types/AmazonSuspendedApplicantReports";

interface SourceBasedSuspendedFileStatusReportProps {
  dateRange: [string, string];
}

const SourceBasedSuspendedFileStatusReport = ({
  dateRange,
}: SourceBasedSuspendedFileStatusReportProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<SuspendFileStatusInput[]>([]);
  const [columns, setColumns] = useState<ColumnsType<SuspendFileStatusInput>>(
    []
  );

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const formattedStartDate = dayjs(dateRange[0]).format(
        "YYYY-MM-DDT00:00:00"
      );
      const formattedEndDate = dayjs(dateRange[1]).format(
        "YYYY-MM-DDT23:59:59"
      );
      getSourceBasedSuspendedFileStatusReport(
        formattedStartDate,
        formattedEndDate
      );
    }
    // eslint-disable-next-line
  }, [dateRange]);

  const getSourceBasedSuspendedFileStatusReport = (
    startDate: string,
    endDate: string
  ) => {
    setLoading(true);
    AmazonSuspendedApplicantReportsService.getSuspendFileStatusInput(
      startDate,
      endDate
    )
      .then((response) => {
        setData(response.data);
        generateColumns(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateColumns = (data: SuspendFileStatusInput[]) => {
    const statusKeys = data.flatMap((item) => Object.keys(item.statusCounts));
    const uniqueStatusKeys = Array.from(new Set(statusKeys));

    const dynamicColumns = uniqueStatusKeys.map((key) => ({
      title: key?.toUpperCase(),
      dataIndex: ["statusCounts", key],
      key,
      width: 150,
      align: "center" as "center",
      sorter: (a: SuspendFileStatusInput, b: SuspendFileStatusInput) =>
        (a.statusCounts[key] ?? 0) - (b.statusCounts[key] ?? 0),
      render: (value: number) => value ?? 0,
    }));

    setColumns([
      {
        title: "Source",
        dataIndex: "inputSource",
        key: "inputSource",
        fixed: "left",
        width: 200,
        ellipsis: true,
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.inputSource.localeCompare(b.inputSource),
        render: (text: string, record: SuspendFileStatusInput) => (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={record.inputSource}
          >
            <b>{record.inputSource}</b>
          </div>
        ),
      },
      ...dynamicColumns,
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        align: "center",
        width: 150,
        sorter: (a, b) => a.total - b.total,
        render: (total: number) => (
          <div>
            <b>{total !== undefined ? total : "N/A"}</b>
          </div>
        ),
      },
      {
        title: "Open Percentage",
        dataIndex: "openPercentage",
        key: "openPercentage",
        align: "center",
        width: 150,
        sorter: (a, b) => a.openPercentage - b.openPercentage,
        render: (openPercentage: number) => (
          <div>
            <b>{openPercentage !== undefined ? openPercentage.toFixed(2) : "N/A"}%</b>
          </div>
        ),
      },
      {
        title: "Overall Percentage",
        dataIndex: "overallPercentage",
        key: "overallPercentage",
        align: "center",
        width: 150,
        sorter: (a, b) => a.overallPercentage - b.overallPercentage,
        render: (overallPercentage: number) => (
          <div>
            <b>{overallPercentage !== undefined ? overallPercentage.toFixed(2) : "N/A"}%</b>
          </div>
        ),
      },
    ]);
  };

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>
          Source Based Suspended File Status Report
        </h3>
        <Table
          loading={loading}
          scroll={{ x: "max-content" }}
          sticky
          rowKey={(record) => record.inputSource}
          columns={columns}
          dataSource={data}
          pagination={false}
          summary={(pageData) => {
            const totalCounts: Record<string, number> = {};
            let totalSum = 0;
            let openPercentageSum = 0;
            let overallPercentageSum = 0;

            pageData.forEach(
              ({ statusCounts, total, openPercentage, overallPercentage }) => {
                // Calculate total for each status
                Object.entries(statusCounts).forEach(([key, value]) => {
                  totalCounts[key] = (totalCounts[key] || 0) + (value ?? 0);
                });

                // Accumulate total, openPercentage, and overallPercentage
                totalSum += total ?? 0;
                openPercentageSum += openPercentage ?? 0;
                overallPercentageSum += overallPercentage ?? 0;
              }
            );

            const rowCount = pageData.length;
            const openPercentageAverage =
              rowCount > 0 ? openPercentageSum / rowCount : 0;
            const overallPercentageAverage =
              rowCount > 0 ? overallPercentageSum / rowCount : 0;

            return (
              <Table.Summary fixed>
                <Table.Summary.Row className="summary-cell">
                  <Table.Summary.Cell
                    index={0}
                    colSpan={1}
                    align="left"
                    className="summary-cell"
                  >
                    <b>Total</b>
                  </Table.Summary.Cell>
                  {columns.slice(1, -3).map((column, index) => (
                    <Table.Summary.Cell
                      key={column.key}
                      index={index + 1}
                      align="center"
                      className="summary-cell"
                    >
                      <b>{totalCounts[column.key as string] ?? 0}</b>
                    </Table.Summary.Cell>
                  ))}
                  <Table.Summary.Cell
                    index={columns.length - 3}
                    align="center"
                    className="summary-cell"
                  >
                    <b>{totalSum}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={columns.length - 2}
                    align="center"
                    className="summary-cell"
                  >
                    <b>{openPercentageAverage.toFixed(2)}%</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={columns.length - 1}
                    align="center"
                    className="summary-cell"
                  >
                    <b>{overallPercentageAverage.toFixed(2)}%</b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default SourceBasedSuspendedFileStatusReport;
