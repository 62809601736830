import React from "react";
import { Card } from "antd";
import "../AcmDashboard/acm-dashboard.css";

interface InfoCardProps {
  count: string;
  title: string;
  description: string;
  loading: boolean;
}

const InfoCard: React.FC<InfoCardProps> = ({
  count,
  title,
  description,
  loading,
}) => {
  return (
    <Card
      bordered={false}
      className="info-card"
      loading={loading}
      style={{ marginBottom: 0 }}
    >
      <div className="info-card-container">
        <div>
          <p
            className="title"
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </p>
          <p className="description" style={{ marginBottom: description === "" ? "24px" : 0 }}>
            {description}
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "end" }}>
          <p className="count">{count}</p>
          <p
            className="description"
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              padding: "0px",
              margin: "0px 10px",
              textAlign: "right",
            }}
          >
            {title === "Retention Process" ? "Pending / Retained" : (title ===  "Under 6 Months For Retention" || title === "Over 6 Months For Retention") ? "Contacted" : "Today"}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default InfoCard;
