import { Input, Row, Space } from "antd";
import React, { useCallback, useState } from "react";
import Filter from "./Filter";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import _ from "lodash";

export const FilterField: React.FC<any> = ({
  onChangeSearch,
  setFilterText,
  pageSize,
  sortOrder,
  sortField,
  setFormFields,
  formFields,
}) => {
  const [searchText, setSearchText] = useState("");

  // eslint-disable-next-line
  const debouncedSearch = useCallback(_.debounce(onChangeSearch, 500), [
    onChangeSearch,
  ]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchText(newValue);
    debouncedSearch(newValue);
  };

  const clearInput = () => {
    setSearchText("");
    debouncedSearch("");
  };

  const handleOnChangeFilter = (filterForm: any) => {
    const normalizedSortOrder =
      sortOrder === "ascend"
        ? "asc"
        : sortOrder === "descend"
        ? "desc"
        : sortOrder;

    let filterQuery = `?page=0&size=${pageSize}&sort=${sortField}&direction=${normalizedSortOrder}`;

    if (filterForm.oldMemberFlag && filterForm.oldMemberFlag != null) {
      filterQuery += `&oldMemberFlag=${filterForm.oldMemberFlag}`;
    }

    if (filterForm.newMemberFlag && filterForm.newMemberFlag != null) {
      filterQuery += `&newMemberFlag=${filterForm.newMemberFlag}`;
    }

    if (filterForm.reasonForDropoutStatus && filterForm.reasonForDropoutStatus != null) {
      filterQuery += `&assignmentReasonStatusIds=${filterForm.reasonForDropoutStatus}`;
    }

    if (filterForm.assignmentDateRange !== undefined) {
      if (filterForm.assignmentDateRange.length > 0) {
        let startDate = dayjs(filterForm.assignmentDateRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.assignmentDateRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&startAssignmentDate=${startDate}&endAssignmentDate=${endDate}`;
      }
    }

    setFilterText(filterQuery);
  };

  return (
    <Row justify="end">
      <Space align="start">
        <Input
          prefix={<SearchOutlined />}
          suffix={
            searchText && (
              <CloseCircleOutlined
                onClick={clearInput}
                style={{ color: "rgba(0,0,0,.45)", cursor: "pointer" }}
              />
            )
          }
          placeholder="Search"
          value={searchText}
          onChange={onSearchChange}
          style={{ maxWidth: 200 }}
        />
        <Filter
          onChangeFilter={handleOnChangeFilter}
          setFormFields={setFormFields}
          formFields={formFields}
        />
      </Space>
    </Row>
  );
};
