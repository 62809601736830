import React from "react";
import { ColumnsType } from "antd/es/table";
import { Button, Modal, Table } from "antd";
import moment from "moment";
import { formatMoney } from "../../../../utils";
import * as XLSX from "xlsx";
import { DownloadOutlined } from "@ant-design/icons";

interface SoftwareRetentionSalesLog {
  fullName: string;
  phone: string;
  email: string;
  renewSubscriptionHistoryList: Array<{
    packageName: string;
    paidAmount: number;
    startDate: string;
  }>;
}

interface EarningsSoftwareRetentionDetailReportModalProps {
  visible: boolean;
  onClose: () => void;
  member: any;
  softwareRetentionSalesLogs: SoftwareRetentionSalesLog[];
}

export const EarningsSoftwareRetentionDetailReportModal: React.FC<
  EarningsSoftwareRetentionDetailReportModalProps
> = ({ visible, onClose, member, softwareRetentionSalesLogs }) => {

  const modalColumns: ColumnsType<SoftwareRetentionSalesLog> = [
    {
      title: "Created Date",
      dataIndex: ["renewSubscriptionHistoryList", "startDate"],
      key: "startDate",
      width: 150,
      align: "center",
      fixed: "left",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) =>
        moment(a?.renewSubscriptionHistoryList[0]?.startDate).unix() -
        moment(b?.renewSubscriptionHistoryList[0]?.startDate).unix(),
      render: (text: string, record: any) =>
        moment(record?.renewSubscriptionHistoryList[0]?.startDate).format(
          "YYYY-MM-DD HH:mm"
        ),
    },

    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      ellipsis: true,
      width: 150,
      fixed: "left" as "left",
      sorter: (a: any, b: any) =>
        (a?.fullName || "").localeCompare(b?.fullName || ""),
      render: (text: string, record: any) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={`${text}`}
        >
          <b>{text}</b>
        </div>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      width: 150,
      render: (text: string) => <>{text}</>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      width: 150,
      render: (text: string, record: any) => <>{record?.email}</>,
    },
    {
      title: "Package Name",
      dataIndex: ["renewSubscriptionHistoryList", "packageName"],
      key: "packageName",
      ellipsis: true,
      width: 150,
      sorter: (a: any, b: any) =>
        a?.renewSubscriptionHistoryList[0]?.packageName.localeCompare(
          b?.renewSubscriptionHistoryList[0]?.packageName
        ),
      render: (text: string, record: any) => (
        <b>{record?.renewSubscriptionHistoryList[0]?.packageName}</b>
      ),
    },
    {
      title: "Package Price",
      dataIndex: ["renewSubscriptionHistoryList", "paidAmount"],
      key: "paidAmount",
      width: 150,
      align: "center",
      sorter: (a: any, b: any) =>
        a?.renewSubscriptionHistoryList[0]?.paidAmount -
        b?.renewSubscriptionHistoryList[0]?.paidAmount,
      render: (text: number, record: any) =>
        `${formatMoney(record?.renewSubscriptionHistoryList[0]?.paidAmount)}`,
    },
    {
      title: "Commission Price",
      dataIndex: "",
      key: "index",
      width: 150,
      align: "center",
      fixed: "right",
      render: (text: number) => <b>{formatMoney("3")}</b>,
    },
  ];

  const downloadExcel = (
    softwareRetentionSalesLogs: SoftwareRetentionSalesLog[],
    member: { firstName: string; lastName: string }
  ) => {
    const data = softwareRetentionSalesLogs?.map(
      (item: SoftwareRetentionSalesLog) => {
        const history = item?.renewSubscriptionHistoryList[0];
        const baseData: any = {
          "Created Date": moment(history?.startDate).format("YYYY-MM-DD HH:mm"),
          "Full Name": item?.fullName,
          "Phone Number": item?.phone,
          Email: item?.email,
          "Package Name": history?.packageName,
          "Commission Price": formatMoney("3"),
          "Package Price": formatMoney(history?.paidAmount),
        };
        return baseData;
      }
    );
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `Earnings Report Details`);
    XLSX.writeFile(
      wb,
      `${member?.firstName} ${member?.lastName} - Software Retention Earnings Report Details.xlsx`
    );
  };

  return (
    <Modal
      title={`${member?.firstName} ${member?.lastName} - Software Retention Earnings Report Details`}
      open={visible}
      onCancel={onClose}
      width={1000}
      footer={
        <Button
          style={{ marginLeft: "20px" }}
          icon={<DownloadOutlined />}
          onClick={() => downloadExcel(softwareRetentionSalesLogs, member)}
          type="primary"
        >
          Export
        </Button>
      }
    >
      <Table
        columns={modalColumns}
        dataSource={softwareRetentionSalesLogs}
        pagination={false}
        rowKey="id"
        size="small"
        sticky
        scroll={{ x: "max-content", y: 500 }}
      />
    </Modal>
  );
};
