import { Card, Row, Col, Typography, Space } from "antd";

const { Title, Text } = Typography;

const CustomService = ({ data }: { data: any }) => {
  const calculateRate = (key: string) => {
    if (data && data.length > 0 && data[0].orderDefectRate?.mfn) {
      let rate =
        (data[0].orderDefectRate.mfn[key].count * 100.0) /
        (data[0].orderDefectRate.mfn.orderCount > 0
          ? data[0].orderDefectRate.mfn.orderCount
          : 1);
      return Number.isInteger(rate) ? rate : rate.toFixed(2);
    }
    return "N/A";
  };

  if (!data || data.length === 0 || !data[0].orderDefectRate) {
    return <Text>No data available</Text>;
  }

  return (
    <Card
      title={
        <span style={{ textTransform: "uppercase" }}>
          Customer Service Performance
        </span>
      }
      bordered={true}
      style={{ borderRadius: "8px" }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row justify="end" style={{ paddingRight: "16px" }}>
          <Text strong>Seller fulfilled</Text>
        </Row>
        <Card bordered={true} style={{ borderRadius: "8px" }}>
          <Row>
            <Col span={16}>
              <Space direction="vertical">
                <Title level={4}>Order Defect Rate</Title>
                <Text>Target: under 1%</Text>
              </Space>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Title level={2}>
                {data[0].orderDefectRate?.mfn
                  ? Number.isInteger(data[0].orderDefectRate.mfn.rate * 100)
                    ? data[0].orderDefectRate.mfn.rate * 100
                    : (data[0].orderDefectRate.mfn.rate * 100).toFixed(2)
                  : "N/A"}
                %
              </Title>
              <Text>
                {data[0].orderDefectRate?.mfn
                  ? `${data[0].orderDefectRate.mfn.orderWithDefects.count} of ${data[0].orderDefectRate.mfn.orderCount} orders`
                  : "N/A"}
              </Text>
              <br />
              <Text>60 days</Text>
            </Col>
          </Row>
        </Card>

        <Text style={{ marginTop: "16px" }}>
          Order Defect Rate consists of different metrics:
        </Text>

        {[
          { label: "Negative Feedback", key: "negativeFeedback" },
          { label: "A-to-Z Guarantee Claims", key: "claims" },
          { label: "Chargeback Claims", key: "chargebacks" },
        ].map((metric) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "8px 0",
              borderBottom: "1px solid #f0f0f0",
            }}
            key={metric.key}
          >
            <Text style={{ display: "flex", alignItems: "center" }}>{`● ${metric.label}`}</Text>
            <Text strong style={{ display: "flex", alignItems: "center" }}>{calculateRate(metric.key)}%</Text>
          </div>
        ))}
      </Space>
    </Card>
  );
};

export default CustomService;
