import React, { useEffect, useState } from "react";
import { Button, Divider, Drawer, Form, Input, Select, Space } from "antd";
import UserService from "../../services/UserService";
import RoleService from "../../services/RoleService";
import { IUserResponseData } from "../../types/User";
import { IRoleResponseData } from "../../types/Role";
import { can, openNotification } from "../../utils";

interface UserDetailProps {
  id: any;
  data: any;
}

const UserDetail: React.FC<UserDetailProps> = ({ id, data }) => {
  const [updateSalesTargetForm] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState<IUserResponseData>();
  const [roles, setRoles] = useState<IRoleResponseData[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
  const [form] = Form.useForm();
  // eslint-disable-next-line
  const [updateSalesTargetFormLoading, setUpdateSalesTargetFormLoading] =
    useState(false);
  const roleName = localStorage.getItem("roleName");

  const showUserDetail = () => {
    setOpen(true);
  };

  useEffect(() => {
    getRoles();
    getUser(id);
    // eslint-disable-next-line
  }, [open]);

  const onClose = () => {
    setOpen(false);
  };

  const updateRoleByUserId = () => {
    UserService.updateRoleByUserId(id, { roleIdList: selectedRoles })
      .then((response: any) => {
        openNotification("success", "Success", "Changed roles");
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getRoles = () => {
    if (can("roles:getAll")) {
      RoleService.getAll()
        .then((response: any) => {
          let data = response.data;
          setRoles(data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const getUser = (id: number) => {
    if (can("users:get")) {
      UserService.get(id)
        .then((response: any) => {
          const data = response.data;

          updateSalesTargetForm.setFieldsValue({
            salesTarget: data.salesTarget,
          });
          setUser(data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const handleChange = (value: number[]) => {
    setSelectedRoles(value);
  };

  const updateSalesTarget = (values: any) => {
    let postObj = {
      salesTarget: values.salesTarget,
    };
    UserService.updateSalesTargetByUserId(id, postObj)
      .then((response: any) => {
        openNotification("success", "Success", "Update sales target");
        updateSalesTargetForm.resetFields();
        getUser(id);
      })
      .catch((e: Error) => {});
  };

  return (
    <>
      <Button type="primary" onClick={showUserDetail}>
        Detail
      </Button>
      <Drawer
        title="User Detail"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <h4>Fullname</h4>
        <p>
          {data?.firstName} {data?.lastName}
        </p>
        <Divider />
        <h4>Sales Target</h4>
        <Form
          form={updateSalesTargetForm}
          layout="vertical"
          onFinish={updateSalesTarget}
        >
          <Form.Item
            name="salesTarget"
            rules={[
              {
                required: true,
                message: "Please input sales target!",
              },
            ]}
          >
            <Space.Compact style={{ width: "100%" }}>
              <Input
                addonBefore={user?.salesTarget}
                type="number"
                placeholder="New Sales Target"
              />
              <Button
                htmlType="submit"
                loading={updateSalesTargetFormLoading}
                type="primary"
              >
                Update
              </Button>
            </Space.Compact>
          </Form.Item>
        </Form>
        <Divider />
        {roleName === "ADMIN" ? (
          <>
            <h4>Role</h4>
            <Form
              layout="horizontal"
              style={{ maxWidth: 600 }}
              onFinish={updateRoleByUserId}
              form={form}
            >
              <Select
                mode="multiple"
                placeholder={"Select"}
                style={{ width: "100%" }}
                onChange={handleChange}
              >
                {roles.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.description}
                  </Select.Option>
                ))}
              </Select>
              <Divider />

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : (
          <></>
        )}
      </Drawer>
    </>
  );
};

export default UserDetail;
