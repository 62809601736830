import { Col, Row, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import { CountryReport } from "../../../../types/AmazonSuspendedApplicantReports";

interface SuspendTypeBasedSuspendedFilesByCountryReportProps {
  dateRange: [string, string];
}

const SuspendTypeBasedSuspendedFilesByCountryReport: React.FC<
  SuspendTypeBasedSuspendedFilesByCountryReportProps
> = ({ dateRange }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [countryColumns, setCountryColumns] = useState<ColumnsType<any>>([]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const formattedStartDate = dayjs(dateRange[0]).format(
        "YYYY-MM-DDT00:00:00"
      );
      const formattedEndDate = dayjs(dateRange[1]).format(
        "YYYY-MM-DDT23:59:59"
      );
      getSuspendTypeBasedSuspendedFilesByCountryReport(
        formattedStartDate,
        formattedEndDate
      );
    }
    // eslint-disable-next-line
  }, [dateRange]);

  const getSuspendTypeBasedSuspendedFilesByCountryReport = (
    startDate: string,
    endDate: string
  ) => {
    setLoading(true);
    AmazonSuspendedApplicantReportsService.getSuspendedCountryReports(
      startDate,
      endDate
    )
      .then((response) => {
        transformDataToPivot(response.data.countryReports);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const transformDataToPivot = (reports: CountryReport[]) => {
    const uniqueSuspendTypes = Array.from(
      new Set(reports.map((item) => item.suspendType))
    );
    const uniqueCountries = Array.from(
      new Set(reports.map((item) => item.suspendedCountry).filter(Boolean))
    );

    const pivotData = uniqueSuspendTypes.map((type) => {
      const rowData: any = { suspendType: type };
      uniqueCountries.forEach((country) => {
        const match = reports.find(
          (item) =>
            item.suspendType === type && item.suspendedCountry === country
        );
        rowData[country] = match ? match.totalCount : 0;
      });
      return rowData;
    });

    const dynamicColumns: ColumnsType<any> = [
      {
        title: "Suspend Type",
        dataIndex: "suspendType",
        key: "suspendType",
        // fixed: "left",
        width: 200,
        ellipsis: true,
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.suspendType.localeCompare(b.suspendType),
        render: (text: string, record: any) => (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={record.suspendType?.toUpperCase()}
          >
            <b>{record.suspendType?.toUpperCase()}</b>
          </div>
        ),
      },
      ...uniqueCountries.map((country) => ({
        title: country,
        dataIndex: country,
        key: country,
        align: "center" as "center",
        width: 150,
      })),
    ];

    setTableData(pivotData);
    setCountryColumns(dynamicColumns);
  };

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>
          Suspend Type Based Suspended Files by Country Report
        </h3>
        <Table
          loading={loading}
          scroll={{ x: "max-content" }}
          sticky
          rowKey="suspendType"
          columns={countryColumns}
          dataSource={tableData}
          pagination={false}
          summary={(pageData) => {
            const totals: { [key: string]: number } = {};

            countryColumns.slice(1).forEach((col: any) => {
              totals[col.dataIndex as string] = pageData.reduce(
                (sum, row) => sum + (row[col.dataIndex] || 0),
                0
              );
            });
            return (
              <Table.Summary fixed>
                <Table.Summary.Row className="summary-cell">
                  <Table.Summary.Cell
                    index={0}
                    colSpan={1}
                    align="left"
                    className="summary-cell"
                  >
                    <b>Total</b>
                  </Table.Summary.Cell>
                  {countryColumns.slice(1).map((col: any, index: number) => (
                    <Table.Summary.Cell
                      index={index + 1}
                      key={col.key}
                      align="center"
                      className="summary-cell"
                    >
                      <b>{totals[col.dataIndex as string] || 0}</b>
                    </Table.Summary.Cell>
                  ))}
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default SuspendTypeBasedSuspendedFilesByCountryReport;
