import React from "react";
import { ColumnsType } from "antd/es/table";
import { Button, Modal, Table } from "antd";
import moment from "moment";
import { formatMoney } from "../../../../utils";
import * as XLSX from "xlsx";
import { DownloadOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

interface PackageSalesLog {
  customer: {
    fullName: string;
    phoneNumber: string;
    email: string;
    source: string;
  };
  prospectCustomer: {
    fullName: string;
    phone: string;
    email: string;
    source: string;
  };
  learner: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
  };
  callCenterSalesPackage: {
    name: string;
  };
  commissionPrice: number;
  packagePrice: number;
  createdAt: string;
}

interface EarningsPackageDetailReportModalProps {
  visible: boolean;
  onClose: () => void;
  user: any;
  packageSalesLogs: PackageSalesLog[];
}

export const EarningsPackageDetailReportModal: React.FC<
  EarningsPackageDetailReportModalProps
> = ({ visible, onClose, user, packageSalesLogs }) => {
  const location = useLocation();

  const modalColumns: ColumnsType<PackageSalesLog> = [
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      align: "center",
      fixed: "left",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) =>
        moment(a?.createdAt).unix() - moment(b?.createdAt).unix(),
      render: (text: string) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    ...(location.pathname.includes("/ctm-reports/earnings-report")
      ? [
          {
            title: "Source",
            dataIndex: ["prospectCustomer", "source"],
            key: "source",
            ellipsis: true,
            width: 150,
            fixed: "left" as "left",
            sorter: (a: any, b: any) =>
              (a?.prospectCustomer?.source || "").localeCompare(
                b?.prospectCustomer?.source || ""
              ),
            render: (text: string, record: any) => (
              <>{text || record?.prospectCustomer?.source}</>
            ),
          },
          {
            title: "Full Name",
            dataIndex: ["prospectCustomer", "fullName"],
            key: "fullName",
            ellipsis: true,
            width: 150,
            fixed: "left" as "left",
            sorter: (a: any, b: any) =>
              (
                a?.prospectCustomer?.fullName ||
                `${a?.learner?.firstName} ${a?.learner?.lastName}` ||
                ""
              ).localeCompare(
                b?.prospectCustomer?.fullName ||
                  `${b?.learner?.firstName} ${b?.learner?.lastName}` ||
                  ""
              ),
            render: (text: string, record: any) => (
              <b>
                {text ||
                  `${record?.learner?.firstName} ${record?.learner?.lastName}`}
              </b>
            ),
          },
          {
            title: "Phone Number",
            dataIndex: ["prospectCustomer", "phone"],
            key: "phone",
            width: 150,
            render: (text: string, record: any) => (
              <>{text || record?.learner?.phoneNumber}</>
            ),
          },
          {
            title: "Email",
            dataIndex: ["prospectCustomer", "email"],
            key: "email",
            ellipsis: true,
            width: 150,
            render: (text: string, record: any) => (
              <>{text || record?.learner?.email}</>
            ),
          },
        ]
      : [
          {
            title: "Full Name",
            dataIndex: ["customer", "fullName"],
            key: "fullName",
            ellipsis: true,
            width: 150,
            fixed: "left" as "left",
            sorter: (a: any, b: any) =>
              (
                a?.customer?.fullName ||
                `${a?.learner?.firstName} ${a?.learner?.lastName}` ||
                ""
              ).localeCompare(
                b?.customer?.fullName ||
                  `${b?.learner?.firstName} ${b?.learner?.lastName}` ||
                  ""
              ),
            render: (text: string, record: any) => (
              <b>
                {text ||
                  `${record?.learner?.firstName} ${record?.learner?.lastName}`}
              </b>
            ),
          },
          {
            title: "Phone Number",
            dataIndex: ["customer", "phoneNumber"],
            key: "phoneNumber",
            width: 150,
            render: (text: string, record: any) => (
              <>{text || record?.learner?.phoneNumber}</>
            ),
          },
          {
            title: "Email",
            dataIndex: ["customer", "email"],
            key: "email",
            ellipsis: true,
            width: 150,
            render: (text: string, record: any) => (
              <>{text || record?.learner?.email}</>
            ),
          },
        ]),
    {
      title: "Package Name",
      dataIndex: ["callCenterSalesPackage", "name"],
      key: "packageName",
      ellipsis: true,
      width: 150,
      sorter: (a: any, b: any) =>
        a?.callCenterSalesPackage?.name.localeCompare(
          b?.callCenterSalesPackage?.name
        ),
    },
    {
      title: "Package Price",
      dataIndex: "packagePrice",
      key: "packagePrice",
      width: 150,
      align: "center",
      sorter: (a: any, b: any) => a?.packagePrice - b?.packagePrice,
      render: (text: number) => `${formatMoney(text)}`,
    },
    {
      title: "Commission Price",
      dataIndex: "commissionPrice",
      key: "commissionPrice",
      width: 150,
      align: "center",
      fixed: "right",
      sorter: (a: any, b: any) => a?.commissionPrice - b?.commissionPrice,
      render: (text: number) => <b>{formatMoney(text)}</b>,
    },
  ];

  const downloadExcel = (
    packageSalesLogs: PackageSalesLog[],
    user: { firstName: string; lastName: string }
  ) => {
    const data = packageSalesLogs?.map((item: PackageSalesLog) => {
      const baseData: any = {
        "Created Date": moment(item.createdAt).format("YYYY-MM-DD HH:mm"),
        "Package Name": item?.callCenterSalesPackage?.name,
        "Commission Price": formatMoney(item?.commissionPrice),
        "Package Price": formatMoney(item?.packagePrice),
      };

      if (location.pathname.includes("/ctm-reports/earnings-report")) {
        baseData["Source"] =
          item?.prospectCustomer?.source;
        baseData["Full Name"] =
          item?.prospectCustomer?.fullName ||
          `${item?.learner?.firstName} ${item?.learner?.lastName}`;
        baseData["Phone Number"] =
          item?.prospectCustomer?.phone || item?.learner?.phoneNumber;
        baseData["E-mail"] =
          item?.prospectCustomer?.email || item?.learner?.email;
      } else {
        baseData["Full Name"] =
          item?.customer?.fullName ||
          `${item?.learner?.firstName} ${item?.learner?.lastName}`;
        baseData["Phone Number"] =
          item?.customer?.phoneNumber || item?.learner?.phoneNumber;
        baseData["E-mail"] = item?.customer?.email || item?.learner?.email;
      }

      return baseData;
    });

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `Earnings Report Details`);
    XLSX.writeFile(
      wb,
      `${user?.firstName} ${user?.lastName} Earnings Report Details.xlsx`
    );
  };

  return (
    <Modal
      title={`${user?.firstName} ${user?.lastName} Earnings Report Details`}
      open={visible}
      onCancel={onClose}
      width={1000}
      footer={
        <Button
          style={{ marginLeft: "20px" }}
          icon={<DownloadOutlined />}
          onClick={() => downloadExcel(packageSalesLogs, user)}
          type="primary"
        >
          Export
        </Button>
      }
    >
      <Table
        columns={modalColumns}
        dataSource={packageSalesLogs}
        pagination={false}
        rowKey="id"
        size="small"
        sticky
        scroll={{ x: "max-content", y: 500 }}
      />
    </Modal>
  );
};
