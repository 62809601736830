import {
  Card,
  Col,
  Row,
  Typography,
  Input,
  Table,
  Button,
  Tag,
  Pagination,
  TableProps,
  Select,
  Tooltip,
  Modal,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  SearchOutlined,
  DownloadOutlined,
  CopyOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "./customers.css";
import { useCallback, useEffect, useState } from "react";
import LearnerService from "../services/LearnerService";
import { ILearnerResponseData } from "../types/Learner";
import type { PaginationProps } from "antd";
import Filter from "../components/Learner/Filter";
import { CopyClipboard } from "../components/General/CopyClipboard";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { can, convertDate } from "../utils";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { openNotification } from "../utils";
import LearnerStatusService from "../services/LearnerStatusService";
import { ILearnerStatusResponseData } from "../types/LearnerStatus";
import UserService from "../services/UserService";
import _ from "lodash";
import { StartCall } from "../components/General/StartCall";
import affilateIcon from "./../assets/affilate.svg";
import redFlag from "./../assets/redFlag.svg";
import yellowFlag from "./../assets/yellowFlag.svg";
import greenFlag from "./../assets/greenFlag.svg";
import LearnerMultiNote from "../components/Learner/LearnerMultiNote";
// import LearnerMultiTask from "../components/Learner/LearnerMultiTask";
import CallTeamService from "../services/CallTeamService";
import { useNavigate } from "react-router-dom";
import LearnerMultiStatus from "../components/Learner/LearnerMultiStatus";
import NewTask from "../components/LearnerDetail/Tasks/NewTask";
import StatusService from "../services/StatusService";

const { Title } = Typography;

export const LearnerList = () => {
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 20;
  const lastFilterText = location.state?.filterText ?? "&becomeCustomer=false";
  const lastSortByField = location.state?.sortByField ?? "lessonsCompleted";
  const lastSortByOrder = location.state?.sortByOrder ?? "asc";
  const lastSearchText = location.state?.searchText ?? "";

  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState(lastSearchText ?? "");
  const [param, setParam] = useState(
    localStorage.getItem("learnerFilterText") ||
      `?page=${lastCurrentPage}&size=${lastPageSize}&sort=${lastSortByField}&direction=${lastSortByOrder}${lastFilterText}${lastSearchText}`
  );
  // eslint-disable-next-line
  const [lastFilterSettings, setLastFilterSettings] = useState(
    `?page=${lastCurrentPage}&size=${lastPageSize}&sort=${lastSortByField}&direction=${lastSortByOrder}${lastFilterText}${lastSearchText}`
  );
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);
  const [sortByField, setSortByField] = useState<string>(
    lastSortByField ?? "lessonsCompleted"
  );
  const [sortByOrder, setSortByOrder] = useState<string>(
    lastSortByOrder ?? "asc"
  );
  const [learners, setLearners] = useState<any[]>([]);
  // eslint-disable-next-line
  const [triggerUpdateLearnerList, setTriggerUpdateLearnerList] = useState(0);
  const [filterText, setFilterText] = useState<string>(
    lastSearchText ?? "&becomeCustomer=false"
  );
  const [learnerStatusOptions, setLearnerStatusOptions] = useState<
    ILearnerStatusResponseData[]
  >([]);
  const [userOptions, setUserOptions] = useState<any[]>([]);
  const [adminUserOptions, setAdminUserOptions] = useState<any[]>([]);
  // eslint-disable-next-line
  const [emailList, setEmailList] = useState<string>("");
  const [emailLoading, setEmailLoading] = useState(false);
  const roleName = localStorage.getItem("roleName");
  // const [textToCopy, setTextToCopy] = useState("");
  // const [copied, setCopied] = useState(false);
  const [currentData, setCurrentData] = useState<string>("Learner");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedLearnerIds, setSelectedLearnerIds] = useState<any>();
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [callTeamMembers, setCallTeamMembers] = useState<any[]>([]);
  const [affiliateNames, setAffiliateNames] = useState<any>({});
  const [visibleTooltip, setVisibleTooltip] = useState(null);
  const isHomePage = location.pathname === "/";
  const navigate = useNavigate();
  // const authUserId = localStorage.getItem("id");
  // eslint-disable-next-line
  const [value, setValue] = useState("");

  const currentUser: any = {
    canLearnersGetAll: can("learners:getAll"),
  };

  useEffect(() => {
    getLearnerStatus();
    getUsers();
    getCallTeamMembers();
    // eslint-disable-next-line
  }, []);

  const getUsers = () => {
    if (can("learners:getAll")) {
      if (roleName === "SUCCESS_TEAM_MANAGER") {
        UserService.getAll("?roleName=SUCCESS_TEAM")
          .then((response: any) => {
            let data = response;
            const newArray = data.map((item: any) => {
              let fullName = `${item.firstName} ${item.lastName}`;
              return { value: item.id, label: fullName };
            });
            setUserOptions(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      } else if (roleName === "ADMIN") {
        UserService.getAll("?roleName=ADMIN")
          .then((response: any) => {
            let data = response;
            const newArray = data.map((item: any) => {
              let fullName = `${item.firstName} ${item.lastName}`;
              return { value: item.id, label: fullName };
            });
            setAdminUserOptions(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
    }
  };

  const getLearnerStatus = () => {
    if (roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM") {
      StatusService.getAll("statusType=CALL_CENTER_STATUS", `&active=true`)
        .then((response: any) => {
          let data = response.data;
          const newArray = data.map((item: any) => {
            return { value: item.id, label: item.name, disabled: !item.active };
          });
          setLearnerStatusOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    } else {
      LearnerStatusService.getAll(`?active=true`)
        .then((response: any) => {
          let data = response.data;

          const newArray = data.map((item: any) => {
            return { value: item.id, label: item.name, disabled: !item.active };
          });

          setLearnerStatusOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const handleChangeStatus = (status: any, id: any) => {
    setLoading(true);
    if (roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM") {
      CallTeamService.updateLearnerStatus(id, { callCenterStatusId: status })
        .then((response: any) => {
          openNotification("success", "Success", "Changed assigne user");
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      LearnerService.update(id, { statusId: status })
        .then((response: any) => {
          openNotification("success", "Success", "Changed status");
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handeChangeAssigneUser = (user: any, id: any) => {
    setLoading(true);
    if (roleName === "CALL_TEAM_MANAGER") {
      let data: any = {
        userId: user,
        learnerIds: [id],
      };
      CallTeamService.assignLearnerToMember(data)
        .then((response: any) => {
          openNotification("success", "Success", "Changed assigne user");
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      let data: any = {
        userId: user,
      };
      LearnerService.updateAssigneUser(id, data)
        .then((response: any) => {
          openNotification("success", "Success", "Changed assigne user");
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchAffiliateName = async (affiliateId: any) => {
    if (!affiliateId || affiliateNames[affiliateId]) {
      setVisibleTooltip(affiliateId);
      return;
    }

    try {
      const response = await LearnerService.getAffiliate(affiliateId);
      setAffiliateNames((prev: any) => ({
        ...prev,
        [affiliateId]: response.data.name,
      }));
      setVisibleTooltip(affiliateId);
    } catch (error) {
      console.error("Failed to fetch affiliate name", error);
    }
  };

  const handleMouseLeave = () => {
    setVisibleTooltip(null);
  };

  useEffect(() => {
    const loadFilterTextFromLocalStorage = () => {
      const storedFilterText = localStorage.getItem("learnerFilterText");
      if (storedFilterText) {
        setParam(storedFilterText);
      }
    };
    loadFilterTextFromLocalStorage();
    //  eslint-disable-next-line
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: "",
      dataIndex: "firstName",
      key: "action",
      fixed: "left",
      width: 40,

      render: (_, row) => {
        return (
          <Row justify={"center"} align={"middle"}>
            {row.affiliateId && (
              <div
                onMouseEnter={() => fetchAffiliateName(row.affiliateId)}
                onMouseLeave={handleMouseLeave}
              >
                <Tooltip
                  title={affiliateNames[row.affiliateId] || "Loading..."}
                  visible={visibleTooltip === row.affiliateId} // Control visibility
                >
                  <img src={affilateIcon} alt="Affiliate Icon" />
                </Tooltip>
              </div>
            )}
            {/* <img src={type == 1 ? greenFlag : type == 2 ? yellowFlag : redFlag} style={{ marginRight: 10 }} /> */}
          </Row>
        );
      },
    },
    {
      title: "Learner",
      dataIndex: "firstName",
      key: "action",
      fixed: "left",
      width: 230,
      sorter: true,
      render: (_, row) => {
        const lastLoginDate = dayjs(row.lastLoginAt);
        const today = dayjs();
        const diffDays = today.diff(lastLoginDate, "day");
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let type;
        if (diffDays <= 3) {
          type = 1;
        } else if (diffDays < 6) {
          type = 2;
        } else {
          //  eslint-disable-next-line @typescript-eslint/no-unused-vars
          type = 3;
        }

        return (
          <Row justify={"start"} align={"middle"}>
            <Col>
              <Title className="customer-fullname" level={5}>
                <b>
                  {row.firstName} {row.lastName}
                </b>
              </Title>
              <span className="customer-fullname">
                <CopyClipboard text={row.email}></CopyClipboard>
              </span>
              <br />
              <span className="customer-fullname">
                {row.phoneNumber} <StartCall text={row?.phoneNumber} />
              </span>
            </Col>
          </Row>
        );
      },
    },
    ...(roleName !== "CALL_TEAM_MANAGER" && roleName !== "CALL_TEAM"
      ? [
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: 200,
            render: (_: any, row: any) => {
              if (!loading) {
                if (
                  roleName === "SUCCESS_TEAM_REPORTER" ||
                  roleName === "ADMIN"
                ) {
                  const statusLabel = learnerStatusOptions.find(
                    (option: any) => option.value === row.status.id
                  );
                  return <span>{statusLabel?.label}</span>;
                } else {
                  return (
                    <Select
                      onChange={(selectedStatus) =>
                        handleChangeStatus(selectedStatus, row.id)
                      }
                      options={learnerStatusOptions}
                      showSearch
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                      key={row.id}
                      {...(row?.status?.id && { defaultValue: row.status.id })}
                      placeholder={"Select"}
                      style={{ width: "180px" }}
                    ></Select>
                  );
                }
              } else {
                // Loading durumunda, boş göster
                return <></>;
              }
            },
          },
        ]
      : []),
    ...(roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM"
      ? [
          {
            title: "Status",
            dataIndex: "callCenterStatus",
            key: "callCenterStatus",
            // sorter: true,
            width: 200,
            render: (_: any, row: any) => {
              return (
                <Select
                  onChange={(selectedStatus) =>
                    handleChangeStatus(selectedStatus, row.id)
                  }
                  options={learnerStatusOptions}
                  {...(row?.callCenterStatus?.id && {
                    defaultValue: row.callCenterStatus.id,
                  })}
                  placeholder={"Select"}
                  style={{ width: "180px" }}
                ></Select>
              );
            },
          },
        ]
      : []),
    {
      title: "Remaining Free Days",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      align: "center",
      sorter: (a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
      render: (_, row) => {
        const createdDate = dayjs(row.createdAt);
        let lastDay = dayjs();
        if (row.becomeCustomerAt) {
          lastDay = dayjs(row.becomeCustomerAt);
        }
        const remainingDays = Math.max(
          0,
          45 - lastDay.diff(createdDate, "day")
        );
        let color;

        if (remainingDays < 10) {
          color = "red";
        } else if (remainingDays >= 10 && remainingDays < 20) {
          color = "volcano";
        } else if (remainingDays >= 20 && remainingDays < 40) {
          color = "orange";
        } else {
          color = "green";
        }

        return <Tag color={color}>{remainingDays}</Tag>;
      },
    },
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
      width: 180,
      sorter: true,
      render: (_: any, row: any) => {
        return <span>{row.team?.name ?? "-"} </span>;
      },
    },
    ...(currentUser.canLearnersGetAll
      ? [
          {
            title: "Success Team Member",
            dataIndex: "agent",
            width: 220,
            key: "agent",
            render: (_: any, row: any) => {
              if (
                roleName === "SUCCESS_TEAM_REPORTER" ||
                roleName === "CALL_TEAM_MANAGER" ||
                roleName === "CALL_TEAM" ||
                roleName === "ADMIN"
              ) {
                return (
                  <span>
                    {row.user?.firstName} {row.user?.lastName}
                  </span>
                );
              } else {
                return (
                  <Select
                    onChange={(selectedUser) =>
                      handeChangeAssigneUser(selectedUser, row.id)
                    }
                    options={userOptions}
                    {...(row?.user?.id && { defaultValue: row.user.id })}
                    placeholder={"Select"}
                    style={{ width: "180px" }}
                  ></Select>
                );
              }
            },
          },
        ]
      : []),
    ...(roleName === "CALL_TEAM_MANAGER"
      ? [
          {
            title: "Call Team Member",
            dataIndex: "callCenterMember",
            key: "callCenterMember",
            // sorter: true,
            width: 200,
            render: (_: any, row: any) => {
              return (
                <Select
                  showSearch
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(selectedUser) =>
                    handeChangeAssigneUser(selectedUser, row.id)
                  }
                  options={callTeamMembers}
                  key={row.id}
                  {...(row?.callCenterMember?.id && {
                    defaultValue: row.callCenterMember.id,
                  })}
                  placeholder={"Seçiniz"}
                  style={{ width: "180px" }}
                ></Select>
              );
            },
          },
        ]
      : []),
    {
      title: "Lesson",
      dataIndex: "lessonsCompleted",
      key: "lessonsCompleted",
      width: 90,
      sorter: true,
      align: "center",
      defaultSortOrder: "ascend",
      render: (_, row) => {
        if (row.lessonsCompleted < 10) {
          return <Tag color="red">{row.lessonsCompleted}</Tag>;
        } else if (row.lessonsCompleted >= 10 && row.lessonsCompleted < 20) {
          return <Tag color="volcano">{row.lessonsCompleted}</Tag>;
        } else if (row.lessonsCompleted >= 20 && row.lessonsCompleted < 40) {
          return <Tag color="orange">{row.lessonsCompleted}</Tag>;
        } else {
          return <Tag color="green">{row.lessonsCompleted}</Tag>;
        }
      },
    },
    {
      title: "Last Login At",
      dataIndex: "lastLoginAt",
      width: 140,
      key: "lastLoginAt",
      sorter: true,
      render: (_, row) => {
        return convertDate(row.lastLoginAt);
      },
    },
    {
      title: "Integrated At",
      dataIndex: "becomeCustomerAt",
      key: "becomeCustomerAt",
      width: 140,
      sorter: true,
      render: (_, row) => {
        return convertDate(row.becomeCustomerAt);
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 140,
      sorter: true,
      render: (_, row) => {
        return convertDate(row.createdAt);
      },
    },
    {
      title: "Product Count",
      dataIndex: "totalProductCount",
      key: "totalProductCount",
      width: 140,
      align: "center",
      sorter: true,
      render: (_, row) => {
        if (row?.totalProductCount) {
          return row?.totalProductCount;
        } else {
          return 0;
        }
      },
    },
    ...(currentData !== "Customer"
      ? [
          {
            title: "",
            dataIndex: "firstName",
            width: 70,
            key: "action",
            render: (_: any, row: any) => {
              const lastLoginDate = dayjs(row.lastLoginAt).startOf("day");
              const today = dayjs().startOf("day");
              const diffDays = today.diff(lastLoginDate, "day");

              let type;
              if (diffDays <= 3) {
                type = 1;
              } else if (diffDays <= 6) {
                type = 2;
              } else {
                type = 3;
              }
              return (
                <Row justify={"center"} align={"middle"}>
                  <Tooltip
                    title={
                      diffDays
                        ? `User hasn't logged in for ${diffDays} days.`
                        : `User is hasn't logged never`
                    }
                  >
                    <img
                      alt="flag"
                      src={
                        type === 1
                          ? greenFlag
                          : type === 2
                          ? yellowFlag
                          : redFlag
                      }
                    />
                  </Tooltip>
                </Row>
              );
            },
          },
        ]
      : []),

    // ...roleName !== 'SUCCESS_TEAM_REPORTER' ? [
    {
      key: "id",
      width: 140,
      align: "center",
      fixed: "right",
      title: "Detail",
      dataIndex: "id",
      render: (_: any, record: any) => {
        // if (roleName === 'SUCCESS_TEAM_REPORTER') {
        //   return <LearnerDetailReporter key={row.id} id={row.id} onHandleLearnerList={handleLearnerList} />
        // } else
        if (roleName === "CALL_TEAM_MANAGER") {
          return (
            <Row justify="center" align="middle" gutter={[0, 8]}>
              <Button
                type="primary"
                onClick={() => {
                  // localStorage.setItem("filterText", param);
                  navigate(`/users/detail/${record.id}`, {
                    state: {
                      currentPage: currentPage,
                      pageSize: pageSize,
                      filterText: filterText,
                      sortByField: sortByField,
                      sortByOrder: sortByOrder,
                      searchText: searchText,
                    },
                  });
                }}
              >
                Detail
              </Button>
              <NewTask
                customerName={record?.firstName + " " + record?.lastName}
                customerId={record?.id}
                setTriggerUpdate={() => {}}
                isCustomer={record?.learner === null}
                userOptions={userOptions}
              />
            </Row>
          );
        } else {
          return (
            <Row justify="center" align="middle" gutter={[0, 8]}>
              <Button
                type="primary"
                onClick={() => {
                  // localStorage.setItem("learnerFilterText", param);
                  navigate(`/learners/detail/${record.id}`, {
                    state: {
                      currentPage: currentPage,
                      pageSize: pageSize,
                      filterText: filterText,
                      sortByField: sortByField,
                      sortByOrder: sortByOrder,
                      searchText: searchText,
                    },
                  });
                }}
              >
                Learner Detail
              </Button>
              {roleName !== "SUCCESS_TEAM_REPORTER" ? (
                <NewTask
                  customerName={record?.firstName + " " + record?.lastName}
                  customerId={record?.id}
                  setTriggerUpdate={() => {}}
                  isCustomer={record?.learner === null}
                  userOptions={
                    roleName === "ADMIN" ? adminUserOptions : userOptions
                  }
                />
              ) : null}
            </Row>
          );
          // return <LearnerDetail key={row.id} id={row.id} onHandleLearnerList={handleLearnerList} />
        }
      },
    },
    // ] : [],
  ];

  const handleSearch = useCallback((searchValue: string) => {
    if (searchValue === "") {
      setSearchText("");
    } else {
      let searchQuery = `&fullName=${searchValue}`;
      setSearchText(searchQuery);
    }
  }, []);

  // eslint-disable-next-line
  const debouncedSearch = useCallback(_.debounce(handleSearch, 500), [
    handleSearch,
  ]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    debouncedSearch(newValue);
  };

  function downloadExcel(jsonData: any[]) {
    const data = jsonData.map((learner: any) => {
      let notes = learner?.notes?.map((item: any) => item.description);

      const status = learner?.status?.name;

      return {
        successTeamMember: `${learner?.user?.firstName} ${learner?.user?.lastName}`,
        ...(() => {
          const {
            becomeCustomerAt,
            user,
            createdAt,
            updatedAt,
            registeredAt,
            oneamzAppId,
            lastLoginAt,
            ...rest
          } = learner;
          return rest;
        })(),
        notes: notes ? notes.join(", \n") : "",
        status: status || "",
        lastLoginAt: convertDate(learner?.lastLoginAt),
        IntegratedAt: convertDate(learner?.becomeCustomerAt),
        createdAt: convertDate(learner?.createdAt),
      };
    });

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook to a binary string
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

    function s2ab(s: string) {
      const buffer = new ArrayBuffer(s.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buffer;
    }

    // Save the workbook to a file
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    // Use FileSaver to save the file
    saveAs(blob, "learner-list.xlsx");
  }

  // const handleLearnerList = (id: any) => {
  //   getLearnerById(id);
  // };

  const handleOnChangeFilter = (filterForm: any) => {
    let filterQuery = "";

    if (filterForm.status && filterForm.status != null) {
      filterQuery += `&statusIds=${filterForm.status.join(",")}`;
    }

    if (filterForm.successTeamMember && filterForm.successTeamMember != null) {
      filterQuery += `&userId=${filterForm.successTeamMember}`;
    }

    // Yeni eklendi
    if (filterForm.learnerTeam && filterForm.learnerTeam != null) {
      filterQuery += `&teamId=${filterForm.learnerTeam}`;
    }

    if (filterForm.callTeamMember && filterForm.callTeamMember != null) {
      filterQuery += `&callCenterMember=${filterForm.callTeamMember}`;
    }

    if (filterForm.goldUser && filterForm.goldUser != null) {
      filterQuery += `&goldUser=${filterForm.goldUser}`;
    }

    if (filterForm.learningRange && filterForm.learningRange != null) {
      let [startLessonsCompleted, endLessonsCompleted] =
        filterForm.learningRange.toString().split("-").map(Number);
      filterQuery += `&startLessonsCompleted=${startLessonsCompleted}&endLessonsCompleted=${endLessonsCompleted}`;
    }

    if (filterForm.becomeCustomer) {
      filterQuery += `&becomeCustomer=${filterForm.becomeCustomer}`;
      setCurrentData("Customer");
    } else {
      filterQuery += `&becomeCustomer=${false}`;
      setCurrentData("Learner");
    }

    if (filterForm.lastLoginAtRange !== undefined) {
      if (filterForm.lastLoginAtRange.length > 0) {
        let startDate = dayjs(filterForm.lastLoginAtRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.lastLoginAtRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&startLastLoginAt=${startDate}&endLastLoginAt=${endDate}`;
      }
    }

    if (filterForm.flag !== undefined) {
      let startDate, endDate;

      // Current date
      const today = dayjs();

      if (filterForm.flag === "green") {
        startDate = today.subtract(3, "day").format("YYYY-MM-DDT00:00:00");
        endDate = today.format("YYYY-MM-DDT23:59:59");
      } else if (filterForm.flag === "yellow") {
        startDate = today.subtract(6, "day").format("YYYY-MM-DDT00:00:00");
        endDate = today.subtract(4, "day").format("YYYY-MM-DDT23:59:59");
      } else if (filterForm.flag === "red") {
        endDate = today.subtract(7, "day").format("YYYY-MM-DDT00:00:00");
        startDate = today.format("2020-01-01T23:59:59");
      }

      filterForm.lastLoginAtRange = [startDate, endDate];

      let startDateFormatted = dayjs(startDate).format("YYYY-MM-DDT00:00:00");
      let endDateFormatted = dayjs(endDate).format("YYYY-MM-DDT23:59:59");
      filterQuery += `&startLastLoginAt=${startDateFormatted}&endLastLoginAt=${endDateFormatted}`;
    }

    if (filterForm.becomeCustomerAtRange !== undefined) {
      if (filterForm.becomeCustomerAtRange.length > 0) {
        let startDate = dayjs(filterForm.becomeCustomerAtRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.becomeCustomerAtRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&startBecomeCustomerAt=${startDate}&endBecomeCustomerAt=${endDate}`;
      }
    }

    if (filterForm.createdAtRange !== undefined) {
      if (filterForm.createdAtRange.length > 0) {
        let startDate = dayjs(filterForm.createdAtRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.createdAtRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&startCreatedAt=${startDate}&endCreatedAt=${endDate}`;
      }
    }

    if (filterForm.hasLoggedInAtAcademy) {
      filterQuery += `&hasLoggedInAtAcademy=false`;
    }

    if (filterForm.startProductCount) {
      filterQuery += `&totalProductCountMin=${filterForm.startProductCount}`;
    }

    if (filterForm.endProductCount) {
      filterQuery += `&totalProductCountMax=${filterForm.endProductCount}`;
    }

    if (filterForm.remainingFreeDays && filterForm.remainingFreeDays !== null) {
      let [remainingFreeDaysStart, remainingFreeDaysEnd] =
        filterForm.remainingFreeDays.toString().split("-").map(Number);
      filterQuery += `&remainingFreeDaysStart=${remainingFreeDaysStart}&remainingFreeDaysEnd=${remainingFreeDaysEnd}`;
    }

    setFilterText(filterQuery);
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    setCurrentPage(page - 1);
    setPageSize(pageSize);
  };

  // const getLearnerById = (id: number) => {
  //   setLoading(true);
  //   LearnerService.get(id)
  //     .then((response: any) => {
  //       const data: any = response.data;
  //       const updateLarners = learners.map((learner: any) => {
  //         if (learner.id === id) {
  //           return data;
  //         }
  //         return learner;
  //       });
  //       setLearners(updateLarners);
  //       setLoading(false);
  //     })
  //     .catch((e: Error) => {
  //       console.log(e);
  //       setLoading(false);
  //     });
  // };

  const handleTableChange: TableProps<ILearnerResponseData>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    const sortObj: any = sorter;

    if (sortObj.order === undefined) {
      setSortByField("id");
      setSortByOrder("asc");
    } else {
      let sortType = "asc";
      if (sortObj.order === "descend") {
        sortType = "desc";
      }
      setSortByField(sortObj.field);
      setSortByOrder(sortType);
    }
  };

  useEffect(() => {
    const emails = learners.map((item: any) => {
      return item.email;
    });

    if (emails.length > 0) {
      setEmailList(emails.join("; "));
    } else {
      setEmailList("");
    }
    // eslint-disable-next-line
  }, [learners]);

  useEffect(() => {
    setLearners([]);
    getLearner(param);
    // eslint-disable-next-line
  }, [
    triggerUpdateLearnerList,
    currentPage,
    pageSize,
    sortByField,
    sortByOrder,
    filterText,
    searchText,
  ]);

  const getLearner = (param: string) => {
    // Değişiklik olmayacak
    let filterCriteria = param;

    if (param === "") {
      filterCriteria = `?page=${currentPage}&size=${pageSize}&sort=${sortByField}&direction=${sortByOrder}${filterText}${searchText}`;
      setParam(filterCriteria);
    }

    setLoading(true);

    let canPermission = can("learners:getAll");

    if (canPermission) {
      LearnerService.getAll(filterCriteria)
        .then((response: any) => {
          let data = response.data.data;
          setTotalItems(response.data.totalElements);
          setLearners(data);
          setLoading(false);
          let filterToSave = filterCriteria;
          const regex = /fullName=[^&]*/g;
          filterToSave = filterToSave.replace(regex, "");
          filterToSave = filterToSave.replace(/^&|&$/g, "");
          localStorage.setItem("learnerFilterText", filterToSave);
          setParam("");
        })
        .catch((e: Error) => {
          console.log(e);
          setLoading(false);
        });
    } else {
      LearnerService.getMyLearners(filterCriteria)
        .then((response: any) => {
          let data = response.data.data;
          setTotalItems(response.data.totalElements);
          setLearners(data);
          setLoading(false);
          let filterToSave = filterCriteria;
          const regex = /fullName=[^&]*/g;
          filterToSave = filterToSave.replace(regex, "");
          filterToSave = filterToSave.replace(/^&|&$/g, "");
          localStorage.setItem("learnerFilterText", filterToSave);
          setParam("");
        })
        .catch((e: Error) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  const getAllWithoutPagination = (download: any = true) => {
    let filterCriteria = `?page=${currentPage}&size=${pageSize}&sort=${sortByField},${sortByOrder}${filterText}${searchText}`;
    let canPermission = can("learners:getAll");

    if (canPermission) {
      LearnerService.getAllWithoutPagination(filterCriteria)
        .then((response: any) => {
          if (download) {
            downloadExcel(response.data);
          } else {
            copyEmailList(response.data);
          }
          setEmailLoading(false);
        })
        .catch((e: Error) => {});
    } else {
      LearnerService.getMyLearnersWithoutPagination(filterCriteria)
        .then((response: any) => {
          if (download) {
            downloadExcel(response.data);
          } else {
            copyEmailList(response.data);
          }
          setEmailLoading(false);
        })
        .catch((e: Error) => {});
    }
  };

  const copyEmailList = (data: any) => {
    const emailList = data.map((item: any) => {
      return item.email;
    });
    navigator.clipboard.writeText(emailList.join("; "));
    openNotification("success", "Success", "Email addresses copied");
  };

  const handleButtonClick = () => {
    setEmailLoading(true);
    try {
      getAllWithoutPagination(false);
    } catch (error) {
      setEmailLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const getCallTeamMembers = () => {
    if (roleName === "CALL_TEAM_MANAGER") {
      UserService.getAll("?roleName=CALL_TEAM")
        .then((response: any) => {
          let data = response;
          const newArray = data.map((item: any) => {
            let fullName = `${item.firstName} ${item.lastName}`;
            return { value: item.id, label: fullName };
          });
          setCallTeamMembers(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const assingUsers = () => {
    function distributeLearnerIds(learnerIds: number[], teamMembers: any[]) {
      const totalMembers = teamMembers.length;
      const perMember = Math.floor(learnerIds.length / totalMembers);
      const remainder = learnerIds.length % totalMembers;

      const shuffledLearnerIds = learnerIds.sort(() => 0.5 - Math.random());

      let startIndex = 0;
      const assignments = teamMembers.map((member, index) => {
        const idsForCurrentMember = shuffledLearnerIds.slice(
          startIndex,
          startIndex + perMember + (index < remainder ? 1 : 0)
        );
        startIndex += perMember + (index < remainder ? 1 : 0);
        return {
          userId: member.value,
          learnerIds: idsForCurrentMember,
        };
      });

      return assignments;
    }

    if (selectedLearnerIds && selectedLearnerIds.length > 0) {
      const results = distributeLearnerIds(selectedLearnerIds, callTeamMembers);
      Promise.all(
        results.map((result) => CallTeamService.assignLearnerToMember(result))
      )
        .then(() => {
          openNotification("success", "Success", "Successfully appointed");
        })
        .catch((e: Error) => {
          console.error(e.message);
        });
    } else {
      openNotification(
        "warning",
        "Warning",
        "All selected users have been previously assigned."
      );
    }
  };

  const showModal = () => {
    let filterCriteria = `?page=${currentPage}&size=${pageSize}&sort=${sortByField},${sortByOrder}${filterText}${searchText}`;
    let canPermission = can("learners:getAll");

    if (canPermission) {
      LearnerService.getAllWithoutPagination(filterCriteria)
        .then((response: any) => {
          const idsOfCustomersWithNullCallCenterMember = response.data
            .filter((item: any) => item.callCenterMember === null)
            .map((item: any) => item.id);

          setSelectedLearnerIds(idsOfCustomersWithNullCallCenterMember);
        })
        .catch((e: Error) => {});
    } else {
      LearnerService.getMyLearnersWithoutPagination(filterCriteria)
        .then((response: any) => {
          const idsOfCustomersWithNullCallCenterMember = response.data
            .filter((item: any) => item.callCenterMember === null)
            .map((item: any) => item.id);

          setSelectedLearnerIds(idsOfCustomersWithNullCallCenterMember);
        })
        .catch((e: Error) => {});
    }

    setIsModalVisible(true);
  };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSelectRow = (record: any) => {
    const newSelectedRowKeys = selectedRowKeys.includes(record.id)
      ? selectedRowKeys.filter((key: any) => key !== record.id)
      : [...selectedRowKeys, record.id];

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: any) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: () => ({
      disabled: true,
    }),
  };

  const selectAll = () => {
    setLoading(true);
    if (roleName === "SUCCESS_TEAM_MANAGER") {
      LearnerService.getAllLearnersLite(`?${filterText}&${searchText}`)
        .then((response: any) => {
          let data = response.data;
          let customerIds = data.map((user: any) => user.id);
          setSelectedRowKeys(customerIds);
          setLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setLoading(false);
        });
    } else if (roleName === "SUCCESS_TEAM") {
      LearnerService.getAllLearnersLiteMe(`?${filterText}&${searchText}`)
        .then((response: any) => {
          let data = response.data;
          let customerIds = data.map((user: any) => user.id);
          setSelectedRowKeys(customerIds);
          setLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Card
        className="card-customers"
        style={{ marginTop: "10px" }}
        title={
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              className="customer-card-title"
              style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
            >
              Learner List
            </div>
            <div className="customer-card-info">
              <b>{totalItems} </b>
              {currentData}
            </div>
            {roleName === "SUCCESS_TEAM" ||
            roleName === "SUCCESS_TEAM_MANAGER" ? (
              <Button
                style={{ marginTop: 10, marginLeft: 10 }}
                onClick={() => {
                  selectAll();
                }}
                type="primary"
              >
                Select All
              </Button>
            ) : null}
          </div>
        }
        bordered={true}
        extra={
          <>
            <Row justify={"end"} align={"middle"}>
              {selectedRowKeys.length > 0 && (
                <LearnerMultiNote selectedRowKeys={selectedRowKeys} />
              )}
              {/* {selectedRowKeys.length > 0 && (
                <LearnerMultiTask selectedRowKeys={selectedRowKeys} />
              )} */}
              {selectedRowKeys.length > 0 && (
                <LearnerMultiStatus selectedRowKeys={selectedRowKeys} />
              )}
              {roleName === "CALL_TEAM_MANAGER" ? (
                <>
                  <Button
                    type="primary"
                    onClick={showModal}
                    style={{ marginLeft: 10, marginRight: 10 }}
                    icon={<UploadOutlined />}
                  >
                    Send
                  </Button>
                  <Modal
                    title="Data Assigne"
                    open={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                  >
                    <p>
                      Selected User Count: <b>{totalItems}</b> <br /> <br />
                      <b> Selected Filters:</b>{" "}
                      {filterOptions.map((filter: any, index) => (
                        <span key={index}>
                          {index > 0 && ", "} <br />
                          {filter.name}:{" "}
                          {Array.isArray(filter.value)
                            ? filter.value.join(", ")
                            : filter.value}
                        </span>
                      ))}
                    </p>
                    <span>
                      <b>
                        With this process, previously assigned users will not be
                        assigned again.
                      </b>
                    </span>
                    <Button
                      type="primary"
                      onClick={assingUsers}
                      style={{ width: "100%" }}
                      icon={<UploadOutlined />}
                    >
                      Assign Users
                    </Button>
                  </Modal>
                </>
              ) : null}
              <Input
                className="search-input"
                onChange={handleChange}
                addonBefore={<SearchOutlined />}
                placeholder="Search"
              />
              <Tooltip placement="top" title={"Copy emails"}>
                <Button
                  loading={emailLoading}
                  type="primary"
                  onClick={handleButtonClick}
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  shape="circle"
                  icon={<CopyOutlined />}
                />
              </Tooltip>
              <Filter
                onChangeFilter={handleOnChangeFilter}
                setFilterOptions={setFilterOptions}
                param={lastFilterSettings}
              />
              {!isHomePage &&
                (roleName === "CALL_TEAM_MANAGER" ||
                  roleName === "SUCCESS_TEAM_MANAGER" ||
                  roleName === "ACCOUNT_MANAGER_MANAGER" ||
                  roleName === "ADMIN") && (
                  <Button
                    type="primary"
                    style={{ marginRight: 16 }}
                    onClick={() => navigate(`/learners/sales-approval`)}
                    // onClick={() => window.open(`/prospect-customers/sales-approval`, "_blank")}
                  >
                    Sales Approval
                  </Button>
                )}
              <Button
                icon={<DownloadOutlined />}
                onClick={getAllWithoutPagination}
                type="primary"
              >
                Export
              </Button>
            </Row>
          </>
        }
      >
        <Table
          rowKey={"id"}
          rowSelection={rowSelection}
          scroll={{ x: "100%" }}
          loading={loading}
          // key={"id"}
          sticky
          columns={columns}
          dataSource={learners}
          pagination={false}
          onChange={handleTableChange}
          onRow={(record) => ({
            onClick: () => onSelectRow(record),
          })}
        />
        <Row style={{ marginTop: "30px" }} justify={"center"}>
          <Pagination
            showSizeChanger={true}
            total={totalItems}
            defaultPageSize={pageSize}
            defaultCurrent={0}
            current={currentPage + 1}
            showQuickJumper
            onChange={paginationOnChange}
          />
        </Row>
      </Card>
    </>
  );
};
