import {
  Card,
  Pagination,
  PaginationProps,
  Popconfirm,
  Row,
  Tag,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import NotificationService from "../../../services/NotificationService";
import { useLocation } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { Table } from "antd/lib";
import { formatDateString, openNotification } from "../../../utils";
import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";

interface NotificationsCardProps {
  roleName: string;
  dateRange: any;
}

const NotificationsCard: React.FC<NotificationsCardProps> = ({
  roleName,
  dateRange,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [notificationsData, setNotificationsData] = useState<any>([]);
  const [totalNotificationsData, setTotalNotificationsData] = useState(0);
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 10;
  // const lastSortField = location.state?.sortField ?? "";
  // const lastSortOrder = location.state?.sortOrder ?? "";
  // const lastFilterText =
  //   location.state?.filterText ??
  //   `?page=${lastCurrentPage}&size=${lastPageSize}&sort=${lastSortField}&direction=${lastSortOrder}`;
  const lastFilterText =
    location.state?.filterText ??
    `?page=${lastCurrentPage}&size=${lastPageSize}`;
  const [filterText, setFilterText] = useState<string>(lastFilterText);
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);
  // const [sortField, setSortField] = useState(lastSortField);
  // const [sortOrder, setSortOrder] = useState(lastSortOrder);

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    setFilterText(newFilterText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const fetchNotifications = async (
    formattedStartDate: string,
    formattedEndDate: string
  ) => {
    setLoading(true);
    try {
      if (roleName === "MAS_TEAM_MANAGER") {
        const response =
          await NotificationService.postNotificationsMasTeamForManager(
            `${filterText}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
          );
        setNotificationsData(response.data.content);
        setTotalNotificationsData(response.data.totalElements);
      } else {
        const response =
          await NotificationService.postNotificationsMasTeamForMember(
            `${filterText}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
          );
        setNotificationsData(response.data.content);
        setTotalNotificationsData(response.data.totalElements);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  // const updateFilterSort = (field: string, order: any) => {
  //   const direction =
  //     order === "ascend" ? "asc" : order === "descend" ? "desc" : "";

  //   const sortField = field;

  //   const sortParamRegex = /&sort=([^&]*)&direction=([^&]*)/;
  //   const sortMatch = filterText.match(sortParamRegex);

  //   let newFilterText = filterText;

  //   if (sortMatch) {
  //     newFilterText = newFilterText.replace(
  //       sortParamRegex,
  //       `&sort=${sortField}&direction=${direction}`
  //     );
  //   } else if (field && direction) {
  //     newFilterText = `${newFilterText}&sort=${sortField}&direction=${direction}`;
  //   }

  //   setFilterText(newFilterText);
  // };

  // const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  //   let nextSortOrder = "";

  //   if (sorter.field !== sortField) {
  //     nextSortOrder = "ascend";
  //   } else {
  //     switch (sortOrder) {
  //       case "ascend":
  //         nextSortOrder = "descend";
  //         break;
  //       case "descend":
  //         nextSortOrder = "";
  //         break;
  //       default:
  //         nextSortOrder = "ascend";
  //     }
  //   }

  //   setSortOrder(nextSortOrder);
  //   setSortField(sorter.field || "");
  //   const field = sorter.field;
  //   updateFilterSort(field, nextSortOrder);
  // };

  useEffect(() => {
    if (dateRange.length > 0) {
      if (dateRange[0] && dateRange[1]) {
        let formattedStartDate = dayjs(dateRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let formattedEndDate = dayjs(dateRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        fetchNotifications(formattedStartDate, formattedEndDate);
        setLoading(true);
      }
    }
  // }, [dateRange, roleName, filterText, sortField, sortOrder]);
  // eslint-disable-next-line
  }, [dateRange, roleName, filterText]);

  const completeNotification = (id: number) => {
    setLoading(true);
    NotificationService.completeNotification(id)
      .then(() => {
        openNotification(
          "success",
          "Success",
          "The status of the notification has been changed to DONE."
        );
        setNotificationsData((prevData: any) =>
          prevData.map((notification: any) =>
            notification.id === id
              ? { ...notification, status: "DONE" }
              : notification
          )
        );
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<any> = [
    // {
    //   title: "Customer",
    //   dataIndex: "customerName",
    //   key: "customerName",
    //   width: 220,
    //   ellipsis: true,
    //   render: (text: string, record: any) => (
    //     <Title className="customer-fullname" level={5}>
    //       <b
    //         style={{
    //           whiteSpace: "nowrap",
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //         }}
    //       >
    //         {text ? text : "N/A"}
    //       </b>
    //     </Title>
    //   ),
    // },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 215,
      align: "center",
      ellipsis: true,
      render: (text: string, record: any) => (
        <Tag
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          color={
            record.type === "SUCCESS"
              ? "success"
              : record.type === "DANGER"
              ? "red"
              : record.type === "WARNING"
              ? "orange"
              : record.type === "INFO"
              ? "blue"
              : ""
          }
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 150,
      ellipsis: true,
      render: (text: string, record: any) => (
        <Tooltip title={text} placement="topLeft">
          <span
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal",
            }}
          >
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 115,
      align: "center",
      render: (text: string, record: any) => (
        <span>{formatDateString(text)}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 90,
      align: "center",
      // sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text: string, record: any) => (
        <Popconfirm
          title="Are you sure the notification should go to DONE?"
          onConfirm={() => completeNotification(record.id)}
          okText="Yes"
          cancelText="No"
          disabled={text !== "TODO"}
        >
          <Tag
            style={{
              cursor: text === "TODO" ? "pointer" : "default",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            icon={
              text === "DONE" ? (
                <CheckCircleOutlined />
              ) : (
                <ClockCircleOutlined />
              )
            }
            color={text === "DONE" ? "success" : "orange"}
          >
            {text.toUpperCase()}
          </Tag>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Card
      className="card-customers"
      style={{ marginTop: "30px" }}
      title="Notification List"
      bordered={true}
    >
      <Table
        rowKey={(record) => record.id}
        sticky
        loading={loading}
        scroll={{ x: "100%" }}
        columns={columns}
        dataSource={notificationsData}
        pagination={false}
        // onChange={handleTableChange}
        className="dashboard-table"
      />
      <Row style={{ marginTop: "30px" }} justify={"center"}>
        <Pagination
          showSizeChanger={true}
          total={totalNotificationsData}
          defaultPageSize={pageSize}
          defaultCurrent={0}
          current={currentPage + 1}
          pageSizeOptions={["10", "20", "50", "100", "250"]}
          showQuickJumper
          onChange={paginationOnChange}
        />
      </Row>
    </Card>
  );
};

export default NotificationsCard;
