import { Row, Col } from "antd";
import { useEffect, useState } from "react";
import DashboardService from "../../../services/DashboardService";
import InfoCard from "./InfoCard";
import RetentionInfoCard from "./RetentionInfoCard";

interface TotalInfoCardProps {
  roleName: string;
  dateRange: any;
}

const TotalInfoCard: React.FC<TotalInfoCardProps> = ({
  roleName,
  dateRange,
}) => {
  const [loading, setLoading] = useState(false);
  const [learnerData, setLearnerData] = useState({ total: 0, today: 0 });
  const [retentionData, setRetentionData] = useState({ total: 0, today: 0 });
  const [softwareSuspendedData, setSoftwareSuspendedData] = useState({
    total: 0,
    today: 0,
  });
  const [retentionProcessData, setRetentionProcessData] = useState({
    totalUsers: 0,
    retainedUsers: 0,
  });
  const [sixMonthsRetentionData, setSixMonthsRetentionData] = useState({
    underSixMonthsCount: 0,
    underSixMonthsContacted: 0,
    overSixMonthsCount: 0,
    overSixMonthsContacted: 0,
  });
  //  eslint-disable-next-line
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  const fetchTotalInfo = async (
    formattedStartDate: string,
    formattedEndDate: string
  ) => {
    setLoading(true);
    try {
      if (roleName === "SUCCESS_TEAM_MANAGER") {
        const responseTotalLearner =
          await DashboardService.getTotalLearnerStmForManager(
            formattedStartDate,
            formattedEndDate
          );
        const allLearners = responseTotalLearner.data;

        const totalLearnersSum = allLearners.reduce(
          (acc: any, curr: any) => acc + curr.totalLearners,
          0
        );
        const todayLearnersSum = allLearners.reduce(
          (acc: any, curr: any) => acc + curr.todayLearners,
          0
        );

        setLearnerData({ total: totalLearnersSum, today: todayLearnersSum });

        const responseTotalRetention =
          await DashboardService.getTotalRetentionCustomerStmForManager(
            formattedStartDate,
            formattedEndDate
          );
        const allRetentions = responseTotalRetention.data;
        const totalRetentionsSum = allRetentions.reduce(
          (acc: any, curr: any) => acc + curr.totalRetentionCount,
          0
        );
        const todayRetentionsSum = allRetentions.reduce(
          (acc: any, curr: any) => acc + curr.todayRetentionCount,
          0
        );
        setRetentionData({
          total: totalRetentionsSum,
          today: todayRetentionsSum,
        });

        const responseTotalSoftwareSuspended =
          await DashboardService.getTotalSoftwareSuspendedCustomerStmForManager(
            formattedStartDate,
            formattedEndDate
          );
        setSoftwareSuspendedData({
          total: responseTotalSoftwareSuspended.data.totalSuspendedCustomers,
          today: responseTotalSoftwareSuspended.data.todaySuspendedCustomers,
        });
      } else {
        const responseTotalLearner =
          await DashboardService.getTotalLearnerStmForMember(
            formattedStartDate,
            formattedEndDate
          );
        setLearnerData({
          total: responseTotalLearner.data.totalLearners,
          today: responseTotalLearner.data.todayLearners,
        });

        const responseTotalRetention =
          await DashboardService.getTotalRetentionCustomerStmForMember(
            formattedStartDate,
            formattedEndDate
          );
        setRetentionData({
          total: responseTotalRetention.data.totalRetentionCount,
          today: responseTotalRetention.data.todayRetentionCount,
        });

        const responseTotalSoftwareSuspended =
          await DashboardService.getTotalSoftwareSuspendedCustomerStmForMember(
            formattedStartDate,
            formattedEndDate
          );
        setSoftwareSuspendedData({
          total: responseTotalSoftwareSuspended.data.totalSuspendedCustomers,
          today: responseTotalSoftwareSuspended.data.todaySuspendedCustomers,
        });
      }

      const responseTotalRetentionProcess =
        await DashboardService.getTotalRetentionProcessStm();
      const responseSixMonthRetention =
        await DashboardService.getSixMonthRetentionStm();
      setRetentionProcessData(responseTotalRetentionProcess.data);
      setSixMonthsRetentionData(responseSixMonthRetention.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dateRange.length > 0) {
      if (dateRange[0] && dateRange[1]) {
        fetchTotalInfo(dateRange[0], dateRange[1]);
      }
    }
    // eslint-disable-next-line
  }, [dateRange, roleName]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} lg={8}>
          <InfoCard
            title="Learners"
            description={`Total ${learnerData.total}`}
            count={`+${learnerData.today}`}
            loading={loading}
          />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <InfoCard
            title="Retention"
            description={`Total ${retentionData.total}`}
            count={`+${retentionData.today}`}
            loading={loading}
          />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <InfoCard
            title="Software Suspended"
            description={`Total ${softwareSuspendedData.total}`}
            count={`+${softwareSuspendedData.today}`}
            loading={loading}
          />
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <InfoCard
            title="Retention Process"
            description={``}
            count={`${retentionProcessData.totalUsers} / ${retentionProcessData.retainedUsers}`}
            loading={loading}
          />
        </Col>
        <Col xs={24} sm={24} lg={12}>
          <RetentionInfoCard
            title="Retention Info"
            dateRange={dateRange}
            roleName={roleName ?? ""}
          />
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <InfoCard
            title="Under 6 Months For Retention"
            description={`Total ${sixMonthsRetentionData.underSixMonthsCount}`}
            count={`${sixMonthsRetentionData.underSixMonthsContacted}`}
            loading={loading}
          />
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <InfoCard
            title="Over 6 Months For Retention"
            description={`Total ${sixMonthsRetentionData.overSixMonthsCount}`}
            count={`${sixMonthsRetentionData.overSixMonthsContacted}`}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default TotalInfoCard;
