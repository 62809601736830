import CopyToClipboard from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
import { openNotification } from "../../utils";

interface CopyClipboardProps {
  text: string;
}

const handleCopyClick = (text: string) => {
  openNotification("success", "Copied to clipboard", text);
};

export const CopyClipboard = ({ text }: CopyClipboardProps) => {
  if (text === null || text === "") {
    return <></>;
  }

  return (
    <>
      <span
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxWidth: "100px",
        }}
      >
        {text}
      </span>
      <CopyToClipboard text={text}>
        <CopyOutlined
          onClick={() => handleCopyClick(text)}
          style={{ marginLeft: "5px" }}
        />
      </CopyToClipboard>
    </>
  );
};
