import React from "react";
import { Card, Col, Row, Table } from "antd";
import moment from "moment";

interface ActionTypeHistoryListCardProps {
  actionTypeHistoryList: any;
}

const ActionTypeHistoryList: React.FC<ActionTypeHistoryListCardProps> = ({
  actionTypeHistoryList,
}) => {

  const columns = [
    {
      title: <span style={{ fontSize: 12 }}>Action Type</span>,
      dataIndex: "actionType",
      key: "actionType",
      ellipsis: true,
      width: 100,
      sorter: (a: any, b: any) =>
        a?.actionType?.name?.localeCompare(b?.actionType?.name),
      render: (text: any, record: any) => (
        <span style={{ fontSize: 12 }}>{record?.actionType?.name}</span>
      ),
    },
    {
      title: <span style={{ fontSize: 12 }}>Action Date</span>,
      dataIndex: "actionDate",
      key: "actionDate",
      ellipsis: true,
      width: 90,
      align: "center" as const,
      sorter: (a: any, b: any) =>
        new Date(a.actionDate).getTime() - new Date(b.actionDate).getTime(),
      defaultSortOrder: "descend" as const,
      render: (text: any) => (
        <span style={{ fontSize: 12 }}>
          {text ? moment(text).format("DD/MM/YYYY HH:mm") : ""}
        </span>
      ),
    },
    {
      title: <span style={{ fontSize: 12 }}>Action By</span>,
      dataIndex: "actionBy",
      key: "actionBy",
      ellipsis: true,
      width: 90,
      align: "center" as const,
      sorter: (a: any, b: any) =>
        a?.actionBy?.firstName - b?.actionBy?.firstName,
      render: (text: any, record: any) => (
        <span style={{ fontSize: 12 }}>
          {record?.actionBy?.firstName} {record?.actionBy?.lastName}
        </span>
      ),
    },
  ];

  return (
    <Card bordered={false} className={"sales-card"}>
      <p className="title">Action Type History</p>
      <Row style={{ marginTop: "24px", height: "170px" }}>
        <Col span={24}>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={actionTypeHistoryList}
            pagination={false}
            scroll={{ x: "100%", y: 160 }}
            size="small"
            bordered
            className="dashboard-table"
          />
        </Col>
      </Row>
    </Card>
  );
};

export default ActionTypeHistoryList;
