import { useParams } from "react-router-dom";
import CustomerService from "../../../services/CustomerService";
import { useEffect, useState } from "react";
import { Button, Card, Col, Row, Select, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import CustomService from "./CustomService";
import PolicyCompliance from "./PolicyCompliance";
import ShippingPerformance from "./ShippingPerformance";
import moment from "moment";

const { Option } = Select;

const CustomerAccountHealth = () => {
  const [customerAccountHealth, setCustomerAccountHealth] = useState<any>([]);
  const [selectedStore, setSelectedStore] = useState<string | null>(null);
  const [accountHealthData, setAccountHealthData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const getCustomerAccountHealth = () => {
    setLoading(true);
    CustomerService.getMasCustomerAccountHealth(Number(id))
      .then((response) => {
        setCustomerAccountHealth(response.data);
        // İlk mağazayı varsayılan olarak seçelim
        if (response.data.length > 0) {
          setSelectedStore(response.data[0].store);
          setAccountHealthData(JSON.parse(response.data[0].accountHealthData));
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCustomerAccountHealth();
    // eslint-disable-next-line
  }, []);

  const handleStoreChange = (store: string) => {
    setSelectedStore(store);
    const selectedStoreData = customerAccountHealth.find(
      (item: any) => item.store === store
    );
    if (selectedStoreData) {
      setAccountHealthData(JSON.parse(selectedStoreData.accountHealthData));
    }
  };

  return (
    <Card
      className="card-customers"
      title={
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <div className="customer-card-title">
            <Row justify={"start"} align={"middle"}>
              <Button
                type="default"
                onClick={() => navigate(-1)}
                style={{ marginRight: 16 }}
              >
                <ArrowLeftOutlined />
              </Button>
              <p style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}>
                Account Health
              </p>
            </Row>
          </div>
        </div>
      }
      extra={
        <Row
          style={{
            minWidth: 400,
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          {customerAccountHealth && customerAccountHealth.length > 0 && (
            <>
              <p style={{ fontSize: 16, marginRight: 10, fontWeight: 600 }}>
                Store:{" "}
              </p>
              <Select
                value={selectedStore}
                onChange={handleStoreChange}
                style={{ width: 200 }}
                placeholder="Select Store"
                allowClear
              >
                {customerAccountHealth.map((item: any) => (
                  <Option key={item.store} value={item.store}>
                    {item.store}
                  </Option>
                ))}
              </Select>
            </>
          )}
        </Row>
      }
      bordered={true}
      style={{ marginTop: "10px" }}
    >
      {loading ? (
        <Spin />
      ) : (
        accountHealthData && (
          <>
            <Row
              gutter={[16, 16]}
              style={{ marginBottom: 20, justifyContent: "end" }}
            >
              <Col style={{ textAlign: "right", marginRight: 20 }}>
                <b>Last Update Date: </b>
                <span className="status status-green-full !text-xs">
                  {moment(accountHealthData?.lastUpdateDate).format(
                    "YYYY-MM-DD"
                  )}
                </span>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col lg={8} xs={24}>
                <CustomService data={accountHealthData?.performanceMetrics} />
              </Col>
              <Col lg={8} xs={24}>
                <PolicyCompliance
                  data={accountHealthData?.performanceMetrics}
                  lineClass="green"
                />
              </Col>
              <Col lg={8} xs={24}>
                <ShippingPerformance
                  data={accountHealthData?.performanceMetrics}
                />
              </Col>
            </Row>
          </>
        )
      )}
    </Card>
  );
};

export default CustomerAccountHealth;
