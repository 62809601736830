import {
  Card,
  Col,
  Row,
  Typography,
  Table,
  Breadcrumb,
  Button,
  Input,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import React from "react";
import DateRange from "../../../Common/DateRange";
import CommissionService from "../../../../services/CommissionService";
import { formatMoney } from "../../../../utils";
import CallSalesPackageService from "../../../../services/CallSalesPackageService";
import { ReportsRouter } from "../../ReportsRouter";
import * as XLSX from "xlsx";
import { EarningsDetailReportModal } from "./EarningsDetailReportModal";
import { EarningsPackageDetailReportModal } from "./EarningsPackageDetailReportModal";
import { EarningsSlpRetentionDetailReportModal } from "./EarningsSlpRetentionDetailReportModal";
import { EarningsGoldRetentionDetailReportModal } from "./EarningsGoldRetentionDetailReportModal";
import { EarningsSoftwareRetentionDetailReportModal } from "./EarningsSoftwareRetentionDetailReportModal";

const { Title } = Typography;

export const EarningsReport: React.FC<any> = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [packages, setPackages] = useState<any>([]);
  const [selectedMember, setSelectedMember] = useState<any>({});
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedSalesLogs, setSelectedSalesLogs] = useState<any[]>([]);
  const [packageModalVisible, setPackageModalVisible] =
    useState<boolean>(false);
  const [selectedPackageSalesLogs, setSelectedPackageSalesLogs] = useState<
    any[]
  >([]);
  const [slpRetentionModalVisible, setSlpRetentionModalVisible] =
    useState<boolean>(false);
  const [selectedSlpRetentionSalesLogs, setSelectedSlpRetentionSalesLogs] =
    useState<any[]>([]);
  const [goldRetentionModalVisible, setGoldRetentionModalVisible] =
    useState<boolean>(false);
  const [selectedGoldRetentionSalesLogs, setSelectedGoldRetentionSalesLogs] =
    useState<any[]>([]);
  const [softwareRetentionModalVisible, setSoftwareRetentionModalVisible] =
    useState<boolean>(false);
  const [
    selectedSoftwareRetentionSalesLogs,
    setSelectedSoftwareRetentionSalesLogs,
  ] = useState<any[]>([]);
  const location = useLocation();

  const getTeamNameAndTitles = () => {
    if (location.pathname.includes("/stm-reports/earnings-report")) {
      return {
        teamName: "success-team-members",
        teamComissionRate: "stmCommissionRate",
        columnTitle: "Success Team Member",
        breadcrumbTitle: "Success Team Reports",
        breadcrumbSubTitle: "Earnings Report",
        cardTitle: "Success Team Member",
        cardSubTitle: "Earnings Report",
      };
    } else if (location.pathname.includes("/ctm-reports/earnings-report")) {
      return {
        teamName: "call-team-members",
        teamComissionRate: "callCommissionRate",
        columnTitle: "Call Team Member",
        breadcrumbTitle: "Call Team Reports",
        breadcrumbSubTitle: "Earnings Report",
        cardTitle: "Call Team Member",
        cardSubTitle: "Earnings Report",
      };
    } else if (location.pathname.includes("/acm-reports/earnings-report")) {
      return {
        teamName: "account-manager-members",
        teamComissionRate: "acmCommissionRate",
        columnTitle: "Account Manager",
        breadcrumbTitle: "Account Manager Reports",
        breadcrumbSubTitle: "Earnings Report",
        cardTitle: "Account Manager",
        cardSubTitle: "Earnings Report",
      };
    } else if (location.pathname.includes("/hi5-acm-reports/earnings-report")) {
      return {
        teamName: "hi5-acm-members",
        teamComissionRate: "hi5AcmCommissionRate",
        columnTitle: "Hi5 Account Manager",
        breadcrumbTitle: "Hi5 Account Manager Reports",
        breadcrumbSubTitle: "Earnings Report",
        cardTitle: "Hi5 Account Manager",
        cardSubTitle: "Earnings Report",
      };
    } else
      return {
        teamName: "",
        teamComissionRate: "",
        columnTitle: "Unknown Role",
        breadcrumbTitle: "Unknown Reports",
        breadcrumbSubTitle: "Unknown Report",
        cardTitle: "Unknown Member",
        cardSubTitle: "Unknown Report",
      };
  };

  useEffect(() => {
    getPackages();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { teamName } = getTeamNameAndTitles();
    if (
      dateRange[0] !== undefined &&
      dateRange[1] !== undefined &&
      teamName !== "" &&
      teamName !== undefined
    ) {
      getReport(teamName, `${dateRange[0]}`, `${dateRange[1]}`);
    }
    // eslint-disable-next-line
  }, [dateRange]);

  const getPackages = () => {
    const { teamComissionRate } = getTeamNameAndTitles();
    CallSalesPackageService.getAll()
      .then((response: any) => {
        const filteredData = response.data.filter(
          (pkg: any) => pkg.isActive && pkg[teamComissionRate] !== null
        );
        setPackages(filteredData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getReport = (teamName: string, startDate: string, endDate: string) => {
    setLoading(true);
    CommissionService.earningReport(teamName, startDate, endDate)
      .then((response) => {
        const filteredResponseData = response.data.filter(
          (item: any) =>
            item.member.username !== "az.success.team.crm@oneamz.com" &&
            item.member.username !== "stm@oneamz.com" &&
            item.member.username !== "duygu.karakilic.hi5acm.crm@oneamz.com"
        );
        setData(filteredResponseData);
        setFilteredData(filteredResponseData);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const capitalize = (text: string) => {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const {
    columnTitle,
    breadcrumbTitle,
    breadcrumbSubTitle,
    cardTitle,
    cardSubTitle,
  } = getTeamNameAndTitles();

  const columns: ColumnsType<any> = [
    {
      title: columnTitle,
      dataIndex: "member",
      key: "member",
      fixed: "left",
      width: 200,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        a?.member?.firstName.localeCompare(b?.member?.firstName),
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${record?.member?.firstName} ${record?.member?.lastName}`}
          >
            <b>
              {record?.member?.firstName} {record?.member?.lastName}
            </b>
          </div>
        );
      },
    },
    ...(
      location.pathname.includes("/ctm-reports/earnings-report")
        ? packages.filter((pkg: any) => {
            return data.some((row: any) =>
              row.salesLogs.some(
                (log: any) => log.callCenterSalesPackage.name === pkg.name
              )
            );
          })
        : packages
    )
      .map((pkg: any) => ({
        title: capitalize(pkg?.name),
        dataIndex: "salesLogs",
        key: pkg?.id,
        width: 150,
        align: "center",
        sorter: (a: any, b: any) => {
          const totalCommissionA = a?.salesLogs
            .filter(
              (log: any) => log?.callCenterSalesPackage?.name === pkg?.name
            )
            .reduce(
              (acc: number, log: any) => acc + (log?.commissionPrice || 0),
              0
            );
          const totalCommissionB = b?.salesLogs
            .filter(
              (log: any) => log?.callCenterSalesPackage?.name === pkg?.name
            )
            .reduce(
              (acc: number, log: any) => acc + (log?.commissionPrice || 0),
              0
            );
          return totalCommissionA - totalCommissionB;
        },
        render: (_: any, row: any) => {
          const filteredLogs = row?.salesLogs.filter(
            (log: any) => log?.callCenterSalesPackage?.name === pkg?.name
          );
          const totalCommissionPackage = filteredLogs
            .map((log: any) => log?.commissionPrice || 0)
            .reduce((acc: number, val: number) => acc + val, 0);
          return (
            <div
              onClick={() => handlePackageRowClick(filteredLogs)}
              style={{ cursor: "pointer" }}
            >
              {formatMoney(totalCommissionPackage)}{" "}
              {filteredLogs.length > 0 && ` (${filteredLogs.length} qty)`}
            </div>
          );
        },
      })),
    ...(location.pathname.includes("/stm-reports/earnings-report")
      ? [
          {
            title: "Software Retention",
            dataIndex: "softwareRetentionCustomers",
            key: "softwareRetentionCustomers",
            align: "center",
            width: 150,
            sorter: (a: any, b: any) =>
              a?.softwareRetentionCustomers - b?.softwareRetentionCustomers,
            render: (_: any, row: any) => {
              return (
                <div
                  onClick={() => handleSoftwareRetentionRowClick(row)}
                  style={{ cursor: "pointer" }}
                >
                  {formatMoney(row?.softwareRetentionCustomers?.length * 3)}{" "}
                  {row?.softwareRetentionCustomers?.length > 0 &&
                    ` (${row?.softwareRetentionCustomers?.length} qty)`}
                </div>
              );
            },
          },
        ]
      : []),
    ...(location.pathname.includes("/hi5-acm-reports/earnings-report")
      ? [
          {
            title: "SLP Retention",
            dataIndex: "slpRetentionCustomers",
            key: "slpRetentionCustomers",
            align: "center",
            width: 150,
            sorter: (a: any, b: any) =>
              a?.slpRetentionCustomers - b?.slpRetentionCustomers,
            render: (_: any, row: any) => {
              return (
                <div
                  onClick={() => handleSlpRetentionRowClick(row)}
                  style={{ cursor: "pointer" }}
                >
                  {formatMoney(row?.slpRetentionCustomers?.length * 6)}{" "}
                  {row?.slpRetentionCustomers?.length > 0 &&
                    ` (${row?.slpRetentionCustomers?.length} qty)`}
                </div>
              );
            },
          },
        ]
      : []),
    ...(location.pathname.includes("/acm-reports/earnings-report") ||
    location.pathname.includes("/hi5-acm-reports/earnings-report")
      ? [
          {
            title: "Gold Retention",
            dataIndex: "goldRetentionCustomers",
            key: "goldRetentionCustomers",
            align: "center",
            width: 150,
            sorter: (a: any, b: any) =>
              a?.goldRetentionCustomers - b?.goldRetentionCustomers,
            render: (_: any, row: any) => {
              return (
                <div
                  onClick={() => handleGoldRetentionRowClick(row)}
                  style={{ cursor: "pointer" }}
                >
                  {formatMoney(row?.goldRetentionCustomers?.length * 6)}{" "}
                  {row?.goldRetentionCustomers?.length > 0 &&
                    ` (${row?.goldRetentionCustomers?.length} qty)`}
                </div>
              );
            },
          },
        ]
      : []),
    {
      title: "Total Price",
      dataIndex: "totalPrice",
      key: "totalPrice",
      align: "center",
      width: 150,
      // fixed: "right",
      sorter: (a, b) => a?.totalPrice - b?.totalPrice,
      render: (_, row) => {
        return (
          <div
            onClick={() => handleRowClick(row)}
            style={{ cursor: "pointer" }}
          >
            <b>{formatMoney(row?.totalPrice)}</b>
          </div>
        );
      },
    },
    {
      title: "Total Commissions",
      dataIndex: "totalCommission",
      key: "totalCommission",
      align: "center",
      width: 150,
      // fixed: "right",
      sorter: (a, b) => a?.totalCommission - b?.totalCommission,
      render: (_, row) => {
        return (
          <div
            onClick={() => handleRowClick(row)}
            style={{ cursor: "pointer" }}
          >
            <b>{formatMoney(row?.totalCommission)}</b>
          </div>
        );
      },
    },
  ];

  const handleRowClick = (record: any) => {
    setSelectedMember(record?.member);
    setSelectedSalesLogs(record?.salesLogs);
    setModalVisible(true);
  };

  const handlePackageRowClick = (packageLogs: any) => {
    setSelectedMember(packageLogs[0]?.user);
    setSelectedPackageSalesLogs(packageLogs);
    setPackageModalVisible(true);
  };

  const handleSlpRetentionRowClick = (slpRetentionLogs: any) => {
    setSelectedMember(slpRetentionLogs?.member);
    setSelectedSlpRetentionSalesLogs(slpRetentionLogs.slpRetentionCustomers);
    setSlpRetentionModalVisible(true);
  };

  const handleGoldRetentionRowClick = (goldRetentionLogs: any) => {
    setSelectedMember(goldRetentionLogs?.member);
    setSelectedGoldRetentionSalesLogs(goldRetentionLogs.goldRetentionCustomers);
    setGoldRetentionModalVisible(true);
  };

  const handleSoftwareRetentionRowClick = (softwareRetentionLogs: any) => {
    setSelectedMember(softwareRetentionLogs?.member);
    setSelectedSoftwareRetentionSalesLogs(
      softwareRetentionLogs.softwareRetentionCustomers
    );
    setSoftwareRetentionModalVisible(true);
  };

  const handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    if (value === "") {
      setFilteredData(data);
    } else {
      const filtered = data.filter((item: any) => {
        const fullName =
          `${item?.member?.firstName} ${item?.member?.lastName}`.toLowerCase();
        return fullName.includes(value);
      });
      setFilteredData(filtered);
    }
  };

  const downloadExcel = () => {
    const { cardTitle, cardSubTitle } = getTeamNameAndTitles();

    const dataForExport = filteredData.map((row: any) => {
      const rowData: any = {
        [cardTitle]: `${row.member.firstName} ${row.member.lastName}`,
      };

      packages.forEach((pkg: any) => {
        const filteredLogs = row.salesLogs.filter(
          (log: any) => log.callCenterSalesPackage?.name === pkg.name
        );
        const totalCommissionPackage = filteredLogs.reduce(
          (acc: number, log: any) => acc + (log.commissionPrice || 0),
          0
        );
        rowData[capitalize(pkg.name)] = `${formatMoney(
          totalCommissionPackage
        )} (${filteredLogs.length} qty)`;
      });

      if (location.pathname.includes("/stm-reports/earnings-report")) {
        rowData["Software Retention"] = `${formatMoney(
          row.softwareRetentionCustomers.length * 3
        )} (${row.softwareRetentionCustomers.length} qty)`;
      }

      if (location.pathname.includes("/hi5-acm-reports/earnings-report")) {
        rowData["SLP Retention"] = `${formatMoney(
          row.slpRetentionCustomers.length * 6
        )} (${row.slpRetentionCustomers.length} qty)`;
      }

      if (
        location.pathname.includes("/acm-reports/earnings-report") ||
        location.pathname.includes("/hi5-acm-reports/earnings-report")
      ) {
        rowData["Gold Retention"] = `${formatMoney(
          row.goldRetentionCustomers.length * 6
        )} (${row.goldRetentionCustomers.length} qty)`;
      }

      rowData["Total Price"] = formatMoney(row.totalPrice);
      rowData["Total Commissions"] = formatMoney(row.totalCommission);

      return rowData;
    });

    const summaryRow: any = {
      [cardTitle]: "Total",
    };

    packages.forEach((pkg: any) => {
      const totalCommissionPackage = filteredData.reduce(
        (acc: { total: number; qty: number }, row: any) => {
          const filteredSalesLogs = row.salesLogs.filter(
            (log: any) => log.callCenterSalesPackage?.name === pkg.name
          );
          const total = filteredSalesLogs.reduce(
            (sum: number, log: any) => sum + (log.commissionPrice || 0),
            0
          );
          const qty = filteredSalesLogs.length || 0;
          return {
            total: acc.total + total,
            qty: acc.qty + qty,
          };
        },
        { total: 0, qty: 0 }
      );

      summaryRow[capitalize(pkg.name)] = `${formatMoney(
        totalCommissionPackage.total
      )} (${totalCommissionPackage.qty} qty)`;
    });

    if (location.pathname.includes("/stm-reports/earnings-report")) {
      const totalSoftwareRetention = filteredData.reduce(
        (acc: number, row: any) =>
          acc + row.softwareRetentionCustomers.length * 3,
        0
      );
      const softwareRetentionQty = filteredData.reduce(
        (acc: number, row: any) => acc + row.softwareRetentionCustomers.length,
        0
      );
      summaryRow["Software Retention"] = `${formatMoney(
        totalSoftwareRetention
      )} (${softwareRetentionQty} qty)`;
    }

    if (location.pathname.includes("/hi5-acm-reports/earnings-report")) {
      const totalSlpRetention = filteredData.reduce(
        (acc: number, row: any) => acc + row.slpRetentionCustomers.length * 6,
        0
      );
      const slpRetentionQty = filteredData.reduce(
        (acc: number, row: any) => acc + row.slpRetentionCustomers.length,
        0
      );
      summaryRow["SLP Retention"] = `${formatMoney(
        totalSlpRetention
      )} (${slpRetentionQty} qty)`;
    }

    if (
      location.pathname.includes("/acm-reports/earnings-report") ||
      location.pathname.includes("/hi5-acm-reports/earnings-report")
    ) {
      const totalGoldRetention = filteredData.reduce(
        (acc: number, row: any) => acc + row.goldRetentionCustomers.length * 6,
        0
      );
      const goldRetentionQty = filteredData.reduce(
        (acc: number, row: any) => acc + row.goldRetentionCustomers.length,
        0
      );
      summaryRow["Gold Retention"] = `${formatMoney(
        totalGoldRetention
      )} (${goldRetentionQty} qty)`;
    }

    summaryRow["Total Price"] = formatMoney(
      filteredData.reduce((acc: number, row: any) => acc + row.totalPrice, 0)
    );

    summaryRow["Total Commissions"] = formatMoney(
      filteredData.reduce(
        (acc: number, row: any) => acc + row.totalCommission,
        0
      )
    );

    dataForExport.push(summaryRow);

    const worksheet = XLSX.utils.json_to_sheet(dataForExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, cardSubTitle);

    XLSX.writeFile(workbook, `${cardSubTitle}.xlsx`);
  };

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/">Home</NavLink>,
              },
              {
                title: breadcrumbTitle,
              },
              {
                title: breadcrumbSubTitle,
              },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />
      <Card
        className="card-customers"
        title={
          <>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                className="customer-card-title"
                style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
              >
                {cardTitle}
              </div>
              <div className="customer-card-info">{cardSubTitle}</div>
            </div>
            <Input
              className="search-input"
              addonBefore={<SearchOutlined />}
              placeholder="Search By Name"
              value={searchText}
              onChange={handleSearch}
              style={{ marginBottom: 16 }}
              size="large"
            />
          </>
        }
        extra={
          <Row justify={"end"}>
            <DateRange thisMonth={true} onChangeDateRange={setDateRange} />
            <Button
              style={{ marginLeft: "20px" }}
              size={"large"}
              icon={<DownloadOutlined />}
              onClick={downloadExcel}
              type="primary"
            >
              Export
            </Button>
          </Row>
        }
        bordered={true}
        style={{ marginTop: "30px" }}
      >
        <Table
          loading={isLoading}
          scroll={{ x: "max-content" }}
          sticky
          rowKey={"id"}
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          // onRow={(record) => ({
          //   onClick: () => handleRowClick(record),
          //   style: { cursor: "pointer" },
          // })}
          summary={(pageData) => {
            // Total Commissions
            const totalCommissions = pageData?.reduce(
              (acc, row) => acc + row?.totalCommission,
              0
            );

            // Total Prices
            const totalPrices = pageData?.reduce(
              (acc, row) => acc + row?.totalPrice,
              0
            );

            // Software Retention Total
            let softwareRetentionTotal = 0;
            let softwareRetentionQty = 0;
            if (location.pathname.includes("/stm-reports/earnings-report")) {
              softwareRetentionTotal = pageData?.reduce(
                (acc, row) => acc + row?.softwareRetentionCustomers?.length * 3,
                0
              );

              softwareRetentionQty = pageData?.reduce(
                (acc, row) => acc + row?.softwareRetentionCustomers?.length,
                0
              );
            }

            // SLP Retention Total
            let slpRetentionTotal = 0;
            let slpRetentionQty = 0;
            if (
              location.pathname.includes("/hi5-acm-reports/earnings-report")
            ) {
              slpRetentionTotal = pageData?.reduce(
                (acc, row) => acc + row?.slpRetentionCustomers?.length * 6,
                0
              );

              slpRetentionQty = pageData?.reduce(
                (acc, row) => acc + row?.slpRetentionCustomers?.length,
                0
              );
            }

            // Gold Retention Total
            let goldRetentionTotal = 0;
            let goldRetentionQty = 0;
            if (
              location.pathname.includes("/hi5-acm-reports/earnings-report") ||
              location.pathname.includes("/acm-reports/earnings-report")
            ) {
              goldRetentionTotal = pageData?.reduce(
                (acc, row) => acc + row?.goldRetentionCustomers?.length * 6,
                0
              );

              goldRetentionQty = pageData?.reduce(
                (acc, row) => acc + row?.goldRetentionCustomers?.length,
                0
              );
            }

            // Packages Totals
            const packagesData = location.pathname.includes(
              "/ctm-reports/earnings-report"
            )
              ? packages.filter((pkg: any) => {
                  return data.some((row: any) =>
                    row.salesLogs.some(
                      (log: any) => log.callCenterSalesPackage.name === pkg.name
                    )
                  );
                })
              : packages;
            const packageTotals = packagesData.map((pkg: any) => {
              const totalCommissionPackage = pageData?.reduce(
                (acc, row) => {
                  const filteredSalesLogs = row?.salesLogs?.filter(
                    (log: any) =>
                      log?.callCenterSalesPackage?.name === pkg?.name
                  );
                  const total = filteredSalesLogs?.reduce(
                    (sum: number, log: any) =>
                      sum + (log?.commissionPrice || 0),
                    0
                  );
                  const qty = filteredSalesLogs?.length || 0;
                  return {
                    total: acc?.total + total,
                    qty: acc?.qty + qty,
                  };
                },
                { total: 0, qty: 0 }
              );

              return totalCommissionPackage;
            });

            return (
              <>
                <Table.Summary.Row className="summary-cell">
                  <Table.Summary.Cell
                    index={0}
                    colSpan={1}
                    className="summary-cell"
                  >
                    <b>Total</b>
                  </Table.Summary.Cell>
                  {/* Packages Totals */}
                  {packageTotals?.map((pkgTotal: any, index: any) => (
                    <Table.Summary.Cell
                      index={index + 1}
                      key={index}
                      align="center"
                      className="summary-cell"
                    >
                      <b>
                        {formatMoney(pkgTotal?.total)} ({pkgTotal?.qty} qty)
                      </b>
                    </Table.Summary.Cell>
                  ))}
                  {/* Software Retention Totals */}
                  {location.pathname.includes(
                    "/stm-reports/earnings-report"
                  ) && (
                    <Table.Summary.Cell
                      index={packageTotals?.length + 1}
                      align="center"
                      className="summary-cell"
                    >
                      <b>
                        {formatMoney(softwareRetentionTotal)} (
                        {softwareRetentionQty} qty)
                      </b>
                    </Table.Summary.Cell>
                  )}
                  {/* SLP Retention Totals */}
                  {location.pathname.includes(
                    "/hi5-acm-reports/earnings-report"
                  ) && (
                    <Table.Summary.Cell
                      index={packageTotals?.length + 1}
                      align="center"
                      className="summary-cell"
                    >
                      <b>
                        {formatMoney(slpRetentionTotal)} ({slpRetentionQty} qty)
                      </b>
                    </Table.Summary.Cell>
                  )}
                  {/* Gold Retention Totals */}
                  {(location.pathname.includes(
                    "/hi5-acm-reports/earnings-report"
                  ) ||
                    location.pathname.includes(
                      "/acm-reports/earnings-report"
                    )) && (
                    <Table.Summary.Cell
                      index={packageTotals?.length + 1}
                      align="center"
                      className="summary-cell"
                    >
                      <b>
                        {formatMoney(goldRetentionTotal)} ({goldRetentionQty}{" "}
                        qty)
                      </b>
                    </Table.Summary.Cell>
                  )}
                  <Table.Summary.Cell
                    index={packageTotals?.length + 2}
                    align="center"
                    className="summary-cell"
                  >
                    <b>{formatMoney(totalPrices)}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={packageTotals?.length + 3}
                    align="center"
                    className="summary-cell"
                  >
                    <b>{formatMoney(totalCommissions)}</b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Card>
      <EarningsDetailReportModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        member={selectedMember}
        salesLogs={selectedSalesLogs}
      />
      <EarningsPackageDetailReportModal
        visible={packageModalVisible}
        onClose={() => setPackageModalVisible(false)}
        user={selectedMember}
        packageSalesLogs={selectedPackageSalesLogs}
      />
      <EarningsSlpRetentionDetailReportModal
        visible={slpRetentionModalVisible}
        onClose={() => setSlpRetentionModalVisible(false)}
        member={selectedMember}
        slpRetentionSalesLogs={selectedSlpRetentionSalesLogs}
      />
      <EarningsGoldRetentionDetailReportModal
        visible={goldRetentionModalVisible}
        onClose={() => setGoldRetentionModalVisible(false)}
        member={selectedMember}
        goldRetentionSalesLogs={selectedGoldRetentionSalesLogs}
      />
      <EarningsSoftwareRetentionDetailReportModal
        visible={softwareRetentionModalVisible}
        onClose={() => setSoftwareRetentionModalVisible(false)}
        member={selectedMember}
        softwareRetentionSalesLogs={selectedSoftwareRetentionSalesLogs}
      />
    </>
  );
};
