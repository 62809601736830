import http from "../http-common";

// List with pagination
const getAll = (filteredDate: string = '') => {
  return http.get<any>(`/amazon-suspended-applicant${filteredDate}`); //
};

// Get Single Customer Info
const get = (id: any) => {
  return http.get<any>(`/amazon-suspended-applicant/${id}`);
};

// Post Single Customer Info
const post = (data: any) => {
  return http.post<any>(`/amazon-suspended-applicant`, data);
};

// List without pagination
const getAllWithoutPagination = (filterCriteria: string = "") => {
  return http.get<Array<any>>(`/amazon-suspended-applicant/all${filterCriteria}`);
};

// Suspend Team Assign
const updateAssigneUser = (id: any, data: any) => {
  return http.put<any>(`/amazon-suspended-applicant/${id}/updateSuspendTeamMember?suspendTeamMemberId=${data}`, );
};

// Statu Update
const update = (amazonSuspendedApplicantId: any, data: any) => {
  return http.put<any>(`/amazon-suspended-applicant/${amazonSuspendedApplicantId}/status`, data);
};

// Get Tasks
const getTasks = (amazonSuspendedApplicantId: any,) => {
  return http.get<Array<any>>(`/amazon-suspended-applicant/${amazonSuspendedApplicantId}/tasks`);
};

// Get Notes
const getNotes = (amazonSuspendedApplicantId: any,) => {
  return http.get<Array<any>>(`/amazon-suspended-applicant/${amazonSuspendedApplicantId}/notes`);
};

// Patch Pin Note
const pinNote = (id: any) => {
  return http.patch<any>(`/amazon-suspended-applicant/notes/${id}/pin`);
};

// Delete Note
const removeNote = (amazonSuspendedApplicantId: any) => {
  return http.delete<any>(`/amazon-suspended-applicant/notes/${amazonSuspendedApplicantId}`);
};

// Create Note
const createNote = (amazonSuspendedApplicantId: number, data: any) => {
  return http.post<any>(`/amazon-suspended-applicant/${amazonSuspendedApplicantId}/notes`, data);
};

// Get Call History
const getCallHistory = (id: any, filteredDate: string = '') => {
  return http.get<Array<any>>(`/amazon-suspended-applicant/${id}/phone-call-histories${filteredDate}`);
};

// Suspend Update
const updateSuspend = (amazonSuspendedApplicantId: any, data: any) => {
  return http.put<any>(`/amazon-suspended-applicant/${amazonSuspendedApplicantId}/suspend-type`, data);
};

// Service Type Update
const updateServiceType = (amazonSuspendedApplicantId: any, data: any) => {
  return http.put<any>(`/amazon-suspended-applicant/${amazonSuspendedApplicantId}/service-type`, data);
};

// Action Type Update
const updateActionType = (amazonSuspendedApplicantId: any, data: any) => {
  return http.put<any>(`/amazon-suspended-applicant/${amazonSuspendedApplicantId}/action-type`, data);
};

// Suspended Country Update
const updateSuspendedCountry = (amazonSuspendedApplicantId: any, data: any) => {
  return http.put<any>(`/amazon-suspended-applicant/${amazonSuspendedApplicantId}/suspended-country`, data);
};

// Number of Amazon Calls Update
const updateNumberOfAmazonCalls = (amazonSuspendedApplicantId: any, data: any) => {
  return http.put<any>(`/amazon-suspended-applicant/${amazonSuspendedApplicantId}/number-of-amazon-calls`, data);
};

// Number of Violations Update
const updateNumberOfViolations = (amazonSuspendedApplicantId: any, data: any) => {
  return http.put<any>(`/amazon-suspended-applicant/${amazonSuspendedApplicantId}/number-of-violations`, data);
};

// Update Email(Buyer-Seller-OneAmz)
const updateEmail = (amazonSuspendedApplicantId: any, data: any) => {
  return http.put<any>(`/amazon-suspended-applicant/${amazonSuspendedApplicantId}`, data);
};

// Upload File
const getUploadFile = (amazonSuspendedApplicantId: any, file: any) => {
  const formData = new FormData();
  formData.append("file", file);
  return http.post<any>(`/amazon-suspended-applicant/upload-file/${amazonSuspendedApplicantId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

// Download File
const getDownloadFile = (amazonSuspendedApplicantId: any, fileName: string) => {
  return http.post<any>(`/amazon-suspended-applicant/download-file`, { amazonSuspendedApplicantId, fileName });
};

// Delete File
const deleteFile = (amazonSuspendedApplicantId: any, fileName: string) => {
  return http.delete<any>(`/amazon-suspended-applicant/delete-file`, {
    data: { amazonSuspendedApplicantId, fileName }
  });
};

// Notify Discord
const notifyDiscord = (data : any) => {
  return http.post<any>(`/amazon-suspended-applicant/crm-discord-notify`, data);
};

// Import Excel
const importExcel = (data: any) => {
  return http.post<any>(`/amazon-suspended-applicant/import-excel`, data);
};

// Input Update
const updateInput = (amazonSuspendedApplicantId: any, data: any) => {
  return http.put<any>(`/amazon-suspended-applicant/${amazonSuspendedApplicantId}/input`, data);
};

const AmazonSuspendedApplicantService = {
  getAll,
  get,
  post,
  getAllWithoutPagination,
  updateAssigneUser,
  update,
  getTasks,
  getNotes,
  pinNote,
  removeNote,
  createNote,
  getCallHistory,
  updateSuspend,
  updateServiceType,
  updateActionType,
  updateSuspendedCountry,
  updateNumberOfAmazonCalls,
  updateNumberOfViolations,
  updateEmail,
  getUploadFile,
  getDownloadFile,
  deleteFile,
  notifyDiscord,
  importExcel,
  updateInput
};

export default AmazonSuspendedApplicantService;