import { useEffect, useState } from "react";
import RetentionReportsService from "../../../services/RetentionReport";
import { Card } from "antd";
// import { Avatar, Card, Col, Row, Table } from "antd";
// import UserService from "../../../services/UserService";
// import { getProfilePhotoPath } from "../../../utils";

interface RetentionInfoCardProps {
  title: string;
  dateRange: any;
  roleName: string;
}

const RetentionInfoCard: React.FC<RetentionInfoCardProps> = ({
  title,
  dateRange,
  roleName,
}) => {
  // eslint-disable-next-line
  const [data, setData] = useState<any>([]);
  // const [allTeamUsers, setAllTeamUsers] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  // eslint-disable-next-line
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [initialDataTotal, setInitialDataTotal] = useState<number>(0);
  const [renewedPackagesCount, setRenewedPackagesCount] = useState<number>(0);
  const [retentionRate, setRetentionRate] = useState<number>(0);
  const userId = localStorage.getItem("id");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchRetentionInfo = async (
    formattedStartDate: string,
    formattedEndDate: string
  ) => {
    try {
      setLoading(true);
      let response;
      // let userResponse;

      if (roleName === "SUCCESS_TEAM_MANAGER") {
        // userResponse = await UserService.getAll("?roleName=SUCCESS_TEAM");
        response = await RetentionReportsService.getRetentionReportStm(
          formattedStartDate,
          formattedEndDate
        );
        // setAllTeamUsers(userResponse);
      } else {
        response = await RetentionReportsService.getRetentionReportStmByUserId(
          Number(userId),
          formattedStartDate,
          formattedEndDate
        );
      }

      const responseData = Array.isArray(response.data) ? response.data : [response.data];
      setData(responseData);

      // Calculate totals
      const initialDataSum = responseData.reduce(
        (total: number, item: any) => total + item.initialDataCount,
        0
      );
      const renewedPackagesSum = responseData.reduce(
        (total: number, item: any) => total + item.renewedPackagesCount,
        0
      );
      setInitialDataTotal(initialDataSum);
      setRenewedPackagesCount(renewedPackagesSum);

      // Calculate retention rate
      const calculatedRetentionRate =
        initialDataSum > 0 ? (renewedPackagesSum / initialDataSum) * 100 : 0;
      setRetentionRate(calculatedRetentionRate);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dateRange.length > 0) {
      if (dateRange[0] && dateRange[1]) {
        fetchRetentionInfo(dateRange[0], dateRange[1]);
      }
    }
    // eslint-disable-next-line
  }, [dateRange, roleName]);

  // const excludedUsernames = [
  //   "duygu.karakilic.hi5acm.crm@oneamz.com",
  //   "murat.yakar.hi5acm.crm@oneamz.com",
  //   "az.success.team.crm@oneamz.com",
  //   "stm@oneamz.com",
  // ];

  // const mergedData = allTeamUsers
  //   .filter(
  //     (user: any) =>
  //       data.some((item: any) => item.userId === user.id) &&
  //       !excludedUsernames.includes(user.username)
  //   )
  //   .map((user: any) => {
  //     const retentionData = data.find(
  //       (item: any) => item.userId === user.id
  //     ) || {
  //       initialDataCount: 0,
  //       soldPackagesCount: 0,
  //       retentionTarget: 0,
  //       renewedPackagesCount: 0,
  //       retentionRate: 0,
  //     };
  //     return {
  //       ...user,
  //       ...retentionData,
  //     };
  //   });

  // const columns = [
  //   {
  //     title: (
  //       <p
  //         style={{
  //           margin: "0px",
  //           fontWeight: "bold",
  //           fontSize:
  //             windowWidth < 480
  //               ? "12px"
  //               : windowWidth < 768
  //               ? "14px"
  //               : windowWidth < 992
  //               ? "12px"
  //               : "14px",
  //         }}
  //       >
  //         Success Team Member
  //       </p>
  //     ),
  //     dataIndex: "firstName",
  //     key: "firstName",
  //     ellipsis: true,
  //     width: 200,
  //     defaultSortOrder: "ascend" as "ascend",
  //     sorter: (a: any, b: any) => a.firstName.localeCompare(b.firstName),
  //     render: (_: any, record: any) => (
  //       <Row
  //         align={"middle"}
  //         style={{
  //           overflow: "hidden",
  //           textOverflow: "ellipsis",
  //           whiteSpace: "nowrap",
  //           padding: "0px 0px",
  //         }}
  //       >
  //         <Avatar
  //           style={{ marginRight: 5 }}
  //           size={30}
  //           src={getProfilePhotoPath(record?.profilePhoto || 1)}
  //         />
  //         <b
  //           style={{
  //             margin: "0px",
  //             whiteSpace: "nowrap",
  //             textOverflow: "ellipsis",
  //             overflow: "hidden",
  //             fontSize:
  //               windowWidth < 480
  //                 ? "12px"
  //                 : windowWidth < 768
  //                 ? "14px"
  //                 : windowWidth < 992
  //                 ? "12px"
  //                 : "14px",
  //           }}
  //         >
  //           {record?.firstName} {record?.lastName}
  //         </b>
  //       </Row>
  //     ),
  //   },
  //   {
  //     title: (
  //       <p
  //         style={{
  //           margin: "0px",
  //           fontWeight: "bold",
  //           fontSize:
  //             windowWidth < 480
  //               ? "12px"
  //               : windowWidth < 768
  //               ? "14px"
  //               : windowWidth < 992
  //               ? "12px"
  //               : "14px",
  //           textAlign: "center",
  //         }}
  //       >
  //         Retention Count
  //       </p>
  //     ),
  //     dataIndex: "initialDataCount",
  //     key: "initialDataCount",
  //     width: 150,
  //     align: "center" as const,
  //     ellipsis: true,
  //     sorter: (a: any, b: any) => a.initialDataCount - b.initialDataCount,
  //     render: (text: string) => (
  //       <div style={{ display: "flex", justifyContent: "center" }}>
  //         <p
  //           style={{
  //             margin: "0px",
  //             whiteSpace: "nowrap",
  //             fontWeight: "bold",
  //             textAlign: "center",
  //             fontSize:
  //               windowWidth < 480
  //                 ? "12px"
  //                 : windowWidth < 768
  //                 ? "14px"
  //                 : windowWidth < 992
  //                 ? "12px"
  //                 : "14px",
  //           }}
  //         >
  //           {text || 0}
  //         </p>
  //       </div>
  //     ),
  //   },
  //   {
  //     title: (
  //       <p
  //         style={{
  //           margin: "0px",
  //           fontWeight: "bold",
  //           fontSize:
  //             windowWidth < 480
  //               ? "12px"
  //               : windowWidth < 768
  //               ? "14px"
  //               : windowWidth < 992
  //               ? "12px"
  //               : "14px",
  //           textAlign: "center",
  //         }}
  //       >
  //         Renewed Packages
  //       </p>
  //     ),
  //     dataIndex: "renewedPackagesCount",
  //     key: "renewedPackagesCount",
  //     width: 150,
  //     align: "center" as const,
  //     ellipsis: true,
  //     sorter: (a: any, b: any) =>
  //       a.renewedPackagesCount - b.renewedPackagesCount,
  //     render: (text: string) => (
  //       <div style={{ display: "flex", justifyContent: "center" }}>
  //         <p
  //           style={{
  //             margin: "0px",
  //             whiteSpace: "nowrap",
  //             fontWeight: "bold",
  //             textAlign: "center",
  //             fontSize:
  //               windowWidth < 480
  //                 ? "12px"
  //                 : windowWidth < 768
  //                 ? "14px"
  //                 : windowWidth < 992
  //                 ? "12px"
  //                 : "14px",
  //           }}
  //         >
  //           {text || 0}
  //         </p>
  //       </div>
  //     ),
  //   },
  //   {
  //     title: (
  //       <p
  //         style={{
  //           margin: "0px",
  //           fontWeight: "bold",
  //           fontSize:
  //             windowWidth < 480
  //               ? "12px"
  //               : windowWidth < 768
  //               ? "14px"
  //               : windowWidth < 992
  //               ? "12px"
  //               : "14px",
  //           textAlign: "center",
  //         }}
  //       >
  //         Ratio
  //       </p>
  //     ),
  //     dataIndex: "retentionRate",
  //     key: "retentionRate",
  //     width: 100,
  //     align: "center" as const,
  //     ellipsis: true,
  //     sorter: (a: any, b: any) => a.retentionRate - b.retentionRate,
  //     render: (text: string) => (
  //       <div style={{ display: "flex", justifyContent: "center" }}>
  //         <p
  //           style={{
  //             margin: "0px",
  //             whiteSpace: "nowrap",
  //             fontWeight: "bold",
  //             textAlign: "center",
  //             fontSize:
  //               windowWidth < 480
  //                 ? "12px"
  //                 : windowWidth < 768
  //                 ? "14px"
  //                 : windowWidth < 992
  //                 ? "12px"
  //                 : "14px",
  //           }}
  //         >
  //           {parseFloat(text).toFixed(2) || 0.0}%
  //         </p>
  //       </div>
  //     ),
  //   },
  // ];

  return (
    <Card
      bordered={false}
      className="info-card"
      loading={loading}
      style={{ marginBottom: 0 }}
    >
      <div className="info-card-container">
        <div>
          <p
            className="title"
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </p>
          <p className="description" style={{ marginBottom: "24px" }}>
            {}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p className="count" style={{ textAlign: "left" }}>
              {initialDataTotal} / {renewedPackagesCount}
            </p>
            <p className="count" style={{ textAlign: "right" }}>
              {retentionRate.toFixed(2)}%
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p
              className="description"
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                padding: "0px",
                margin: "0px",
                textAlign: "left",
              }}
            >
              {"Retention Count / Renewed Packages"}
            </p>
            <p
              className="description"
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                padding: "0px",
                margin: "0px 10px",
                textAlign: "right",
              }}
            >
              {"Rate"}
            </p>
          </div>
        </div>
      </div>
      {/* {roleName === "SUCCESS_TEAM_MANAGER" && (
        <>
          <Row>
            <Col span={24}>
              <Table
                rowKey={(record) => record?.id}
                columns={columns}
                dataSource={mergedData}
                pagination={false}
                scroll={{ x: "100%", y: 185 }}
                size="small"
                className="dashboard-table"
              />
            </Col>
          </Row>
        </>
      )} */}
    </Card>
  );
};

export default RetentionInfoCard;
