import { Card, Row, Col, Typography, Space } from "antd";
import { useEffect, useState } from "react";

const { Text, Title } = Typography;

interface ShippingItem {
  label: string;
  value: any;
  target: string;
  percent?: any;
  orders?: any;
}

const ShippingPerformance = ({ data }: { data: any }) => {
  console.log("data", data);
  const [items, setItems] = useState<ShippingItem[]>([]);

  const calculateRate = (count: number, totalCount: number) => {
    let rate = (count * 100.0) / (totalCount > 0 ? totalCount : 1);
    return Number.isInteger(rate) ? rate : rate.toFixed(2);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setItems([
        {
          label: "Late Shipment Rate",
          value:
            data[0]?.lateShipmentRate?.orderCount > 0
              ? calculateRate(
                  data[0]?.lateShipmentRate?.lateShipmentCount,
                  data[0]?.lateShipmentRate?.orderCount
                ) + "%"
              : "N/A",
          target: "Target: under 4%",
        },
        {
          label: "Pre-fulfillment Cancel Rate",
          value:
            data[0]?.preFulfillmentCancellationRate?.orderCount > 0
              ? calculateRate(
                  data[0]?.preFulfillmentCancellationRate?.cancellationCount,
                  data[0]?.preFulfillmentCancellationRate?.orderCount
                ) + "%"
              : "N/A",
          target: "Target: under 2.5%",
        },
        {
          label: "Valid Tracking Rate",
          value: "30 days",
          target: "Target: over 95%",
          percent:
            (Number.isInteger(data[0]?.validTrackingRate?.rate * 100)
              ? data[0]?.validTrackingRate?.rate * 100
              : (data[0]?.validTrackingRate?.rate * 100).toFixed(2)) + "%",
          orders: `${data[0]?.validTrackingRate?.validTrackingCount} of ${data[0]?.validTrackingRate?.shipmentCount} orders`,
        },
      ]);
    }
  }, [data]);

  return (
    <Card
      title={
        <span style={{ textTransform: "uppercase" }}>Shipping Performance</span>
      }
      bordered={true}
      style={{ borderRadius: "8px" }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row justify="end" style={{ paddingRight: "16px" }}>
          <Text strong>Seller fulfilled</Text>
        </Row>
        {items &&
          items.map((i, index) => (
            <Card key={index} bordered={true} style={{ borderRadius: "8px" }}>
              <Row>
                <Col span={16}>
                  <Space direction="vertical">
                    <Title level={5}>{i?.label}</Title>
                    <Text>{i?.target}</Text>
                  </Space>
                </Col>
                <Col span={8} style={{ textAlign: "right" }}>
                  {i?.percent && <Title level={3}>{i?.percent}</Title>}
                  {i?.orders && <Text>{i?.orders}</Text>}
                  <br />
                  <Text>{i?.value}</Text>
                </Col>
              </Row>
            </Card>
          ))}
      </Space>
    </Card>
  );
};

export default ShippingPerformance;
