import { Col, Row, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import { SuspendFileStatus } from "../../../../types/AmazonSuspendedApplicantReports";

interface SuspendedFileStatusReportProps {
  dateRange: [string, string];
}

const SuspendedFileStatusReport = ({
  dateRange,
}: SuspendedFileStatusReportProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<SuspendFileStatus[]>([]);
  const [columns, setColumns] = useState<ColumnsType<SuspendFileStatus>>([]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const formattedStartDate = dayjs(dateRange[0]).format(
        "YYYY-MM-DDT00:00:00"
      );
      const formattedEndDate = dayjs(dateRange[1]).format(
        "YYYY-MM-DDT23:59:59"
      );
      getSuspendedFileStatusReport(formattedStartDate, formattedEndDate);
    }
    // eslint-disable-next-line
  }, [dateRange]);

  const getSuspendedFileStatusReport = (startDate: string, endDate: string) => {
    setLoading(true);
    AmazonSuspendedApplicantReportsService.getSuspendFileStatus(
      startDate,
      endDate
    )
      .then((response) => {
        setData(response.data);
        generateColumns(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateColumns = (data: SuspendFileStatus[]) => {
    const statusKeys = data.flatMap((item) => Object.keys(item.statusCounts));
    const uniqueStatusKeys = Array.from(new Set(statusKeys));
  
    const dynamicColumns = uniqueStatusKeys.map((key) => ({
      title: key.toUpperCase(),
      dataIndex: ["statusCounts", key],
      key,
      width: 150,
      align: "center" as "center",
      sorter: (a: SuspendFileStatus, b: SuspendFileStatus) =>
        (a.statusCounts[key] ?? 0) - (b.statusCounts[key] ?? 0),
      render: (value: number) => value ?? 0,
    }));
  
    setColumns([
      {
        title: "Member",
        dataIndex: "memberName",
        key: "memberName",
        // fixed: "left",
        width: 200,
        ellipsis: true,
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.memberName.localeCompare(b.memberName),
        render: (text: string, record: SuspendFileStatus) => (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={record.memberName}
          >
            <b>{record.memberName}</b>
          </div>
        ),
      },
      ...dynamicColumns,
    ]);
  };

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>Suspended File Status Report</h3>
        <Table
          loading={loading}
          scroll={{ x: "max-content" }}
          sticky
          rowKey={(record) => record.memberName}
          columns={columns}
          dataSource={data}
          pagination={false}
          summary={(pageData) => {
            const totalCounts: Record<string, number> = {};

            pageData.forEach(({ statusCounts }) => {
              Object.entries(statusCounts).forEach(([key, value]) => {
                totalCounts[key] = (totalCounts[key] || 0) + (value ?? 0);
              });
            });

            return (
              <Table.Summary fixed>
                <Table.Summary.Row className="summary-cell">
                  <Table.Summary.Cell
                    index={0}
                    colSpan={1}
                    align="left"
                    className="summary-cell"
                  >
                    <b>Total</b>
                  </Table.Summary.Cell>
                  {columns.slice(1).map((column, index: number) => (
                    <Table.Summary.Cell
                      key={column.key}
                      index={index + 1}
                      align="center"
                      className="summary-cell"
                    >
                      <b>{totalCounts[column.key as string] ?? 0}</b>
                    </Table.Summary.Cell>
                  ))}
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default SuspendedFileStatusReport;
