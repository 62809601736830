import { Col, Row, Statistic, Table } from "antd";
import CallTeamReportService from "../../../../services/CallTeamReportService";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { PhoneOutlined, ClockCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const CtmSourceBasedPhoneCallSummaryReport = ({
  dateRange,
}: {
  dateRange: any;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line
  const [data, setData] = useState<any[]>([]);
  const [uniqueMembers, setUniqueMembers] = useState<any[]>([]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      let formattedStartDate = dayjs(dateRange[0]).format("YYYY-MM-DDT00:00:00");
      let formattedEndDate = dayjs(dateRange[1]).format("YYYY-MM-DDT23:59:59");
      getSourceBasedPhoneCallSummaryReport(
        formattedStartDate,
        formattedEndDate
      );
    }
    // eslint-disable-next-line
  }, [dateRange]);

  const getSourceBasedPhoneCallSummaryReport = (
    startDate: string,
    endDate: string
  ) => {
    setLoading(true);
    CallTeamReportService.getSourceBasedPhoneCallSummaryReport(
      startDate,
      endDate
    )
      .then((response) => {
        setData(response.data);
        extractUniqueMembers(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Benzersiz üyeleri çıkar ve uniqueMembers olarak kaydet
  const extractUniqueMembers = (data: any[]) => {
    const memberMap = new Map<number, any>();
    data.forEach((sourceData) => {
      sourceData?.phoneCallSummaryResponseDtoList.forEach((record: any) => {
        const memberId = record?.userLiteResponseDto?.id;
        if (!memberMap.has(memberId)) {
          memberMap.set(memberId, {
            ...record?.userLiteResponseDto,
            sources: [],
            totalCalls: 0,
            answered: 0,
            missed: 0,
          });
        }
        const member = memberMap.get(memberId);
        member?.sources?.push({
          source: sourceData?.source,
          ...record,
        });
        member.totalCalls += record?.totalCalls || 0;
        member.answered += record?.answered || 0;
        member.missed += record?.missed || 0;
      });
    });
    setUniqueMembers(Array.from(memberMap.values()));
  };

  const calculateMemberTotals = (sources: any[] = []) => {
    return sources.reduce(
      (totals, sourceData) => {
        totals.totalCalls += sourceData?.totalCalls || 0;
        totals.answered += sourceData?.answered || 0;
        totals.missed += sourceData?.missed || 0;
        return totals;
      },
      { totalCalls: 0, answered: 0, missed: 0 }
    );
  };

  const calculateSummaryTotals = () => {
    return uniqueMembers.reduce(
      (totals, member) => {
        const memberTotals = calculateMemberTotals(member?.sources);
        totals.totalCalls += memberTotals?.totalCalls;
        totals.answered += memberTotals?.answered;
        totals.missed += memberTotals?.missed;
        return totals;
      },
      { totalCalls: 0, answered: 0, missed: 0 }
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: "Call Team Member",
      dataIndex: "firstName",
      key: "firstName",
      width: 300,
      align: "left",
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => a?.firstName.localeCompare(b?.firstName),
      render: (_, record) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={`${record?.firstName} ${record?.lastName}`}
        >
          <b>
            {record?.firstName} {record?.lastName}
          </b>
        </div>
      ),
    },
    {
      title: "Total Calls",
      key: "totalCalls",
      width: 150,
      align: "center",
      sorter: (a, b) => a?.totalCalls - b?.totalCalls,
      render: (_, record) => (
        <Statistic
          value={record?.totalCalls || 0}
          prefix={<PhoneOutlined />}
          valueStyle={{ fontSize: "14px", fontWeight: "400" }}
        />
      ),
    },
    {
      title: "Answered",
      key: "answered",
      width: 150,
      align: "center",
      sorter: (a, b) => a?.answered - b?.answered,
      render: (_, record) => (
        <Statistic
          value={record?.answered || 0}
          prefix={<PhoneOutlined />}
          valueStyle={{
            color: "#3f8600",
            fontSize: "14px",
            fontWeight: "400",
          }}
        />
      ),
    },
    {
      title: "Missed",
      key: "missed",
      width: 150,
      align: "center",
      sorter: (a, b) => a?.missed - b?.missed,
      render: (_, record) => (
        <Statistic
          value={record?.missed || 0}
          prefix={<PhoneOutlined />}
          valueStyle={{
            color: "#cf1322",
            fontSize: "14px",
            fontWeight: "400",
          }}
        />
      ),
    },
  ];

  const expandedRowRender = (record: any) => {
    const filteredSources = record?.sources.filter((sourceData: any) => sourceData?.source?.active);
    const innerColumns = [
      {
        title: "Source Name",
        dataIndex: ["source", "name"],
        key: "sourceName",
        align: "left" as "left",
        ellipsis: true,
        width: 150,
        defaultSortOrder: "ascend" as "ascend",
        sorter: (a: any, b: any) =>
          a?.source?.name.localeCompare(b?.source?.name),
        render: (text: string) => (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${text}`}
          >
            <b>{text}</b>
          </div>
        ),
      },
      {
        title: "Total Calls",
        dataIndex: "totalCalls",
        key: "totalCalls",
        align: "center" as "center",
        width: 100,
        sorter: (a: any, b: any) => a?.totalCalls - b?.totalCalls,
        render: (value: number) => (
          <Statistic
            value={value || 0}
            prefix={<PhoneOutlined />}
            valueStyle={{ fontSize: "14px", fontWeight: "400" }}
          />
        ),
      },
      {
        title: "Answered",
        dataIndex: "answered",
        key: "answered",
        align: "center" as "center",
        width: 100,
        sorter: (a: any, b: any) => a?.answered - b?.answered,
        render: (value: number) => (
          <Statistic
            value={value || 0}
            prefix={<PhoneOutlined />}
            valueStyle={{
              color: "#3f8600",
              fontSize: "14px",
              fontWeight: "400",
            }}
          />
        ),
      },
      {
        title: "Missed",
        dataIndex: "missed",
        key: "missed",
        align: "center" as "center",
        width: 100,
        sorter: (a: any, b: any) => a?.missed - b?.missed,
        render: (value: number) => (
          <Statistic
            value={value || 0}
            prefix={<PhoneOutlined />}
            valueStyle={{
              color: "#cf1322",
              fontSize: "14px",
              fontWeight: "400",
            }}
          />
        ),
      },
      {
        title: "Answered Ratio",
        dataIndex: "answeredCallRatio",
        key: "answeredCallRatio",
        align: "center" as "center",
        width: 100,
        sorter: (a: any, b: any) => a?.answeredCallRatio - b?.answeredCallRatio,
        render: (value: number) => (
          <Statistic
            value={value || 0}
            suffix="%"
            valueStyle={{ fontSize: "14px", fontWeight: "400" }}
          />
        ),
      },
      {
        title: "Average Call Duration",
        dataIndex: "durationTimeAvg",
        key: "durationTimeAvg",
        align: "center" as "center",
        width: 100,
        sorter: (a: any, b: any) => a?.durationTimeAvg - b?.durationTimeAvg,
        render: (value: number) => (
          <Statistic
            value={value || 0}
            suffix="s"
            prefix={<ClockCircleOutlined />}
            valueStyle={{ fontSize: "14px", fontWeight: "400" }}
          />
        ),
      },
    ];

    return (
      <Table
        columns={innerColumns}
        dataSource={filteredSources}
        pagination={false}
        rowKey={(record) => record?.source?.name}
      />
    );
  };

  const summaryTotals = calculateSummaryTotals();

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>
          Source Based Phone Call Summary Report
        </h3>
        <Table
          rowKey={(record) => record?.id}
          loading={loading}
          dataSource={uniqueMembers}
          columns={columns}
          scroll={{ x: "100%" }}
          expandable={{ expandedRowRender }}
          sticky
          pagination={false}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2} align="center">
                <strong>Total</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align="center">
                <Statistic
                  value={summaryTotals?.totalCalls}
                  prefix={<PhoneOutlined />}
                  valueStyle={{ fontSize: "14px", fontWeight: "bold" }}
                />
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} align="center">
                <Statistic
                  value={summaryTotals?.answered}
                  prefix={<PhoneOutlined />}
                  valueStyle={{
                    color: "#3f8600",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                />
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} align="center">
                <Statistic
                  value={summaryTotals?.missed}
                  prefix={<PhoneOutlined />}
                  valueStyle={{
                    color: "#cf1322",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                />
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      </Col>
    </Row>
  );
};

export default CtmSourceBasedPhoneCallSummaryReport;
