import {
  Breadcrumb,
  Col,
  Row,
  Typography,
  Table,
  Button,
  Form,
  Modal,
  Input,
  Switch,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { openNotification } from "../../utils";
import SettingsRouter from "./SettingsRouter";
import { NavLink } from "react-router-dom";
import StatusService from "../../services/StatusService";

const { Item } = Form;
const { Title } = Typography;

export const ACMStatus = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [accountManagerStatus, setAccountManagerStatus] =
    useState<[]>([]);
  const [loading, setLoading] = useState(false);
  const [newName, setNewName] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChange = (id: any, name: string, checked: boolean) => {
    StatusService.update(id, { name: name, active: checked })
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          `Status made ${checked ? "active" : "passive"}`
        );
        getAccountManagerStatus();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      postAccountManagerStatus(values);
      setIsModalVisible(false);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "action",
      width: 300,
      sorter: (a, b) => {
        if (!a.name) return -1;
        if (!b.name) return 1;
        return a.name.localeCompare(b.name);
      },
      defaultSortOrder: "ascend",
      render: (_, row) => (
        <Row>
          <Input
            defaultValue={row?.name}
            onChange={(e) => setNewName(e.target.value)}
          />
        </Row>
      ),
    },
    {
      title: "Active/Passive",
      dataIndex: "active",
      key: "active",
      align: "center",
      width: 100,
      sorter: (a, b) => a.active - b.active,
      render: (_, row) => (
        <Switch
          onChange={(checked) => onChange(row?.id, row?.name, checked)}
          checked={row.active}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      render: (_, row) => (
        <Button
          onClick={() => {
            onChange(row?.id, newName, row?.active);
          }}
        >
          Update
        </Button>
      ),
    },
  ];

  useEffect(() => {
    getAccountManagerStatus();
    // eslint-disable-next-line
  }, []);

  const postAccountManagerStatus = (data: any) => {
    setLoading(true);
    StatusService.create({
      ...data,
      statusType: "ACM_CUSTOMER_STATUS",
    })
      .then((response: any) => {
        setLoading(false);
        getAccountManagerStatus();
        form.resetFields();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getAccountManagerStatus = () => {
    setLoading(true);
    StatusService.getAll("statusType=ACM_CUSTOMER_STATUS")
      .then((response: any) => {
        let data = response.data;
        setAccountManagerStatus(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  return (
    <motion.div
      initial={{ y: 200, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 300, opacity: 0 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 40,
      }}
    >
      <div>
        <Row>
          <Col>
            <Title className="onemaz-page-title" level={3}>
              {}
            </Title>
            <Breadcrumb
              items={[
                {
                  title: <NavLink to="/">Home</NavLink>,
                },
                {
                  title: "Settings",
                },
                {
                  title: "Account Manager Status",
                },
              ]}
            />
          </Col>
        </Row>
        <SettingsRouter />

        <Button type="primary" onClick={showModal}>
          Create Status
        </Button>
        <Row style={{ marginTop: "30px" }}>
          <Col span={24}>
            <Table
              loading={loading}
              columns={columns}
              dataSource={accountManagerStatus}
              pagination={false}
              scroll={{ x: "100%" }}
              sticky
              rowKey={(record) => record.id}
            />
          </Col>
        </Row>

        <Modal
          title="Create New Status"
          open={isModalVisible}
          onOk={handleCreate}
          onCancel={handleCancel}
        >
          <Form form={form} layout="vertical">
            <Item
              name="name"
              label="Status"
              rules={[
                {
                  required: true,
                  message: "Name required",
                },
              ]}
            >
              <Input />
            </Item>
          </Form>
        </Modal>
      </div>
    </motion.div>
  );
};
