import { Col, Row, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import { SuspendTypeMemberReport } from "../../../../types/AmazonSuspendedApplicantReports";

interface SuspendTypeBasedMemberReportProps {
  dateRange: [string, string];
}

const SuspendTypeBasedMemberReport = ({
  dateRange,
}: SuspendTypeBasedMemberReportProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<SuspendTypeMemberReport[]>([]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const formattedStartDate = dayjs(dateRange[0]).format(
        "YYYY-MM-DDT00:00:00"
      );
      const formattedEndDate = dayjs(dateRange[1]).format(
        "YYYY-MM-DDT23:59:59"
      );
      getSuspendTypeBasedMemberReport(formattedStartDate, formattedEndDate);
    }
  }, [dateRange]);

  const getSuspendTypeBasedMemberReport = (
    startDate: string,
    endDate: string
  ) => {
    setLoading(true);
    AmazonSuspendedApplicantReportsService.getSuspendTypeMemberReport(
      startDate,
      endDate
    )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<SuspendTypeMemberReport> = [
    {
      title: "Suspend Type",
      dataIndex: "suspendType",
      key: "suspendType",
      fixed: "left",
      width: 200,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.suspendType.localeCompare(b.suspendType),
      render: (text: string, record: SuspendTypeMemberReport) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={record.suspendType?.toUpperCase()}
        >
          <b>{record.suspendType?.toUpperCase()}</b>
        </div>
      ),
    },
    ...Object.keys(
      data.reduce((acc, item) => ({ ...acc, ...item.userCounts }), {})
    ).map((member) => ({
      title: member,
      dataIndex: "userCounts",
      key: member,
      width: 220,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: SuspendTypeMemberReport, b: SuspendTypeMemberReport) =>
        (a.userCounts[member] ?? 0) - (b.userCounts[member] ?? 0),
      render: (userCounts: Record<string, number>) => userCounts[member] || 0,
    })),
    {
      title: "Total Files",
      dataIndex: "totalFiles",
      key: "totalFiles",
      width: 180,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a, b) => a.totalFiles - b.totalFiles,
      render: (totalFiles: number) => (
        <div>
          <b>{totalFiles !== undefined ? totalFiles : "N/A"}</b>
        </div>
      ),
    },
    {
      title: "Total Not Opened",
      dataIndex: "totalNotOpened",
      key: "totalNotOpened",
      width: 180,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a, b) => a.totalNotOpened - b.totalNotOpened,
      render: (totalNotOpened: number) => (
        <div>
          <b>{totalNotOpened !== undefined ? totalNotOpened : "N/A"}</b>
        </div>
      ),
    },
    {
      title: "Total Resolved",
      dataIndex: "totalResolved",
      key: "totalResolved",
      width: 180,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a, b) => a.totalResolved - b.totalResolved,
      render: (totalResolved: number) => (
        <div>
          <b>{totalResolved !== undefined ? totalResolved : "N/A"}</b>
        </div>
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>Suspend Type Based Member Report</h3>
        <Table
          loading={loading}
          scroll={{ x: "max-content" }}
          sticky
          rowKey={(record) => record.suspendType}
          columns={columns}
          dataSource={data}
          pagination={false}
          summary={(pageData) => {
            // Her sütun için toplamları hesapla
            const totalFilesSum = pageData.reduce(
              (sum, record) => sum + record.totalFiles,
              0
            );
            const totalNotOpenedSum = pageData.reduce(
              (sum, record) => sum + record.totalNotOpened,
              0
            );
            const totalResolvedSum = pageData.reduce(
              (sum, record) => sum + record.totalResolved,
              0
            );

            // Member sütunlarındaki toplamları hesapla
            const memberTotals: Record<string, number> = {};
            pageData.forEach((record) => {
              Object.entries(record.userCounts).forEach(([member, count]) => {
                memberTotals[member] = (memberTotals[member] || 0) + count;
              });
            });

            return (
              <Table.Summary fixed>
                <Table.Summary.Row className="summary-cell">
                  <Table.Summary.Cell
                    index={0}
                    colSpan={1}
                    align="left"
                    className="summary-cell"
                  >
                    <b>Total</b>
                  </Table.Summary.Cell>
                  {Object.keys(memberTotals).map((member, index) => (
                    <Table.Summary.Cell
                      key={member}
                      index={index + 1}
                      align="center"
                      className="summary-cell"
                    >
                      <b>{memberTotals[member]}</b>
                    </Table.Summary.Cell>
                  ))}
                  <Table.Summary.Cell
                    index={Object.keys(memberTotals).length + 1}
                    align="center"
                    className="summary-cell"
                  >
                    <b>{totalFilesSum}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={Object.keys(memberTotals).length + 2}
                    align="center"
                    className="summary-cell"
                  >
                    <b>{totalNotOpenedSum}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={Object.keys(memberTotals).length + 3}
                    align="center"
                    className="summary-cell"
                  >
                    <b>{totalResolvedSum}</b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default SuspendTypeBasedMemberReport;
