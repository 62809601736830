import {
  Card,
  Row,
  Table,
  Button,
  Tag,
  Modal,
  Pagination,
  Select,
  Tooltip,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { CopyClipboard } from "../../General/CopyClipboard";
import moment from "moment";
import { StartCall } from "../../General/StartCall";
import { openNotification } from "../../../utils";
import dayjs from "dayjs";
import CustomerService from "../../../services/CustomerService";
import { useNavigate } from "react-router-dom";
import type { PaginationProps } from "antd";
import DownloadExcel from "../../CustomerDetail/DownloadExcel";
import { ICustomerStatusResponseData } from "../../../types/CustomerStatus";
import UserService from "../../../services/UserService";
import { SuspendedFilterField } from "./SuspendedFilterField";
import CallTeamService from "../../../services/CallTeamService";
import { UploadOutlined } from "@ant-design/icons";
import NewTask from "../../UserDetail/Tasks/NewTask";
import StatusService from "../../../services/StatusService";
import StmMultiAssign from "./StmMultiAssign";

interface User {
  id: number;
  createdDate: string;
  fullName: string;
  email: string;
  phone: string;
  currentPackage: number;
  status: any;
  activityStatus: string;
  state: string;
  passiveDays: number;
  productCount: number;
  orderCount: number;
  oneamzOrderCount: number;
  freeDays: number;
  lastSalesDate: string;
  sellerLevel: string;
  feedback: number;
  username: string;
  goldUser: boolean;
  lastLoginDate: string;
}

export const StmSuspendedCustomerList: React.FC<any> = () => {
  const [users, setUsers] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [totalCustomerCount, setTotalCustomerCount] = useState(0);
  const [formFields, setFormFields] = useState({});
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const roleName = localStorage.getItem("roleName");
  const authUserId = localStorage.getItem("id");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerStatusOptions, setCustomerStatusOptions] = useState<
    ICustomerStatusResponseData[]
  >([]);
  const [userOptions, setUserOptions] = useState<any[]>([]);
  const [filterText, setFilterText] = useState<string>(
    localStorage.getItem("softwareSuspendedFilterText") ||
      (roleName === "SUCCESS_TEAM"
        ? `?page=0&size=20&state=Suspended&memberFlag=SUCCESS_TEAM_SOFTWARE_SUSPEND&successTeamMember=${authUserId}`
        : `?page=0&size=20&state=Suspended&memberFlag=SUCCESS_TEAM_SOFTWARE_SUSPEND`)
  );
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [successTeamMembers, setSuccessTeamMembers] = useState<any[]>([]);
  const [selectedcustomerIds, setSelectedcustomerIds] = useState<any>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [softwareSuspendedStatusOptions, setSoftwareSuspendedStatusOptions] = useState<any[]>([]);
  const [triggerUpdate, setTriggerUpdate] = useState(0);

  // const currentUser: any = {
  //   canLearnersGetAll: can("learners:getAll"),
  // };

  const navigate = useNavigate();

  useEffect(() => {
    getCustomerStatus();
    getSuccessTeamMembers();
    getSoftwareSuspendedStatus();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    // newFilterText = newFilterText.replace(/(fullName=)[^&]*/, `$1${searchText}`);
    setFilterText(newFilterText);
    // eslint-disable-next-line
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (roleName === "SUCCESS_TEAM_MANAGER" || roleName === "SUCCESS_TEAM" || roleName === "ADMIN") {
      setSortField("subscriptionEndDate");
      setSortOrder("ascend");
      updateFilterSort("subscriptionEndDate", "ascend");
    }
    // eslint-disable-next-line
  }, [roleName]);

  const onChangeSearch = (search: string) => {
    const params = new URLSearchParams(filterText);

    if (search) {
      params.set("nameOrEmailOrPhone", search);
    } else {
      params.delete("nameOrEmailOrPhone");
    }

    const newFilterText = "?" + params.toString();

    setFilterText(newFilterText);
  };

  const getCustomerStatus = () => {
    CustomerService.getAllStatus(`?active=true`)
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  // const handleChangeStatus = (status: any, id: any) => {
  //   CustomerService.update(id, { statusId: status })
  //     .then((response: any) => {
  //       openNotification("success", "Success", "Changed status");
  //     })
  //     .catch((e: Error) => {
  //       console.log(e);
  //     });
  // };

  // const handleChangeUser = (value: any) => {
  //   const userIdPattern = /&userId=\d*/;
  //   if (filterText.match(userIdPattern)) {
  //     const newFilterText = filterText.replace(
  //       userIdPattern,
  //       `&userId=${value}`
  //     );
  //     setFilterText(newFilterText);
  //   } else {
  //     setFilterText(filterText + `&userId=${value}`);
  //   }
  // };

  const handleChangeSoftwareSuspendedStatus = (status: any, id: any) => {
    if (roleName === "SUCCESS_TEAM_MANAGER" || roleName === "SUCCESS_TEAM" || roleName === "ADMIN") {
      let data: any = {
        suspendStatusId: status,
        customerIds: [id],
      };
      setIsLoading(true);
      CustomerService.assignCustomerToSoftwareSuspendStatus(data)
        .then((response: any) => {
          openNotification(
            "success",
            "Success",
            "Changed software suspend status"
          );
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const getSoftwareSuspendedStatus = () => {
    if (roleName === "SUCCESS_TEAM_MANAGER" || roleName === "SUCCESS_TEAM" || roleName === "ADMIN") {
      let mainStatuses: any[] = [];
      let subStatuses: any[] = [];
      setIsLoading(true);
      StatusService.getAll("statusType=SOFTWARE_SUSPENDED_STATUS")
        .then((response: any) => {
          let data = response.data;
          data.forEach((item: any) => {
            if (item.parent === null) {
              mainStatuses.push(item);
            } else {
              subStatuses.push(item);
            }
          });

          let processedData = mainStatuses.map((mainStatus: any) => {
            return {
              ...mainStatus,
              subStatuses: subStatuses.filter(
                (subStatus: any) => subStatus.parent.id === mainStatus.id
              ),
            };
          });
          setSoftwareSuspendedStatusOptions(processedData);
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const getUsers = () => {
    setIsLoading(true);
    let queryParams = filterText;

    if (roleName === "SUCCESS_TEAM_MANAGER" || roleName === "ADMIN" || roleName === "SUCCESS_TEAM") {
      if (roleName === "SUCCESS_TEAM") {
        queryParams += `&successTeamMember=${authUserId}`;
      }

      CustomerService.getAll(queryParams)
        .then((response: any) => {
          setUsers(response.data.data);
          setTotalCustomerCount(response.data.totalElements);
          setFilteredUsers(response.data.data);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });

      UserService.getAll("?roleName=SUCCESS_TEAM")
        .then((response: any) => {
          let data = response;

          const newArray = data.map((item: any) => {
            let fullName = `${item?.firstName} ${item?.lastName}`;
            return { value: item?.id, label: fullName };
          });
          setUserOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const handeChangeAssigneUser = (user: any, id: any) => {
    let data: any = {
      userId: user,
    };
    setIsLoading(true);
    CustomerService.updateAssigneUser(id, data)
      .then((response: any) => {
        openNotification("success", "Success", "Changed assigne user");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const loadFilterTextFromLocalStorage = () => {
      const storedFilterText = localStorage.getItem(
        "softwareSuspendedFilterText"
      );
      if (storedFilterText) {
        setFilterText(storedFilterText);
      }
    };
    loadFilterTextFromLocalStorage();
    // eslint-disable-next-line
  }, []);

  const columns: ColumnsType<User> = [
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      fixed: "left",
      sorter: true,
      width: 110,
      render: (text: string) => (
        <p style={{ fontWeight: "bolder", fontSize: 12 }}>
          {text ? moment(text).format("DD/MM/YYYY") : ""}
        </p>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      fixed: "left",
      sorter: true,
      width: 180,
      render: (text: string, record: User) => (
        <div>
          <CopyClipboard text={text}></CopyClipboard>{" "}
        </div>
      ),
    },
    {
      title: "E-Mail",
      dataIndex: "email",
      key: "email",
      width: 200,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 120,
      render: (text: string) => <StartCall text={text} />,
    },
    {
      title: "Current Package",
      dataIndex: "currentPackage",
      key: "currentPackage",
      sorter: true,
      width: 100,
    },
    {
      title: "Passive Days",
      dataIndex: "passiveDays",
      key: "passiveDays",
      width: 100,
    },
    ...(roleName === "SUCCESS_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            title: "Success Team Member",
            dataIndex: "successTeamMember",
            key: "successTeamMember",
            width: 200,
            render: (_: any, row: any) => {
              return (
                <Select
                  showSearch
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(selectedUser) =>
                    handeChangeAssigneUser(selectedUser, row.id)
                  }
                  options={successTeamMembers}
                  key={row.id}
                  {...(row?.successTeamMember?.id && {
                    defaultValue: row.successTeamMember.id,
                  })}
                  placeholder={"Select"}
                  style={{ width: "180px" }}
                ></Select>
              );
            },
          },
        ]
      : []),
    ...(roleName === "ADMIN" || roleName === "SUCCESS_TEAM_MANAGER" || roleName === "SUCCESS_TEAM"
      ? [
          {
            title: "Software Suspend Status",
            dataIndex: "suspendStatus",
            key: "suspendStatus",
            width: 200,
            render: (_: any, row: any) => {
              return (
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option && option.label && typeof option.label === "string"
                      ? option.label.toLowerCase().includes(input.toLowerCase())
                      : false
                  }
                  onChange={(selectedStatus) =>
                    handleChangeSoftwareSuspendedStatus(selectedStatus, row.id)
                  }
                  key={row.id}
                  defaultValue={row.suspendStatus && row.suspendStatus.id}
                  placeholder={"Select"}
                  style={{ width: "180px" }}
                >
                  {softwareSuspendedStatusOptions.map((mainStatus) => (
                    <Select.OptGroup
                      key={mainStatus.id}
                      label={mainStatus.name}
                    >
                      {mainStatus.subStatuses.map((subStatus: any) => (
                        <Select.Option key={subStatus.id} value={subStatus.id}>
                          {subStatus.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  ))}
                </Select>
              );
            },
          },
        ]
      : []),
    {
      title: "Suspended Status",
      dataIndex: "subscriptionEndDate",
      key: "subscriptionEndDate",
      sorter: true,
      width: 120,
      render: (subscriptionEndDate: string) => {
        if (subscriptionEndDate) {
          const daysUntilEnd = dayjs(subscriptionEndDate).diff(dayjs(), "day");
          let text;
          let color;
          let tooltipMessage = null;

          if (daysUntilEnd <= 7 && daysUntilEnd >= 0) {
            text = "Pending";
            color = "gold";
            tooltipMessage =
              "Users who have not yet completed 7 days after the software suspend";
          } else {
            text = "Suspend";
            color = "red";
          }

          const tagElement = (
            <Tag color={color} style={{ textTransform: "capitalize" }}>
              {text}
            </Tag>
          );

          return tooltipMessage ? (
            <Tooltip title={tooltipMessage}>{tagElement}</Tooltip>
          ) : (
            tagElement
          );
        } else {
          return (
            <Tag color="red" style={{ textTransform: "capitalize" }}>
              Suspend
            </Tag>
          );
        }
      },
    },
    {
      title: "Reason for Suspension",
      dataIndex: "cancellationReason",
      key: "cancellationReason",
      sorter: true,
      width: 150,
      render: (text) => (text && text !== "" ? text.replace(/_/g, " ") : ""),
    },
    {
      title: "Suspended Date",
      dataIndex: "subscriptionEndDate",
      key: "subscriptionEndDate",
      sorter: true,
      width: 110,
      align: "center",
      render: (text: string) => (
        <p style={{ fontWeight: "bolder", fontSize: 12 }}>
          {text ? moment(text).format("DD/MM/YYYY HH:mm") : ""}
        </p>
      ),
    },
    {
      title: "Detail",
      key: "detail",
      width: 120,
      align: "center",
      fixed: "right" as const,
      render: (_: any, record: any) => (
        <Row justify="center" align="middle" gutter={[0, 8]}>
          <Button
            type="primary"
            onClick={() => {
              let filterToSave = filterText;
              const regex = /nameOrEmailOrPhone=[^&]*/g;
              filterToSave = filterToSave.replace(regex, "");
              filterToSave = filterToSave.replace(/^&|&$/g, "");
              localStorage.setItem("softwareSuspendedFilterText", filterToSave);
              navigate(`/customers/detail/${record.id}`, {
                state: {
                  currentPage: currentPage,
                  pageSize: pageSize,
                  formFields: formFields,
                  filterText: filterText,
                },
              });
            }}
          >
            Detail
          </Button>
          {roleName !== "SUCCESS_TEAM_REPORTER" ? (
            <NewTask
              customerName={record?.fullName}
              customerId={record?.id}
              setTriggerUpdate={() => {}}
              isCustomer={record?.learner === null}
              userOptions={userOptions}
            />
          ) : null}
        </Row>
      ),
    },
  ];

  const updateFilterSort = (field: string, order: string) => {
    const direction =
      order === "ascend" ? "asc" : order === "descend" ? "desc" : "";
    let newFilterText = `?page=${currentPage}&size=${pageSize}&state=Suspended&memberFlag=SUCCESS_TEAM_SOFTWARE_SUSPEND${
      field && order ? `&sort=${field}&direction=${direction}` : ""
    }`;
    if (roleName === "SUCCESS_TEAM") {
      newFilterText = `?page=${currentPage}&size=${pageSize}&state=Suspended&memberFlag=SUCCESS_TEAM_SOFTWARE_SUSPEND${
        field && order ? `&sort=${field}&direction=${direction}` : ""
      }`;
    }
    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";

    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }

    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    updateFilterSort(sorter.field, nextSortOrder);
  };

  useEffect(() => {
    if (filterText && (sortField || sortOrder)) {
      getUsers();
    }
    // eslint-disable-next-line
  }, [filterText, sortField, sortOrder]);

  const showSendModal = () => {
    const params = new URLSearchParams(filterText);
    params.delete("page");
    params.delete("size");
    params.delete("search");
    const newFilterText = "?" + params.toString();

    CustomerService.getAllWithoutPagination(newFilterText)
      .then((response: any) => {
        setSelectedcustomerIds(response.data.map((item: any) => item.id));
      })
      .catch((e: Error) => {});

    setIsModalVisible(true);
  };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const assingUsers = () => {
    function distributecustomerIds(customerIds: number[], teamMembers: any[]) {
      const totalMembers = teamMembers.length;
      const perMember = Math.floor(customerIds.length / totalMembers);
      const remainder = customerIds.length % totalMembers;

      const shuffledcustomerIds = customerIds.sort(() => 0.5 - Math.random());

      let startIndex = 0;
      const assignments = teamMembers.map((member, index) => {
        const idsForCurrentMember = shuffledcustomerIds.slice(
          startIndex,
          startIndex + perMember + (index < remainder ? 1 : 0)
        );
        startIndex += perMember + (index < remainder ? 1 : 0);
        return {
          userId: member.value,
          customerIds: idsForCurrentMember,
        };
      });

      return assignments;
    }

    if (selectedcustomerIds && selectedcustomerIds.length > 0) {
      const results = distributecustomerIds(
        selectedcustomerIds,
        successTeamMembers
      );
      Promise.all(
        results.map((result) => CallTeamService.assignCustomerToMember(result))
      )
        .then(() => {
          openNotification("success", "Success", "Successfully appointed");
        })
        .catch((e: Error) => {
          console.error(e.message);
        });
    } else {
      openNotification(
        "warning",
        "Warning",
        "All selected users have been previously assigned."
      );
    }
  };

  const getSuccessTeamMembers = () => {
    if (roleName === "SUCCESS_TEAM_MANAGER") {
      UserService.getAll("?roleName=SUCCESS_TEAM")
        .then((response: any) => {
          let data = response;
          const newArray = data.map((item: any) => {
            let fullName = `${item.firstName} ${item.lastName}`;
            return { value: item.id, label: fullName };
          });
          setSuccessTeamMembers(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const onSelectRow = (record: any) => {
    const newSelectedRowKeys = selectedRowKeys.includes(record.id)
      ? selectedRowKeys.filter((key: any) => key !== record.id)
      : [...selectedRowKeys, record.id];

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: any) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: () => ({
      disabled: true,
    }),
  };

  const selectAll = () => {
    if (selectedRowKeys.length === totalCustomerCount) {
      setSelectedRowKeys([]);
    } else {
      setIsLoading(true);
      if (roleName === "SUCCESS_TEAM_MANAGER") {
        CustomerService.getAllCustomersLite(`?state=Suspended&memberFlag=SUCCESS_TEAM_SOFTWARE_SUSPEND`)
          .then((response: any) => {
            let data = response.data;
            let customerIds = data.map((user: any) => user.id);
            setSelectedRowKeys(customerIds);
            setIsLoading(false);
          })
          .catch((e: Error) => {
            console.log(e);
            setIsLoading(false);
          });
      }
    }
  };

  return (
    <>
      <Card
        className="card-customers"
        style={{ marginTop: "10px" }}
        title={
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              className="customer-card-title"
              style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
            >
              Software Suspended Customer List
            </div>
            <div className="customer-card-info">
              <b>{totalCustomerCount} </b>Customers
            </div>
            {roleName === "SUCCESS_TEAM_MANAGER" ? (
              <Button
                style={{ marginTop: 10, marginLeft: 10 }}
                onClick={() => {
                  selectAll();
                }}
                type="primary"
              >
                Select All
              </Button>
            ) : null}
          </div>
        }
        extra={
          <Row
            style={{ minWidth: 300, display: "flex", justifyContent: "end" }}
          >
            {roleName === "SUCCESS_TEAM_MANAGER" ? (
              <>
                <Button
                  type="primary"
                  onClick={showSendModal}
                  style={{ marginLeft: 10, marginRight: 10 }}
                  icon={<UploadOutlined />}
                >
                  Send
                </Button>
                <Modal
                  title="Data Assigne"
                  open={isModalVisible}
                  onCancel={handleCancel}
                  footer={null}
                >
                  <p>
                    Selected User Count: <b>{totalCustomerCount}</b> <br />{" "}
                    <br />
                    <b> Selected Filters:</b>{" "}
                    {filterOptions.map((filter: any, index) => (
                      <span key={index}>
                        {index > 0 && ", "} <br />
                        {filter.name}:{" "}
                        {Array.isArray(filter.value)
                          ? filter.value.join(", ")
                          : filter.value}
                      </span>
                    ))}
                  </p>
                  <span>
                    <b>
                      With this process, previously assigned users will not be
                      assigned again.
                    </b>
                  </span>
                  <Button
                    type="primary"
                    onClick={assingUsers}
                    style={{ width: "100%" }}
                    icon={<UploadOutlined />}
                  >
                    Assign Users
                  </Button>
                </Modal>
              </>
            ) : null}
            {selectedRowKeys.length > 0 &&
              roleName === "SUCCESS_TEAM_MANAGER" && (
                <StmMultiAssign
                  selectedRowKeys={selectedRowKeys}
                  setTriggerUpdate={setTriggerUpdate}
                />
              )}
            <SuspendedFilterField
              onChangeSearch={onChangeSearch}
              setFilterText={setFilterText}
              pageSize={pageSize}
              setFormFields={setFormFields}
              formFields={formFields}
            />
            <DownloadExcel filterText={filterText} />
          </Row>
        }
        bordered={true}
      >
        <Table
          loading={isLoading}
          scroll={{ x: "100%" }}
          rowKey={"id"}
          sticky
          columns={columns}
          dataSource={users}
          pagination={false}
          onChange={handleTableChange}
          onRow={(record) => ({
            onClick: () => onSelectRow(record),
          })}
          rowSelection={rowSelection}
        />
        <Row style={{ marginTop: "30px" }} justify={"center"}>
          <Pagination
            showSizeChanger={true}
            total={totalCustomerCount}
            defaultPageSize={pageSize}
            defaultCurrent={0}
            current={currentPage + 1}
            showQuickJumper
            onChange={paginationOnChange}
          />
        </Row>
      </Card>
    </>
  );
};
