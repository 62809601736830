import { Col, Modal, Row, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import {
  CustomerDetail,
  CustomerDetailsReport,
} from "../../../../types/AmazonSuspendedApplicantReports";

interface SolvedStatusBasedOneAmzAppUserReportProps {
  dateRange: [string, string];
}

const SolvedStatusBasedOneAmzAppUserReport: React.FC<
  SolvedStatusBasedOneAmzAppUserReportProps
> = ({ dateRange }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<CustomerDetailsReport[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customerDetails, setCustomerDetails] = useState<CustomerDetail[]>([]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const formattedStartDate = dayjs(dateRange[0]).format(
        "YYYY-MM-DDT00:00:00"
      );
      const formattedEndDate = dayjs(dateRange[1]).format(
        "YYYY-MM-DDT23:59:59"
      );
      getSolvedStatusBasedOneAmzAppUserReport(
        formattedStartDate,
        formattedEndDate
      );
    }
  }, [dateRange]);

  const getSolvedStatusBasedOneAmzAppUserReport = (
    startDate: string,
    endDate: string
  ) => {
    setLoading(true);
    AmazonSuspendedApplicantReportsService.getCustomerDetails(
      startDate,
      endDate
    )
      .then((response) => {
        setData([response.data]);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTotalUserCountClick = (record: CustomerDetailsReport) => {
    setCustomerDetails(record.customerDetails || []);
    setIsModalVisible(true);
  };

  const columns: ColumnsType<CustomerDetailsReport> = [
    {
      title: "OneAmz App User Count",
      dataIndex: "oneAmzUserCount",
      key: "oneAmzUserCount",
      align: "center",
      width: 150,
      sorter: (a, b) => a.oneAmzUserCount - b.oneAmzUserCount,
      render: (oneAmzUserCount: number) => (
        <div>{oneAmzUserCount !== undefined ? oneAmzUserCount : "N/A"}</div>
      ),
    },
    {
      title: "Non OneAmz App User Count",
      dataIndex: "nonOneAmzUserCount",
      key: "nonOneAmzUserCount",
      align: "center",
      width: 150,
      sorter: (a, b) => a.nonOneAmzUserCount - b.nonOneAmzUserCount,
      render: (nonOneAmzUserCount: number) => (
        <div>
          {nonOneAmzUserCount !== undefined ? nonOneAmzUserCount : "N/A"}
        </div>
      ),
    },
    {
      title: "Total User Count",
      dataIndex: "totalCount",
      key: "totalCount",
      align: "center",
      width: 150,
      sorter: (a, b) => a.totalCount - b.totalCount,
      render: (totalCount: number, record) => (
        <div
          onClick={() => handleTotalUserCountClick(record)}
          style={{ cursor: "pointer" }}
        >
          <b>{totalCount !== undefined ? totalCount : "N/A"}</b>
        </div>
      ),
    },
  ];

  const customerDetailsColumns: ColumnsType<CustomerDetail> = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      fixed: "left",
      width: 200,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.customerName.localeCompare(b.customerName),
      render: (text: string) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={text}
        >
          <b
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {text}
          </b>
        </div>
      ),
    },
    {
      title: "Resolved At",
      dataIndex: "resolvedAt",
      key: "resolvedAt",
      align: "center",
      width: 150,
      sorter: (a, b) =>
        a.resolvedAt && b.resolvedAt
          ? dayjs(a.resolvedAt).unix() - dayjs(b.resolvedAt).unix()
          : 0,
      render: (resolvedAt: string) => (
        <div>
          {resolvedAt ? dayjs(resolvedAt).format("DD MMM YYYY") : "N/A"}
        </div>
      ),
    },
    {
      title: "OneAmz User",
      dataIndex: "oneAmzUser",
      key: "oneAmzUser",
      align: "center",
      width: 120,
      sorter: (a, b) => {
        if (a.oneAmzUser && !b.oneAmzUser) return -1;
        if (!a.oneAmzUser && b.oneAmzUser) return 1;
        return 0;
      },
      render: (text: boolean) => (
        <Tag color={text === true ? "green" : "red"}>
          {text === true ? "Yes" : "No"}
        </Tag>
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>
          Solved Status Based OneAmz App User Report
        </h3>
        <Table
          loading={loading}
          scroll={{ x: "max-content" }}
          sticky
          rowKey={(record) => record.totalCount}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
        <Modal
          title="Customer Details"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
          width={"60%"}
        >
          <Table
            columns={customerDetailsColumns}
            size="small"
            scroll={{ x: "max-content", y: "200px" }}
            dataSource={customerDetails}
            pagination={false}
            rowKey={(record) => record.customerName}
            className="dashboard-table"
          />
        </Modal>
      </Col>
    </Row>
  );
};

export default SolvedStatusBasedOneAmzAppUserReport;
