import http from "../http-common";

const getMasCheckStatusLog = (filterCriteria: any) => {
  return http.get<any>(
    `/mas-check-status-log${filterCriteria}`
  );
};

const updateMasCheckStatusLog = (data: any) => {
  return http.put<any>(`/mas-check-status-log`, data);
};

const MasTeamCheckStatusService = {
  getMasCheckStatusLog,
  updateMasCheckStatusLog,
};

export default MasTeamCheckStatusService;
