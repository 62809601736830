
import "./dashboard.css";
import { useTranslation } from "react-i18next";
import { SuccessManagerDashboard } from "./SuccessManagerDashboard";
import { AcmDashboard } from "./AcmDashboard";
import { LearnerList } from "./LearnerList";
import { CtmDashboard } from "./CtmDashboard";
import { SuspendTeamDashboard } from "./SuspendTeamDashboard";
import { Hi5AcmDashboard } from "./Hi5AcmDashboard";
import { MasDashboard } from "./MasDashboard";

export const Dashboard = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const roleName = localStorage.getItem("roleName");

  return (
    <>
      {roleName === "SUCCESS_TEAM" ? (
        <SuccessManagerDashboard />
      ) : roleName === "SUCCESS_TEAM_MANAGER" ? (
        <SuccessManagerDashboard />
      ) : roleName === "ACCOUNT_MANAGER" ? (
        <AcmDashboard />
      ) : roleName === "ACCOUNT_MANAGER_MANAGER" ? (
        <AcmDashboard />
      ) : roleName === "HI5_ACM_MANAGER" ? (
        <Hi5AcmDashboard />
      ) : roleName === "HI5_ACM_MEMBER" ? (
        <Hi5AcmDashboard />
      ) : roleName === "ADMIN" ? null : roleName === "SUCCESS_TEAM_REPORTER" ? (
        <LearnerList />
      ) : roleName === "CALL_TEAM" ? (
        <CtmDashboard />
      ) : roleName === "CALL_TEAM_MANAGER" ? (
        <CtmDashboard />
      ) : roleName === "SUSPEND_TEAM_MANAGER" ? (
        <SuspendTeamDashboard />
      ) : roleName === "SUSPEND_TEAM" ? (
        <SuspendTeamDashboard />
      ) : roleName === "MAS_TEAM_MANAGER" ? (
        <MasDashboard />
      ) : roleName === "MAS_TEAM_MEMBER" ? (
        <MasDashboard />
      ) : null}
    </>
  );
};
