import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Input,
  Row,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { NavLink } from "react-router-dom";
import { ReportsRouter } from "../../ReportsRouter";
import DateRange from "../../../Common/DateRange";
import { useEffect, useState } from "react";
import FeedbackService from "../../../../services/FeedbackService";
import FeedbackDetailReportModal from "./FeedbackDetailReportModal";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";

const { Title } = Typography;

interface IFeedbackReport {
  memberId: number;
  member: string;
  feedbackCount: number;
  averageRating: number;
  soru1: number;
  soru2: number;
  soru3: number;
}

export const FeedbackReport = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<[string, string]>(["", ""]);
  const [data, setData] = useState<IFeedbackReport[]>([]);
  const [userDetails, setUserDetails] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const originalRoleName = localStorage.getItem("roleName") || "";

  const getAdjustedRoleName = (roleName: string): string => {
    const roleMap: { [key: string]: string } = {
      ACCOUNT_MANAGER_MANAGER: "ACCOUNT_MANAGER",
      HI5_ACM_MANAGER: "HI5_ACM_MEMBER",
      SUCCESS_TEAM_MANAGER: "SUCCESS_TEAM",
      CALL_TEAM_MANAGER: "CALL_TEAM",
      SUSPEND_TEAM_MANAGER: "SUSPEND_TEAM",
      ADMIN: "ADMIN",
    };
    return roleMap[roleName] || roleName;
  };

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      getFeedbackReport(dateRange[0], dateRange[1]);
    }
    // eslint-disable-next-line
  }, [dateRange]);

  const getFeedbackReport = (startDate: string, endDate: string) => {
    const formattedStartDate = new Date(startDate).toISOString();
    const formattedEndDate = new Date(endDate).toISOString();
    const adjustedRoleName = getAdjustedRoleName(originalRoleName);

    setLoading(true);
    FeedbackService.getReport(
      formattedStartDate,
      formattedEndDate,
      adjustedRoleName
    )
      .then((response) => {
        const filteredData = response.data.filter(
          (item: IFeedbackReport) =>
            item.member !== "AZ Success Team" &&
            item.member !== "system STM Member" &&
            item.member !== "Duygu Karakilic"
        );
        setData(filteredData);
        setFilteredData(filteredData);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const getUserDetails = (userId: number) => {
    const formattedStartDate = new Date(dateRange[0]).toISOString();
    const formattedEndDate = new Date(dateRange[1]).toISOString();

    FeedbackService.getDetailsByUserId(
      userId,
      formattedStartDate,
      formattedEndDate
    )
      .then((response: any) => {
        setUserDetails(response.data);
        setIsModalVisible(true);
      })
      .catch((error) => console.error(error));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setUserDetails([]);
  };

  const columns = [
    {
      title: "Member Name",
      dataIndex: "member",
      key: "member",
      width: 250,
      fixed: "left" as "left",
      align: "left" as "left",
      sorter: (a: IFeedbackReport, b: IFeedbackReport) =>
        a?.member.localeCompare(b?.member),
      render: (text: string) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={`${text}`}
        >
          <b>{text}</b>
        </div>
      ),
    },
    {
      title: "Feedback Count",
      dataIndex: "feedbackCount",
      key: "feedbackCount",
      width: 150,
      align: "center" as "center",
      sorter: (a: IFeedbackReport, b: IFeedbackReport) =>
        a?.feedbackCount - b?.feedbackCount,
      render: (text: number) => <span>{text}</span>,
    },
    {
      title: (
        <Tooltip
          title={
            "Yaptığınız telefon görüşmesi deneyiminden ne kadar memnun kaldınız?"
          }
          placement="bottom"
        >
          <span>Question 1</span>
        </Tooltip>
      ),
      dataIndex: "soru1",
      key: "soru1",
      width: 150,
      align: "center" as "center",
      sorter: (a: IFeedbackReport, b: IFeedbackReport) => a?.soru1 - b?.soru1,
      render: (text: number) => <span>{text?.toFixed(2)}</span>,
    },
    {
      title: (
        <Tooltip
          title={
            "Görüşme yaptığınız kişi konuya ne kadar hakimdi ve bilgiliydi?"
          }
          placement="bottom"
        >
          <span>Question 2</span>
        </Tooltip>
      ),
      dataIndex: "soru2",
      key: "soru2",
      width: 150,
      align: "center" as "center",
      sorter: (a: IFeedbackReport, b: IFeedbackReport) => a?.soru2 - b?.soru2,
      render: (text: number) => <span>{text?.toFixed(2)}</span>,
    },
    {
      title: (
        <Tooltip
          title={"Görüşme yaptığınız kişinin nezaketi ve ilgisi nasıldı?"}
          placement="bottom"
        >
          <span>Question 3</span>
        </Tooltip>
      ),
      dataIndex: "soru3",
      key: "soru3",
      width: 150,
      align: "center" as "center",
      sorter: (a: IFeedbackReport, b: IFeedbackReport) => a?.soru3 - b?.soru3,
      render: (text: number) => <span>{text?.toFixed(2)}</span>,
    },
    {
      title: "Average Rating",
      dataIndex: "averageRating",
      key: "averageRating",
      width: 150,
      fixed: "right" as "right",
      align: "center" as "center",
      sorter: (a: IFeedbackReport, b: IFeedbackReport) =>
        a?.averageRating - b?.averageRating,
      render: (text: number) => <b>{text?.toFixed(2)}</b>,
    },
  ];

  const handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    if (value === "" || value === undefined || value === null) {
      setFilteredData(data);
    } else {
      const filtered = data.filter((item: any) => {
        const fullName = `${item?.member}`.toLowerCase();
        return fullName.includes(value);
      });
      setFilteredData(filtered);
    }
  };

  const downloadExcel = () => {
    const headers = columns.map((col) => {
      if (typeof col.title === "object" && col.title.type === Tooltip) {
        return col.title.props.title;
      }
      return col.title;
    });

    const dataForExcel = filteredData.map((item: IFeedbackReport) => [
      item.member,
      item.feedbackCount,
      item.soru1.toFixed(2),
      item.soru2.toFixed(2),
      item.soru3.toFixed(2),
      item.averageRating.toFixed(2),
    ]);

    // Summary satırını ekliyoruz
    const totalFeedbackCount = filteredData.reduce(
      (sum: number, record: IFeedbackReport) => sum + record.feedbackCount,
      0
    );
    const totalSoru1 = (
      filteredData.reduce(
        (sum: number, record: IFeedbackReport) => sum + record.soru1,
        0
      ) / filteredData.length
    ).toFixed(2);
    const totalSoru2 = (
      filteredData.reduce(
        (sum: number, record: IFeedbackReport) => sum + record.soru2,
        0
      ) / filteredData.length
    ).toFixed(2);
    const totalSoru3 = (
      filteredData.reduce(
        (sum: number, record: IFeedbackReport) => sum + record.soru3,
        0
      ) / filteredData.length
    ).toFixed(2);
    const totalAverageRating = (
      filteredData.reduce(
        (sum: number, record: IFeedbackReport) => sum + record.averageRating,
        0
      ) / filteredData.length
    ).toFixed(2);

    dataForExcel.push([
      "Total",
      totalFeedbackCount,
      totalSoru1,
      totalSoru2,
      totalSoru3,
      totalAverageRating,
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...dataForExcel]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Feedback Report");

    XLSX.writeFile(
      workbook,
      `Feedback_Report_${new Date().toISOString()}.xlsx`
    );
  };

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/">Home</NavLink>,
              },
              {
                title: "Feedback Report",
              },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />
      <Card
        className="card-customers"
        title={
          <>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                className="customer-card-title"
                style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
              >
                Feedback Report
              </div>
            </div>
            <Input
              className="search-input"
              addonBefore={<SearchOutlined />}
              placeholder="Search By Name"
              value={searchText}
              onChange={handleSearch}
              style={{ marginBottom: 16 }}
              size="large"
            />
          </>
        }
        extra={
          <Row justify={"end"}>
            <DateRange thisMonth={true} onChangeDateRange={setDateRange} />
            <Button
              style={{ marginLeft: "20px" }}
              size={"large"}
              icon={<DownloadOutlined />}
              onClick={downloadExcel}
              type="primary"
            >
              Export
            </Button>
          </Row>
        }
        bordered
        style={{ marginTop: "30px" }}
      >
        <Table
          loading={isLoading}
          scroll={{ x: "100%" }}
          sticky
          rowKey="memberId"
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          onRow={(record) => ({
            onClick: () => getUserDetails(record.memberId),
            style: { cursor: "pointer" },
          })}
          summary={(pageData) => {
            let totalFeedbackCount = 0;
            let totalSoru1 = 0;
            let totalSoru2 = 0;
            let totalSoru3 = 0;
            let totalAverageRating = 0;

            pageData.forEach(
              ({ feedbackCount, soru1, soru2, soru3, averageRating }) => {
                totalFeedbackCount += feedbackCount;
                totalSoru1 += soru1;
                totalSoru2 += soru2;
                totalSoru3 += soru3;
                totalAverageRating += averageRating;
              }
            );

            const averageSoru1 = totalSoru1 / pageData.length || 0;
            const averageSoru2 = totalSoru2 / pageData.length || 0;
            const averageSoru3 = totalSoru3 / pageData.length || 0;
            const averageOverallRating =
              totalAverageRating / pageData.length || 0;

            return (
              <Table.Summary.Row className="summary-cell">
                <Table.Summary.Cell index={0} className="summary-cell">
                  <b>Total</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={1}
                  align="center"
                  className="summary-cell"
                >
                  <b>{totalFeedbackCount}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={2}
                  align="center"
                  className="summary-cell"
                >
                  <b>{averageSoru1.toFixed(2)}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={3}
                  align="center"
                  className="summary-cell"
                >
                  <b>{averageSoru2.toFixed(2)}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={4}
                  align="center"
                  className="summary-cell"
                >
                  <b>{averageSoru3.toFixed(2)}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={5}
                  align="center"
                  className="summary-cell"
                >
                  <b>{averageOverallRating.toFixed(2)}</b>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </Card>

      <FeedbackDetailReportModal
        visible={isModalVisible}
        onCancel={handleCancel}
        data={userDetails}
      />
    </>
  );
};
