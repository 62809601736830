import http from "../http-common";

const getMemberBasedTotalCustomerReport = (startDate: string, endDate: string) => {
  return http.get<any>(
    `/report/call-team/member-based-total-customer-report?startDate=${startDate}&endDate=${endDate}`
  );
};

const getSourceBasedTotalCustomerReport = (startDate: string, endDate: string) => {
  return http.get<any>(
    `/report/call-team/source-based-total-customer-report?startDate=${startDate}&endDate=${endDate}`
  );
};

const getSourceBasedSalesReport = (startDate: string, endDate: string, memberId: number) => {
  return http.get<any>(
    `/report/call-team/source-based-sales-report?startDate=${startDate}&endDate=${endDate}&memberId=${memberId}`
  );
};

const getAllSourceBasedSalesReport = (startDate: string, endDate: string) => {
  return http.get<any>(
    `/report/call-team/all-source-based-sales-report?startDate=${startDate}&endDate=${endDate}`
  );
};

const getSourceBasedPhoneCallSummaryReport = (startDate: string, endDate: string) => {
  return http.get<any>(
    `/report/call-team/source-based-phone-call-summary-report?startDate=${startDate}&endDate=${endDate}`
  );
};

const CallTeamReportService = {
  getMemberBasedTotalCustomerReport,
  getSourceBasedTotalCustomerReport,
  getSourceBasedSalesReport,
  getAllSourceBasedSalesReport,
  getSourceBasedPhoneCallSummaryReport,
};
export default CallTeamReportService;
