import { Input, Row, Space } from "antd";
import React, { useCallback, useState } from "react";
import Filter from "./Filter";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import _ from "lodash";

export const FilterField: React.FC<any> = ({
  onChangeSearch,
  setFilterText,
  pageSize,
  sortOrder,
  sortField,
  setFormFields,
  formFields,
  setFilterOptions,
}) => {
  const [searchText, setSearchText] = useState("");
  // eslint-disable-next-line
  const debouncedSearch = useCallback(_.debounce(onChangeSearch, 500), [
    onChangeSearch,
  ]);
  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchText(newValue);
    debouncedSearch(newValue);
  };

  const clearInput = () => {
    setSearchText("");
    debouncedSearch("");
  };

  const handleOnChangeFilter = (filterForm: any) => {
    const normalizedSortOrder = sortOrder === 'ascend' ? 'asc' : sortOrder === 'descend' ? 'desc' : sortOrder;

    let filterQuery = `?page=0&size=${pageSize}&sort=${sortField}&direction=${normalizedSortOrder}&search=${searchText}&state=Active`;

    if (filterForm.state && filterForm.state != null) {
      filterQuery += `&sessionStatus=${filterForm.state}`;
    }

    if (
      filterForm.accountManagerMember &&
      filterForm.accountManagerMember != null
    ) {
      filterQuery += `&userId=${filterForm.accountManagerMember}`;
    }

    if (filterForm.callTeamMember && filterForm.callTeamMember != null) {
      filterQuery += `&callCenterMember=${filterForm.callTeamMember}`;
    }

    if (filterForm.hasNoPackages && filterForm.hasNoPackages != null) {
      filterQuery += `&hasNoPackages=${filterForm.hasNoPackages}`;
    }

    if (filterForm.additionalPackage && filterForm.additionalPackage != null) {
      filterQuery += `&additionalPackgeIds=${filterForm.additionalPackage}`;
    }

    if (filterForm.status && filterForm.status != null) {
      filterQuery += `&statusIds=${filterForm.status}`;
    }

    if (filterForm.acmStatus && filterForm.acmStatus != null) {
      filterQuery += `&acmStatusIds=${filterForm.acmStatus}`;
    }

    if (filterForm.currentPackage && filterForm.currentPackage != null) {
      filterQuery += `&currentPackage=${filterForm.currentPackage}`;
    }

    if (
      filterForm.packageOfInterestACM &&
      filterForm.packageOfInterestACM != null
    ) {
      filterQuery += `&packageOfInterestACMIds=${filterForm.packageOfInterestACM}`;
    }

    if (
      filterForm.currentPackageOfInterestACM &&
      filterForm.currentPackageOfInterestACM != null
    ) {
      filterQuery += `&currentPackageOfInterestACMIds=${filterForm.currentPackageOfInterestACM}`;
    }

    if (filterForm.startPassiveDay) {
      filterQuery += `&startPassiveDays=${filterForm.startPassiveDay}`;
    }

    if (filterForm.endPassiveDay) {
      filterQuery += `&endPassiveDays=${filterForm.endPassiveDay}`;
    }

    if (filterForm.startLastContactedAcm) {
      filterQuery += `&startLastContactedAcm=${filterForm.startLastContactedAcm}`;
    }

    if (filterForm.endLastContactedAcm) {
      filterQuery += `&endLastContactedAcm=${filterForm.endLastContactedAcm}`;
    }

    if (filterForm.startTotalOrderCount) {
      filterQuery += `&startTotalOrderCount=${filterForm.startTotalOrderCount}`;
    }

    if (filterForm.endTotalOrderCount) {
      filterQuery += `&endTotalOrderCount=${filterForm.endTotalOrderCount}`;
    }

    if (filterForm.sellerLevel) {
      filterQuery += `&sellerLevel=${filterForm.sellerLevel}`;
    }

    if (filterForm.level) {
      filterQuery += `&level=${filterForm.level}`;
    }

    if (filterForm.unassigned) {
      filterQuery += `&assignedUser=${!filterForm.unassigned}`;
    }

    if (filterForm.goldPackage) {
      filterQuery += `&goldPackage=${filterForm.goldPackage}`;
    }

    if (filterForm.slpPackage) {
      filterQuery += `&slpPackage=${filterForm.slpPackage}`;
    }

    if (filterForm.autoPilotPackage) {
      filterQuery += `&autoPilotPackage=${filterForm.autoPilotPackage}`;
    }

    if (filterForm.inactiveGold7Days) {
      filterQuery += `&inactiveGold7Days=${filterForm.inactiveGold7Days}`;
    }

    if (filterForm.lastSalesDay !== undefined) {
      if (filterForm.lastSalesDay.length > 0) {
        let startDate = dayjs(filterForm.lastSalesDay[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.lastSalesDay[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&startLastSalesDate=${startDate}&endLastSalesDate=${endDate}`;
      }
    }

    if (filterForm.goldPackageEndDate !== undefined) {
      if (filterForm.goldPackageEndDate.length > 0) {
        let startDate = dayjs(filterForm.goldPackageEndDate[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.goldPackageEndDate[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&startGoldPackageEndDate=${startDate}&endGoldPackageEndDate=${endDate}`;
      }
    }

    if (filterForm.createdAtRange !== undefined) {
      if (filterForm.createdAtRange.length > 0) {
        let startDate = dayjs(filterForm.createdAtRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.createdAtRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&startCreatedAt=${startDate}&endCreatedAt=${endDate}`;
      }
    }

    if (filterForm.assignmentDateRange !== undefined) {
      if (filterForm.assignmentDateRange.length > 0) {
        let startDate = dayjs(filterForm.assignmentDateRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.assignmentDateRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&startAssignmentDate=${startDate}&endAssignmentDate=${endDate}`;
      }
    }

    if (filterForm.subscriptionEndDateRange !== undefined) {
      if (filterForm.subscriptionEndDateRange.length > 0) {
        let startDate = dayjs(filterForm.subscriptionEndDateRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.subscriptionEndDateRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&subscriptionEndDateStartDate=${startDate}&subscriptionEndDateEndDate=${endDate}`;
      }
    }

    if (filterForm.registeredDateRange !== undefined) {
      if (filterForm.registeredDateRange.length > 0) {
        let startDate = dayjs(filterForm.registeredDateRange[0]).format(
          "YYYY-MM-DD"
        );
        let endDate = dayjs(filterForm.registeredDateRange[1]).format(
          "YYYY-MM-DD"
        );
        filterQuery += `&registeredAtStartDate=${startDate}&registeredAtEndDate=${endDate}`;
      }
    }

    if (filterForm.autoSubscriptionRenewalEnabled) {
      filterQuery += `&autoSubscriptionRenewalEnabled=${filterForm.autoSubscriptionRenewalEnabled}`;
    }

    if (filterForm.autoGoldSubscriptionRenewalEnabled) {
      filterQuery += `&autoGoldSubscriptionRenewalEnabled=${filterForm.autoGoldSubscriptionRenewalEnabled}`;
    }

    setFilterText(filterQuery);
  };

  return (
    <Row justify="end">
      <Space align="start">
        <Input
          prefix={<SearchOutlined />}
          suffix={
            searchText && (
              <CloseCircleOutlined
                onClick={clearInput}
                style={{ color: "rgba(0,0,0,.45)", cursor: "pointer" }}
              />
            )
          }
          placeholder="Search"
          value={searchText}
          onChange={onSearchChange}
          style={{ maxWidth: 200 }}
        />
        <Filter
          onChangeFilter={handleOnChangeFilter}
          setFormFields={setFormFields}
          formFields={formFields}
          setFilterOptions={setFilterOptions}
        />
      </Space>
    </Row>
  );
};
