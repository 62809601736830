import { Breadcrumb, Button, Col, Form, Input, Modal, Row, Select, Switch, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SettingsRouter from "./SettingsRouter";
import EmployeeService from "../../services/EmployeeService";
import { IEmployeeRequestData, IEmployeeResponseData } from "../../types/Employee";
import ButtonGroup from "antd/es/button/button-group";
import { openNotification } from "../../utils";
import { CopyClipboard } from "../General/CopyClipboard";
import DepartmentService from "../../services/DepartmentService";
import { IDepartmentResponseData } from "../../types/Department";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

const { Item } = Form;
const { Title } = Typography;

export const EmployeeList = () => {
  const [form] = Form.useForm();
  const [formEditEmployee] = Form.useForm();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [newEmployeeFullName, setNewEmployeeFullName] = useState<string | undefined>(undefined);
  const [newEmployeeDescription, setNewEmployeeDescription] = useState<string | undefined>(undefined);
  const [newEmployeePhone, setNewEmployeePhone] = useState<string | undefined>(undefined);
  const [newEmployeeEmail, setNewEmployeeEmail] = useState<string | undefined>(undefined);
  const [newEmployeeDepartment, setNewEmployeeDepartment] = useState<string | undefined>(undefined);
  const [newEmployeeTools, setNewEmployeeTools] = useState<string | undefined>(undefined);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [employees, setEmployees] = useState<IEmployeeResponseData[]>([]);
  const [departments, setDepartments] = useState<IDepartmentResponseData[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  const showCreateModal = () => {
    setIsCreateModalVisible(true);
    if (departments.length === 0) {
      getDepartments();
    }
  };

  const handleCreateModalCancel = () => {
    setIsCreateModalVisible(false);
  };

  const showEditModal = (employeeData: any) => {
    setSelectedEmployee(employeeData);
    setNewEmployeeFullName(employeeData.fullName);
    setNewEmployeeDescription(employeeData.description);
    setNewEmployeePhone(employeeData.phone);
    setNewEmployeeEmail(employeeData.email);
    setNewEmployeeDepartment(employeeData.department?.id);
    setNewEmployeeTools(employeeData.tools?.join(", "));
    setIsEditModalVisible(true);
    if (departments.length === 0) {
      getDepartments();
    }
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
    setSelectedEmployee(null);
  };

  const showDeleteModal = (employeeData: any) => {
    setSelectedEmployee(employeeData);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteModalCancel = () => {
    setIsDeleteModalVisible(false);
    setSelectedEmployee(null);
  };

  // POST
  const postEmployee = (data: IEmployeeRequestData) => {
    setLoading(true);
    EmployeeService.create(data)
      .then((response: any) => {
        getEmployees();
        form.resetFields();
        openNotification("success", "Success", `Employee created`);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      const newData: IEmployeeRequestData = {
        ...values,
        department: departments.find((d) => d.id === values.department) || null,
        tools: values.tools.split(",").map((tool: string) => tool.trim()),
      };
      postEmployee(newData);
      setIsCreateModalVisible(false);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const clearInput = () => {
    setSearchText("");
  };

  // GETALL
  const getEmployees = () => {
    setLoading(true);
    EmployeeService.getAll(`${searchText}`)
      .then((response: any) => {
        let data = response.data;
        setEmployees(data);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const getDepartments = () => {
    setLoading(true);
    DepartmentService.getAll()
      .then((response: any) => {
        let data = response.data;
        setDepartments(data);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  // PUT UPDATE
  const handleUpdateEmployee = (
    id: number,
    fullName: string,
    description: string,
    phone: string,
    email: string,
    department: number | null,
    tools: string
  ) => {
    setLoading(true);
    const updateData: IEmployeeRequestData = {
      id: id,
      fullName: fullName,
      description: description,
      phone: phone,
      email: email,
      active: true,
      department: departments.find((d) => d.id === department) || null,
      tools: tools.split(",").map((tool: string) => tool.trim()),
    };
    EmployeeService.update(id, updateData)
      .then((response: any) => {
        openNotification("success", "Success", `Employee updated`);
        handleEditModalCancel();
        formEditEmployee.resetFields();
        getEmployees();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const onChange = (id: any, checked: boolean) => {
    const employeeToUpdate = employees.find((employee) => employee.id === id);
    if (employeeToUpdate) {
      const updateData: IEmployeeRequestData = {
        id: employeeToUpdate.id,
        fullName: employeeToUpdate.fullName,
        description: employeeToUpdate.description,
        phone: employeeToUpdate.phone,
        email: employeeToUpdate.email,
        active: checked,
        tools: employeeToUpdate.tools,
        department: employeeToUpdate.department,
      };
      EmployeeService.update(id, updateData)
        .then((response: any) => {
          openNotification(
            "success",
            "Success",
            `Employee made ${checked ? "active" : "passive"}`
          );
          getEmployees();
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  // DELETE
  const handleDeleteEmployee = (id: number) => {
    setLoading(true);
    EmployeeService.remove(id)
      .then((response: any) => {
        openNotification("success", "Success", `Employee deleted`);
        handleDeleteModalCancel();
        getEmployees();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const columns: ColumnsType<IEmployeeResponseData> = [
    {
      title: "Employee",
      dataIndex: "fullName",
      key: "fullName",
      width: 250,
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      render: (_, row) => (
        <Row justify={"start"} align={"middle"}>
          <Col>
            <div>
              <b>{row.fullName}</b>
            </div>
            <div>
              <CopyClipboard text={row.email}></CopyClipboard>
            </div>
            <div>
              <CopyClipboard text={row.phone}></CopyClipboard>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
      sorter: (a, b) => a.description.localeCompare(b.description),
      render: (_, row) => <span>{row.description}</span>,
    },
    {
      title: "Active/Passive",
      dataIndex: "active",
      key: "active",
      width: 150,
      align: "center",
      sorter: (a: any, b: any) => a.active - b.active,
      render: (_, row) => (
        <Switch
          onChange={(checked) => onChange(row?.id, checked)}
          checked={row.active}
        />
      ),
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      width: 200,
      sorter: (a, b) => {
        const aName = a?.department?.name || "";
        const bName = b?.department?.name || "";
        return aName.localeCompare(bName);
      },
      render: (_, row) => <span>{row.department?.name}</span>,
    },
    {
      title: "Tools",
      dataIndex: "tools",
      key: "tools",
      width: 250,
      render: (_, row) => <span>{row.tools.join(", ")}</span>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      width: 100,
      render: (_, record) => (
        <ButtonGroup style={{ marginLeft: "10px", justifyContent: "flex-end" }}>
          <Button type="primary" onClick={() => showEditModal(record)}>
            Edit
          </Button>
          <Button onClick={() => showDeleteModal(record)}>Delete</Button>
        </ButtonGroup>
      ),
    },
  ];

  useEffect(() => {
    const debounceSearch = debounce(() => {
      getEmployees();
    }, 300);

    debounceSearch();
    return () => {
      debounceSearch.cancel();
    };
    // eslint-disable-next-line
  }, [searchText]);

  useEffect(() => {
    formEditEmployee.setFieldsValue({
      selectedFullName: newEmployeeFullName,
      selectedDescription: newEmployeeDescription,
      selectedPhone: newEmployeePhone,
      selectedEmail: newEmployeeEmail,
      selectedDepartment: newEmployeeDepartment,
      selectedTools: newEmployeeTools,
    });
    // eslint-disable-next-line
  }, [
    newEmployeeFullName,
    newEmployeeDescription,
    newEmployeePhone,
    newEmployeeEmail,
    newEmployeeDepartment,
    newEmployeeTools,
  ]);

  return (
    <motion.div
      initial={{ y: 200, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 300, opacity: 0 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 40,
      }}
    >
      <div>
        <Row align={"middle"} justify={"space-between"}>
          <Col span={12}>
            <Title className="onemaz-page-title" level={3}>
              {}
            </Title>
            <Breadcrumb
              items={[
                {
                  title: <NavLink to="/">Home</NavLink>,
                },
                {
                  title: "Settings",
                },
                {
                  title: "Employees",
                },
              ]}
            />
          </Col>
          <Col span={12} style={{ textAlign: "right"}}>
            <Input
              prefix={<SearchOutlined />}
              suffix={
                searchText && (
                  <CloseCircleOutlined
                    onClick={clearInput}
                    style={{ color: "rgba(0,0,0,.45)", cursor: "pointer" }}
                  />
                )
              }
              placeholder="Search by full name"
              value={searchText}
              onChange={onSearchChange}
              style={{ maxWidth: 200, marginRight: "90px" }}
            />
          </Col>
        </Row>
        <SettingsRouter />
        <Button type="primary" onClick={showCreateModal}>
          Create Employee
        </Button>
        <Row style={{ marginTop: "30px" }}>
          <Col span={24}>
            <Table
              loading={loading}
              columns={columns}
              dataSource={employees}
              scroll={{ x: "100%" }}
              rowKey={(record) =>
                record.id?.toString() || `temp_key_${Math.random()}`
              }
              key={"id"}
            />
          </Col>
        </Row>
        {/* Create Modal */}
        <Modal
          title="Create New Employee"
          open={isCreateModalVisible}
          onOk={handleCreate}
          onCancel={handleCreateModalCancel}
        >
          <Form form={form} layout="vertical">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <Item
                  name="fullName"
                  label="Employee Name"
                  rules={[
                    {
                      required: true,
                      message: "Employee name required",
                    },
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Item>
              </Col>
              <Col xs={24} sm={12}>
                <Item
                  name="description"
                  label="Employee Description"
                  rules={[
                    {
                      required: true,
                      message: "Employee description required",
                    },
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <Item
                  name="phone"
                  label="Employee Phone"
                  rules={[
                    {
                      required: true,
                      message: "Employee phone required",
                    },
                    {
                      pattern: /^\d+$/,
                      message: "Employee phone must be numeric",
                    },
                    {
                      min: 10,
                      message: "Employee phone must be at least 10 digits",
                    },
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Item>
              </Col>
              <Col xs={24} sm={12}>
                <Item
                  name="email"
                  label="Employee Email"
                  rules={[
                    {
                      required: true,
                      message: "Employee email required",
                    },
                    {
                      type: "email",
                      message: "Employee email must be valid",
                    },
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <Item
                  name="department"
                  label="Department"
                  rules={[
                    {
                      required: true,
                      message: "Department required",
                    },
                  ]}
                >
                  <Select placeholder={"Select Department"}>
                    <Select.Option key={0} value={null}>
                      Select Department
                    </Select.Option>
                    {departments?.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              <Col xs={24} sm={12}>
                <Item
                  name="tools"
                  label="Tools"
                  rules={[
                    {
                      required: true,
                      message: "Tools required",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "100%" }}
                    placeholder="Enter tools separated by commas"
                  />
                </Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        {/* Edit Modal */}
        <Modal
          title="Edit Employee"
          open={isEditModalVisible}
          onCancel={handleEditModalCancel}
          footer={[
            <Button key="back" onClick={handleEditModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                handleUpdateEmployee(
                  selectedEmployee?.id ?? 0,
                  newEmployeeFullName ?? "",
                  newEmployeeDescription ?? "",
                  newEmployeePhone ?? "",
                  newEmployeeEmail ?? "",
                  newEmployeeDepartment ? Number(newEmployeeDepartment) : null,
                  newEmployeeTools ?? ""
                );
              }}
            >
              Save
            </Button>,
          ]}
        >
          <Form form={formEditEmployee} layout="vertical">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <Item
                  name="selectedFullName"
                  label="Edit Employee FullName"
                  rules={[
                    {
                      required: true,
                      message: "Employee fullname required",
                    },
                  ]}
                >
                  <Input
                    value={newEmployeeFullName}
                    onChange={(e) => setNewEmployeeFullName(e.target.value)}
                  />
                </Item>
              </Col>
              <Col xs={24} sm={12}>
                <Item
                  name="selectedDescription"
                  label="Edit Employee Description"
                  rules={[
                    {
                      required: true,
                      message: "Employee description required",
                    },
                  ]}
                >
                  <Input
                    value={newEmployeeDescription}
                    onChange={(e) => setNewEmployeeDescription(e.target.value)}
                  />
                </Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <Item
                  name="selectedPhone"
                  label="Edit Employee Phone"
                  rules={[
                    {
                      required: true,
                      message: "Employee phone required",
                    },
                    {
                      pattern: /^\d+$/,
                      message: "Employee phone must be numeric",
                    },
                    {
                      min: 10,
                      message: "Employee phone must be at least 10 digits",
                    },
                  ]}
                >
                  <Input
                    value={newEmployeePhone}
                    onChange={(e) => setNewEmployeePhone(e.target.value)}
                  />
                </Item>
              </Col>
              <Col xs={24} sm={12}>
                <Item
                  name="selectedEmail"
                  label="Edit Employee Email"
                  rules={[
                    {
                      required: true,
                      message: "Employee email required",
                    },
                    {
                      type: "email",
                      message: "Employee email must be valid",
                    },
                  ]}
                >
                  <Input
                    value={newEmployeeEmail}
                    onChange={(e) => setNewEmployeeEmail(e.target.value)}
                  />
                </Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <Item
                  name="selectedDepartment"
                  label="Edit Employee Department"
                  rules={[
                    {
                      required: true,
                      message: "Employee department required",
                    },
                  ]}
                >
                  <Select
                    onChange={(value: any) => setNewEmployeeDepartment(value)}
                    {...(departments && { defaultValue: departments[0] })}
                    placeholder={"Select Department"}
                    options={departments?.map((option) => ({
                      label: option.name,
                      value: option.id,
                    }))}
                  />
                </Item>
              </Col>
              <Col xs={24} sm={12}>
                <Item
                  name="selectedTools"
                  label="Edit Employee Tools"
                  rules={[
                    {
                      required: true,
                      message: "Employee tools required",
                    },
                  ]}
                >
                  <Input
                    value={newEmployeeTools}
                    onChange={(e) => setNewEmployeeTools(e.target.value)}
                  />
                </Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        {/* Delete Modal */}
        <Modal
          title="Delete Employee"
          open={isDeleteModalVisible}
          onCancel={handleDeleteModalCancel}
          footer={[
            <Button key="back" onClick={handleDeleteModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => handleDeleteEmployee(selectedEmployee?.id)}
            >
              Delete
            </Button>,
          ]}
        >
          <p>Are you sure you want to delete this employee?</p>
        </Modal>
      </div>
    </motion.div>
  );
};
