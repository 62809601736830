import { Col, Row, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import CustomerAssignmentHistoryService from "../../../../services/CustomerAssignmentHistoryService";
import dayjs from "dayjs";

const { Text } = Typography;

const MemberFlagReport = ({
  dateRange,
  memberFlag,
}: {
  dateRange: any;
  memberFlag: string;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line
  const [oldMemberFlagData, setOldMemberFlagData] = useState<any>([]);
  // eslint-disable-next-line
  const [oldMemberFlagByNewMemberFlagData, setOldMemberFlagByNewMemberFlagData] = useState<any>([]);
  // eslint-disable-next-line
  const [newMemberFlagData, setNewMemberFlagData] = useState<any>([]);
  const [groupedReport, setGroupedReport] = useState<any>({});
  const [dataSource, setDataSource] = useState<any[]>([]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1] && memberFlag) {
      const formattedStartDate = dayjs(dateRange[0]).format(
        "YYYY-MM-DDT00:00:00"
      );
      const formattedEndDate = dayjs(dateRange[1]).format(
        "YYYY-MM-DDT23:59:59"
      );

      getReports(formattedStartDate, formattedEndDate, memberFlag);
    }
    // eslint-disable-next-line
  }, [dateRange, location.pathname]);

  const getReports = async (
    startDate: string,
    endDate: string,
    memberFlag: string
  ) => {
    setLoading(true);

    try {
      const oldMemberFlagPromise =
        CustomerAssignmentHistoryService.getOldMemberFlagReport(
          startDate,
          endDate,
          memberFlag
        );

      const newMemberFlagPromise =
        CustomerAssignmentHistoryService.getNewMemberFlagReport(
          startDate,
          endDate,
          memberFlag
        );

      const oldMemberFlagByNewMemberFlagPromise =
        memberFlag === "SUCCESS_TEAM"
          ? CustomerAssignmentHistoryService.getOldMemberFlagReport(
              startDate,
              endDate,
              "NEW"
            )
          : Promise.resolve({ data: [] });

      const [
        oldMemberFlagResponse,
        newMemberFlagResponse,
        oldMemberFlagByNewMemberFlagResponse,
      ] = await Promise.all([
        oldMemberFlagPromise,
        newMemberFlagPromise,
        oldMemberFlagByNewMemberFlagPromise,
      ]);

      setOldMemberFlagData(oldMemberFlagResponse.data);
      setNewMemberFlagData(
        newMemberFlagResponse.data.customerAssignmentHistoryList
      );
      setGroupedReport(newMemberFlagResponse.data.groupedReport);
      setOldMemberFlagByNewMemberFlagData(
        oldMemberFlagByNewMemberFlagResponse.data
      );

      // Set dataSource after all data is ready
      const newDataSource = [
        {
          key: "1",
          joiningCount:
            newMemberFlagResponse.data.customerAssignmentHistoryList.length,
          leavingCount: oldMemberFlagResponse.data.length,
          ...(memberFlag === "SUCCESS_TEAM" && {
            newCount: oldMemberFlagByNewMemberFlagResponse.data.length,
          }),
        },
      ];
      setDataSource(newDataSource);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Joined Customers Count",
      dataIndex: "joiningCount",
      align: "center",
      render: (text) => <b>{text}</b>,
    },
    {
      title: "Left Customers Count",
      dataIndex: "leavingCount",
      align: "center",
      render: (text) => <b>{text}</b>,
    },
    ...(memberFlag === "SUCCESS_TEAM"
      ? [
          {
            title: "New Customers Count",
            dataIndex: "newCount",
            align: "center" as "center",
            render: (text: number) => <b>{text}</b>,
          },
        ]
      : []),
  ];

  const expandedRowRender = () => {
    const groupedColumns: ColumnsType<any> = [
      {
        title: "Transferred From",
        dataIndex: "memberFlag",
        key: "memberFlag",
        width: 300,
        ellipsis: true,
        render: (text: string) => {
          const formattedText = text
            .toLowerCase()
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={`${formattedText}`}
            >
              <b>{formattedText}</b>
            </div>
          );
        },
      },
      {
        title: "Customer Count",
        dataIndex: "count",
        key: "count",
        align: "center",
        width: 150,
        render: (customerCount) => <b>{customerCount || "N/A"}</b>,
      },
    ];
    return (
      <Table
        columns={groupedColumns}
        dataSource={Object.keys(groupedReport).map((key) => ({
          memberFlag: key,
          count: groupedReport[key],
        }))}
        pagination={false}
        rowKey="memberFlag"
      />
    );
  };

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>
          Joined & Left & New Customers Count Report
        </h3>
        <Text style={{ margin: "0 0 5px 0" }} type="warning">
          ** Note: The data displayed in the expanded table includes only
          customers who have been transferred from other locations.
        </Text>
        <Table
          rowKey={(record) => record?.key}
          loading={loading}
          dataSource={dataSource}
          columns={columns}
          scroll={{ x: "100%" }}
          sticky
          pagination={false}
          expandable={{
            expandedRowRender,
          }}
        />
      </Col>
    </Row>
  );
};

export default MemberFlagReport;
