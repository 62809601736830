import {
  Button,
  Modal,
  Form,
  Select,
  DatePicker,
  Row,
  InputNumber,
  Input,
  Tooltip,
  Col,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import { ICustomerStatusResponseData } from "../../../types/CustomerStatus";
import { can, openNotification } from "../../../utils";
import filterIcon from "../../../assets/filter.svg";
import filterClearIcon from "../../../assets/filter-clear.svg";
import saveIcon from "../../../assets/save.svg";
import { CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import FilterService from "../../../services/FilterService";
import { IFilterRequestData, IFilterResponseData } from "../../../types/Filter";
import AdditionalPackageHistoryService from "../../../services/AdditionalPackageHistoryService";
import StatusService from "../../../services/StatusService";
import CallSalesPackageService from "../../../services/CallSalesPackageService";

const { Item } = Form;

interface CustomerFilterProps {
  onChangeFilter: (values: any) => void;
  setFormFields: any;
  formFields: any;
  setFilterOptions: any;
}

const Filter: React.FC<CustomerFilterProps> = ({
  onChangeFilter,
  setFormFields,
  formFields,
  setFilterOptions,
}) => {
  const { RangePicker } = DatePicker;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerHi5ACMStatusOptions, setCustomerHi5ACMStatusOptions] =
    useState<ICustomerStatusResponseData[]>([]);
  const [hi5AcmTeamOptions, setHi5AcmTeamOptions] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [disableState, setDisableState] = useState(false);
  const [disablePassiveDays, setDisablePassiveDays] = useState(false);
  const [disableHi5AcmLastContactedDays, setDisableHi5AcmLastContactedDays] =
    useState(false);
  const roleName = localStorage.getItem("roleName");
  const [selectedHi5ACMStatusItems, setSelectedHi5ACMStatusItems] = useState<
    any[]
  >([]);
  const [isFilterSaveModalOpen, setIsFilterSaveModalOpen] = useState(false);
  const [savedFilters, setSavedFilters] = useState<any[]>([]);
  const [filterName, setFilterName] = useState("");
  const [isFilterSavedListModalOpen, setIsFilterSavedListModalOpen] =
    useState(false);
  const [isFilterDeleteModalOpen, setIsFilterDeleteModalOpen] = useState(false);
  const [savedFiltersList, setSavedFiltersList] = useState<any[]>([]);
  const [savedFilterId, setSavedFilterId] = useState<any>();
  const [packageOptions, setPackageOptions] = useState<any[]>([]);
  const [additionalPackageOptions, setAdditionalPackageOptions] = useState<
    any[]
  >([]);
  const [
    packageOfInterestStatusForHi5AcmOptions,
    setPackageOfInterestStatusForHi5AcmOptions,
  ] = useState<any[]>([]);
  const [
    currentPackageOfInterestStatusForHi5AcmOptions,
    setCurrentPackageOfInterestStatusForHi5AcmOptions,
  ] = useState<any[]>([]);
  const savedLocalFilters = localStorage.getItem("hi5CustomerFilterText");

  const showModal = () => {
    if (hi5AcmTeamOptions.length === 0) {
      getUsers();
    }
    if (customerHi5ACMStatusOptions.length === 0) {
      getCustomerHi5ACMStatus();
    }
    if (packageOptions.length === 0) {
      getPackages();
    }
    if (additionalPackageOptions.length === 0) {
      getAdditionalPackages();
    }
    if (packageOfInterestStatusForHi5AcmOptions.length === 0) {
      getPackageOfInterestStatusForHi5Acm();
    }
    if (currentPackageOfInterestStatusForHi5AcmOptions.length === 0) {
      getCurrentPackageOfInterestStatusForHi5Acm();
    }
    setIsModalOpen(true);
  };

  const onFinish = (values: any) => {
    const filters = [];
    if (values.state !== undefined) {
      filters.push({ name: "State", value: values.state });
    }
    if (values.hi5AcmMember !== undefined && values.hi5AcmMember !== null) {
      const selectedUser = hi5AcmTeamOptions.find(
        (user) => user.id === values.hi5AcmMember
      );
      const userName = `${selectedUser?.firstName} ${selectedUser?.lastName}`;
      filters.push({ name: "Hi5 ACM Member", value: userName });
    }
    if (values.currentPackage !== undefined && values.currentPackage !== null) {
      filters.push({ name: "Current Package", value: values.currentPackage });
    }
    if (
      values.slpPackageEndDate !== undefined &&
      values.slpPackageEndDate !== null
    ) {
      filters.push({
        name: "SLP Package End Date",
        value: `${values.slpPackageEndDate[0].format(
          "DD/MM/YYYY"
        )} - ${values.slpPackageEndDate[1].format("DD/MM/YYYY")}`,
      });
    }
    if (
      values.goldPackageEndDate !== undefined &&
      values.goldPackageEndDate !== null
    ) {
      filters.push({
        name: "Gold Package End Date",
        value: `${values.goldPackageEndDate[0].format(
          "DD/MM/YYYY"
        )} - ${values.goldPackageEndDate[1].format("DD/MM/YYYY")}`,
      });
    }
    if (
      values.startPassiveDay !== undefined &&
      values.startPassiveDay !== null
    ) {
      filters.push({
        name: "Start Passive Day",
        value: `${values.startPassiveDay}`,
      });
    }
    if (values.endPassiveDay !== undefined && values.endPassiveDay !== null) {
      filters.push({
        name: "End Passive Day",
        value: `${values.endPassiveDay}`,
      });
    }
    if (values.lastSalesDay !== undefined && values.lastSalesDay !== null) {
      filters.push({
        name: "Last Sales Day",
        value: `${values.lastSalesDay[0].format(
          "DD/MM/YYYY"
        )} - ${values.lastSalesDay[1].format("DD/MM/YYYY")}`,
      });
    }
    if (
      values.startLastContactedHi5Acm !== undefined &&
      values.startLastContactedHi5Acm !== null
    ) {
      filters.push({
        name: "Start Last Contacted Day Hi5 Acm",
        value: `${values.startLastContactedHi5Acm}`,
      });
    }
    if (
      values.endLastContactedHi5Acm !== undefined &&
      values.endLastContactedHi5Acm !== null
    ) {
      filters.push({
        name: "End Last Contacted Day Hi5 Acm",
        value: `${values.endLastContactedHi5Acm}`,
      });
    }
    if (values.sellerLevel !== undefined && values.sellerLevel !== null) {
      filters.push({ name: "Seller Level", value: values.sellerLevel });
    }
    if (values.level !== undefined && values.level !== null) {
      filters.push({ name: "Level", value: values.level });
    }
    if (values.additionalPackage && values.additionalPackage.length > 0) {
      const selectedPackages = values.additionalPackage
        .map((id: any) => {
          const pkg = additionalPackageOptions.find(
            (pkg: any) => pkg.id === id
          );
          return pkg ? pkg.name : null;
        })
        .filter((name: any) => name !== null);
      filters.push({ name: "Additional Package", value: selectedPackages });
    }
    const selectedHi5ACMStatuses =
      values.hi5AcmStatus && values.hi5AcmStatus.length > 0
        ? values.hi5AcmStatus
            .map((statusId: any) => {
              const statusOption = customerHi5ACMStatusOptions.find(
                (option: any) => option.value === statusId
              );
              return statusOption ? statusOption.label : null;
            })
            .filter((name: any) => name !== null)
        : [];
    if (selectedHi5ACMStatuses.length > 0) {
      filters.push({ name: "hi5AcmStatus", value: selectedHi5ACMStatuses });
    }
    setFilterOptions(filters);
    onChangeFilter(values);
    setFormFields(form.getFieldsValue());
    setIsModalOpen(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const getUsers = () => {
    if (can("users:getAll")) {
      Promise.all([UserService.getAll("?roleName=HI5_ACM")])
        .then(([hi5AcmResponse]) => {
          setHi5AcmTeamOptions(hi5AcmResponse);
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const clearForm = () => {
    form.resetFields();
    setSelectedHi5ACMStatusItems([]);
    setDisablePassiveDays(false);
    setDisableHi5AcmLastContactedDays(false);
    setDisableState(false);
    setFormFields(form.getFieldsValue());
    localStorage.removeItem("hi5CustomerFilterText");
  };

  const clearLocalFilterAndForm = () => {
    clearForm();
    onFinish({ ...form.getFieldsValue() });
  };

  const getCustomerHi5ACMStatus = () => {
    StatusService.getAll("statusType=HI5_ACM_CUSTOMER_STATUS", `&active=true`)
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerHi5ACMStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getPackageOfInterestStatusForHi5Acm = () => {
    let mainStatuses: any[] = [];
    let subStatuses: any[] = [];
    StatusService.getAll("statusType=PACKAGE_OF_INTEREST_HI5_ACM")
      .then((response: any) => {
        let data = response.data;
        data.forEach((item: any) => {
          if (item.parent === null) {
            mainStatuses.push(item);
          } else {
            subStatuses.push(item);
          }
        });

        let processedData = mainStatuses.map((mainStatus: any) => {
          return {
            ...mainStatus,
            subStatuses: subStatuses.filter(
              (subStatus: any) => subStatus.parent.id === mainStatus.id
            ),
          };
        });
        setPackageOfInterestStatusForHi5AcmOptions(processedData);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getCurrentPackageOfInterestStatusForHi5Acm = () => {
    let mainStatuses: any[] = [];
    let subStatuses: any[] = [];
    StatusService.getAll("statusType=CURRENT_PACKAGE_OF_INTEREST_HI5_ACM")
      .then((response: any) => {
        let data = response.data;
        data.forEach((item: any) => {
          if (item.parent === null) {
            mainStatuses.push(item);
          } else {
            subStatuses.push(item);
          }
        });

        let processedData = mainStatuses.map((mainStatus: any) => {
          return {
            ...mainStatus,
            subStatuses: subStatuses.filter(
              (subStatus: any) => subStatus.parent.id === mainStatus.id
            ),
          };
        });
        setCurrentPackageOfInterestStatusForHi5AcmOptions(processedData);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getPackages = () => {
    CallSalesPackageService.getAll()
      .then((response: any) => {
        const filteredData = response.data.filter(
          (pkg: any) => pkg.isActive && pkg.hi5AcmCommissionRate !== null
        );
        setPackageOptions(filteredData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getAdditionalPackages = () => {
    AdditionalPackageHistoryService.getListPackages()
      .then((response: any) => {
        setAdditionalPackageOptions(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    const {
      startPassiveDay,
      endPassiveDay,
      startLastContactedHi5Acm,
      endLastContactedHi5Acm,
      state,
      startTotalOrderCount,
      endTotalOrderCount,
    } = allValues;
    if (
      startPassiveDay &&
      (endPassiveDay < startPassiveDay || !endPassiveDay)
    ) {
      form.setFieldsValue({ endPassiveDay: startPassiveDay });
    }
    if (startPassiveDay || endPassiveDay) {
      setDisableState(true);
      setDisablePassiveDays(false);
    }
    if (
      startLastContactedHi5Acm &&
      (endLastContactedHi5Acm < startLastContactedHi5Acm ||
        !endLastContactedHi5Acm)
    ) {
      form.setFieldsValue({ endLastContactedHi5Acm: startLastContactedHi5Acm });
    }
    if (startLastContactedHi5Acm || endLastContactedHi5Acm) {
      setDisableHi5AcmLastContactedDays(false);
    }
    if (state) {
      setDisablePassiveDays(true);
      setDisableState(false);
    }
    if (startTotalOrderCount && !endTotalOrderCount) {
      form.setFieldsValue({ endTotalOrderCount: startTotalOrderCount });
    }
    if (
      startTotalOrderCount &&
      endTotalOrderCount &&
      endTotalOrderCount < startTotalOrderCount
    ) {
      form.setFieldsValue({ endTotalOrderCount: startTotalOrderCount });
    }
  };

  const selectAllHi5ACMStatus = () => {
    const allIds = customerHi5ACMStatusOptions.map(
      (option: any) => option.value
    );
    setSelectedHi5ACMStatusItems(allIds);
    form.setFieldsValue({
      hi5AcmStatus: allIds,
    });
  };

  const handleChangeHi5ACMStatus = (selected: any) => {
    setSelectedHi5ACMStatusItems(selected);
    form.setFieldsValue({
      hi5AcmStatus: selected,
    });
  };

  const handleResetHi5AcmLastContactedDays = (form: any) => {
    setDisableHi5AcmLastContactedDays(false);
    form.setFieldsValue({
      startLastContactedHi5Acm: null,
      endLastContactedHi5Acm: null,
    });
  };

  const handleResetPassiveDays = (form: any) => {
    setDisableState(false);
    setDisablePassiveDays(false);
    form.setFieldsValue({
      state: null,
      startPassiveDay: null,
      endPassiveDay: null,
    });
  };

  const handleResetOrderCounts = () => {
    form.setFieldsValue({
      startTotalOrderCount: null,
      endTotalOrderCount: null,
    });
  };

  const showFilterSaveModal = () => {
    setIsFilterSaveModalOpen(true);
  };

  const handleSaveFilter = () => {
    const formData = form.getFieldsValue();
    const {
      state,
      accountManagerMember,
      hi5AcmMember,
      callTeamMember,
      successTeamMember,
      status,
      acmStatus,
      hi5AcmStatus,
      currentPackage,
      startPassiveDay,
      endPassiveDay,
      startLastContactedStm,
      endLastContactedStm,
      startLastContactedAcm,
      endLastContactedAcm,
      startLastContactedHi5Acm,
      endLastContactedHi5Acm,
      startTotalOrderCount,
      endTotalOrderCount,
      freeDays,
      level,
      unassigned,
      goldPackageHi5ACM,
      goldPackage,
      slpPackage,
      autoPilotPackage,
      inactiveGold7Days,
      inactiveSlp7Days,
      lastSalesDay,
      createdAtRange,
      assignmentDateRange,
      subscriptionEndDateRange,
      registeredDateRange,
      retentionAssignmentDateRange,
      hi5AcmAssignmentDateRange,
      hasNoPackages,
      additionalPackage,
      packageOfInterestSTM,
      oldPackageOfInterestSTM,
      packageOfInterestACM,
      currentPackageOfInterestACM,
      packageOfInterestHi5Acm,
      currentPackageOfInterestHi5Acm,
      goldPackageEndDate,
      slpPackageEndDate,
      autoSubscriptionRenewalEnabled,
      autoSlpSubscriptionRenewalEnabled,
      autoGoldSubscriptionRenewalEnabled,
    } = formData;
    const newFilter: IFilterRequestData = {
      name: filterName,
      query: {
        state: state ? state : null,
        userId: accountManagerMember ? accountManagerMember : null,
        hi5AcmMember: hi5AcmMember ? hi5AcmMember : null,
        callCenterMember: callTeamMember ? callTeamMember : null,
        successTeamMember: successTeamMember ? successTeamMember : null,
        hasNoPackages: hasNoPackages ? hasNoPackages : null,
        additionalPackgeIds: additionalPackage
          ? additionalPackage.join(",")
          : null,
        statusIds: status ? status.join(",") : null,
        acmStatusIds: acmStatus ? acmStatus.join(",") : null,
        hi5AcmStatusIds: hi5AcmStatus ? hi5AcmStatus.join(",") : null,
        currentPackage,
        startPassiveDays: startPassiveDay ? startPassiveDay : null,
        endPassiveDays: endPassiveDay ? endPassiveDay : null,
        startLastContactedStm: startLastContactedStm
          ? startLastContactedStm
          : null,
        endLastContactedStm: endLastContactedStm ? endLastContactedStm : null,
        startLastContactedAcm: startLastContactedAcm
          ? startLastContactedAcm
          : null,
        endLastContactedAcm: endLastContactedAcm ? endLastContactedAcm : null,
        startLastContactedHi5Acm: startLastContactedHi5Acm
          ? startLastContactedHi5Acm
          : null,
        endLastContactedHi5Acm: endLastContactedHi5Acm
          ? endLastContactedHi5Acm
          : null,
        startTotalOrderCount,
        endTotalOrderCount,
        freeDays,
        level,
        assignedUser: unassigned ? unassigned : null,
        goldPackageHi5ACM: goldPackageHi5ACM ? goldPackageHi5ACM : null,
        goldPackage: goldPackage ? goldPackage : null,
        slpPackage: slpPackage ? slpPackage : null,
        autoPilotPackage: autoPilotPackage ? autoPilotPackage : null,
        inactiveGold7Days: inactiveGold7Days ? inactiveGold7Days : null,
        inactiveSlp7Days: inactiveSlp7Days ? inactiveSlp7Days : null,
        startLastSalesDate: lastSalesDay ? lastSalesDay[0]["$d"] : null,
        endLastSalesDate: lastSalesDay ? lastSalesDay[1]["$d"] : null,
        startCreatedAt: createdAtRange ? createdAtRange[0]["$d"] : null,
        endCreatedAt: createdAtRange ? createdAtRange[1]["$d"] : null,
        startAssignmentDate: assignmentDateRange
          ? assignmentDateRange[0]["$d"]
          : null,
        endAssignmentDate: assignmentDateRange
          ? assignmentDateRange[1]["$d"]
          : null,
        subscriptionEndDateStartDate: subscriptionEndDateRange
          ? subscriptionEndDateRange[0]["$d"]
          : null,
        subscriptionEndDateEndDate: subscriptionEndDateRange
          ? subscriptionEndDateRange[1]["$d"]
          : null,
        registeredAtStartDate: registeredDateRange
          ? registeredDateRange[0]["$d"]
          : null,
        registeredAtEndDate: registeredDateRange
          ? registeredDateRange[1]["$d"]
          : null,
        retentionAssignmentDateStartDate: retentionAssignmentDateRange
          ? retentionAssignmentDateRange[0]["$d"]
          : null,
        retentionAssignmentDateEndDate: retentionAssignmentDateRange
          ? retentionAssignmentDateRange[1]["$d"]
          : null,
        hi5AcmAssignmentDateStartDate: hi5AcmAssignmentDateRange
          ? hi5AcmAssignmentDateRange[0]["$d"]
          : null,
        hi5AcmAssignmentDateEndDate: hi5AcmAssignmentDateRange
          ? hi5AcmAssignmentDateRange[1]["$d"]
          : null,
        packageOfInterestSTMIds: packageOfInterestSTM
          ? packageOfInterestSTM
          : null,
        oldPackageOfInterestSTMIds: oldPackageOfInterestSTM
          ? oldPackageOfInterestSTM
          : null,
        packageOfInterestACMIds: packageOfInterestACM
          ? packageOfInterestACM
          : null,
        currentPackageOfInterestACMIds: currentPackageOfInterestACM
          ? currentPackageOfInterestACM
          : null,
        packageOfInterestHi5AcmIds: packageOfInterestHi5Acm
          ? packageOfInterestHi5Acm
          : null,
        currentPackageOfInterestHi5AcmIds: currentPackageOfInterestHi5Acm
          ? currentPackageOfInterestHi5Acm
          : null,
        startGoldPackageEndDate: goldPackageEndDate
          ? goldPackageEndDate[0]["$d"]
          : null,
        endGoldPackageEndDate: goldPackageEndDate
          ? goldPackageEndDate[1]["$d"]
          : null,
        startSlpEndDate: slpPackageEndDate ? slpPackageEndDate[0]["$d"] : null,
        endSlpEndDate: slpPackageEndDate ? slpPackageEndDate[1]["$d"] : null,
        autoSubscriptionRenewalEnabled: autoSubscriptionRenewalEnabled
          ? autoSubscriptionRenewalEnabled
          : null,
        autoSlpSubscriptionRenewalEnabled: autoSlpSubscriptionRenewalEnabled
          ? autoSlpSubscriptionRenewalEnabled
          : null,
        autoGoldSubscriptionRenewalEnabled: autoGoldSubscriptionRenewalEnabled
          ? autoGoldSubscriptionRenewalEnabled
          : null,
      },
    };
    FilterService.create(newFilter)
      .then((response: any) => {
        const responseData: IFilterResponseData = response.data;
        const updatedFilters = [...savedFilters, responseData];
        setSavedFilters(updatedFilters);
        openNotification("success", "Success", "New filter created");
      })
      .catch((error: any) => {
        console.error("Error while saving filter:", error);
        openNotification("error", "Error", "Failed to create new filter");
      })
      .finally(() => {
        setIsFilterSaveModalOpen(false);
      });
  };

  useEffect(() => {
    const storedFilters = localStorage.getItem("savedFilters");
    if (storedFilters) {
      setSavedFilters(JSON.parse(storedFilters));
    }
  }, []);

  useEffect(() => {
    if (isModalOpen && formFields) {
      if (formFields.hi5AcmMember) {
        if (hi5AcmTeamOptions.length > 0) {
          const selectedUser: any = hi5AcmTeamOptions.find(
            // eslint-disable-next-line
            (option: any) => option.id == formFields.hi5AcmMember
          );
          form.setFieldsValue({
            hi5AcmMember: selectedUser.id,
          });
        }
      }
      if (formFields.hasNoPackages) {
        form.setFieldsValue({
          hasNoPackages: formFields.hasNoPackages,
        });
      }
      if (formFields.additionalPackage) {
        form.setFieldsValue({
          additionalPackage: formFields.additionalPackage,
        });
      }
      if (formFields.state) {
        form.setFieldsValue({ state: formFields.state });
      }
      if (formFields.startPassiveDay) {
        form.setFieldsValue({ startPassiveDay: formFields.startPassiveDay });
      }
      if (formFields.endPassiveDay) {
        form.setFieldsValue({ endPassiveDay: formFields.endPassiveDay });
      }
      if (formFields.startLastContactedHi5Acm) {
        form.setFieldsValue({
          startLastContactedHi5Acm: formFields.startLastContactedHi5Acm,
        });
      }
      if (formFields.endLastContactedHi5Acm) {
        form.setFieldsValue({
          endLastContactedHi5Acm: formFields.endLastContactedHi5Acm,
        });
      }
      if (formFields.currentPackage) {
        form.setFieldsValue({ currentPackage: formFields.currentPackage });
      }
      if (formFields.level) {
        form.setFieldsValue({ level: formFields.level });
      }
      if (formFields.startTotalOrderCount) {
        form.setFieldsValue({
          startTotalOrderCount: formFields.startTotalOrderCount,
        });
      }
      if (formFields.endTotalOrderCount) {
        form.setFieldsValue({
          endTotalOrderCount: formFields.endTotalOrderCount,
        });
      }
      if (formFields.subscriptionEndDate) {
        form.setFieldsValue({
          subscriptionEndDate: formFields.subscriptionEndDate,
        });
      }
      if (formFields.goldPackageHi5ACM) {
        form.setFieldsValue({
          goldPackageHi5ACM:
            formFields.goldPackageHi5ACM === "true" ? true : false,
        });
      }
      if (formFields.slpPackage) {
        form.setFieldsValue({
          slpPackage: formFields.slpPackage === "true" ? true : false,
        });
      }
      if (formFields.autoPilotPackage) {
        form.setFieldsValue({
          autoPilotPackage:
            formFields.autoPilotPackage === "true" ? true : false,
        });
      }
      if (formFields.inactiveSlp7Days) {
        form.setFieldsValue({
          inactiveSlp7Days:
            formFields.inactiveSlp7Days === "true" ? true : false,
        });
      }
      if (formFields.hi5AcmStatus) {
        const statusIdsParam: any = formFields.hi5AcmStatus;
        setSelectedHi5ACMStatusItems(statusIdsParam);
        form.setFieldsValue({ hi5AcmStatus: statusIdsParam });
      }
      if (formFields.createdAtRange?.[0] && formFields.createdAtRange?.[1]) {
        const formattedStartDate = dayjs(
          dayjs(formFields.createdAtRange[0]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        const formattedEndDate = dayjs(
          dayjs(formFields.createdAtRange[1]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        form.setFieldsValue({
          createdAtRange: [formattedStartDate, formattedEndDate],
        });
      }
      if (
        formFields.assignmentDateRange?.[0] &&
        formFields.assignmentDateRange?.[1]
      ) {
        const formattedStartDate = dayjs(
          dayjs(formFields.assignmentDateRange[0]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        const formattedEndDate = dayjs(
          dayjs(formFields.assignmentDateRange[1]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        form.setFieldsValue({
          assignmentDateRange: [formattedStartDate, formattedEndDate],
        });
      }
      if (
        formFields.subscriptionEndDateRange?.[0] &&
        formFields.subscriptionEndDateRange?.[1]
      ) {
        const formattedStartDate = dayjs(
          dayjs(formFields.subscriptionEndDateRange[0]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        const formattedEndDate = dayjs(
          dayjs(formFields.subscriptionEndDateRange[1]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        form.setFieldsValue({
          subscriptionEndDateRange: [formattedStartDate, formattedEndDate],
        });
      }
      if (
        formFields.registeredDateRange?.[0] &&
        formFields.registeredDateRange?.[1]
      ) {
        const formattedStartDate = dayjs(
          dayjs(formFields.registeredDateRange[0]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        const formattedEndDate = dayjs(
          dayjs(formFields.registeredDateRange[1]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        form.setFieldsValue({
          registeredDateRange: [formattedStartDate, formattedEndDate],
        });
      }
      if (
        formFields.hi5AcmAssignmentDateRange?.[0] &&
        formFields.hi5AcmAssignmentDateRange?.[1]
      ) {
        const formattedStartDate = dayjs(
          dayjs(formFields.hi5AcmAssignmentDateRange[0]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        const formattedEndDate = dayjs(
          dayjs(formFields.hi5AcmAssignmentDateRange[1]["$d"]).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "YYYY-MM-DDTHH:mm:ss"
        );
        form.setFieldsValue({
          hi5AcmAssignmentDateRange: [formattedStartDate, formattedEndDate],
        });
      }
      if (formFields.lastSalesDay?.[0] && formFields.lastSalesDay?.[1]) {
        const formattedStartDate = dayjs(
          dayjs(formFields.lastSalesDay[0]["$d"]).format("YYYY-MM-DDTHH:mm:ss"),
          "YYYY-MM-DDTHH:mm:ss"
        );
        const formattedEndDate = dayjs(
          dayjs(formFields.lastSalesDay[1]["$d"]).format("YYYY-MM-DDTHH:mm:ss"),
          "YYYY-MM-DDTHH:mm:ss"
        );
        form.setFieldsValue({
          lastSalesDay: [formattedStartDate, formattedEndDate],
        });
      }
      if (formFields.packageOfInterestHi5Acm) {
        form.setFieldsValue({
          packageOfInterestHi5Acm: formFields.packageOfInterestHi5Acm,
        });
      }
      if (formFields.currentPackageOfInterestHi5Acm) {
        form.setFieldsValue({
          currentPackageOfInterestHi5Acm:
            formFields.currentPackageOfInterestHi5Acm,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, hi5AcmTeamOptions]);

  const showSavedFilterListModal = () => {
    getSavedFiltersList();
    setIsFilterSavedListModalOpen(true);
  };

  const getSavedFiltersList = () => {
    FilterService.getList()
      .then((response: any) => {
        let data = response.data;
        setSavedFiltersList(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const showFilterDeleteModal = () => {
    setIsFilterDeleteModalOpen(true);
  };

  const handleDeleteFilter = () => {
    const selectedFilterId = savedFilterId;
    if (!selectedFilterId) {
      return;
    }
    FilterService.remove(selectedFilterId)
      .then(() => {
        openNotification("success", "Success", "Filter deleted successfully");
        const updatedFilters = savedFilters.filter(
          (filter) => filter.id !== selectedFilterId
        );
        setSavedFilters(updatedFilters);
        setSavedFilterId(null);
        form.setFieldsValue({ savedFilter: null });
        setSavedFiltersList(
          savedFiltersList.filter((filter) => filter.id !== selectedFilterId)
        );
        setIsFilterDeleteModalOpen(false);
      })
      .catch((error) => {
        console.error("Error while deleting filter:", error);
        openNotification("error", "Error", "Failed to delete filter");
      });
  };

  const getSavedFilterById = () => {
    if (!savedFilterId) return;
    FilterService.get(savedFilterId)
      .then((response: any) => {
        const filterData = response.data;

        const formattedData = {
          accountManagerMember: filterData.userId || null,
          hi5AcmMember: filterData.hi5AcmMember || null,
          callCenterMember: filterData.callCenterMember || null,
          successTeamMember: filterData.successTeamMember || null,
          assignmentDateRange: [
            filterData.startAssignmentDate,
            filterData.endAssignmentDate,
          ].filter((date) => date),
          hasNoPackages: filterData.hasNoPackages
            ? filterData.hasNoPackages
            : null,
          additionalPackage: filterData.additionalPackgeIds
            ? filterData.additionalPackgeIds
                .split(",")
                .map((id: string) => parseInt(id))
            : null,
          createdAtRange: [
            filterData.startCreatedAt,
            filterData.endCreatedAt,
          ].filter((date) => date),
          currentPackage:
            filterData.currentPackage != null
              ? filterData.currentPackage.toString()
              : null,
          endPassiveDay:
            filterData.endPassiveDays != null
              ? filterData.endPassiveDays
              : null,
          endLastContactedStm:
            filterData.endLastContactedStm != null
              ? filterData.endLastContactedStm
              : null,
          endLastContactedAcm:
            filterData.endLastContactedAcm != null
              ? filterData.endLastContactedAcm
              : null,
          endLastContactedhi5Acm:
            filterData.endLastContactedhi5Acm != null
              ? filterData.endLastContactedhi5Acm
              : null,
          endTotalOrderCount:
            filterData.endTotalOrderCount != null
              ? filterData.endTotalOrderCount
              : null,
          freeDays:
            filterData.freeDays != null ? filterData.freeDays.toString() : null,
          lastSalesDay: [
            filterData.startLastSalesDate,
            filterData.endLastSalesDate,
          ].filter((date) => date),
          level: filterData.level != null ? filterData.level.toString() : null,
          startPassiveDay:
            filterData.startPassiveDays != null
              ? filterData.startPassiveDays
              : null,
          startLastContactedStm:
            filterData.startLastContactedStm != null
              ? filterData.startLastContactedStm
              : null,
          startLastContactedAcm:
            filterData.startLastContactedAcm != null
              ? filterData.startLastContactedAcm
              : null,
          startLastContactedHi5Acm:
            filterData.startLastContactedHi5Acm != null
              ? filterData.startLastContactedHi5Acm
              : null,
          startTotalOrderCount:
            filterData.startTotalOrderCount != null
              ? filterData.startTotalOrderCount
              : null,
          state: filterData.state || null,
          status: filterData.statusIds
            ? filterData.statusIds.split(",").map((id: string) => parseInt(id))
            : null,
          acmStatus: filterData.acmStatusIds
            ? filterData.acmStatusIds
                .split(",")
                .map((id: string) => parseInt(id))
            : null,
          hi5AcmStatus: filterData.hi5AcmStatusIds
            ? filterData.hi5AcmStatusIds
                .split(",")
                .map((id: string) => parseInt(id))
            : null,
          subscriptionEndDateRange: [
            filterData.subscriptionEndDateStartDate,
            filterData.subscriptionEndDateEndDate,
          ].filter((date) => date),
          registeredDateRange: [
            filterData.registeredAtStartDate,
            filterData.registeredAtEndDate,
          ].filter((date) => date),
          retentionAssignmentDateRange: [
            filterData.retentionAssignmentDateStartDate,
            filterData.retentionAssignmentDateEndDate,
          ].filter((date) => date),
          hi5AcmAssignmentDateRange: [
            filterData.hi5AcmAssignmentDateStartDate,
            filterData.hi5AcmAssignmentDateEndDate,
          ].filter((date) => date),
          unassigned:
            filterData.assignedUser != null ? filterData.assignedUser : null,
          goldPackageHi5ACM:
            filterData.goldPackageHi5ACM != null
              ? filterData.goldPackageHi5ACM
              : null,
          goldPackage:
            filterData.goldPackage != null ? filterData.goldPackage : null,
          slpPackage:
            filterData.slpPackage != null ? filterData.slpPackage : null,
          autoPilotPackage:
            filterData.autoPilotPackage != null
              ? filterData.autoPilotPackage
              : null,
          inactiveGold7Days:
            filterData.inactiveGold7Days != null
              ? filterData.inactiveGold7Days
              : null,
          inactiveSlp7Days:
            filterData.inactiveSlp7Days != null
              ? filterData.inactiveSlp7Days
              : null,
          packageOfInterestSTM: filterData.packageOfInterestSTMIds
            ? filterData.packageOfInterestSTMIds
            : null,
          oldPackageOfInterestSTM: filterData.oldPackageOfInterestSTMIds
            ? filterData.oldPackageOfInterestSTMIds
            : null,
          packageOfInterestACM: filterData.packageOfInterestACMIds
            ? filterData.packageOfInterestACMIds
            : null,
          currentPackageOfInterestACM: filterData.currentPackageOfInterestACMIds
            ? filterData.currentPackageOfInterestACMIds
            : null,
          packageOfInterestHi5Acm: filterData.packageOfInterestHi5AcmIds
            ? filterData.packageOfInterestHi5AcmIds
            : null,
          currentPackageOfInterestHi5Acm:
            filterData.currentPackageOfInterestHi5AcmIds
              ? filterData.currentPackageOfInterestHi5AcmIds
              : null,
          goldPackageEndDate: [
            filterData.startGoldPackageEndDate,
            filterData.endGoldPackageEndDate,
          ].filter((date) => date),
          slpPackageEndDate: [
            filterData.startSlpEndDate,
            filterData.endSlpEndDate,
          ].filter((date) => date),
          autoSubscriptionRenewalEnabled:
            filterData.autoSubscriptionRenewalEnabled != null
              ? filterData.autoSubscriptionRenewalEnabled
              : null,
          autoSlpSubscriptionRenewalEnabled:
            filterData.autoSlpSubscriptionRenewalEnabled != null
              ? filterData.autoSlpSubscriptionRenewalEnabled
              : null,
          autoGoldSubscriptionRenewalEnabled:
            filterData.autoGoldSubscriptionRenewalEnabled != null
              ? filterData.autoGoldSubscriptionRenewalEnabled
              : null,
        };
        onChangeFilter(formattedData);
        setIsFilterSavedListModalOpen(false);
      })
      .catch((error: any) => {
        console.error("Error while getting filter data:", error);
        openNotification("error", "Error", "Failed to get filter data");
      });
  };

  return (
    <>
      <Modal
        title="Customer Filter"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
        >
          <Row gutter={16}>
            {(roleName === "HI5_ACM_MANAGER" || roleName === "ADMIN") &&
            hi5AcmTeamOptions ? (
              <Col xs={24}>
                Hi5 ACM Member
                <Item name="hi5AcmMember">
                  <Select placeholder={"Select a Member"}>
                    <Select.Option key={0} value={null}>
                      Select
                    </Select.Option>
                    {hi5AcmTeamOptions?.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.firstName} {option.lastName}
                      </Select.Option>
                    ))}
                  </Select>
                </Item>
              </Col>
            ) : null}
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={8}>
              State
              <Item name="state">
                <Select placeholder="Select a State" disabled={disableState}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  <Select.Option key={1} value="active">
                    Active
                  </Select.Option>
                  <Select.Option key={2} value="static">
                    Static
                  </Select.Option>
                  <Select.Option key={3} value="critic">
                    Critic
                  </Select.Option>
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={16}>
              Passive Days
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <Form.Item
                  name="startPassiveDay"
                  style={{ width: "45%", marginRight: 5, marginBottom: 0 }}
                >
                  <Select
                    placeholder="Select a Start Day"
                    disabled={disablePassiveDays}
                  >
                    {Array.from({ length: 30 }, (_, index) => (
                      <Select.Option key={index + 1} value={index + 1}>
                        {index + 1}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{ width: "45%", marginLeft: 5, marginBottom: 0 }}
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.startPassiveDay !== currentValues.startPassiveDay
                  }
                >
                  {({ getFieldValue }) => (
                    <Form.Item name="endPassiveDay">
                      <Select
                        placeholder="Select a End Day"
                        disabled={disablePassiveDays}
                      >
                        {Array.from({ length: 30 }, (_, index) => {
                          const startDay =
                            getFieldValue("startPassiveDay") || 0;
                          return (
                            index + 1 >= startDay && (
                              <Select.Option key={index + 1} value={index + 1}>
                                {index + 1}
                              </Select.Option>
                            )
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}
                </Form.Item>
                <CloseCircleOutlined
                  onClick={() => handleResetPassiveDays(form)}
                  style={{ marginTop: 5, marginLeft: 10, height: 24 }}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              Status
              <Item name="hi5AcmStatus">
                <Row gutter={16}>
                  <Col span={18}>
                    <Select
                      placeholder={"Select a Status"}
                      options={customerHi5ACMStatusOptions}
                      showSearch
                      allowClear
                      mode="multiple"
                      value={selectedHi5ACMStatusItems}
                      onChange={handleChangeHi5ACMStatus}
                      filterOption={(input, option: any) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                    ></Select>
                  </Col>
                  <Col span={6}>
                    <Button
                      style={{ float: "right" }}
                      onClick={selectAllHi5ACMStatus}
                    >
                      Select All
                    </Button>
                  </Col>
                </Row>
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              Additional Packages
              <Item name="additionalPackage">
                <Select
                  placeholder={"Select a Package"}
                  allowClear
                  mode="multiple"
                >
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  {additionalPackageOptions?.map((option) => (
                    <Select.Option
                      key={option.packageId}
                      value={option.packageId}
                    >
                      {option.packageName}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              Has No Package
              <Item name="hasNoPackages">
                <Select placeholder={"Select a Package"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  {additionalPackageOptions?.map((option) => (
                    <Select.Option
                      key={option.packageId}
                      value={option.packageId}
                    >
                      {option.packageName}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              SLP Package End Date
              <Item name="slpPackageEndDate">
                <RangePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              Gold Package End Date
              <Item name="goldPackageEndDate">
                <RangePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              Last Sales Day
              <Item name="lastSalesDay">
                <RangePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              Created Date
              <Item name="createdAtRange">
                <RangePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              Subscription End Date
              <Item name="subscriptionEndDateRange">
                <RangePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              Auto Subscription Renewal
              <Item name="autoSubscriptionRenewalEnabled">
                <Select placeholder={"Select Auto Subscription Renewal"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  <Select.Option key={"true"} value="true">
                    Yes
                  </Select.Option>
                  <Select.Option key={"false"} value="false">
                    No
                  </Select.Option>
                </Select>
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              Auto SLP Subscription Renewal
              <Item name="autoSlpSubscriptionRenewalEnabled">
                <Select placeholder={"Select Auto SLP Subscription Renewal"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  <Select.Option key={"true"} value="true">
                    Yes
                  </Select.Option>
                  <Select.Option key={"false"} value="false">
                    No
                  </Select.Option>
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              Auto Gold Subscription Renewal
              <Item name="autoGoldSubscriptionRenewalEnabled">
                <Select placeholder={"Select Auto Gold Subscription Renewal"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  <Select.Option key={"true"} value="true">
                    Yes
                  </Select.Option>
                  <Select.Option key={"false"} value="false">
                    No
                  </Select.Option>
                </Select>
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              Registered Date
              <Item name="registeredDateRange">
                <RangePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
              </Item>
            </Col>

            <Col xs={24} sm={12}>
              Hi5 ACM Assignment Date
              <Item name="hi5AcmAssignmentDateRange">
                <RangePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              Current Package
              <Item name="currentPackage">
                <Select placeholder={"Select a Package Count"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  <Select.Option key={"5000"} value="5000">
                    5000
                  </Select.Option>
                  <Select.Option key={"10000"} value="10000">
                    10000
                  </Select.Option>
                  <Select.Option key={"25000"} value="25000">
                    25000
                  </Select.Option>
                  <Select.Option key={"50000"} value="50000">
                    50000
                  </Select.Option>
                  <Select.Option key={"100000"} value="100000">
                    100000
                  </Select.Option>
                  <Select.Option key={"150000"} value="150000">
                    150000
                  </Select.Option>
                  <Select.Option key={"200000"} value="200000">
                    200000
                  </Select.Option>
                  <Select.Option key={"250000"} value="250000">
                    250000
                  </Select.Option>
                  <Select.Option key={"500000"} value="500000">
                    500000
                  </Select.Option>
                  <Select.Option key={"750000"} value="750000">
                    750000
                  </Select.Option>
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              Seller Level
              <Item name="level">
                <Select placeholder={"Select a Level"}>
                  <Select.Option key={7} value={null}>
                    Select
                  </Select.Option>
                  <Select.Option key={0} value="0">
                    LVL0
                  </Select.Option>
                  <Select.Option key={1} value="1">
                    LVL1
                  </Select.Option>
                  <Select.Option key={2} value="2">
                    LVL2
                  </Select.Option>
                  <Select.Option key={3} value="3">
                    LVL3
                  </Select.Option>
                  <Select.Option key={4} value="4">
                    LVL4
                  </Select.Option>
                  <Select.Option key={5} value="5">
                    LVL5
                  </Select.Option>
                  <Select.Option key={6} value="6">
                    LVL6
                  </Select.Option>
                </Select>
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              Package of Interest
              <Item name="packageOfInterestHi5Acm">
                <Select placeholder={"Select Package of Interest"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  {packageOfInterestStatusForHi5AcmOptions?.map(
                    (mainStatus) => (
                      <Select.Option
                        key={mainStatus?.id}
                        value={mainStatus?.id}
                      >
                        {mainStatus?.name}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              Current Package of Interest
              <Item name="currentPackageOfInterestHi5Acm">
                <Select placeholder={"Select Current Package of Interest"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  {currentPackageOfInterestStatusForHi5AcmOptions?.map(
                    (mainStatus) => (
                      <Select.Option
                        key={mainStatus?.id}
                        value={mainStatus?.id}
                      >
                        {mainStatus?.name}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              Order Count
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <Form.Item
                  name="startTotalOrderCount"
                  style={{ width: "50%", marginRight: 10 }}
                >
                  <InputNumber
                    placeholder="Start Count"
                    min={0}
                    style={{ width: "100%" }}
                    disabled={disablePassiveDays}
                  />
                </Form.Item>
                <Form.Item
                  name="endTotalOrderCount"
                  style={{ width: "45%", marginLeft: 10 }}
                >
                  <InputNumber
                    placeholder="End Count"
                    min={0}
                    style={{ width: "100%" }}
                    disabled={disablePassiveDays}
                  />
                </Form.Item>
                <CloseCircleOutlined
                  onClick={handleResetOrderCounts}
                  style={{ marginTop: 5, marginLeft: 10, height: 24 }}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              Hi5 ACM Last Contacted Days
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                  marginBottom: 0,
                }}
              >
                <Form.Item
                  name="startLastContactedHi5Acm"
                  style={{ width: "50%", marginRight: 10, marginBottom: 0 }}
                >
                  <Select
                    placeholder="Select a Start Day"
                    disabled={disableHi5AcmLastContactedDays}
                  >
                    {Array.from({ length: 30 }, (_, index) => (
                      <Select.Option key={index + 1} value={index + 1}>
                        {index + 1}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{ width: "45%", marginLeft: 10, marginBottom: 0 }}
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.startLastContactedHi5Acm !==
                    currentValues.startLastContactedHi5Acm
                  }
                >
                  {({ getFieldValue }) => (
                    <Form.Item name="endLastContactedHi5Acm">
                      <Select
                        placeholder="Select a End Day"
                        disabled={disableHi5AcmLastContactedDays}
                      >
                        {Array.from({ length: 30 }, (_, index) => {
                          const startDay =
                            getFieldValue("startLastContactedHi5Acm") || 0;
                          return (
                            index + 1 >= startDay && (
                              <Select.Option key={index + 1} value={index + 1}>
                                {index + 1}
                              </Select.Option>
                            )
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}
                </Form.Item>
                <CloseCircleOutlined
                  onClick={() => handleResetHi5AcmLastContactedDays(form)}
                  style={{ marginTop: 5, marginLeft: 10, height: 24 }}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              Inactive SLP (Last 7 Days)
              <Item name="inactiveSlp7Days">
                <Select placeholder={"Select Inactive SLP (Last 7 Days)"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  <Select.Option key={"true"} value="true">
                    Yes
                  </Select.Option>
                  <Select.Option key={"false"} value="false">
                    No
                  </Select.Option>
                </Select>
              </Item>
            </Col>
          </Row>
          {roleName !== "HI5_ACM_MEMBER" ? (
            <>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Item
                    label="Gold Package"
                    name="goldPackageHi5ACM"
                    valuePropName="checked"
                  >
                    <Switch style={{ marginTop: "12px" }} />
                  </Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Item
                    label="SLP Package"
                    name="slpPackage"
                    valuePropName="checked"
                  >
                    <Switch style={{ marginTop: "12px" }} />
                  </Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24}>
                  <Item
                    label="Auto Pilot Package"
                    name="autoPilotPackage"
                    valuePropName="checked"
                  >
                    <Switch style={{ marginTop: "12px" }} />
                  </Item>
                </Col>
              </Row>
            </>
          ) : null}
          <Item style={{ marginTop: "10px" }}>
            <Row justify="end">
              <Button
                key="back"
                style={{ marginRight: "10px" }}
                onClick={clearForm}
              >
                Clear
              </Button>
              <Button htmlType="submit" type="primary">
                Apply
              </Button>
            </Row>
          </Item>
        </Form>
        <Row justify="start">
          <Button onClick={showFilterSaveModal} type="primary">
            Save Filter
          </Button>
        </Row>
        <Modal
          title="Save Filter"
          open={isFilterSaveModalOpen}
          onCancel={() => setIsFilterSaveModalOpen(false)}
          footer={[
            <Button
              key="cancel"
              onClick={() => setIsFilterSaveModalOpen(false)}
            >
              Cancel
            </Button>,
            <Button key="save" type="primary" onClick={handleSaveFilter}>
              Save
            </Button>,
          ]}
        >
          <Form>
            Filter Name
            <Form.Item name="filterName">
              <Input
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                placeholder="Filter Name"
                required
                style={{ width: "100%" }}
                maxLength={50}
              />
            </Form.Item>
          </Form>
        </Modal>
      </Modal>
      <Tooltip title="Filters">
        <Button
          style={{ marginRight: "10px" }}
          onClick={showModal}
          type="default"
        >
          <img src={filterIcon} alt="userIcon" style={{ margin: "1px" }} />
        </Button>
      </Tooltip>
      {savedLocalFilters && savedLocalFilters.length > 0 ? (
        <Tooltip title="Clear Filters">
          <Button
            style={{ marginRight: "10px" }}
            onClick={clearLocalFilterAndForm}
            type="default"
          >
            <img
              src={filterClearIcon}
              alt="userIcon"
              style={{ margin: "1px" }}
            />
          </Button>
        </Tooltip>
      ) : null}
      <Tooltip title="Saved Filters">
        <Button
          style={{ marginRight: "10px" }}
          onClick={showSavedFilterListModal}
          type="default"
        >
          <img src={saveIcon} alt="userIcon" style={{ margin: "1px" }} />
        </Button>
      </Tooltip>
      <Modal
        title="Saved Filters"
        open={isFilterSavedListModalOpen}
        onCancel={() => setIsFilterSavedListModalOpen(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => setIsFilterSavedListModalOpen(false)}
          >
            Cancel
          </Button>,
          <Button key="delete" type="primary" onClick={showFilterDeleteModal}>
            Delete
          </Button>,
          <Button key="apply" type="primary" onClick={getSavedFilterById}>
            Apply
          </Button>,
        ]}
      >
        <Modal
          title="Delete Filter"
          open={isFilterDeleteModalOpen}
          onCancel={() => setIsFilterDeleteModalOpen(false)}
          footer={[
            <Button
              key="cancel"
              onClick={() => setIsFilterDeleteModalOpen(false)}
            >
              Cancel
            </Button>,
            <Button key="delete" type="primary" onClick={handleDeleteFilter}>
              Delete
            </Button>,
          ]}
        >
          <h3>Are you sure you want to delete the saved filter?</h3>
        </Modal>
        <Form>
          Saved Filters
          <Item name="savedFilter">
            <Select
              onChange={(value) => setSavedFilterId(value)}
              value={savedFilterId}
              placeholder="Select"
            >
              {savedFiltersList?.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default Filter;
