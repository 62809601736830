import { Avatar, Badge, Card, Col, Row, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import CallCenterSalesLogService from "../../../services/CallCenterSalesLogService";
import dayjs from "dayjs";
import { getProfilePhotoPath } from "../../../utils";
import DashboardService from "../../../services/DashboardService";

interface TotalAdditionalPackageCardProps {
  title: string;
  dateRange: any;
  roleName: string;
}

const TotalAdditionalPackageCard: React.FC<TotalAdditionalPackageCardProps> = ({
  title,
  dateRange,
  roleName,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line
  const [data, setData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [chartData, setChartData] = useState<any>({ series: [], labels: [] });

  const packageColors: { [key: string]: string } = {
    "Gold Paket": "#EEC73B",
    "Seller Launch Pad": "#FF4560",
    "Seller Sheild": "#0086FF",
    "Seller Auto Pillot": "#00E396",
    "ONEAMZ Care Paketi": "#775DD0",
    "Seller Pre-Auto Pilot": "#56CF3C",
  };

  const [options, setOptions] = useState<any>({
    chart: {
      type: "donut",
    },
    stroke: {
      lineCap: "round",
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "14px",
              fontWeight: 600,
              formatter: function (val: string) {
                const maxCharsPerLine = 15;
                if (val.length > maxCharsPerLine) {
                  const words = val.split(" ");
                  const lines = [];
                  let currentLine = words[0];
  
                  for (let i = 1; i < words.length; i++) {
                    if ((currentLine + " " + words[i]).length <= maxCharsPerLine) {
                      currentLine += " " + words[i];
                    } else {
                      lines.push(currentLine);
                      currentLine = words[i];
                    }
                  }
                  lines.push(currentLine);
                  return lines.join("\n");
                }
                return val;
              },
            },
            value: {
              show: true,
              fontSize: "14px",
              fontWeight: 600,
              formatter: function (val: any) {
                return val;
              },
            },
            total: {
              show: true,
              fontSize: "14px",
              fontWeight: 600,
              label: "Total",
              color: "#EEC73B",
              formatter: function (w: any) {
                return w.globals.seriesTotals.reduce((a: any, b: any) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    labels: chartData.labels,
    colors: chartData.labels.map(
      (label: string) => packageColors[label] || "#000000"
    ),
    tooltip: {
      enabled: true,
      theme: "dark",
      y: {
        formatter: function (val: number) {
          return `${val} Sales`;
        },
      },
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 992,
        options: {
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: "12px",
                    fontWeight: 600,
                  },
                  value: {
                    show: true,
                    fontSize: "12px",
                    fontWeight: 600,
                    formatter: function (val: any) {
                      return val;
                    },
                  },
                },
              },
            },
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: "15px",
                    fontWeight: 600,
                  },
                  value: {
                    show: true,
                    fontSize: "15px",
                    fontWeight: 600,
                    formatter: function (val: any) {
                      return val;
                    },
                  },
                },
              },
            },
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: "12px",
                    fontWeight: 600,
                  },
                  value: {
                    show: true,
                    fontSize: "12px",
                    fontWeight: 600,
                    formatter: function (val: any) {
                      return val;
                    },
                  },
                },
              },
            },
          },
        },
      },
      {
        breakpoint: 380,
        options: {
          chart: {
            width: 100,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  useEffect(() => {
    setOptions((prevOptions: any) => ({
      ...prevOptions,
      labels: chartData.labels,
      colors: chartData.labels.map(
        (label: string) => packageColors[label] || "#000000"
      ),
    }));
    // eslint-disable-next-line
  }, [chartData]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  const calculateChartData = (data: any) => {
    const totals: any = {};
    data.forEach((item: any) => {
      Object.keys(item.salesDataMap).forEach((packageName) => {
        if (!totals[packageName]) totals[packageName] = 0;
        totals[packageName] += parseInt(
          item.salesDataMap[packageName]?.split(" ")[0] || "0"
        );
      });
    });

    setChartData({
      series: Object.values(totals),
      labels: Object.keys(totals),
    });
  };

  const fetchTotalAdditionalPackage = async (
    startDate: string,
    endDate: string
  ) => {
    try {
      let filteredResponseData;
      if (roleName === "SUCCESS_TEAM_MANAGER") {
        const responseTotalAdditionalPackage =
          await CallCenterSalesLogService.getSalesLogReport(
            startDate,
            endDate,
            "SUCCESS_TEAM"
          );
        filteredResponseData = responseTotalAdditionalPackage.data.filter(
          (item: any) =>
            item.user.username !== "az.success.team.crm@oneamz.com" &&
            item.user.username !== "stm@oneamz.com" &&
            item.user.username !== "duygu.karakilic.hi5acm.crm@oneamz.com"
        );
        setData(filteredResponseData);
        setFilteredData(filteredResponseData);
        calculateChartData(filteredResponseData);
      } else if (roleName === "SUCCESS_TEAM") {
        const responseTotalAdditionalPackage =
          await DashboardService.getTotalAdditionalReportStmForMember(
            startDate,
            endDate,
            "SUCCESS_TEAM"
          );
        filteredResponseData = [responseTotalAdditionalPackage.data];
        setData(filteredResponseData);
        setFilteredData(filteredResponseData);
        calculateChartData(filteredResponseData);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (dateRange.length > 0) {
      if (dateRange[0] && dateRange[1]) {
        let formattedStartDate = dayjs(dateRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let formattedEndDate = dayjs(dateRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        fetchTotalAdditionalPackage(formattedStartDate, formattedEndDate);
        setLoading(true);
      }
    }
    // eslint-disable-next-line
  }, [dateRange, roleName]);

  const renderBadges = () =>
    chartData.labels.map((label: string, index: number) => (
      <Col
        xs={24}
        sm={24}
        style={{
          textAlign: windowWidth < 576 ? "center" : "left",
          marginTop: windowWidth < 576 ? "10px" : "0px",
        }}
        key={label}
      >
        <Badge
          color={packageColors[label] || "#000000"}
          style={{ whiteSpace: "nowrap" }}
          text={
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize:
                  windowWidth < 480
                    ? "12px"
                    : windowWidth < 768
                    ? "14px"
                    : windowWidth < 992
                    ? "12px"
                    : "14px",
              }}
            >
              {label}: <b>{chartData.series[index]}</b>
            </span>
          }
        />
      </Col>
    ));

  const columns = [
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Success Team Member
        </p>
      ),
      dataIndex: "user",
      key: "user",
      ellipsis: true,
      width: 220,
      sorter: (a: any, b: any) =>
        a?.user?.firstName?.localeCompare(b?.user?.firstName),
      defaultSortOrder: "ascend" as "ascend",
      render: (text: string, record: any) => (
        <Row
          align={"middle"}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            padding: "0px 0px",
          }}
        >
          <Avatar
            style={{ marginRight: 5 }}
            size={30}
            src={getProfilePhotoPath(1)}
          />
          <b
            style={{
              margin: "0px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {record?.user?.firstName} {record?.user?.lastName}
          </b>
        </Row>
      ),
    },
    ...Object.keys(data[0]?.salesDataMap || {}).map((packageName) => ({
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          {packageName}
        </p>
      ),
      dataIndex: "salesDataMap",
      key: packageName,
      width: 130,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) =>
        parseInt(a.salesDataMap[packageName]?.split(" ")[0] || "0") -
        parseInt(b.salesDataMap[packageName]?.split(" ")[0] || "0"),
      render: (text: string, record: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px",
              whiteSpace: "nowrap",
              fontWeight: "bold",
              textAlign: "center",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {record.salesDataMap[packageName]?.split(" ")[0] || 0}
          </p>
        </div>
      ),
    })),
  ];

  return (
    <Card bordered={false} className="info-card" loading={loading}>
      <p
        className="title"
        style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
      >
        {title}
      </p>
      <Row gutter={[12, 12]} align="middle" justify="space-between">
        <Col xs={24} sm={13} md={13}>
          <ReactApexChart
            options={options}
            series={chartData.series}
            type="donut"
            height={200}
          />
        </Col>
        <Col xs={24} sm={11} md={11}>
          <Row
            align="middle"
            justify="center"
            style={{
              margin:
                windowWidth < 480
                  ? "-30px 0px 10px 0px"
                  : windowWidth < 576
                  ? "-20px 0px 10px 0px"
                  : "0px 0px 0px 0px",
            }}
          >
            <Space direction="vertical" size={0}>
              <Row gutter={[3, 3]}>{renderBadges()}</Row>
            </Space>
          </Row>
        </Col>
      </Row>
      {roleName === "SUCCESS_TEAM_MANAGER" && (
        <Row>
          <Col span={24}>
            <Table
              rowKey={(record) => record?.user?.id}
              columns={columns}
              dataSource={filteredData}
              pagination={false}
              scroll={{ x: "100%", y: 185 }}
              size="small"
              className="dashboard-table"
            />
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default TotalAdditionalPackageCard;
