import { Col, Row, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CommissionService from "../../../../services/CommissionService";

const RetentionCountReport = ({
  dateRange,
  memberFlag,
  teamName,
}: {
  dateRange: any;
  memberFlag: string;
  teamName: string;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  //   eslint-disable-next-line
  const [data, setData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const location = useLocation();

  useEffect(() => {
    if (dateRange[0] && dateRange[1] && teamName) {
      getRetentionCountReport(teamName, dateRange[0], dateRange[1]);
    }
    // eslint-disable-next-line
  }, [dateRange, location.pathname]);

  const getRetentionCountReport = (
    teamName: string,
    startDate: string,
    endDate: string
  ) => {
    setLoading(true);
    CommissionService.earningReport(teamName, startDate, endDate)
      .then((response) => {
        const filteredResponseData = response.data.filter(
          (item: any) =>
            item.member.username !== "az.success.team.crm@oneamz.com" &&
            item.member.username !== "stm@oneamz.com" &&
            item.member.username !== "duygu.karakilic.hi5acm.crm@oneamz.com"
        );
        setData(filteredResponseData);
        setFilteredData(filteredResponseData);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Member",
      dataIndex: "member",
      key: "member",
      fixed: "left",
      width: 200,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        a?.member?.firstName.localeCompare(b?.member?.firstName),
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${record?.member?.firstName} ${record?.member?.lastName}`}
          >
            <b>
              {record?.member?.firstName} {record?.member?.lastName}
            </b>
          </div>
        );
      },
    },
    ...(location.pathname.includes("/stm-reports/summary-report")
      ? [
          {
            title: "Software Retention",
            dataIndex: "softwareRetentionCustomers",
            key: "softwareRetentionCustomers",
            align: "center" as "center",
            width: 150,
            sorter: (a: any, b: any) =>
              a?.softwareRetentionCustomers - b?.softwareRetentionCustomers,
            render: (_: any, row: any) => {
              return (
                <b>
                  {row?.softwareRetentionCustomers?.length > 0 ?
                    ` ${row?.softwareRetentionCustomers?.length} qty` : "0 qty"}
                </b>
              );
            },
          },
        ]
      : []),
    ...(location.pathname.includes("/hi5-acm-reports/summary-report")
      ? [
          {
            title: "SLP Retention",
            dataIndex: "slpRetentionCustomers",
            key: "slpRetentionCustomers",
            align: "center" as "center",
            width: 150,
            sorter: (a: any, b: any) =>
              a?.slpRetentionCustomers - b?.slpRetentionCustomers,
            render: (_: any, row: any) => {
              return (
                <b>
                  {row?.slpRetentionCustomers?.length > 0 ?
                    ` ${row?.slpRetentionCustomers?.length} qty` : "0 qty"}
                </b>
              );
            },
          },
        ]
      : []),
    ...(location.pathname.includes("/acm-reports/summary-report") ||
    location.pathname.includes("/hi5-acm-reports/summary-report")
      ? [
          {
            title: "Gold Retention",
            dataIndex: "goldRetentionCustomers",
            key: "goldRetentionCustomers",
            align: "center" as "center",
            width: 150,
            sorter: (a: any, b: any) =>
              a?.goldRetentionCustomers - b?.goldRetentionCustomers,
            render: (_: any, row: any) => {
              return (
                <b>
                  {row?.goldRetentionCustomers?.length > 0 ?
                    ` ${row?.goldRetentionCustomers?.length} qty` : "0 qty"}
                </b>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>Retention Count Report</h3>
        <Table
          loading={loading}
          scroll={{ x: "max-content" }}
          sticky
          rowKey={"id"}
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          summary={(pageData) => {
            // Software Retention Total
            let softwareRetentionQty = 0;
            if (location.pathname.includes("/stm-reports/summary-report")) {
              softwareRetentionQty = pageData?.reduce(
                (acc, row) => acc + row?.softwareRetentionCustomers?.length,
                0
              );
            }

            // SLP Retention Total
            let slpRetentionQty = 0;
            if (location.pathname.includes("/hi5-acm-reports/summary-report")) {
              slpRetentionQty = pageData?.reduce(
                (acc, row) => acc + row?.slpRetentionCustomers?.length,
                0
              );
            }

            // Gold Retention Total
            let goldRetentionQty = 0;
            if (
              location.pathname.includes("/hi5-acm-reports/summary-report") ||
              location.pathname.includes("/acm-reports/summary-report")
            ) {
              goldRetentionQty = pageData?.reduce(
                (acc, row) => acc + row?.goldRetentionCustomers?.length,
                0
              );
            }

            return (
              <Table.Summary fixed>
                <Table.Summary.Row className="summary-cell">
                  <Table.Summary.Cell
                    index={0}
                    colSpan={1}
                    align="left"
                    className="summary-cell"
                  >
                    <b>Total</b>
                  </Table.Summary.Cell>
                  {/* Software Retention Totals */}
                  {location.pathname.includes(
                    "/stm-reports/summary-report"
                  ) && (
                    <Table.Summary.Cell
                      index={1}
                      align="center"
                      className="summary-cell"
                    >
                      <b>{softwareRetentionQty || 0} qty</b>
                    </Table.Summary.Cell>
                  )}
                  {/* SLP Retention Totals */}
                  {location.pathname.includes(
                    "/hi5-acm-reports/summary-report"
                  ) && (
                    <Table.Summary.Cell
                      index={2}
                      align="center"
                      className="summary-cell"
                    >
                      <b>{slpRetentionQty || 0} qty</b>
                    </Table.Summary.Cell>
                  )}
                  {/* Gold Retention Totals */}
                  {(location.pathname.includes(
                    "/hi5-acm-reports/summary-report"
                  ) ||
                    location.pathname.includes(
                      "/acm-reports/summary-report"
                    )) && (
                    <Table.Summary.Cell
                      index={3}
                      align="center"
                      className="summary-cell"
                    >
                      <b>{goldRetentionQty || 0} qty</b>
                    </Table.Summary.Cell>
                  )}
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default RetentionCountReport;
