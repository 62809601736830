import { Breadcrumb, Card, Col, Row, Typography } from "antd";
import { NavLink } from "react-router-dom";
import { ReportsRouter } from "../../ReportsRouter";
import DateRange from "../../../Common/DateRange";
import { useState } from "react";
import CtmMemberBasedTotalCustomerReport from "./CtmMemberBasedTotalCustomerReport";
import CtmSourceBasedTotalCustomerReport from "./CtmSourceBasedTotalCustomerReport";
import CtmSalesLogReport from "./CtmSalesLogReport";
import CtmSourceBasedSalesReport from "./CtmSourceBasedSalesReport";
import CtmSourceBasedPhoneCallSummaryReport from "./CtmSourceBasedPhoneCallSummaryReport";

const { Title } = Typography;

const CtmSummaryReport = () => {
  const [dateRange, setDateRange] = useState<[string, string]>(["", ""]);

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/">Home</NavLink>,
              },
              {
                title: "Summary Report",
              },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />
      <Card
        className="card-customers zebra-table"
        title={
          <>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                className="customer-card-title"
                style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
              >
                Summary Report
              </div>
            </div>
          </>
        }
        extra={
          <Row justify={"end"}>
            <DateRange thisMonth={true} onChangeDateRange={setDateRange} />
          </Row>
        }
        bordered
        style={{ marginTop: "30px" }}
      >
        <CtmMemberBasedTotalCustomerReport dateRange={dateRange} />
        <CtmSourceBasedTotalCustomerReport dateRange={dateRange} />
        <CtmSalesLogReport dateRange={dateRange}/>
        <CtmSourceBasedSalesReport dateRange={dateRange}/>
        <CtmSourceBasedPhoneCallSummaryReport dateRange={dateRange}/>
      </Card>
    </>
  );
};

export default CtmSummaryReport;
