import React from "react";
import { Modal, Card, Row, Col } from "antd";

interface UserDetailsModalProps {
  visible: boolean;
  onClose: () => void;
  userDetails: any;
  packageSales: any;
}

const UserDetailsModal: React.FC<UserDetailsModalProps> = ({
  visible,
  onClose,
  userDetails,
  packageSales,
}) => {
  return (
    <Modal
      title={`Package Sales for ${userDetails?.user?.firstName} ${userDetails?.user?.lastName}`}
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      {packageSales ? (
        <Card>
          <Row
            gutter={[16, 16]}
            style={{
              marginBottom: 10,
              border: "1px solid #eee",
              borderRadius: 5,
              padding: 10,
            }}
          >
            <Col span={12}>
              <strong>Package Name</strong>
            </Col>
            <Col span={12} style={{ textAlign: "center" }}>
              <strong>Sales Count</strong>
            </Col>
          </Row>
          {Object.keys(packageSales).map((packageName) => (
            <Row
              gutter={[16, 16]}
              key={packageName}
              style={{
                marginBottom: "5px",
                border: "1px solid #eee",
                borderRadius: 5,
                padding: 10,
              }}
            >
              <Col span={12}>{packageName}</Col>
              <Col span={12} style={{ textAlign: "center" }}>
                {packageSales[packageName]}
              </Col>
            </Row>
          ))}
        </Card>
      ) : (
        <p>No package sales available for this user.</p>
      )}
    </Modal>
  );
};

export default UserDetailsModal;
