import { useEffect, useState } from "react";
import { Dropdown, MenuProps, Tooltip } from "antd";
import { UserSwitchOutlined } from "@ant-design/icons";
import UserService from "../../services/UserService";
import { openNotification } from "../../utils";
import { useNavigate } from "react-router-dom";

const SelectRole = () => {
  const [roleOptions, setRoleOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const roleName = localStorage.getItem("roleName");
  
  useEffect(() => {
    UserService.getMeRoles()
      .then((response: any) => {
        if (response.data.length > 0) {
          setRoleOptions(response.data);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
    // eslint-disable-next-line
  }, []);

  const handleRoleChange = (value: { name: string; description: string }) => {
    if (loading) return;
    setLoading(true);
    const selectedRole = value.name;
    UserService.updateMeSelectedRole({ selectedRole: selectedRole })
      .then((response: any) => {
        if (response.data) {
          localStorage.setItem("role", value.description);
          localStorage.setItem("roleName", value.name);
          navigate("/");
          window.location.reload();
          openNotification("success", "Success", "Changed role");
        }
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const items: MenuProps["items"] =
    roleOptions &&
    roleOptions.map((role) => ({
      key: role.id,
      label: role.description,
      onClick: () => handleRoleChange(role),
    }));

  return (
    <Tooltip title="Change Role">
      <Dropdown
        placement="topRight"
        menu={{ items }}
        disabled={
          (!roleName?.includes("MANAGER") && roleName === "ACCOUNT_MANAGER") ||
          roleOptions?.length < 2 ||
          loading
        }
      >
        <UserSwitchOutlined
          style={{
            cursor:
              (!roleName?.includes("MANAGER") &&
                roleName === "ACCOUNT_MANAGER") ||
              roleOptions?.length < 2 ||
              loading
                ? "not-allowed"
                : "pointer",
            fontSize: "24px",
            padding: "10px",
            width: "40px",
            height: "40px",
            marginLeft: "5px",
            color: "white",
            background: "black",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
            opacity:
              (!roleName?.includes("MANAGER") &&
                roleName === "ACCOUNT_MANAGER") ||
              roleOptions?.length < 2 ||
              loading
                ? 0.6
                : 1,
          }}
        />
      </Dropdown>
    </Tooltip>
  );
};

export default SelectRole;
