import { Breadcrumb, Card, Col, Row, Typography } from "antd";
import { NavLink } from "react-router-dom";
import { ReportsRouter } from "../../ReportsRouter";
import DateRange from "../../../Common/DateRange";
import { useState } from "react";
import SuspendedFileCountReport from "./SuspendedFileCountReport";
import MemberBasedSuspendedFileCountReport from "./MemberBasedSuspendedFileCountReport";
import SuspendedFileStatusReport from "./SuspendedFileStatusReport";
import SourceBasedSuspendedFileStatusReport from "./SourceBasedSuspendedFileStatusReport";
import SuspendTypeBasedMemberReport from "./SuspendTypeBasedMemberReport";
import SuspendTypeBasedMonthlySuspendReport from "./SuspendTypeBasedMonthlySuspendReport";
import MemberBasesMonthlySuspendReport from "./MemberBasesMonthlySuspendReport";
import SuspendTypeBasedAvarageOpeningTimeOfSuspendReport from "./SuspendTypeBasedAvarageOpeningTimeOfSuspendReport";
import MemberBasedAvarageOpeningTimeOfSuspendReport from "./MemberBasedAvarageOpeningTimeOfSuspendReport";
import SolvedStatusBasedOneAmzAppUserReport from "./SolvedStatusBasedOneAmzAppUserReport";
import NumberOfAmazonCallsReport from "./NumberOfAmazonCallsReport";
import SuspendTypeBasedSuspendedFilesByCountryReport from "./SuspendTypeBasedSuspendedFilesByCountryReport";

const { Title } = Typography;

const AmazonSuspendedApplicantReports = () => {
  const [dateRange, setDateRange] = useState<[string, string]>(["", ""]);

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/">Home</NavLink>,
              },
              {
                title: "Amazon Suspended Applicant Reports",
              },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />
      <Card
        className="card-customers zebra-table"
        title={
          <>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                className="customer-card-title"
                style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
              >
                Amazon Suspended Applicant Reports
              </div>
            </div>
          </>
        }
        extra={
          <Row justify={"end"}>
            <DateRange thisMonth={true} onChangeDateRange={setDateRange} />
          </Row>
        }
        bordered
        style={{ marginTop: "30px" }}
      >
        <SuspendedFileCountReport dateRange={dateRange} />
        <MemberBasedSuspendedFileCountReport dateRange={dateRange} />
        <SuspendedFileStatusReport dateRange={dateRange} />
        <SourceBasedSuspendedFileStatusReport dateRange={dateRange} />
        <SuspendTypeBasedMemberReport dateRange={dateRange} />
        <SuspendTypeBasedMonthlySuspendReport dateRange={dateRange} />
        <MemberBasesMonthlySuspendReport dateRange={dateRange} />
        <SuspendTypeBasedAvarageOpeningTimeOfSuspendReport dateRange={dateRange} />
        <MemberBasedAvarageOpeningTimeOfSuspendReport dateRange={dateRange} />
        <SolvedStatusBasedOneAmzAppUserReport dateRange={dateRange} />
        <NumberOfAmazonCallsReport dateRange={dateRange} />
        <SuspendTypeBasedSuspendedFilesByCountryReport dateRange={dateRange} /> 
      </Card>
    </>
  );
};

export default AmazonSuspendedApplicantReports;
