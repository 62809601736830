import { Col, Row, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import { SuspendFileCount } from "../../../../types/AmazonSuspendedApplicantReports";

interface SuspendedFileCountReportProps {
  dateRange: [string, string];
}

const SuspendedFileCountReport: React.FC<SuspendedFileCountReportProps> = ({
  dateRange,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<SuspendFileCount[]>([]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const formattedStartDate = dayjs(dateRange[0]).format(
        "YYYY-MM-DDT00:00:00"
      );
      const formattedEndDate = dayjs(dateRange[1]).format(
        "YYYY-MM-DDT23:59:59"
      );
      getSuspendedFileCountReport(formattedStartDate, formattedEndDate);
    }
  }, [dateRange]);

  const getSuspendedFileCountReport = (startDate: string, endDate: string) => {
    setLoading(true);
    AmazonSuspendedApplicantReportsService.getSuspendFileCount(
      startDate,
      endDate
    )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<SuspendFileCount> = [
    {
      title: "Report Date",
      dataIndex: "reportDate",
      key: "reportDate",
      align: "center",
      width: 150,
      sorter: (a, b) => dayjs(a.reportDate).unix() - dayjs(b.reportDate).unix(),
      render: (reportDate: string) => (
        <div>
          {reportDate ? dayjs(reportDate).format("DD MMM YYYY") : "N/A"}
        </div>
      ),
    },
    {
      title: "File Count",
      dataIndex: "fileCount",
      key: "fileCount",
      align: "center",
      width: 150,
      sorter: (a, b) => a.fileCount - b.fileCount,
      render: (fileCount: number) => (
        <div>{fileCount !== undefined ? fileCount : "N/A"}</div>
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>Suspended File Count Report</h3>
        <Table
          loading={loading}
          scroll={{ x: "max-content" }}
          sticky
          rowKey={(record) => record.reportDate}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </Col>
    </Row>
  );
};

export default SuspendedFileCountReport;
