import http from "../http-common";

const getRetentionReportStm = (startDate: string, endDate: string) => {
  return http.get<Array<any>>(
    `/retention-reports/stm?startDate=${startDate}&endDate=${endDate}`
  );
};

const getRetentionReportStmByUserId = (
  userId: number,
  startDate: string,
  endDate: string
) => {
  return http.get<Array<any>>(
    `/retention-reports/stm/user/${userId}?startDate=${startDate}&endDate=${endDate}`
  );
};

const getRetentionReportAcm = (startDate: string, endDate: string) => {
  return http.get<Array<any>>(
    `/retention-reports/acm?startDate=${startDate}&endDate=${endDate}`
  );
};

const getRetentionReportAcmByUserId = (
  userId: number,
  startDate: string,
  endDate: string
) => {
  return http.get<Array<any>>(
    `/retention-reports/acm/user/${userId}?startDate=${startDate}&endDate=${endDate}`
  );
};

const getRetentionReportHi5Acm = (startDate: string, endDate: string) => {
  return http.get<Array<any>>(
    `/retention-reports/hi5?startDate=${startDate}&endDate=${endDate}`
  );
};

const getRetentionReportHi5AcmByUserId = (
  userId: number,
  startDate: string,
  endDate: string
) => {
  return http.get<Array<any>>(
    `/retention-reports/hi5/user/${userId}?startDate=${startDate}&endDate=${endDate}`
  );
};

const RetentionReportsService = {
  getRetentionReportStm,
  getRetentionReportStmByUserId,
  getRetentionReportAcm,
  getRetentionReportAcmByUserId,
  getRetentionReportHi5Acm,
  getRetentionReportHi5AcmByUserId,
};

export default RetentionReportsService;
