import { Card, Progress, Typography, Divider, Row } from "antd";
import { useEffect, useState } from "react";

const { Title, Text } = Typography;

interface PolicyItem {
  label: string;
  value: any;
}

function ProgressBar({
  value,
  max,
  lineClass,
}: {
  value: number;
  max: number;
  lineClass: string;
}) {
  const percentage = (value / max) * 100;
  const labels = [0, 100, 200, max];

  return (
    <div>
      <div style={{ position: "relative", paddingTop: "12px" }}>
        <Text strong>{value}</Text>
        <Progress
          percent={percentage * 1.8}
          showInfo={false}
          strokeWidth={8}
          strokeColor={lineClass === "red" ? "#ff4d4f" : "#52c41a"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "8px",
        }}
      >
        {labels.map((label, index) => (
          <Text key={index}>{label}</Text>
        ))}
      </div>
    </div>
  );
}

const PolicyCompliance = ({
  data,
  lineClass,
}: {
  data: any;
  lineClass: string;
}) => {
  const [items, setItems] = useState<PolicyItem[]>([]);

  useEffect(() => {
    if (data && data.length > 0) {
      setItems([
        {
          label: "Suspected Intellectual Property Violations",
          value:
            data[0]?.suspectedIntellectualPropertyViolations?.defectsCount || 0,
        },
        {
          label: "Received Intellectual Property Complaints",
          value:
            data[0]?.receivedIntellectualPropertyComplaints?.defectsCount || 0,
        },
        {
          label: "Product Authenticity Property Complaints",
          value:
            data[0]?.productAuthenticityCustomerComplaints?.defectsCount || 0,
        },
        {
          label: "Product Condition Customer Complaints",
          value: data[0]?.productConditionCustomerComplaints?.defectsCount || 0,
        },
        {
          label: "Food and Product Safety Issues",
          value: data[0]?.foodAndProductSafetyIssues?.defectsCount || 0,
        },
        {
          label: "Listing Policy Violations",
          value: data[0]?.listingPolicyViolations?.defectsCount || 0,
        },
        {
          label: "Restricted Product Policy Violations",
          value: data[0]?.restrictedProductPolicyViolations?.defectsCount || 0,
        },
        {
          label: "Customer Product Reviews Policy Violations",
          value:
            data[0]?.customerProductReviewsPolicyViolations?.defectsCount || 0,
        },
        {
          label: "Other Policy Violations",
          value: data[0]?.otherPolicyViolations?.defectsCount || 0,
        },
      ]);
    }
  }, [data]);

  return (
    <Card
      title={
        <>
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ textTransform: "uppercase" }}>
              Policy Compliance
            </span>
            {data[0]?.accountHealthRating?.ahrStatus && (
              <Text
                strong
                className={lineClass}
                style={{ color: lineClass === "red" ? "#ff4d4f" : "#52c41a" }}
              >
                {data[0]?.accountHealthRating?.ahrStatus}
              </Text>
            )}
          </Row>
        </>
      }
      bordered={true}
      style={{ borderRadius: "8px" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        <Title level={5}>Account Health Rating</Title>
        <div style={{ width: "150px" }}>
          <ProgressBar
            lineClass={lineClass}
            value={data[0]?.accountHealthRating?.ahrScore}
            max={1000}
          />
        </div>
      </div>

      <Divider />

      <Title level={5}>All issues</Title>
      {items?.map((i, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "8px 0",
            borderBottom: "1px solid #f0f0f0",
          }}
        >
          <Text>{i?.label}</Text>
          <Text strong style={{ color: i?.value > 0 ? "#ff4d4f" : undefined }}>
            {i?.value}
          </Text>
        </div>
      ))}
    </Card>
  );
};

export default PolicyCompliance;
