import React from "react";
import { Card } from "antd";
import "../AcmDashboard/acm-dashboard.css";

interface InfoCardProps {
  count: number;
  title: string;
  description: string;
  loading: boolean;
}

const InfoCard: React.FC<InfoCardProps> = ({
  count,
  title,
  description,
  loading,
}) => {
  return (
    <Card bordered={false} className="info-card" loading={loading} style={{ marginBottom: 0 }}>
      <div className="info-card-container">
        <div>
          <p className="title" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{title}</p>
          <p className="description" style={{ marginBottom: 0 }}>{description}</p>
        </div>
        <p className="count">{count}</p>
      </div>
    </Card>
  );
};

export default InfoCard;
