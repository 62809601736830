import http from "../http-common";
import {
  CountryReport,
  SuspendTypeMemberReport,
  SuspendFileStatus,
  SuspendFileStatusInput,
  SuspendFileMember,
  SuspendFileCount,
  SuspendAverageOpeningTime,
  MonthlySuspend,
  MemberMonthlySuspend,
  MemberAverageOpeningTime,
  CustomerDetailsReport,
  AmazonCallsReport,
} from "../types/AmazonSuspendedApplicantReports";

const AmazonSuspendedApplicantReportsService = {
  getSuspendedCountryReports: (startDate: string, endDate: string) =>
    http.get<{ countryReports: CountryReport[] }>("/amazon-suspended-applicant-reports/suspended-country", {
      params: { startDate, endDate },
    }),

  getSuspendTypeMemberReport: (startDate: string, endDate: string) =>
    http.get<SuspendTypeMemberReport[]>("/amazon-suspended-applicant-reports/suspend-type-member-report", {
      params: { startDate, endDate },
    }),

  getSuspendFileStatus: (startDate: string, endDate: string) =>
    http.get<SuspendFileStatus[]>("/amazon-suspended-applicant-reports/suspend-file-status", {
      params: { startDate, endDate },
    }),

  getSuspendFileStatusInput: (startDate: string, endDate: string) =>
    http.get<SuspendFileStatusInput[]>("/amazon-suspended-applicant-reports/suspend-file-status-input", {
      params: { startDate, endDate },
    }),

  getSuspendFileMembers: (startDate: string, endDate: string) =>
    http.get<SuspendFileMember[]>("/amazon-suspended-applicant-reports/suspend-file-members", {
      params: { startDate, endDate },
    }),

  getSuspendFileCount: (startDate: string, endDate: string) =>
    http.get<SuspendFileCount[]>("/amazon-suspended-applicant-reports/suspend-file-count", {
      params: { startDate, endDate },
    }),

  getSuspendAverageOpeningTime: (startDate: string, endDate: string) =>
    http.get<SuspendAverageOpeningTime[]>("/amazon-suspended-applicant-reports/suspend-average-opening-time", {
      params: { startDate, endDate },
    }),

  getMonthlySuspend: (startDate: string, endDate: string) =>
    http.get<MonthlySuspend[]>("/amazon-suspended-applicant-reports/monthly-suspend", {
      params: { startDate, endDate },
    }),

  getMemberMonthlySuspend: (startDate: string, endDate: string) =>
    http.get<MemberMonthlySuspend[]>("/amazon-suspended-applicant-reports/member-monthly-suspend", {
      params: { startDate, endDate },
    }),

  getMemberAverageOpeningTime: (startDate: string, endDate: string) =>
    http.get<MemberAverageOpeningTime[]>("/amazon-suspended-applicant-reports/member-average-opening-time", {
      params: { startDate, endDate },
    }),

  getCustomerDetails: (startDate: string, endDate: string) =>
    http.get<CustomerDetailsReport>("/amazon-suspended-applicant-reports/customer-details", {
      params: { startDate, endDate },
    }),

  getAmazonCalls: (startDate: string, endDate: string) =>
    http.get<AmazonCallsReport>("/amazon-suspended-applicant-reports/amazon-calls", {
      params: { startDate, endDate },
    }),
};

export default AmazonSuspendedApplicantReportsService;
