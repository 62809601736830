import React, { useEffect, useState } from "react";
import { Avatar, Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { UserOutlined, MailOutlined } from "@ant-design/icons";
import "./header-bar.css";
import DateRange from "../Common/DateRange";

let avatarSrc = require(`../../assets/animojis/Number=1.png`);

const { Title } = Typography;

interface HeaderBarProps {
  setDateRange: (values: any) => void;
  thisMonth?: boolean;
}

export const HeaderBar: React.FC<HeaderBarProps> = ({
  setDateRange,
  thisMonth = false,
}) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [role, setRole] = useState<any>("");

  useEffect(() => {
    setFirstName(localStorage.getItem("firstName"));
    setLastName(localStorage.getItem("lastName"));
    setEmail(localStorage.getItem("email"));
    setRole(localStorage.getItem("role"));
    let profilePhoto = localStorage.getItem("profilePhoto");

    if (localStorage.getItem("profilePhoto") !== null) {
      avatarSrc = require(`../../assets/animojis/Number=${profilePhoto}.png`);
    }
  }, []);

  return (
    <>
      <Row
        className="header-bar"
        justify={"space-between"}
        align={"middle"}
        gutter={[16, 16]}
      >
        <Col>
          <Row align={"middle"}>
            <Col>
              <Avatar
                className="avatar"
                size={100}
                src={avatarSrc}
                style={{ marginRight: "15px" }}
              />
            </Col>
            <Col>
              <Title className="m-0 p-0 mr-10" level={4}>
                {t("hello")}, {firstName} {lastName}
              </Title>
              <span className="sub-title role">
                <UserOutlined /> {role}
              </span>
              <span className="sub-title">
                <MailOutlined /> {email}
              </span>
            </Col>
          </Row>
        </Col>

        <Col>
          <DateRange onChangeDateRange={setDateRange} thisMonth={thisMonth} />
        </Col>
      </Row>
    </>
  );
};
