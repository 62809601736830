import {
  Breadcrumb,
  Col,
  Row,
  Typography,
  Table,
  Button,
  Form,
  Modal,
  Input,
  Switch,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { openNotification } from "../../utils";
import SettingsRouter from "./SettingsRouter";
import { NavLink, useLocation } from "react-router-dom";
import StatusService from "../../services/StatusService";
import ButtonGroup from "antd/es/button/button-group";

const { Item } = Form;
const { Title } = Typography;

export const MasStatus = () => {
  const [form] = Form.useForm();
  const [formEditStatus] = Form.useForm();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newStatus, setStatus] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [processedData, setProcessedData] = useState<any[]>([]);
  const location = useLocation();

  let statusType = "";
  if (location.pathname.includes("/feedback-status")) {
    statusType = "MAS_TEAM_FEEDBACK_STATUS";
  } else if (location.pathname.includes("/feedback-channel")) {
    statusType = "MAS_TEAM_FEEDBACK_CHANNEL";
  } else if (location.pathname.includes("/checklist-status")) {
    statusType = "MAS_TEAM_CHECK_STATUS";    
  }

  const showCreateModal = () => {
    setIsCreateModalVisible(true);
  };

  const handleCreateModalCancel = () => {
    setIsCreateModalVisible(false);
  };

  const showEditModal = (status: any) => {
    setSelectedStatus(status);
    setStatus(status.name);
    setIsEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
    setSelectedStatus(null);
  };

  const showDeleteModal = (status: any) => {
    setSelectedStatus(status);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteModalCancel = () => {
    setIsDeleteModalVisible(false);
    setSelectedStatus(null);
  };

  // POST
  const postStatus = (data: any) => {
    setLoading(true);
    StatusService.create({ ...data, statusType })
      .then((response: any) => {
        getStatus();
        form.resetFields();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      postStatus(values);
      setIsCreateModalVisible(false);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  // GETALL
  const getStatus = () => {
    setLoading(true);
    StatusService.getAll("statusType=" + statusType)
      .then((response: any) => {
        let data = response.data;
        setProcessedData(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  // PUT UPDATE
  const handleChangeStatus = (id: number, name: string, active: boolean) => {
    setLoading(true);
    let data = {
      active: active,
      name: name,
    };
    StatusService.update(id, data)
      .then((response: any) => {
        openNotification("success", "Success", `Status updated`);
        handleEditModalCancel();
        getStatus();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const handleActiveChange = (record: any, checked: boolean) => {
    handleChangeStatus(record.id, record.name, checked);
  };

  // DELETE
  const handleDeleteStatus = (id: number) => {
    setLoading(true);
    StatusService.remove(id)
      .then((response: any) => {
        openNotification("success", "Success", `Status deleted`);
        handleDeleteModalCancel();
        getStatus();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, row) => <span>{row.name}</span>,
    },
    {
      title: "Active/Passive",
      dataIndex: "active",
      key: "active",
      width: 200,
      align: "center",
      sorter: (a, b) => a.active - b.active,
      render: (_, record) => (
        <Switch
          checked={record.active}
          onChange={(checked) => handleActiveChange(record, checked)}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      width: 200,
      render: (_, record) => (
        <ButtonGroup style={{ marginLeft: "10px", justifyContent: "flex-end" }}>
          <Button type="primary" onClick={() => showEditModal(record)}>
            Edit
          </Button>
          <Button onClick={() => showDeleteModal(record)}>Delete</Button>
        </ButtonGroup>
      ),
    },
  ];

  useEffect(() => {
    getStatus();
    // eslint-disable-next-line
  }, [statusType]);

  useEffect(() => {
    formEditStatus.setFieldsValue({ selectedSource: newStatus });
    // eslint-disable-next-line
  }, [newStatus]);

  return (
    <motion.div
      initial={{ y: 200, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 300, opacity: 0 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 40,
      }}
    >
      <div>
        <Row>
          <Col>
            <Title className="onemaz-page-title" level={3}>
              {}
            </Title>
            <Breadcrumb
              items={[
                {
                  title: <NavLink to="/">Home</NavLink>,
                },
                {
                  title: "Settings",
                },
                {
                  title: "Feedback Channel",
                },
              ]}
            />
          </Col>
        </Row>
        <SettingsRouter />
        <Button type="primary" onClick={showCreateModal}>
          Create Status
        </Button>
        <Row style={{ marginTop: "30px" }}>
          <Col span={24}>
            <Table
              loading={loading}
              columns={columns}
              dataSource={processedData}
              scroll={{ x: "max-content" }}
              pagination={false}
              rowKey={"id"}
              sticky
            />
          </Col>
        </Row>
        {/* Create Modal */}
        <Modal
          title="Create New Status"
          open={isCreateModalVisible}
          onOk={handleCreate}
          onCancel={handleCreateModalCancel}
        >
          <Form form={form} layout="vertical">
            <Item
              name="name"
              label="Status Name"
              rules={[
                {
                  required: true,
                  message: "Status name required",
                },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Item>
          </Form>
        </Modal>
        {/* Edit Modal */}
        <Modal
          title="Edit Status"
          open={isEditModalVisible}
          onCancel={handleEditModalCancel}
          footer={[
            <Button key="back" onClick={handleEditModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() =>
                handleChangeStatus(
                  selectedStatus?.id,
                  newStatus,
                  selectedStatus?.active
                )
              }
            >
              Save
            </Button>,
          ]}
        >
          <Form form={formEditStatus} layout="vertical">
            <Item
              name="selectedSource"
              label="Status"
              rules={[
                {
                  required: true,
                  message: "Status name required",
                },
              ]}
            >
              <Input
                value={newStatus}
                onChange={(e) => setStatus(e.target.value)}
              />
            </Item>
          </Form>
        </Modal>
        {/* Delete Modal */}
        <Modal
          title="Delete Status"
          open={isDeleteModalVisible}
          onCancel={handleDeleteModalCancel}
          footer={[
            <Button key="back" onClick={handleDeleteModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => handleDeleteStatus(selectedStatus?.id)}
            >
              Delete
            </Button>,
          ]}
        >
          <p>Are you sure you want to delete this status?</p>
        </Modal>
      </div>
    </motion.div>
  );
};
