import { Col, Row } from "antd";
import "./dashboard.css";
import { useState } from "react";
import { HeaderBar } from "../components/Dashboard/HeaderBar";
import { motion } from "framer-motion";
import TotalSalesCard from "../components/Dashboard/Hi5AcmDashboard/TotalSalesCard";
import CallCard from "../components/Dashboard/Hi5AcmDashboard/CallCard";
import RetentionCard from "../components/Dashboard/Hi5AcmDashboard/RetentionCard";
import FeedbackCard from "../components/Dashboard/Hi5AcmDashboard/FeedbackCard";
import TotalCustomersCard from "../components/Dashboard/Hi5AcmDashboard/TotalCustomersCard";
import RetentionInfoCard from "../components/Dashboard/Hi5AcmDashboard/RetentionInfoCard";

export const Hi5AcmDashboard = () => {
  const [dateRange, setDateRange] = useState<any>([]);
  const roleName = localStorage.getItem("roleName");

  return (
    <div
      style={{ background: "#F0F0F0", padding: "30px" }}
      className="acm-dashboard"
    >
      <motion.div
        initial={{ y: 200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 300, opacity: 0 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 40,
        }}
        className="acm-dashboard"
      >
        <HeaderBar setDateRange={setDateRange} thisMonth={true} />

        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col sm={24} md={12} xl={8}>
            <TotalSalesCard
              title="Total Sales"
              dateRange={dateRange}
              roleName={roleName ?? ""}
            />
          </Col>
          <Col xs={24} md={12} xl={8}>
            <RetentionCard
              title="Total Retention"
              dateRange={dateRange}
              roleName={roleName ?? ""}
            />
          </Col>
          <Col xs={24} md={12} xl={8}>
            <CallCard
              title="Call Chart"
              dateRange={dateRange}
              roleName={roleName ?? ""}
            />
          </Col>
          <Col xs={24} md={12} lg={8}>
            <TotalCustomersCard
              title="Total Customers"
              dateRange={dateRange}
              roleName={roleName ?? ""}
            />
          </Col>
          <Col xs={24} md={12} lg={8}>
            <FeedbackCard
              title="Feedback Ratings"
              dateRange={dateRange}
              roleName={roleName ?? ""}
            />
          </Col>
          <Col xs={24} md={12} lg={8}>
            <RetentionInfoCard
              title="Retention Info"
              dateRange={dateRange}
              roleName={roleName ?? ""}
            />
          </Col>
        </Row>
      </motion.div>
    </div>
  );
};
