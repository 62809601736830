import { Card, Col, Row, Typography, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import TaskDetail from "../../components/Task/TaskDetail";
import TaskService from "../../services/TaskService";
import { useEffect, useState } from "react";
import { CopyClipboard } from "../General/CopyClipboard";
import moment from "moment";
import { StartCall } from "../General/StartCall";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";

const { Title } = Typography;

export const TaskList = () => {
  // eslint-disable-next-line
  const [value, setValue] = useState(() => dayjs());
  const [loading, setLoading] = useState<boolean>(false);
  const [tasks, setTasks] = useState<any[]>([]);
  const roleName = localStorage.getItem("roleName");

  const columns: ColumnsType<any> = [
    {
      title: "Customer",
      dataIndex: "id",
      key: "action",
      width: 200,
      render: (_, row) => {
        const person =
          row.learner ||
          row.customer ||
          row.prospectCustomer ||
          row.amazonSuspendedApplicant;
        return (
          <Row justify={"start"} align={"middle"}>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Title className="customer-fullname" level={5}>
                <b style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  {person?.fullName ||
                    `${person?.firstName} ${person?.lastName}`}
                </b>
              </Title>
              {person?.email && (
                <span className="customer-fullname" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  <CopyClipboard text={person.email} />
                </span>
              )}
              {(person?.phoneNumber || person?.phone) && (
                <span
                  className="customer-fullname"
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <span>{person?.phoneNumber || person?.phone} </span>{" "}
                  <StartCall text={person?.phoneNumber || person?.phone} />
                </span>
              )}
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
      width: 150,
      align: "center",
      render: (_, row) => {
        return (
          <TaskDetail item={row} onHandleTaskList={handeTaskList} type={0} />
        );
      },
    },
    {
      title: "Tag",
      dataIndex: "status",
      key: "status",
      width: 100,
      ellipsis: true,
      align: "center",
      render: (_, row) => {
        if (row.tag) {
          return (
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              #{row.tag}
            </span>
          );
        }
        return null;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      align: "center",
      ellipsis: true,
      render: (_, row) => (
        <Tag color={"orange"}>{row.status.toUpperCase()}</Tag>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      width: 110,
      align: "center",
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
      render: (_, row) => {
        return moment(row.dueDate).format("DD/MM/YYYY HH:mm");
      },
    },
  ];

  const handeTaskList = () => {
    getTasks();
  };

  const getTasks = () => {
    setLoading(true);
    const { startDueDate, endDueDate } = calculateMonthRange(value);
    const criteria = `?startDueDate=${startDueDate}&endDueDate=${endDueDate}`;
    if (
      roleName === "ADMIN" ||
      roleName === "ACCOUNT_MANAGER_MANAGER" ||
      roleName === "SUCCESS_TEAM_MANAGER" ||
      roleName === "MAS_TEAM_MANAGER" ||
      roleName === "CALL_TEAM_MANAGER" ||
      roleName === "HI5_ACM_MANAGER" ||
      roleName === "SUSPEND_TEAM_MANAGER"
    ) {
      TaskService.getAllCriteria(criteria)
        .then((response: any) => {
          let taskData = response.data;
          const todoTasks = taskData.filter(
            (task: any) => task.status === "TODO"
          );
          setTasks(todoTasks);
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      TaskService.getAllMyTask("")
        .then((response: any) => {
          let taskData = response.data;
          const todoTasks = taskData.filter(
            (task: any) => task.status === "TODO"
          );
          setTasks(todoTasks);
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getTasks();
    // eslint-disable-next-line
  }, []);

  const calculateMonthRange = (date: Dayjs) => {
    const startOfMonth = date.startOf("month").format("YYYY-MM-DDTHH:mm:ss");
    const endOfMonth = date.endOf("month").format("YYYY-MM-DDT23:59:59");
    return { startDueDate: startOfMonth, endDueDate: endOfMonth };
  };

  return (
    <Card
      className="card-customers"
      style={{ marginTop: "30px" }}
      title="Task List"
      bordered={true}
    >
      <Table
        rowKey={(record) => record.id}
        sticky
        loading={loading}
        scroll={{ x: "100%" }}
        columns={columns}
        dataSource={tasks}
        className="dashboard-table"
      />
    </Card>
  );
};
