import { Card, Row, Table, Button, Tag, Modal, Pagination, Select, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { CopyClipboard } from "../../General/CopyClipboard";
import { StartCall } from "../../General/StartCall";
import { convertDate, formatMoney, openNotification } from "../../../utils";
import CustomerService from "../../../services/CustomerService";
import { useLocation, useNavigate } from "react-router-dom";
import type { PaginationProps } from "antd";
import { FilterField } from "./FilterField";
import DownloadExcel from "../../CustomerDetail/DownloadExcel";
import { ICustomerStatusResponseData } from "../../../types/CustomerStatus";
import UserService from "../../../services/UserService";
import goldUser from "./../../../assets/goldUser.svg";
import ReportService from "../../../services/ReportService";
import MultiNote from "./MultiNote";
// import CustomerMultiTask from "./CustomerMultiTask";
import CallTeamService from "../../../services/CallTeamService";
import MultiStatus from "./MultiStatus";
import DateRange from "../../Common/DateRange";
import moment from "moment";
import MultiAssign from "./MultiAssign";
import NewTask from "../../UserDetail/Tasks/NewTask";
import StatusService from "../../../services/StatusService";
import { InfoCircleOutlined } from "@ant-design/icons";

interface User {
  id: number;
  createdDate: string;
  fullName: string;
  email: string;
  phone: string;
  currentPackage: number;
  status: any;
  activityStatus: string;
  state: string;
  passiveDays: number;
  productCount: number;
  orderCount: number;
  oneamzOrderCount: number;
  freeDays: number;
  lastSalesDate: string;
  sellerLevel: string;
  feedback: number;
  username: string;
  goldUser: boolean;
  lastLoginDate: string;
  subscriptionStartDate: string;
}

export const CustomerList: React.FC<any> = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [totalCustomerCount, setTotalCustomerCount] = useState(0);
  const roleName = localStorage.getItem("roleName");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customerStatusOptions, setCustomerStatusOptions] = useState<
    ICustomerStatusResponseData[]
  >([]);
  const [
    packageOfInterestStatusForAcmOptions,
    setPackageOfInterestStatusForAcmOptions,
  ] = useState<any[]>([]);
  const [
    currentPackageOfInterestStatusForAcmOptions,
    setCurrentPackageOfInterestStatusForAcmOptions,
  ] = useState<any[]>([]);
  const [userOptions, setUserOptions] = useState<any[]>([]);
  const [adminUserOptions, setAdminUserOptions] = useState<any[]>([]);
  // const [sortField, setSortField] = useState("createdAt");
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 20;
  const lastSortField =
    location.state?.sortField ?? "autoSubscriptionRenewalEnabled";
  const lastSortOrder = location.state?.sortOrder ?? "asc";
  const lastFilterText =
    location.state?.filterText ??
    `?page=${lastCurrentPage}&size=${lastPageSize}&sort=${lastSortField}&direction=${lastSortOrder}&state=Active`;
  const lastFormFields = location.state?.formFields ?? {};
  const [formFields, setFormFields] = useState(lastFormFields);
  const [filterText, setFilterText] = useState<string>(
    localStorage.getItem("customerFilterText") || lastFilterText
  );
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);
  const [sortField, setSortField] = useState(lastSortField);
  const [sortOrder, setSortOrder] = useState(lastSortOrder);
  const authUserId = localStorage.getItem("id");
  const [data, setData] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const isHomePage = location.pathname === "/";
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [callTeamMembers, setCallTeamMembers] = useState<any[]>([]);
  const [selectedcustomerIds, setSelectedcustomerIds] = useState<any>();
  const [dateRange, setDateRange] = useState<any>([]);
  const [triggerUpdate, setTriggerUpdate] = useState(0);
  const [inactiveGold7DaysIds, setInactiveGold7DaysIds] = useState<number[]>(
    []
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (dateRange[0] !== undefined && dateRange[1] !== undefined) {
      getReport(dateRange[0], dateRange[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    if (customerStatusOptions.length === 0) {
      getCustomerStatus();
    }
    if (packageOfInterestStatusForAcmOptions.length === 0) {
      getPackageOfInterestStatusForAcm();
    }
    if (currentPackageOfInterestStatusForAcmOptions.length === 0) {
      getCurrentPackageOfInterestStatusForAcm();
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerStatusOptions]);

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    setFilterText(newFilterText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  useEffect(() => {
    const loadFilterTextFromLocalStorage = () => {
      const storedFilterText = localStorage.getItem("customerFilterText");
      if (storedFilterText) {
        setFilterText(storedFilterText);
      }
    };
    loadFilterTextFromLocalStorage();
    // eslint-disable-next-line
  }, []);

  const onChangeSearch = (search: string) => {
    const params = new URLSearchParams(filterText);
    if (search) {
      params.set("nameOrEmailOrPhone", search);
    } else {
      params.delete("nameOrEmailOrPhone");
    }
    setCurrentPage(0);
    setPageSize(20);
    const newFilterText = "?" + params.toString();
    setFilterText(newFilterText);
  };

  const getCustomerStatus = () => {
    if (roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM") {
      StatusService.getAll("statusType=CALL_CENTER_STATUS", `&active=true`)
        .then((response: any) => {
          let data = response.data;
          const newArray = data.map((item: any) => {
            return { value: item.id, label: item.name, disabled: !item.active };
          });
          setCustomerStatusOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    } else {
      StatusService.getAll("statusType=ACM_CUSTOMER_STATUS", `&active=true`)
        .then((response: any) => {
          let data = response.data;
          const newArray = data.map((item: any) => {
            return { value: item.id, label: item.name, disabled: !item.active };
          });
          setCustomerStatusOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const getPackageOfInterestStatusForAcm = () => {
    let mainStatuses: any[] = [];
    let subStatuses: any[] = [];
    StatusService.getAll("statusType=PACKAGE_OF_INTEREST_ACM")
      .then((response: any) => {
        let data = response.data;
        data.forEach((item: any) => {
          if (item.parent === null) {
            mainStatuses.push(item);
          } else {
            subStatuses.push(item);
          }
        });

        let processedData = mainStatuses.map((mainStatus: any) => {
          return {
            ...mainStatus,
            subStatuses: subStatuses.filter(
              (subStatus: any) => subStatus.parent.id === mainStatus.id
            ),
          };
        });
        setPackageOfInterestStatusForAcmOptions(processedData);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getCurrentPackageOfInterestStatusForAcm = () => {
    let mainStatuses: any[] = [];
    let subStatuses: any[] = [];
    StatusService.getAll("statusType=CURRENT_PACKAGE_OF_INTEREST_ACM")
      .then((response: any) => {
        let data = response.data;
        data.forEach((item: any) => {
          if (item.parent === null) {
            mainStatuses.push(item);
          } else {
            subStatuses.push(item);
          }
        });

        let processedData = mainStatuses.map((mainStatus: any) => {
          return {
            ...mainStatus,
            subStatuses: subStatuses.filter(
              (subStatus: any) => subStatus.parent.id === mainStatus.id
            ),
          };
        });
        setCurrentPackageOfInterestStatusForAcmOptions(processedData);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleChangeStatus = (status: any, id: any) => {
    setIsLoading(true);
    if (roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM") {
      CallTeamService.updateCustomerStatus(id, { callCenterStatusId: status })
        .then((response: any) => {
          openNotification("success", "Success", "Changed status");
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      CustomerService.updateACMStatus(id, { statusId: status })
        .then((response: any) => {
          openNotification("success", "Success", "Changed status");
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    CustomerService.getAll(
      `?page=0&size=350&sort=autoSubscriptionRenewalEnabled&direction=asc&search=&state=Active&inactiveGold7Days=true&memberFlag=ACCOUNT_MANAGER`
    )
      .then((response: any) => {
        const ids = response.data.data.map((user: any) => user.id);
        setInactiveGold7DaysIds(ids);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }, []);

  const getUsers = () => {
    setIsLoading(true);
    if (roleName === "ACCOUNT_MANAGER_MANAGER") {
      CustomerService.getAll(`${filterText}&memberFlag=ACCOUNT_MANAGER`)
        .then((response: any) => {
          setUsers(response.data.data);
          setTotalCustomerCount(response.data.totalElements);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
      if (userOptions.length === 0) {
        UserService.getAll("?roleName=ACCOUNT_MANAGER")
          .then((response: any) => {
            let data = response;
            const newArray = data.map((item: any) => {
              let fullName = `${item.firstName} ${item.lastName}`;
              return { value: item.id, label: fullName };
            });
            setUserOptions(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
      if (callTeamMembers.length === 0) {
        UserService.getAll("?roleName=CALL_TEAM")
          .then((response: any) => {
            let data = response;
            const newArray = data.map((item: any) => {
              let fullName = `${item.firstName} ${item.lastName}`;
              return { value: item.id, label: fullName };
            });
            setCallTeamMembers(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
    } else if (roleName === "ACCOUNT_MANAGER") {
      CustomerService.getAllMe(`${filterText}&memberFlag=ACCOUNT_MANAGER`)
        .then((response: any) => {
          setUsers(response.data.data);
          setTotalCustomerCount(response.data.totalElements);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
    } else if (roleName === "CALL_TEAM_MANAGER") {
      CustomerService.getAll(`${filterText}`)
        .then((response: any) => {
          setUsers(response.data.data);
          setTotalCustomerCount(response.data.totalElements);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
      if (userOptions.length === 0) {
        UserService.getAll("?roleName=ACCOUNT_MANAGER")
          .then((response: any) => {
            let data = response;
            const newArray = data.map((item: any) => {
              let fullName = `${item.firstName} ${item.lastName}`;
              return { value: item.id, label: fullName };
            });
            setUserOptions(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
      if (callTeamMembers.length === 0) {
        UserService.getAll("?roleName=CALL_TEAM")
          .then((response: any) => {
            let data = response;
            const newArray = data.map((item: any) => {
              let fullName = `${item.firstName} ${item.lastName}`;
              return { value: item.id, label: fullName };
            });
            setCallTeamMembers(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
    } else if (roleName === "CALL_TEAM") {
      CustomerService.getAllMe(`${filterText}`)
        .then((response: any) => {
          setUsers(response.data.data);
          setTotalCustomerCount(response.data.totalElements);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
    } else {
      CustomerService.getAll(`${filterText}`)
        .then((response: any) => {
          setUsers(response.data.data);
          setTotalCustomerCount(response.data.totalElements);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
      if (userOptions.length === 0) {
        UserService.getAll("?roleName=ACCOUNT_MANAGER")
          .then((response: any) => {
            let data = response;
            const newArray = data.map((item: any) => {
              let fullName = `${item.firstName} ${item.lastName}`;
              return { value: item.id, label: fullName };
            });
            setUserOptions(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
      if (callTeamMembers.length === 0) {
        UserService.getAll("?roleName=CALL_TEAM")
          .then((response: any) => {
            let data = response;
            const newArray = data.map((item: any) => {
              let fullName = `${item.firstName} ${item.lastName}`;
              return { value: item.id, label: fullName };
            });
            setCallTeamMembers(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
      if (adminUserOptions.length === 0) {
        UserService.getAll("?roleName=ADMIN")
          .then((response: any) => {
            let data = response;
            const newArray = data.map((item: any) => {
              let fullName = `${item.firstName} ${item.lastName}`;
              return { value: item.id, label: fullName };
            });
            setAdminUserOptions(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
    }
  };

  const onRow = (record: any) => {
    let className = "";

    if (inactiveGold7DaysIds.includes(record.id)) {
      className = "highlight-row-blue";
    }

    if (!record.autoSubscriptionRenewalEnabled) {
      className = "highlight-row-red";
    } else if (
      record.autoSubscriptionRenewalEnabled &&
      !record.autoGoldSubscriptionRenewalEnabled
    ) {
      className = "highlight-row-orange";
    }

    return {
      onClick: () => onSelectRow(record),
      className,
    };
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const columns: ColumnsType<User> = [
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      fixed: "left",
      align: "center",
      sorter: true,
      width: 110,
      render: (text: string) => (
        <p style={{ fontWeight: "bolder", fontSize: 12 }}>
          {convertDate(text)}
        </p>
      ),
    },
    ...(roleName === "ACCOUNT_MANAGER_MANAGER" ||
    roleName === "ACCOUNT_MANAGER" ||
    roleName === "ADMIN"
      ? [
          {
            title: "Register Date",
            dataIndex: "registeredAt",
            key: "registeredAt",
            fixed: "left" as "left",
            align: "center" as "center",
            sorter: true,
            width: 110,
            render: (text: string) => {
              const getMonthDifference = (startDate: any) => {
                const start = new Date(startDate);
                const end = new Date();
                const months =
                  (end.getFullYear() - start.getFullYear()) * 12 +
                  (end.getMonth() - start.getMonth());
                return months;
              };
              const monthsPassed = getMonthDifference(text);
              return (
                <div
                  style={{
                    fontSize: 12,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span>{monthsPassed} months</span>
                </div>
              );
            },
          },
        ]
      : []),
    {
      title: "Customer",
      dataIndex: "fullName",
      key: "fullName",
      fixed: "left",
      sorter: true,
      width: 240,
      render: (
        text: string,
        record: {
          phone: string;
          goldUser: boolean;
          email: string;
        }
      ) => (
        <>
          {record.goldUser ? <img src={goldUser} alt="gold-icon" /> : null}
          <div>
            <CopyClipboard text={text}></CopyClipboard>{" "}
          </div>
          <span>
            <CopyClipboard text={record.email}></CopyClipboard>{" "}
          </span>
          <br />
          <span>
            <span>{record.phone}</span> <StartCall text={record.phone} />
          </span>
        </>
      ),
    },
    ...(roleName === "ACCOUNT_MANAGER_MANAGER" ||
    roleName === "ACCOUNT_MANAGER" ||
    roleName === "ADMIN"
      ? [
          {
            title: "ACM Assignment Date",
            dataIndex: "assignmentDate",
            key: "assignmentDate",
            align: "center" as "center",
            sorter: true,
            width: 110,
            render: (text: string) => (
              <p style={{ fontWeight: "bolder", fontSize: 12 }}>
                {convertDate(text)}
              </p>
            ),
          },
        ]
      : []),
    ...(roleName === "ACCOUNT_MANAGER_MANAGER" ||
    roleName === "ACCOUNT_MANAGER" ||
    roleName === "ADMIN"
      ? [
          {
            title: "ACM Last Contacted",
            dataIndex: "lastContactedAcm",
            key: "lastContactedAcm",
            align: "center" as "center",
            sorter: true,
            width: 110,
            render: (text: string, row: any) => {
              const isMoreThanSixMonths = (date: any) => {
                const sixMonthsAgo = new Date();
                sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
                return new Date(date) < sixMonthsAgo;
              };
              const days = parseInt(text);
              const isOldRegistration = isMoreThanSixMonths(row?.registeredAt);
              const threshold = isOldRegistration ? 14 : 7;
              const color = days > threshold ? "red" : "green";
              return (
                <Tag color={color} style={{ textTransform: "capitalize" }}>
                  {text} days ago
                </Tag>
              );
            },
          },
        ]
      : []),
    ...(roleName !== "CALL_TEAM_MANAGER" && roleName !== "CALL_TEAM"
      ? [
          {
            title: "Status",
            dataIndex: "acmStatus",
            key: "acmStatus",
            // sorter: true,
            width: 200,
            render: (_: any, row: any) => {
              if (roleName === "ADMIN") {
                const statusOption = customerStatusOptions.find(
                  (option: any) => option.value === row?.acmStatus?.id
                );
                return <span>{statusOption ? statusOption?.label : "-"}</span>;
              } else {
                return (
                  <Select
                    onChange={(selectedStatus) =>
                      handleChangeStatus(selectedStatus, row.id)
                    }
                    options={customerStatusOptions}
                    showSearch
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    key={row.id}
                    {...(row?.acmStatus?.id && {
                      defaultValue: row.acmStatus.id,
                    })}
                    placeholder={"Select"}
                    style={{ width: "180px" }}
                  ></Select>
                );
              }
            },
          },
        ]
      : []),
    ...(roleName === "CALL_TEAM_MANAGER" ||
    roleName === "CALL_TEAM" ||
    roleName === "ADMIN"
      ? [
          {
            title: "Call Center Status",
            dataIndex: "callCenterStatus1",
            key: "callCenterStatus1",
            // sorter: true,
            width: 200,
            render: (_: any, row: any) => {
              if (roleName === "CALL_TEAM" || roleName === "ADMIN") {
                const statusOption = customerStatusOptions.find(
                  (option: any) => option.value === row.callCenterStatus1?.id
                );
                return (
                  <span>
                    {statusOption ? statusOption?.label : "Undefined"}
                  </span>
                );
              } else {
                return (
                  <Select
                    onChange={(selectedStatus) =>
                      handleChangeStatus(selectedStatus, row?.id)
                    }
                    options={customerStatusOptions}
                    showSearch
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    key={row?.id}
                    {...(row?.callCenterStatus1?.id && {
                      defaultValue: row.callCenterStatus1?.id,
                    })}
                    placeholder={"Select"}
                    style={{ width: "180px" }}
                  ></Select>
                );
              }
            },
          },
        ]
      : []),
    ...(roleName === "ACCOUNT_MANAGER_MANAGER" ||
    roleName === "ACCOUNT_MANAGER" ||
    roleName === "ADMIN"
      ? [
          {
            title: "Package of Interest",
            dataIndex: "packageOfInterestACM",
            key: "packageOfInterestACM",
            width: 200,
            render: (_: any, row: any) => {
              if (roleName === "ADMIN") {
                const statusOption = packageOfInterestStatusForAcmOptions.find(
                  (option: any) => option.value === row.status.id
                );
                return <span>{statusOption ? statusOption?.label : "-"}</span>;
              } else {
                return (
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option && option.label && typeof option.label === "string"
                        ? option.label
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        : false
                    }
                    onChange={(selectedStatus) =>
                      handleChangePackageOfInterestStatusForAcm(
                        selectedStatus,
                        row.id
                      )
                    }
                    key={row.id}
                    defaultValue={
                      row.packageOfInterestACM && row.packageOfInterestACM.id
                    }
                    placeholder={"Select"}
                    style={{ width: "180px" }}
                  >
                    {packageOfInterestStatusForAcmOptions.map((mainStatus) => (
                      <Select.OptGroup
                        key={mainStatus.id}
                        label={mainStatus.name}
                      >
                        {mainStatus.subStatuses.map((subStatus: any) => (
                          <Select.Option
                            key={subStatus.id}
                            value={subStatus.id}
                          >
                            {subStatus.name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    ))}
                  </Select>
                );
              }
            },
          },
        ]
      : []),

    ...(roleName === "ACCOUNT_MANAGER_MANAGER" ||
    roleName === "ACCOUNT_MANAGER" ||
    roleName === "ADMIN"
      ? [
          {
            title: "Current Package of Interest",
            dataIndex: "currentPackageOfInterestACM",
            key: "currentPackageOfInterestACM",
            width: 200,
            render: (_: any, row: any) => {
              if (roleName === "ADMIN") {
                const statusOption =
                  currentPackageOfInterestStatusForAcmOptions.find(
                    (option: any) => option.value === row.status.id
                  );
                return <span>{statusOption ? statusOption?.label : "-"}</span>;
              } else {
                return (
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option && option.label && typeof option.label === "string"
                        ? option.label
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        : false
                    }
                    onChange={(selectedStatus) =>
                      handleChangeCurrentPackageOfInterestStatusForAcm(
                        selectedStatus,
                        row.id
                      )
                    }
                    key={row.id}
                    defaultValue={
                      row.currentPackageOfInterestACM &&
                      row.currentPackageOfInterestACM.id
                    }
                    placeholder={"Select"}
                    style={{ width: "180px" }}
                  >
                    {currentPackageOfInterestStatusForAcmOptions.map(
                      (mainStatus) => (
                        <Select.OptGroup
                          key={mainStatus.id}
                          label={mainStatus.name}
                        >
                          {mainStatus.subStatuses.map((subStatus: any) => (
                            <Select.Option
                              key={subStatus.id}
                              value={subStatus.id}
                            >
                              {subStatus.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      )
                    )}
                  </Select>
                );
              }
            },
          },
        ]
      : []),

    {
      title: "Current Package",
      dataIndex: "currentPackage",
      key: "currentPackage",
      sorter: true,
      width: 100,
    },
    ...(roleName === "ACCOUNT_MANAGER_MANAGER" || roleName === "ADMIN"
      ? [
          {
            title: "Account Manager",
            dataIndex: "username",
            key: "username",
            // sorter: true,
            width: 200,
            render: (_: any, row: any) => {
              if (roleName === "ADMIN") {
                return (
                  <span>
                    {row.user?.firstName} {row.user?.lastName}
                  </span>
                );
              } else {
                return (
                  <Select
                    showSearch
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={(selectedUser) =>
                      handleChangeAssigneUser(selectedUser, row.id)
                    }
                    options={userOptions}
                    key={row.id}
                    {...(row?.user?.id && { defaultValue: row.user.id })}
                    placeholder={"Select"}
                    style={{ width: "180px" }}
                  ></Select>
                );
              }
            },
            // },
          },
        ]
      : []),
    ...(roleName === "CALL_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            title: "Call Team Member",
            dataIndex: "callCenterMember",
            key: "callCenterMember",
            // sorter: true,
            width: 200,
            render: (_: any, row: any) => {
              if (roleName === "ADMIN") {
                return (
                  <span>
                    {row.callCenterMember?.firstName}{" "}
                    {row.callCenterMember?.lastName}
                  </span>
                );
              } else {
                return (
                  <Select
                    showSearch
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={(selectedUser) =>
                      handleChangeAssigneUser(selectedUser, row.id)
                    }
                    options={callTeamMembers}
                    key={row.id}
                    {...(row?.callCenterMember?.id && {
                      defaultValue: row.callCenterMember?.id,
                    })}
                    placeholder={"Select"}
                    style={{ width: "180px" }}
                  ></Select>
                );
              }
            },
          },
        ]
      : []),
    {
      title: "State",
      dataIndex: "sessionStatus",
      key: "sessionStatus",
      width: 100,
      render: (text: string) => (
        <Tag
          color={
            text === "active" ? "green" : text === "static" ? "orange" : "red"
          }
          style={{ textTransform: "capitalize" }}
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Passive Days",
      dataIndex: "passiveDays",
      key: "passiveDays",
      sorter: true,
      width: 100,
      render: (text) => <div>{text ? text : "-"}</div>,
    },
    {
      title: "Product Count",
      dataIndex: "totalProductCount",
      key: "totalProductCount",
      sorter: true,
      width: 100,
    },
    {
      title: "Order Count",
      dataIndex: "totalOrderCount",
      key: "totalOrderCount",
      sorter: true,
      width: 100,
    },
    {
      title: "Oneamz Order Count",
      dataIndex: "totalOneamzOrderCount",
      key: "totalOneamzOrderCount",
      sorter: true,
      width: 100,
    },
    {
      title: "Last Sales Date",
      dataIndex: "lastSalesDate",
      key: "lastSalesDate",
      sorter: true,
      width: 120,
      render: (text: string) => (
        <p style={{ fontWeight: "bolder", fontSize: 12 }}>
          {convertDate(text)}
        </p>
      ),
    },
    {
      title: "Seller Level",
      dataIndex: "level",
      key: "level",
      sorter: true,
      width: 100,
      render: (level: number) => {
        let color = "";
        switch (level) {
          case 0:
            color = "red";
            break;
          case 1:
            color = "volcano";
            break;
          case 2:
            color = "orange";
            break;
          case 3:
            color = "gold";
            break;
          case 4:
            color = "lime";
            break;
          case 5:
            color = "green";
            break;
          case 6:
            color = "cyan";
            break;
          default:
            color = "gray";
        }
        if (level) {
          return <Tag color={color}>LVL {level}</Tag>;
        } else {
          return <Tag color={"red"}>LVL 0</Tag>;
        }
      },
    },
    {
      title: "Feedback",
      dataIndex: "feedbackRating",
      key: "feedbackRating",
      sorter: true,
      width: 100,
      render: (text) => <div>{text ? text : "-"}</div>,
    },
    {
      title: "Subscription End Date",
      dataIndex: "subscriptionEndDate",
      key: "subscriptionEndDate",
      sorter: true,
      width: 130,
      align: "center",
      render: (text: string) => (
        <p style={{ fontWeight: "bolder", fontSize: 12 }}>
          {text ? moment(text).format("DD/MM/YYYY") : ""}
        </p>
      ),
    },
    {
      title: "Auto Subscription Renewal",
      dataIndex: "autoSubscriptionRenewalEnabled",
      key: "autoSubscriptionRenewalEnabled",
      sorter: true,
      align: "center",
      width: 120,
      render: (text: boolean, record: any) => (
        <Tag
          color={
            record.autoSubscriptionRenewalEnabled === true ? "green" : "red"
          }
        >
          {record?.autoSubscriptionRenewalEnabled === true ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "Auto Gold Subscription Renewal",
      dataIndex: "autoGoldSubscriptionRenewalEnabled",
      key: "autoGoldSubscriptionRenewalEnabled",
      sorter: true,
      align: "center",
      width: 120,
      render: (text: boolean, record: any) => (
        <Tag
          color={
            record.autoGoldSubscriptionRenewalEnabled === true ? "green" : "red"
          }
        >
          {record?.autoGoldSubscriptionRenewalEnabled === true ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "Detail",
      key: "detail",
      width: 120,
      align: "center",
      fixed: "right" as const,
      render: (_: any, record: any) => (
        <Row justify="center" align="middle" gutter={[0, 8]}>
          <Button
            type="primary"
            onClick={() => {
              let filterToSave = filterText;
              const regex = /nameOrEmailOrPhone=[^&]*/g;
              filterToSave = filterToSave.replace(regex, "");
              filterToSave = filterToSave.replace(/^&|&$/g, "");
              localStorage.setItem("customerFilterText", filterToSave);
              navigate(
                roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM"
                  ? `/users/detail/${record.id}`
                  : `/customers/detail/${record.id}`,
                {
                  state: {
                    currentPage: currentPage,
                    pageSize: pageSize,
                    formFields: formFields,
                    filterText: filterText,
                  },
                }
              );
            }}
          >
            Detail
          </Button>
          <NewTask
            customerName={record?.fullName}
            customerId={record?.id}
            setTriggerUpdate={() => {}}
            isCustomer={record?.learner === null}
            userOptions={
              roleName === "CALL_TEAM_MANAGER"
                ? callTeamMembers
                : roleName === "ADMIN"
                ? adminUserOptions
                : userOptions
            }
          />
        </Row>
      ),
    },
  ];

  const handleChangeAssigneUser = (user: any, id: any) => {
    setIsLoading(true);
    if (roleName === "CALL_TEAM_MANAGER") {
      let data: any = {
        userId: user,
        customerIds: [id],
      };
      CallTeamService.assignCustomerToMember(data)
        .then((response: any) => {
          openNotification("success", "Success", "Changed assigne user");
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      let data: any = {
        userId: user,
      };
      CustomerService.updateAssigneUser(id, data)
        .then((response: any) => {
          openNotification("success", "Success", "Changed assigne user");
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleChangePackageOfInterestStatusForAcm = (
    statusId: any,
    id: any
  ) => {
    setIsLoading(true);
    CustomerService.assignCustomerToPackageOfInterestStatusForAcm(id, {
      statusId,
    })
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          "Changed package of interest status"
        );
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeCurrentPackageOfInterestStatusForAcm = (
    statusId: any,
    id: any
  ) => {
    setIsLoading(true);
    CustomerService.assignCustomerToCurrentPackageOfInterestStatusForAcm(id, {
      statusId,
    })
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          "Changed current package of interest status"
        );
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateFilterSort = (field: string, order: any) => {
    const direction =
      order === "ascend" ? "asc" : order === "descend" ? "desc" : "";

    const sortField =
      field === "lastContactedAcm" ? "lastContactedDateAcm" : field;

    const sortParamRegex = /&sort=([^&]*)&direction=([^&]*)/;
    const sortMatch = filterText.match(sortParamRegex);

    let newFilterText = filterText;

    if (sortMatch) {
      newFilterText = newFilterText.replace(
        sortParamRegex,
        `&sort=${sortField}&direction=${direction}`
      );
    } else if (sortField && direction) {
      newFilterText = `${newFilterText}&sort=${sortField}&direction=${direction}`;
    }

    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";

    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }

    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    const field =
      sorter.field === "lastContactedAcm"
        ? "lastContactedDateAcm"
        : sorter.field;
    updateFilterSort(field, nextSortOrder);
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, sortField, sortOrder, triggerUpdate]);

  // useEffect(() => {
  // ReportService.customerEarningReport(``)
  //     .then((response) => {
  //         const combinedData: any[] = [];
  //         response.data.forEach((item: any) => {
  //             const matchedItem = authUserId == item.userId;
  //             if (matchedItem) {
  //                 combinedData.push({
  //                     ...item,
  //                     user: matchedItem ? matchedItem : null,
  //                 });
  //             }
  //         });
  //         setData(combinedData);
  //     })
  // }, []);

  const getReport = (startDate: any, endDate: any) => {
    ReportService.customerEarningReport(
      `?startDate=${startDate}&endDate=${endDate}`
    ).then((response) => {
      const combinedData: any[] = [];
      response.data.forEach((item: any) => {
        // eslint-disable-next-line
        const matchedItem = authUserId == item.user;
        if (matchedItem) {
          combinedData.push({
            ...item,
            user: matchedItem ? matchedItem : null,
          });
        }
      });

      setData(combinedData);
    });
  };

  const earningDataSource = data.map((item: any, index: any) => ({
    key: index,
    oneamzOrder: item.oneamzOrder,
    packageChangedIn30Days: item.packageChangedIn30Days,
    packageChangedIn60Days: item.packageChangedIn60Days,
    packageChangedIn90Days: item.packageChangedIn90Days,
    packageUpgrade: item.packageUpgrade,
    totalCustomer: item.totalCustomer,
  }));

  const earningColumns: ColumnsType<any> = [
    {
      title: "Total Customer",
      dataIndex: "totalCustomer",
      key: "totalCustomer",
      align: "center",
      width: 100,
    },
    {
      title: "Product Count Commission",
      dataIndex: "packageUpgrade",
      key: "packageUpgrade",
      width: 100,
      align: "center",
      render: (text: number) => <p>{formatMoney(text)}</p>,
    },
    {
      title: "30 Days First Sale Comission",
      dataIndex: "packageChangedIn30Days",
      key: "packageChangedIn30Days",
      align: "center",
      render: (text: number) => <p>{formatMoney(text)}</p>,
      width: 100,
    },
    {
      title: "60 Days First Sale Comission",
      dataIndex: "packageChangedIn60Days",
      key: "packageChangedIn60Days",
      align: "center",
      render: (text: number) => <p>{formatMoney(text)}</p>,
      width: 100,
    },
    {
      title: "90 Days First Sale Comission",
      dataIndex: "packageChangedIn90Days",
      key: "packageChangedIn9Days",
      align: "center",
      render: (text: number) => <p>{formatMoney(text)}</p>,
      width: 100,
    },
    {
      title: "OneAmz Order",
      dataIndex: "oneamzOrder",
      key: "oneamzOrder",
      align: "center",
      render: (text: number) => <p>{formatMoney(text ? text * 0.5 : 0)}</p>,
      width: 100,
    },

    {
      title: "Total Earnings",
      dataIndex: "totalEarnings",
      key: "totalEarnings",
      align: "center",
      render: (text: any, record: any) => {
        const productCountCommission = record.packageUpgrade;
        const firstSaleCommission30Days = record.packageChangedIn30Days ?? 0;
        const firstSaleCommission60Days = record.packageChangedIn60Days ?? 0;
        const firstSaleCommission90Days = record.packageChangedIn90Days ?? 0;
        const oneAmzOrderCommission = record.oneamzOrder
          ? record.oneamzOrder * 0.5
          : 0;
        const totalEarnings =
          productCountCommission +
          firstSaleCommission30Days +
          firstSaleCommission60Days +
          firstSaleCommission90Days +
          oneAmzOrderCommission;

        return (
          <p>
            {" "}
            <b>{formatMoney(totalEarnings)}</b>
          </p>
        );
      },
    },
  ];

  const onSelectRow = (record: any) => {
    const newSelectedRowKeys = selectedRowKeys.includes(record.id)
      ? selectedRowKeys.filter((key: any) => key !== record.id)
      : [...selectedRowKeys, record.id];

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: any) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: () => ({
      disabled: true,
    }),
  };

  const assingUsers = () => {
    function distributecustomerIds(customerIds: number[], teamMembers: any[]) {
      const totalMembers = teamMembers.length;
      const perMember = Math.floor(customerIds.length / totalMembers);
      const remainder = customerIds.length % totalMembers;

      const shuffledcustomerIds = customerIds.sort(() => 0.5 - Math.random());

      let startIndex = 0;
      const assignments = teamMembers.map((member, index) => {
        const idsForCurrentMember = shuffledcustomerIds.slice(
          startIndex,
          startIndex + perMember + (index < remainder ? 1 : 0)
        );
        startIndex += perMember + (index < remainder ? 1 : 0);
        return {
          userId: member.value,
          customerIds: idsForCurrentMember,
        };
      });

      return assignments;
    }

    if (selectedcustomerIds && selectedcustomerIds.length > 0) {
      const results = distributecustomerIds(
        selectedcustomerIds,
        callTeamMembers
      );
      Promise.all(
        results.map((result) => CallTeamService.assignCustomerToMember(result))
      )
        .then(() => {
          openNotification("success", "Success", "Successfully appointed");
        })
        .catch((e: Error) => {
          console.error(e.message);
        });
    } else {
      openNotification(
        "warning",
        "Warning",
        "All selected users have been previously assigned."
      );
    }
  };

  const showSendModal = () => {
    const params = new URLSearchParams(filterText);
    params.delete("page");
    params.delete("size");
    params.delete("search");
    const newFilterText = "?" + params.toString();

    CustomerService.getAllWithoutPagination(newFilterText)
      .then((response: any) => {
        const idsOfCustomersWithNullCallCenterMember = response.data
          .filter((item: any) => item.callCenterMember === null)
          .map((item: any) => item.id);

        setSelectedcustomerIds(idsOfCustomersWithNullCallCenterMember);
      })
      .catch((e: Error) => {});

    setIsModalVisible(true);
  };

  const selectAll = () => {
    if (selectedRowKeys.length === totalCustomerCount) {
      setSelectedRowKeys([]);
    } else {
      setIsLoading(true);
      if (roleName === "ACCOUNT_MANAGER_MANAGER") {
        CustomerService.getAllCustomersLite(`?${filterText}`)
          .then((response: any) => {
            let data = response.data;
            let customerIds = data.map((user: any) => user.id);
            setSelectedRowKeys(customerIds);
            setIsLoading(false);
          })
          .catch((e: Error) => {
            console.log(e);
            setIsLoading(false);
          });
      } else if (roleName === "ACCOUNT_MANAGER") {
        CustomerService.getAllCustomersLiteMe(`?${filterText}`)
          .then((response: any) => {
            let data = response.data;
            let customerIds = data.map((user: any) => user.id);
            setSelectedRowKeys(customerIds);
            setIsLoading(false);
          })
          .catch((e: Error) => {
            console.log(e);
            setIsLoading(false);
          });
      }
    }
  };

  return (
    <>
      <Card
        className="card-customers"
        title={
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              className="customer-card-title"
              style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
            >
              Customer List
              <Tooltip
                  title={
                    <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                      <li>
                        <Tag color="#b22222">
                          <span style={{ fontWeight: "bold" }}>Red:</span>{" "}
                        </Tag>
                        Auto Subscription Renewal is disabled.
                      </li>
                      <li>
                        <Tag color="#EEC73B">
                          <span style={{ fontWeight: "bold" }}>Orange:</span>
                        </Tag>
                        Auto Subscription Renewal is enabled, but either Auto Gold Subscription is disabled.
                      </li>
                      <li>
                        <Tag color="lightblue">
                          <span
                            style={{ fontWeight: "bold" }}
                          >
                            Blue:
                          </span>
                        </Tag>
                        Inactive in the last 7 days.
                      </li>
                    </ul>
                  }
                  placement="top"
                >
                  <InfoCircleOutlined
                    style={{ marginLeft: 8, color: "#EEC73B" }}
                  />
                </Tooltip>
            </div>
            <div className="customer-card-info">
              <b>{totalCustomerCount} </b>Customers{" "}
            </div>
            {roleName === "ACCOUNT_MANAGER_MANAGER" ||
            roleName === "ACCOUNT_MANAGER" ? (
              <Button
                style={{ marginTop: 10, marginLeft: 10 }}
                onClick={() => {
                  selectAll();
                }}
                type="primary"
              >
                Select All
              </Button>
            ) : null}
          </div>
        }
        extra={
          <Row style={{ minWidth: 400, display: "flex" }}>
            {isHomePage && roleName === "ACCOUNT_MANAGER" ? (
              <>
                {selectedRowKeys.length === 0 && (
                  <Button
                    type="primary"
                    onClick={showModal}
                    style={{ marginRight: 16 }}
                  >
                    Earnings
                  </Button>
                )}
                <Modal
                  title={`This Month Earnings`}
                  width={750}
                  open={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={null}
                >
                  <Row justify={"end"} style={{ marginBottom: 10 }}>
                    <DateRange onChangeDateRange={setDateRange} />
                  </Row>
                  <Table
                    dataSource={earningDataSource}
                    columns={earningColumns}
                    pagination={false}
                  />
                </Modal>
              </>
            ) : null}
            {roleName === "CALL_TEAM_MANAGER" ? (
              <>
                <Button
                  type="primary"
                  onClick={showSendModal}
                  style={{ marginLeft: 10, marginRight: 10 }}
                  icon={<UploadOutlined />}
                >
                  Send
                </Button>
                <Modal
                  title="Data Assigne"
                  open={isModalVisible}
                  onCancel={handleCancel}
                  footer={null}
                >
                  <p>
                    Selected User Count: <b>{totalCustomerCount}</b> <br />{" "}
                    <br />
                    <b> Selected Filters:</b>{" "}
                    {filterOptions.map((filter: any, index) => (
                      <span key={index}>
                        {index > 0 && ", "} <br />
                        {filter.name}:{" "}
                        {Array.isArray(filter.value)
                          ? filter.value.join(", ")
                          : filter.value}
                      </span>
                    ))}
                  </p>
                  <span>
                    <b>
                      With this process, previously assigned users will not be
                      assigned again.
                    </b>
                  </span>
                  <Button
                    type="primary"
                    onClick={assingUsers}
                    style={{ width: "100%" }}
                    icon={<UploadOutlined />}
                  >
                    Assign Users
                  </Button>
                </Modal>
              </>
            ) : null}
            {selectedRowKeys.length > 0 &&
              roleName === "ACCOUNT_MANAGER_MANAGER" && (
                <MultiAssign
                  selectedRowKeys={selectedRowKeys}
                  setTriggerUpdate={setTriggerUpdate}
                />
              )}
            {selectedRowKeys.length > 0 && (
              <MultiNote selectedRowKeys={selectedRowKeys} />
            )}
            {/* {selectedRowKeys.length > 0 && (
              <CustomerMultiTask selectedRowKeys={selectedRowKeys} />
            )} */}
            {selectedRowKeys.length > 0 && (
              <MultiStatus selectedRowKeys={selectedRowKeys} />
            )}
            <FilterField
              onChangeSearch={onChangeSearch}
              setFilterText={setFilterText}
              pageSize={pageSize}
              sortField={sortField}
              sortOrder={sortOrder}
              setFormFields={setFormFields}
              formFields={formFields}
              setFilterOptions={setFilterOptions}
            />
            {!isHomePage &&
              (roleName === "CALL_TEAM_MANAGER" ||
                roleName === "ACCOUNT_MANAGER_MANAGER" ||
                roleName === "ADMIN") && (
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={() => navigate(`/customers/sales-approval`)}
                >
                  Sales Approval
                </Button>
              )}
            <DownloadExcel filterText={filterText} />
          </Row>
        }
        bordered={true}
        style={{ marginTop: "10px" }}
      >
        <Table
          rowKey={"id"}
          sticky
          rowSelection={rowSelection}
          loading={isLoading}
          scroll={{ x: "100%" }}
          columns={columns}
          dataSource={users}
          pagination={false}
          onRow={onRow}
          // onRow={(record) => ({
          //   onClick: () => onSelectRow(record),
          //   className: !record.autoSubscriptionRenewalEnabled
          //     ? "highlight-row-red"
          //     : record.autoSubscriptionRenewalEnabled &&
          //       !record.autoGoldSubscriptionRenewalEnabled
          //     ? "highlight-row-orange"
          //     : "",
          // })}
          onChange={handleTableChange}
        />
        <Row style={{ marginTop: "30px" }} justify={"center"}>
          <Pagination
            showSizeChanger={true}
            total={totalCustomerCount}
            defaultPageSize={pageSize}
            defaultCurrent={0}
            current={currentPage + 1}
            pageSizeOptions={["10", "20", "50", "100", "250"]}
            showQuickJumper
            onChange={paginationOnChange}
          />
        </Row>
      </Card>
    </>
  );
};
