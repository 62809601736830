import { Col, Row, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";

export interface SuspendFileMember {
  memberName: string;
  [key: `countWeek${number}`]: number;
  [key: `dateWeek${number}`]: string;
}

interface MemberBasedSuspendedFileCountReportProps {
  dateRange: [string, string];
}

const MemberBasedSuspendedFileCountReport = ({
  dateRange,
}: MemberBasedSuspendedFileCountReportProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<SuspendFileMember[]>([]);
  const [dynamicColumns, setDynamicColumns] = useState<ColumnsType<SuspendFileMember>>([]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const formattedStartDate = dayjs(dateRange[0]).format("YYYY-MM-DDT00:00:00");
      const formattedEndDate = dayjs(dateRange[1]).format("YYYY-MM-DDT23:59:59");
      getMemberBasedSuspendedFileCountReport(formattedStartDate, formattedEndDate);
    }
  }, [dateRange]);

  useEffect(() => {
    if (data.length > 0) {
      const weeksCount = Object.keys(data[0])
        .filter((key) => key.startsWith("countWeek"))
        .length;

      const generatedColumns: ColumnsType<SuspendFileMember> = [
        {
          title: "Member",
          dataIndex: "memberName",
          key: "memberName",
          // fixed: "left",
          width: 200,
          ellipsis: true,
          defaultSortOrder: "ascend",
          sorter: (a, b) => a.memberName.localeCompare(b.memberName),
          render: (text: string, record: SuspendFileMember) => (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={record.memberName}
            >
              <b>{record.memberName}</b>
            </div>
          ),
        },
        ...Array.from({ length: weeksCount }).map((_, index) => ({
          title: data[0]
            ? dayjs(data[0][`dateWeek${index + 1}`]).format("DD MMM YYYY")
            : `Week ${index + 1}`,
          dataIndex: `countWeek${index + 1}`,
          key: `countWeek${index + 1}`,
          align: "center" as "center",
          width: 150,
          sorter: (a: SuspendFileMember , b: SuspendFileMember) => (a[`countWeek${index + 1}`] || 0) - (b[`countWeek${index + 1}`] || 0),
          render: (count: number | undefined) => <div>{count ?? "N/A"}</div>,
        })),
      ];

      setDynamicColumns(generatedColumns);
    }
  }, [data]);

  const getMemberBasedSuspendedFileCountReport = (startDate: string, endDate: string) => {
    setLoading(true);
    AmazonSuspendedApplicantReportsService.getSuspendFileMembers(startDate, endDate)
      .then((response) => {
        setData(response.data as unknown as SuspendFileMember[]);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
      <Col span={24}>
        <h3 style={{ marginLeft: 16 }}>Member Based Suspended File Count Report</h3>
        <Table
          loading={loading}
          scroll={{ x: "max-content" }}
          sticky
          rowKey={(record) => record.memberName}
          columns={dynamicColumns}
          dataSource={data}
          pagination={false}
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row className="summary-cell">
                  <Table.Summary.Cell index={0} colSpan={1} align="left" className="summary-cell">
                    <b>Total</b>
                  </Table.Summary.Cell>
                  {dynamicColumns.slice(1).map((column, index) => {
                    const total = pageData.reduce(
                      (sum, record) => sum + (record[`countWeek${index + 1}`] || 0),
                      0
                    );
                    return (
                      <Table.Summary.Cell
                        key={column.key}
                        index={index + 1}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{total}</b>
                      </Table.Summary.Cell>
                    );
                  })}
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default MemberBasedSuspendedFileCountReport;
